import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { layoutPropsSpec } from 'core/assets/js/lib/objectSpecs';
import { LAYOUTS } from 'core/assets/js/constants';
import { matchRoute } from 'core/assets/js/routes.jsx';
import AuthWrapper from 'core/assets/js/config/routes/AuthWrapper.jsx';
import { isReactTest } from 'core/assets/js/config/checks';
import Logger from 'core/assets/js/lib/Logger';
import ErrorBoundary from 'core/assets/js/ReactErrorView.jsx';
import LayoutPlainSolo from 'core/assets/js/layout/LayoutPlainSolo.jsx';
import LayoutDefault from 'core/assets/js/layout/LayoutDefault.jsx';
import LayoutError from 'core/assets/js/layout/LayoutError.jsx';
import LayoutNaked from 'core/assets/js/layout/LayoutNaked.jsx';
import LayoutWithHeader from 'core/assets/js/layout/LayoutWithHeader.jsx';
import AccountProvider from 'accounts/assets/js/components/AccountProvider.jsx';
import PeopleListViewTypeProvider from 'core/assets/js/components/PeopleListViewTypeProvider.jsx';
import PendingCountProvider from 'core/assets/js/components/PendingCountProvider.jsx';
import PendingInvitationsProvider from 'core/assets/js/components/PendingInvitationsProvider.jsx';
import OrganizationsProvider from 'core/assets/js/components/OrganizationsProvider.jsx';

const layouts = {
  LayoutPlainSolo,
  LayoutDefault,
  LayoutError,
  LayoutNaked,
  LayoutWithHeader,
};

export const LayoutWrapper = ({ children, layout, layoutProps, location }) => (
  <React.Fragment>
    {Object.entries(layouts).map(([layoutName, Layout]) => (
      <React.Fragment key={layoutName}>
        {layout === layoutName && (
          <Layout layoutProps={layoutProps} location={location}>
            {children}
          </Layout>
        )}
      </React.Fragment>
    ))}
  </React.Fragment>
);

LayoutWrapper.propTypes = {
  location: PropTypes.object.isRequired,
  layout: PropTypes.string,
  layoutProps: layoutPropsSpec,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

LayoutWrapper.defaultProps = {
  layout: LAYOUTS.PLAIN_SOLO,
  layoutProps: {},
  children: null,
};

class CoreRoute extends React.PureComponent {
  constructor(props) {
    super(props);
    this.logger = new Logger('coreroute');
  }

  render() {
    const { children, location } = this.props;
    const { matchedRoute, requirements, targetOrgAlias, skipOrgSwitch } = matchRoute(location);
    if (!matchedRoute) {
      return children;
    }

    const { layout, layoutProps } = matchedRoute;

    if (!layout) {
      this.logger.error('undefined Layout, using default LayoutPlainSolo');
    }

    if (!LayoutWrapper) {
      throw new Error('Cyclic dependency on LayoutWrapper');
    }

    if (isReactTest) {
      return children;
    }

    return (
      <ErrorBoundary>
        <AuthWrapper
          requirements={requirements}
          targetOrgAlias={targetOrgAlias}
          skipOrgSwitch={skipOrgSwitch}
        >
          <AccountProvider />
          <OrganizationsProvider />
          <PendingCountProvider />
          <PendingInvitationsProvider />
          <PeopleListViewTypeProvider />
          <LayoutWrapper layout={layout} layoutProps={layoutProps} location={location}>
            {children}
          </LayoutWrapper>
        </AuthWrapper>
      </ErrorBoundary>
    );
  }
}

CoreRoute.propTypes = {
  dispatch: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

CoreRoute.defaultProps = {
  children: null,
};

const mapStateToProps = () => ({});
const mapDispatchToProps = dispatch => ({ dispatch });

const ConnectedCoreRoute = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CoreRoute);

export default withRouter(ConnectedCoreRoute);
