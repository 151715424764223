/* globals File, FormData */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import {
  completeStepDS, fetchSetupStepsDS, selectSetupSteps,
} from 'accounts/assets/js/ducks/account';
import {
  orgGetStartedUrl, profileGetTaxInformationApiUrl, profileUpdateTaxInformationApiUrl,
} from 'accounts/urls';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { fetchViewDS } from 'core/assets/js/ducks/view';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import axios from 'core/assets/js/lib/tdAxios';
import { extractAddressComponent, parseAxiosErrorForFinalForm } from 'core/assets/js/lib/utils';
import UserUSTaxFilingsList from 'finance/assets/js/components/UserUSTaxFilingsList.jsx';
import { US_TAX_FORM_TYPE_LABEL, US_TAX_FORM_TYPE_VALUES } from 'finance/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import CompanyForm from 'settings/assets/js/components/CompanyForm.jsx';

const ProfileCompanyInformationTab = ({ history }) => {
  // Check if is coming from get started page
  let fromGetStarted = false;
  if (history?.location?.state?.fromGetStarted) {
    fromGetStarted = true;
  }

  const dispatch = useDispatch();

  const componentName = ProfileCompanyInformationTab.GetComponentName();

  const activeOrg = useSelector(selectActiveOrg);
  const apiUrl = profileGetTaxInformationApiUrl(activeOrg.alias);
  const { hasLoaded, item } = fetchDataHook({ componentName, url: apiUrl });

  const steps = useSelector(selectSetupSteps);

  if (!hasLoaded) {
    return <SettingsPageSkeleton />;
  }

  const {
    company,
    extras: {
      hasOrgWithoutVatSet,
      hasRaisedWorksheet,
      latestUSTaxFormRejected,
      orgsWithPendingWorksheets,
      usTaxFilings,
      usTaxForms,
    } = {},
  } = item;

  const reloadTaxInformation = () => dispatch(fetchViewDS({ componentName, url: apiUrl }));

  return (
    <>
      <CompanyForm
        fromGetStarted={fromGetStarted}
        hasOrgWithoutVatSet={!!hasOrgWithoutVatSet}
        hasRaisedWorksheet={hasRaisedWorksheet}
        initialValues={company}
        onSubmit={async values => {
          const errors = {};
          if (![true, false].includes(values.is_incorporated)) {
            errors.is_incorporated = 'Please select if you are incorporated';
          }
          const usTaxFormSelected = values.usTaxForm instanceof File;
          const companyAddressIsUS = (
            values.address && extractAddressComponent(values.address, 'country_code') === 'US'
          );
          if (values.is_incorporated) {
            if (!values.name) {
              errors.name = 'Please enter your company name';
            }
            if (!values.address) {
              errors.address = 'Please enter your company address';
            }
            if (
              activeOrg.user_us_tax_number_required
              && companyAddressIsUS
              && !values.tax_identification_number
            ) {
              errors.tax_identification_number = 'Please enter your tax identification number';
            }
          } else {
            if (![true, false].includes(values.isUSTaxpayer)) {
              errors.isUSTaxpayer = 'Please select';
            }
            if (!values.full_legal_name) {
              errors.full_legal_name = 'Please enter your full legal name';
            }
            if (
              activeOrg.user_us_tax_number_required
              && values.isUSTaxpayer
              && !values.social_security_number
            ) {
              errors.social_security_number = 'Please enter your social security number';
            }
          }
          const usTaxFormTypeSelected = US_TAX_FORM_TYPE_VALUES.includes(values.usTaxFormType);
          if (usTaxFormTypeSelected && !usTaxFormSelected) {
            errors.usTaxForm = (
              `Please select your ${US_TAX_FORM_TYPE_LABEL[values.usTaxFormType]} form PDF`
            );
          }
          if (
            !usTaxFormSelected
            && activeOrg.user_us_tax_form_required
            && (
              (values.is_incorporated && companyAddressIsUS)
              || (!values.is_incorporated && values.isUSTaxpayer)
            )
          ) {
            if (usTaxForms.length === 0) {
              errors.usTaxFormRequired = (
                'This organization requires that you upload your IRS Taxpayer Identification Form'
              );
            } else if (latestUSTaxFormRejected) {
              errors.usTaxFormRequired = (
                'This organization has rejected your latest IRS Taxpayer Identification Form, '
                + 'please upload a new one'
              );
            }
          }
          if (Object.keys(errors).length > 0) {
            return errors;
          }
          try {
            const companyDetailsStep = ((steps && steps.userSetupSteps) || []).find(step => (
              step.name === 'CompanyDetailsStep'
            ));
            const alreadyCompleted = companyDetailsStep && companyDetailsStep.completed;
            const formData = new FormData();
            Object.keys(values).forEach(key => {
              const value = values[key];
              if (
                (
                  !values.is_incorporated
                  && !values.isUSTaxpayer
                  && key === 'social_security_number'
                )
                || (
                  values.is_incorporated
                  && extractAddressComponent(values.address, 'country_code') !== 'US'
                  && key === 'tax_identification_number'
                )
                || (values.is_incorporated && key === 'full_legal_name')
                || (
                  !values.is_incorporated
                  && ['logo', 'name', 'registration_number', 'address'].includes(key)
                )
                || (!usTaxFormTypeSelected && key === 'usTaxForm')
              ) {
                return;
              }
              if (key === 'address') {
                formData.append(key, JSON.stringify(value));
                return;
              }
              formData.append(key, value);
            });
            await axios.put(profileUpdateTaxInformationApiUrl(activeOrg.alias), formData);
            if (!alreadyCompleted) {
              await completeStepDS(dispatch, activeOrg.alias, 'CompanyDetailsStep');
              history.push({
                pathname: orgGetStartedUrl(activeOrg.alias),
                state: { moveToNextStep: true },
              });
              return null;
            }
            await dispatch(fetchSetupStepsDS());
            reloadTaxInformation();
            toastr.success('Well Done!', 'Taxation details updated successfully.');
            return null;
          } catch (error) {
            return parseAxiosErrorForFinalForm(error);
          }
        }}
        reloadTaxInformation={reloadTaxInformation}
        orgsWithPendingWorksheets={orgsWithPendingWorksheets}
        usTaxForms={usTaxForms}
      />
      <UserUSTaxFilingsList usTaxFilings={usTaxFilings} />
    </>
  );
};

ProfileCompanyInformationTab.GetComponentName = () => 'ProfileCompanyInformationTab';

ProfileCompanyInformationTab.propTypes = {
  history: routerHistorySpec.isRequired,
};

export default withRouter(ProfileCompanyInformationTab);
