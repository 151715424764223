import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import { toastr } from 'react-redux-toastr';

import { projectReturnFundsDS } from 'projects/assets/js/data-services/form';
import { getIsModalOpen, getModalPayload, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, BS_SIZE } from 'core/assets/js/constants';
import ProjectFundsBreakdown from 'projects/assets/js/components/ProjectFundsBreakdown.jsx';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import InputNumberField from 'core/assets/js/components/FinalFormFields/InputNumberField.jsx';

export const MODAL_ID = 'project-return-funds';

class ProjectReturnFundsModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleModalSubmit = this.handleModalSubmit.bind(this);
  }

  handleModalClose() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  handleModalSubmit(props) {
    const { budgetToDeallocate } = props;
    const { dispatch, modalPayload, componentName } = this.props;

    return dispatch(projectReturnFundsDS(
      modalPayload.orgAlias, modalPayload.projectId, { budgetToDeallocate }, componentName,
    )).then(() => {
      this.handleModalClose();
      toastr.success('Well Done!', 'Funds were returned successfully.');
    });
  }

  render() {
    const { open, project } = this.props;
    const parsedAvailable = parseFloat(project.budgetBreakdown.available);
    if (!(project && project.budgetBreakdown)) {
      return null;
    }
    const fieldsDisabled = !project.budgetBreakdown.available
    || parsedAvailable <= 0;
    return (
      <div className="static-modal">
        <Form
          onSubmit={this.handleModalSubmit}
          render={({
            handleSubmit,
            form: { getState },
          }) => {
            const { submitting, values: { budgetToDeallocate } } = getState();
            return (
              <form onSubmit={handleSubmit}>
                <ModalSimple
                  open={open}
                  onClose={this.handleModalClose}
                  className="project-return-funds"
                  heading="Return project funds"
                  body={(
                    <div>
                      <div className="row">
                        <div className="col-12">
                          <p>
                            You can return any portion of the available project budget back
                            to the organisation owner.
                          </p>
                        </div>
                      </div>

                      <ProjectFundsBreakdown
                        className="simple my-5"
                        budgetBreakdown={project.budgetBreakdown}
                        projectCurrency={project.currency_symbol}
                      />

                      <InputNumberField
                        className={`input-${BS_SIZE.LARGE}`}
                        disabled={fieldsDisabled}
                        label="Amount to return"
                        name="budgetToDeallocate"
                      />
                    </div>
                  )}
                  footer={[
                    <TDButton
                      key={1}
                      className="float-right"
                      label="Return funds"
                      variant={BS_STYLE.PRIMARY}
                      disabled={
                        submitting || fieldsDisabled
                        || parseFloat(budgetToDeallocate) > parsedAvailable
                      }
                      onClick={handleSubmit}
                    />,
                    <TDButton
                      key={2}
                      className="mr-4 float-right"
                      label="Cancel"
                      disabled={submitting}
                      onClick={this.handleModalClose}
                    />,
                  ]}
                />
              </form>
            );
          }}
        />
      </div>
    );
  }
}

ProjectReturnFundsModal.propTypes = {
  componentName: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  modalPayload: PropTypes.object,
  open: PropTypes.bool,
  project: projectSpec.isRequired,
};

ProjectReturnFundsModal.defaultProps = {
  open: false,
  modalPayload: null,
};

const mapStateToProps = state => ({
  modalPayload: getModalPayload(state, MODAL_ID),
  open: getIsModalOpen(state, MODAL_ID),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProjectReturnFundsModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectReturnFundsModal);

export default ProjectReturnFundsModalConnected;
