import { CURRENCY } from 'core/assets/js/constants';

export const INTEGRATION_STATUS = {
  // Generic Integrations
  ACTIVE: /** @type {const} */('active'),
  DISABLED: /** @type {const} */('disabled'),
  INITIATED: /** @type {const} */('initiated'),
};

export const CODAT_LINK_STATUS = {
  READY_TO_LINK: /** @type {const} */('ready_to_link'),
  LINKED: /** @type {const} */('linked'),
  UNLINKED: /** @type {const} */('unlinked'),
  UNKNOWN: /** @type {const} */('unknown'),
};

export const CODAT_CONTRACTOR_MAPPING_STATUS = {
  OK: /** @type {const} */('mapping_ok'),
  REQUIRED: /** @type {const} */('mapping_required'),
  BROKEN: /** @type {const} */('mapping_broken'),
};

export const CODAT_INVOICE_CONFIG_STATUS = {
  CONFIGURATION_REQUIRED: /** @type {const} */('config_required'),
  CONFIGURATION_OK: /** @type {const} */('config_ok'),
};

export const CODAT_DATA_SYNC_STATUS = {
  IN_PROGRESS: /** @type {const} */('in_progress'),
  IDLE: /** @type {const} */('idle'),
  ERROR: /** @type {const} */('error'),
};

// status of the remote accounting system currency, vs
// the Organization base currency. They _should_ match
export const CODAT_REMOTE_CURRENCY_STATUS = {
  PENDING: /** @type {const} */('pending'),
  OK: /** @type {const} */('ok'),
  CONFLICT: /** @type {const} */('conflict'),
};

export const CODAT_STATUS_KEYS = {
  LINK: /** @type {const} */('link'),
  REMOTE_CURRENCY: /** @type {const} */('remote_currency'),
  CONTRACTOR_MAPPING: /** @type {const} */('contractor_mapping'),
  INVOICE_CONFIGURATION: /** @type {const} */('invoice_configuration'),
  PULL_DATA: /** @type {const} */('pull_data'),
  PUSH_DATA: /** @type {const} */('push_data'),
};

export const INTEGRATION_STATUS_LABEL = {
  [INTEGRATION_STATUS.ACTIVE]: 'Active',
  [INTEGRATION_STATUS.DISABLED]: 'Disabled',
  [INTEGRATION_STATUS.INITIATED]: 'Initiated',
};

export const INTEGRATION_STATUS_CLASS = {
  [INTEGRATION_STATUS.ACTIVE]: 'accepted',
  [INTEGRATION_STATUS.DISABLED]: 'cancelled',
  [INTEGRATION_STATUS.INITIATED]: 'pending',
};

export const INTEGRATION_TYPE = {
  CODAT_SANDBOX: /** @type {const} */('accounting-codat-sandbox'),
  DYNAMICS_365: /** @type {const} */('accounting-dynamics-365'),
  QUICKBOOKS_DESKTOP: /** @type {const} */('accounting-quickbooks-desktop'),
  QUICKBOOKS_ONLINE: /** @type {const} */('accounting-quickbooks-online'),
  QUICKBOOKS_ONLINE_SANDBOX: /** @type {const} */('accounting-quickbooks-online-sandbox'),
  XERO: /** @type {const} */('accounting-xero'),
};

export const INTEGRATION_TYPE_VALUES = Object.values(INTEGRATION_TYPE);

export const INTEGRATION_TYPE_CONFIG = {
  [INTEGRATION_TYPE.CODAT_SANDBOX]: {
    description: "Codat's own sandbox platform",
    // Overridden in the integration class, based on env
    isEnabled: false,
    isUnique: true,
    logo: 'https://static.codat.io/public/platforms/mqjo.png',
    name: 'Codat Sandbox',
    type: INTEGRATION_TYPE.CODAT_SANDBOX,
  },
  [INTEGRATION_TYPE.DYNAMICS_365]: {
    description: 'Streamline and grow your business with Dynamics 365 Business Central, a'
      + ' comprehensive business management solution for small and medium-sized businesses.',
    isEnabled: true,
    isUnique: true,
    logo: '/img/integrations/dynamics365.png',
    name: 'Dynamics 365 Business Central',
    type: INTEGRATION_TYPE.DYNAMICS_365,
  },
  [INTEGRATION_TYPE.QUICKBOOKS_DESKTOP]: {
    description: 'QuickBooks automates everyday accounting tasks so you can focus on your'
      + ' business more than your books.',
    // Disabled while we focus on cloud based integrations
    isEnabled: false,
    isUnique: true,
    logo: '/img/integrations/quickbooks-desktop.png',
    name: 'QuickBooks Desktop',
    type: INTEGRATION_TYPE.QUICKBOOKS_DESKTOP,
  },
  [INTEGRATION_TYPE.QUICKBOOKS_ONLINE]: {
    description: 'QuickBooks automates everyday accounting tasks so you can focus on your'
      + ' business more than your books.',
    isEnabled: true,
    isUnique: true,
    logo: '/img/integrations/quickbooks-online.png',
    name: 'QuickBooks Online',
    type: INTEGRATION_TYPE.QUICKBOOKS_ONLINE,
  },
  [INTEGRATION_TYPE.QUICKBOOKS_ONLINE_SANDBOX]: {
    description: 'QuickBooks automates everyday accounting tasks so you can focus on your'
      + ' business more than your books.',
    // Overridden in the integration class, based on env
    isEnabled: false,
    isUnique: true,
    logo: '/img/integrations/quickbooks-online-sandbox.png',
    name: 'QuickBooks Online Sandbox',
    type: INTEGRATION_TYPE.QUICKBOOKS_ONLINE_SANDBOX,
  },
  [INTEGRATION_TYPE.XERO]: {
    description: 'Xero online accounting software for your business connects you to your bank,'
      + ' accountant, bookkeeper, and other business apps.',
    isEnabled: true,
    isUnique: true,
    logo: '/img/integrations/xero.png',
    name: 'Xero',
    type: INTEGRATION_TYPE.XERO,
  },
};

export const CODAT_INTEGRATION_TYPES = [
  INTEGRATION_TYPE.CODAT_SANDBOX,
  INTEGRATION_TYPE.DYNAMICS_365,
  INTEGRATION_TYPE.QUICKBOOKS_DESKTOP,
  INTEGRATION_TYPE.QUICKBOOKS_ONLINE,
  INTEGRATION_TYPE.QUICKBOOKS_ONLINE_SANDBOX,
  INTEGRATION_TYPE.XERO,
];

export const INTEGRATION_DETAILS_TABS = {
  ACCOUNT_MAPPING: /** @type {const} */('account-mapping'),
  ACTIVITY: /** @type {const} */('activity'),
  SETTINGS: /** @type {const} */('settings'),
  INVOICE_MAPPING: /** @type {const} */('invoice-mapping'),
  SUPPLIER_MAPPING: /** @type {const} */('supplier-mapping'),
};

export const INTEGRATION_ACTIVITY_TABS = {
  ALL_ACTIVITY: 'all-activity',
  PENDING: 'pending',
  COMPLETED: 'completed',
  FAILED: 'failed',
};

export const INTEGRATION_SYNC_TYPE = {
  DISABLED: 'disabled',
  MANUAL: 'manual',
  AUTOMATIC: 'automatic',
};

export const INTEGRATION_SYNC_TYPE_LABEL = {
  [INTEGRATION_SYNC_TYPE.DISABLED]: 'Disabled',
  [INTEGRATION_SYNC_TYPE.MANUAL]: 'Manual',
  [INTEGRATION_SYNC_TYPE.AUTOMATIC]: 'Automatic',
};

export const INSTALLATION_FLOWS = {
  REDIRECT: /** @type {const} */('redirect'),
};

export const INTEGRATION_MAPPING_STATUSES = {
  NONE: 'no-mapping',
  AUTO: 'auto-mapped',
  CONFIRMED: 'confirmed',
  BROKEN: 'broken',
};

export const CODAT_MAPPING_STATUS = INTEGRATION_MAPPING_STATUSES;

export const CODAT_AUTO_MAPPING_CONFIDENCE = {
  HIGH: 90,
  MEDIUM: 60,
  LOW: 30,
};

export const CODAT_MAPPING_STATUS_MESSAGES = {
  [CODAT_MAPPING_STATUS.AUTO]: { text: 'Requires Confirmation', variant: 'warn' },
  [CODAT_MAPPING_STATUS.CONFIRMED]: { text: 'OK', variant: 'ok' },
  [CODAT_MAPPING_STATUS.BROKEN]: { text: 'Mapping Broken', variant: 'bad' },
};

// These define both the local (contractor/organization)
// and remote (supplier) types
export const INTEGRATION_ENTITY_TYPES = {
  CONTRACTOR: 'contractor',
  INVOICING_ENTITY: 'invoicing-entity',
  SUPPLIER: 'supplier',
};

export const CODAT_STATUSES = {
  ACTIVE: 'active',
  DELETED: 'deleted',
};

export const INTEGRATION_CONFLICT_STATUSES = {
  OPEN: 'open',
  RESOLVED: 'resolved',
  CLOSED: 'closed',
};

export const FIELD_TYPE = {
  TEXT: 'text',
  DATE: 'date',
  NUMBER: 'number',
  CUSTOM: 'custom',
};

export const SETTINGS_SECTION = {
  ACCOUNT: 'account-mapping',
  TAX_RATES: 'tax-rates',
  INVOICE: 'invoice-mapping',
  LINE_ITEMS: 'line-items-mapping',
  TRACKING_CATEGORIES: 'tracking-categories-mapping',
};

export const INTERPOLATION_INVOICE_TYPES = {
  SERVICE_ORDERS: 'service_order',
  LICENCE_FEES: 'licence',
};

export const INTERPOLATION_INVOICE_TYPES_LABELS = {
  [INTERPOLATION_INVOICE_TYPES.SERVICE_ORDERS]: 'Service Order Invoice',
  [INTERPOLATION_INVOICE_TYPES.LICENCE_FEES]: 'Licence Fee Invoice',
};

export const INTERPOLATION_ITEM_TYPES = {
  WORKSHEET: 'worksheet',
  EXPENSE: 'expense',
  PROFORMA: 'proforma',
  LICENCE_FEES: 'licence',
  PROCESSING_FEES: 'processing',
  SALES_TAX_RECHARGE: 'sales_tax_recharge',
  CHILD_BANK_FEES: 'child_bank',
};

export const INTERPOLATION_ITEM_TYPES_VALUES = Object.values(INTERPOLATION_ITEM_TYPES);

export const INTERPOLATION_ITEM_TYPES_LABELS = {
  [INTERPOLATION_ITEM_TYPES.WORKSHEET]: 'Worksheet',
  [INTERPOLATION_ITEM_TYPES.EXPENSE]: 'Expense',
  [INTERPOLATION_ITEM_TYPES.PROFORMA]: 'Proforma Invoice',
  [INTERPOLATION_ITEM_TYPES.LICENCE_FEES]: 'Licence Fee',
  [INTERPOLATION_ITEM_TYPES.PROCESSING_FEES]: 'Payment Processing Fee',
  [INTERPOLATION_ITEM_TYPES.SALES_TAX_RECHARGE]: 'Sales Tax Recharge',
  [INTERPOLATION_ITEM_TYPES.CHILD_BANK_FEES]: 'Bank Charges (Legacy)',
};

export const INTERPOLATION_ITEM_FIELDS = {
  DESCRIPTION: 'description',
  ACCOUNT_REF: 'account_ref',
};

export const INTERPOLATION_INVOICE_FIELDS = {
  REFERENCE: 'reference',
  NOTE: 'note',
};

export const TAX_RATE_FIELD_PREFIX = 'tax_rate:';

// @TODO: there should be an inversion of control here.
// which field goes where should be controlled by the UI_FIELDS_DEFINITION
// on the back end, and sent to the frontend, not hardcoded on the front end
// meaning we have to keep it in sync and up to date when adding fields.
export const FIELDS_PER_SETTINGS_SECTION = {
  [SETTINGS_SECTION.ACCOUNT]: [
    `item:${INTERPOLATION_ITEM_TYPES.WORKSHEET}:${INTERPOLATION_ITEM_FIELDS.ACCOUNT_REF}`,
    `item:${INTERPOLATION_ITEM_TYPES.EXPENSE}:${INTERPOLATION_ITEM_FIELDS.ACCOUNT_REF}`,
    `item:${INTERPOLATION_ITEM_TYPES.PROFORMA}:${INTERPOLATION_ITEM_FIELDS.ACCOUNT_REF}`,
    `item:${INTERPOLATION_ITEM_TYPES.LICENCE_FEES}:${INTERPOLATION_ITEM_FIELDS.ACCOUNT_REF}`,
    `item:${INTERPOLATION_ITEM_TYPES.PROCESSING_FEES}:${INTERPOLATION_ITEM_FIELDS.ACCOUNT_REF}`,
    `item:${INTERPOLATION_ITEM_TYPES.CHILD_BANK_FEES}:${INTERPOLATION_ITEM_FIELDS.ACCOUNT_REF}`,
    `item:${INTERPOLATION_ITEM_TYPES.SALES_TAX_RECHARGE}:${INTERPOLATION_ITEM_FIELDS.ACCOUNT_REF}`,
  ],
  [SETTINGS_SECTION.TAX_RATES]: [],
  [SETTINGS_SECTION.INVOICE]: [
    `invoice:${INTERPOLATION_INVOICE_TYPES.SERVICE_ORDERS}:${INTERPOLATION_INVOICE_FIELDS.REFERENCE}`,
    `invoice:${INTERPOLATION_INVOICE_TYPES.SERVICE_ORDERS}:${INTERPOLATION_INVOICE_FIELDS.NOTE}`,
    `invoice:${INTERPOLATION_ITEM_TYPES.LICENCE_FEES}:${INTERPOLATION_INVOICE_FIELDS.REFERENCE}`,
    `invoice:${INTERPOLATION_ITEM_TYPES.LICENCE_FEES}:${INTERPOLATION_INVOICE_FIELDS.NOTE}`,
  ],
  [SETTINGS_SECTION.LINE_ITEMS]: [
    `item:${INTERPOLATION_ITEM_TYPES.WORKSHEET}:${INTERPOLATION_ITEM_FIELDS.DESCRIPTION}`,
    `item:${INTERPOLATION_ITEM_TYPES.EXPENSE}:${INTERPOLATION_ITEM_FIELDS.DESCRIPTION}`,
    `item:${INTERPOLATION_ITEM_TYPES.PROFORMA}:${INTERPOLATION_ITEM_FIELDS.DESCRIPTION}`,
    `item:${INTERPOLATION_ITEM_TYPES.LICENCE_FEES}:${INTERPOLATION_ITEM_FIELDS.DESCRIPTION}`,
    `item:${INTERPOLATION_ITEM_TYPES.PROCESSING_FEES}:${INTERPOLATION_ITEM_FIELDS.DESCRIPTION}`,
    `item:${INTERPOLATION_ITEM_TYPES.CHILD_BANK_FEES}:${INTERPOLATION_ITEM_FIELDS.DESCRIPTION}`,
    `item:${INTERPOLATION_ITEM_TYPES.SALES_TAX_RECHARGE}:${INTERPOLATION_ITEM_FIELDS.DESCRIPTION}`,
  ],
  [SETTINGS_SECTION.TRACKING_CATEGORIES]: [],
};

export const INTEGRATION_SYNC_JOB_STATUS = {
  INITIATED: 'initiated',
  COMPLETED: 'completed',
  ERRORED: 'error',
};

export const INTEGRATION_SYNC_JOB_STATUS_LABEL = {
  [INTEGRATION_SYNC_JOB_STATUS.INITIATED]: 'In Progress',
  [INTEGRATION_SYNC_JOB_STATUS.COMPLETED]: 'Completed',
  [INTEGRATION_SYNC_JOB_STATUS.ERRORED]: 'Failed',
};

export const INTEGRATION_SYNC_JOB_STATUS_CSS_CLASS = {
  [INTEGRATION_SYNC_JOB_STATUS.INITIATED]: 'pending',
  [INTEGRATION_SYNC_JOB_STATUS.COMPLETED]: 'accepted',
  [INTEGRATION_SYNC_JOB_STATUS.ERRORED]: 'rejected',
};

export const INTEGRATION_ERROR_TYPE = {
  // These are Codat Errors: https://docs.codat.io/docs/sync-status
  // Fetching: The data is currently being pulled from the integration
  FETCH_ERROR: 'FetchError',
  // Mapping: The data is being converted into Codat's standard model
  MAP_ERROR: 'MapError',
  // ???
  INTERNAL_ERROR: 'InternalError',
  // Processing: The data is being stored into Codat's cache
  PROCESSING_ERROR: 'ProcessingError',
  // Validating: The data is being checked for consistency and correctness
  VALIDATION_ERROR: 'ValidationError',
  /*
    The authentication to the data source has expired. This usually means you will need to relink,
    by getting the end user to follow the data connections's linkUrl to re-enter their credentials.
  */
  AUTH_ERROR: 'AuthError',
  // ???
  CANCELLED: 'Cancelled',
  /*
    The integration does not support the datatype that was requested. For example, Clearbooks does
    not support pulling the Profit and Loss Report.
  */
  UNSUPPORTED: 'NotSupported',
};

export const INTEGRATION_ERROR_LABEL = {
  [INTEGRATION_ERROR_TYPE.FETCH_ERROR]: 'Communication between TalentDesk and the integration was unsuccessful',
  [INTEGRATION_ERROR_TYPE.MAP_ERROR]: 'There is an error in the mapping',
  [INTEGRATION_ERROR_TYPE.INTERNAL_ERROR]: 'An error occurred on the integration side',
  [INTEGRATION_ERROR_TYPE.PROCESSING_ERROR]: 'There was an error while processing your data',
  [INTEGRATION_ERROR_TYPE.VALIDATION_ERROR]: 'There was an error while processing your data',
  [INTEGRATION_ERROR_TYPE.AUTH_ERROR]: 'We couldn’t authenticate ourselves in the integration provider',
  [INTEGRATION_ERROR_TYPE.CANCELLED]: 'The operation was cancelled',
  [INTEGRATION_ERROR_TYPE.UNSUPPORTED]: 'The operation is unsupported',
};

export const CODAT_TRACKING_CATEGORY_STATUS = {
  ACTIVE: 'Active',
  ARCHIVED: 'Archived',
  UNKNOWN: 'Unknown',
};

// allow "type" field for accounts in Codat.
export const CODAT_ACCOUNT_TYPE = {
  UNKNOWN: /** @type {const} */('Unknown'),
  ASSET: /** @type {const} */('Asset'),
  EXPENSE: /** @type {const} */('Expense'),
  INCOME: /** @type {const} */('Income'),
  LIABILITY: /** @type {const} */('Liability'),
  EQUITY: /** @type {const} */('Equity'),
};

export const CODAT_ACCOUNT_STATUS = {
  UNKNOWN: /** @type {const} */('Unknown'),
  ACTIVE: /** @type {const} */('Active'),
  ARCHIVED: /** @type {const} */('Archived'),
  PENDING: /** @type {const} */('Pending'),
};

export const INTEGRATION_CANNOT_SYNC_REASON = {
  INTEGRATION_NOT_ACTIVE: 1,
  INTEGRATION_SYNC_DISABLED: 2,
};

export const INTEGRATION_CANNOT_SYNC_REASON_LABEL = {
  [INTEGRATION_CANNOT_SYNC_REASON.INTEGRATION_NOT_ACTIVE]: 'The integration is not active.',
  [INTEGRATION_CANNOT_SYNC_REASON.INTEGRATION_SYNC_DISABLED]: "The integration's sync setting is disabled.",
};

export const INTEGRATION_CANNOT_BE_ADDED_REASON = {
  CODAT_INTEGRATION_ORG_NOT_VIA: 1,
  CODAT_INTEGRATION_ORG_NOT_ENABLED: 2,
};

export const INTEGRATION_CANNOT_BE_ADDED_REASON_DESCRIPTION = {
  [INTEGRATION_CANNOT_BE_ADDED_REASON.CODAT_INTEGRATION_ORG_NOT_VIA]: 'Not supported by your payment mode',
  [INTEGRATION_CANNOT_BE_ADDED_REASON.CODAT_INTEGRATION_ORG_NOT_ENABLED]: 'Your organization does'
    + ' not have accounting integrations enabled. Please contact us to change this.',
};

export const INTEGRATION_AVAILABILITY_STATUS = {
  COMING_SOON: 'coming-soon',
  BETA: 'beta',
  ACTIVE: 'active',
};

export const INTEGRATION_AVAILABILITY_STATUS_VALUES = Object.values(
  INTEGRATION_AVAILABILITY_STATUS,
);

export const INTEGRATION_AVAILABILITY_STATUS_LABEL = {
  [INTEGRATION_AVAILABILITY_STATUS.COMING_SOON]: 'Coming soon',
  [INTEGRATION_AVAILABILITY_STATUS.BETA]: 'Beta',
  [INTEGRATION_AVAILABILITY_STATUS.ACTIVE]: 'Active',
};

export const INTEGRATION_AVAILABILITY_STATUS_CLASS = {
  [INTEGRATION_AVAILABILITY_STATUS.COMING_SOON]: 'pending',
  [INTEGRATION_AVAILABILITY_STATUS.BETA]: 'posted',
  [INTEGRATION_AVAILABILITY_STATUS.ACTIVE]: 'approved',
};

/**
 * This curl gets a list of the "internal" identifiers that Codat uses for its
 * platform types. We need to use these when requesting a specific type.
 *
 * curl --request GET \
 *  --url 'https://api.codat.io/integrations' \
 *   --header 'Accept: application/json' \
 *   --header 'Authorization: Basic ${BASE64_API_KEY}' \
 * | jq '[.results[] | select(.sourceType == "Accounting") | { name: .name, key: .key }]'
 *
 * Here we make an Enum with them that we can use internally.
 * These are a subset that we need at the moment.
 */
export const CODAT_PLATFORM_KEYS = {
  [INTEGRATION_TYPE.CODAT_SANDBOX]: 'mqjo',
  [INTEGRATION_TYPE.DYNAMICS_365]: 'trji',
  [INTEGRATION_TYPE.QUICKBOOKS_DESKTOP]: 'pqsw',
  [INTEGRATION_TYPE.QUICKBOOKS_ONLINE]: 'qhyg',
  [INTEGRATION_TYPE.QUICKBOOKS_ONLINE_SANDBOX]: 'ndsk',
  [INTEGRATION_TYPE.XERO]: 'gbol',
  // ORACLE_NETSUITE: 'akxx'
};

// These are weird string values, that must be matched exactly.
export const CODAT_WEBHOOK_TYPES = {
  DATA_CONNECTION_STATUS_CHANGED: /** @type {const} */('DataConnectionStatusChanged'),
  DATA_SYNC_COMPLETED: /** @type {const} */('Data sync completed'),
  NEW_COMPANY_SYNCHRONISED: /** @type {const} */('New company synchronised'),
  DATA_SYNC_ERROR: /** @type {const} */('Data Sync Status Changed To Error'),
  PUSH_OPERATION_STATUS_CHANGE: /** @type {const} */('Push Operation Status Changed()'),
  PUSH_OPERATION_TIMEOUT: /** @type {const} */('Push Operation Timed Out'),
  DATASET_DATA_CHANGED: /** @type {const} */('Dataset data changed'),
};

// the known values for Data Connection Status field
export const CODAT_KNOWN_CONNECTION_STATUS = {
  PENDING_AUTH: /** @type {const} */('PendingAuth'),
  LINKED: /** @type {const} */('Linked'),
  UNLINKED: /** @type {const} */('Unlinked'),
};

export const CODAT_SUPPLIER_STATUS = {
  UNKNOWN: /** @type {const} */('Unknown'),
  ACTIVE: /** @type {const} */('Active'),
  ARCHIVED: /** @type {const} */('Archived'),
};

// the status of a push bill (e.g. an Invoice from our side)

export const CODAT_BILL_STATUS = {
  DRAFT: /** @type {const} */ ('Draft'),
  OPEN: /** @type {const} */ ('Open'),
  PARTIALLY_PAID: /** @type {const} */ ('PartiallyPaid'),
  PAID: /** @type {const} */ ('Paid'),
  VOID: /** @type {const} */ ('Void'),
  UNKNOWN: /** @type {const} */ ('Unknown'),
};

// allow "type" field for addresses in Codat.
export const CODAT_ADDRESS_TYPE = {
  UNKNOWN: /** @type {const} */('Unknown'),
  BILLING: /** @type {const} */('Billing'),
  DELIVERY: /** @type {const} */('Delivery'),
};

export const CODAT_PUSH_OPERATION_STATUS = {
  PENDING: /** @type {const} */('Pending'),
  FAILED: /** @type {const} */('Failed'),
  SUCCESS: /** @type {const} */('Success'),
  TIMEOUT: /** @type {const} */('Timeout'),
};

// Data types used for push and pull
export const CODAT_DATA_TYPE = {
  SUPPLIERS: /** @type {const} */('suppliers'),
  CATEGORIES: /** @type {const} */('trackingCategories'),
  INVOICES: /** @type {const} */('bills'),
  ACCOUNTS: /** @type {const} */('chartOfAccounts'),
  COMPANY: /** @type {const} */('company'),
  TAX_RATES: /** @type {const} */('taxRates'),
};

export const CODAT_DATA_TYPE_LABELS = {
  [CODAT_DATA_TYPE.SUPPLIERS]: 'Suppliers',
  [CODAT_DATA_TYPE.CATEGORIES]: 'Tracking categories',
  [CODAT_DATA_TYPE.INVOICES]: 'Bills',
  [CODAT_DATA_TYPE.ACCOUNTS]: 'Chart of accounts',
  [CODAT_DATA_TYPE.COMPANY]: 'Company',
  [CODAT_DATA_TYPE.TAX_RATES]: 'Tax rates',
};

// these are the keys in the data base we use to identify these specific
// sync jobs.
// @see CodatSyncJob model virtual field `is_push_job`
// All push jobs should start `push:` and all pull jobs `pull:`
export const CODAT_DATA_SYNC_JOB_TYPES = {
  [CODAT_DATA_TYPE.SUPPLIERS]: /** @type {const} */('pull:suppliers'),
  [CODAT_DATA_TYPE.CATEGORIES]: /** @type {const} */('pull:tracking_categories'),
  [CODAT_DATA_TYPE.ACCOUNTS]: /** @type {const} */('pull:accounts'),
  [CODAT_DATA_TYPE.COMPANY]: /** @type {const} */('pull:company'),
  [CODAT_DATA_TYPE.TAX_RATES]: /** @type {const} */('pull:tax_rates'),
};
export const CODAT_DATA_PUSH_JOB_TYPES = {
  [CODAT_DATA_TYPE.SUPPLIERS]: /** @type {const} */('push:suppliers'),
  [CODAT_DATA_TYPE.INVOICES]: /** @type {const} */('push:bills'),
};

// the codat API defaults to 100, but we may want to tweak
export const CODAT_DATA_SYNC_PAGE_SIZE = {
  [CODAT_DATA_TYPE.SUPPLIERS]: /** @type {const} */(100),
  [CODAT_DATA_TYPE.CATEGORIES]: /** @type {const} */(100),
  [CODAT_DATA_TYPE.ACCOUNTS]: /** @type {const} */(100),
  [CODAT_DATA_TYPE.TAX_RATES]: /** @type {const} */(100),
};

// the sync jobs start initiated and can change to complete or error
export const CODAT_SYNC_JOB_STATUS = INTEGRATION_SYNC_JOB_STATUS;

export const CODAT_ORGANIZATION_MAPPING_CURRENCY = CURRENCY.USD;
