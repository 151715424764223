import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useParams } from 'react-router';
import { isEmail } from 'validator';

import DocumentAssignmentsListItem from 'core/assets/js/components/DocumentAssignmentsListItem.jsx';
import DocumentSelectField from 'core/assets/js/components/FinalFormFields/DocumentSelectField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_STYLE, MODAL_SIZES } from 'core/assets/js/constants';
import { getIsModalOpen, modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { fetchViewDS, viewUpdateKeysAC } from 'core/assets/js/ducks/view';
import axios from 'core/assets/js/lib/tdAxios';
import { parseAxiosErrorForFinalForm } from 'core/assets/js/lib/utils';
import { DOCUMENT_ASSIGNMENT_STATUS } from 'documents/assets/js/constants';
import { documentOptionsApiUrl, documentRequestSignaturesApiUrl } from 'documents/urls';
import { fetchManagerDS } from 'people/assets/js/ducks/managers';
import { fetchProviderDS } from 'people/assets/js/ducks/providers';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import { getProfileOnboardingInfo } from 'people/urls';

const MODAL_ID = 'profile-onboarding-add-contract-modal';

const ProfileOnboardingWidgets = ({ parentComponentName }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const componentName = ProfileOnboardingWidgets.GetComponentName(params);
  const fetchDataUrl = getProfileOnboardingInfo(params.orgAlias, params.userId);
  const { hasLoaded, item: { documentAssignments } = {} } = fetchDataHook({
    componentName, url: fetchDataUrl,
  });
  const isModalOpen = useSelector(state => getIsModalOpen(state, MODAL_ID));
  const [submitting, setSubmitting] = useState(false);
  const onClose = () => dispatch(modalCloseAC());
  const [documentOptions, setDocumentOptions] = useState([]);

  const formRef = useRef(null);

  useEffect(() => {
    if (isModalOpen && formRef.current) {
      // unset if the modal is opened, so the Form's render will set it to the correct
      // form instance
      formRef.current = null;
    }
  }, [isModalOpen]);

  useEffect(() => {
    axios
      .get(documentOptionsApiUrl(params.orgAlias, true))
      .then(({ data }) => {
        setDocumentOptions(data);
      })
      .catch(err => {
        toastr.error('Oh Snap!', err.response?.data?._error || err.message);
      });
  }, []);

  if (!hasLoaded) {
    return null;
  }

  const reloadAssignments = () => dispatch(fetchViewDS({ componentName, url: fetchDataUrl }));
  const reloadProfile = () => {
    const fetchDS = params.userType === PEOPLE_TYPE.PROVIDERS ? fetchProviderDS : fetchManagerDS;
    dispatch(fetchDS({ componentName: parentComponentName, params }));
  };

  return (
    <div className="col-12 mt-5">
      {documentAssignments.some(da => da.status === DOCUMENT_ASSIGNMENT_STATUS.PENDING) && (
        <TDSystemMessage
          className="mb-5"
          title="Pending contract template signing!"
          type={BS_STYLE.WARNING}
        >
          Provider has not signed contract templates yet.
        </TDSystemMessage>
      )}
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between">
          Required contracts
          <TDButton
            type="button"
            variant={BS_STYLE.PRIMARY}
            onClick={() => dispatch(modalOpenAC(MODAL_ID))}
            label="Add contract"
          />
        </Card.Header>
        <TDList
          cardItem={{
            // eslint-disable-next-line react/prop-types, react/no-multi-comp
            component: ({ item }) => (
              <DocumentAssignmentsListItem
                documentAssignment={item}
                onUpdated={updatedAssignment => {
                  const newDocumentAssignments = [...documentAssignments];
                  const index = newDocumentAssignments.findIndex(a => a.id === item.id);
                  if (index === -1) {
                    return;
                  }
                  newDocumentAssignments[index] = updatedAssignment;
                  dispatch(viewUpdateKeysAC(
                    { documentAssignments: newDocumentAssignments },
                    ['documentAssignments'],
                    componentName,
                  ));
                  reloadProfile();
                }}
              />
            ),
          }}
          emptyListMessage="There aren't any document assignments."
          items={documentAssignments}
          listClassName="profile-documents-list m-3"
        />
      </Card>
      <ModalConfirm
        closeOnConfirm={false}
        confirmButtonDisabled={submitting}
        confirmLabel="Add"
        heading="Add contract"
        onClose={onClose}
        onConfirm={() => formRef.current.submit()}
        open={isModalOpen}
        size={MODAL_SIZES.LARGE}
      >
        <Form
          // eslint-disable-next-line consistent-return
          onSubmit={async ({ counterSignerEmails, documentId }) => {
            if (!documentId) {
              return { documentId: 'Please select a document' };
            }
            const document = documentOptions.find(o => o.value === documentId);
            if (!document) {
              return { documentId: 'Please select a document' };
            }
            if (typeof document.countersignersCount === 'number') {
              const errors = {};
              new Array(document.countersignersCount).fill('').forEach((_, index) => {
                if (!Array.isArray(counterSignerEmails) || !isEmail(counterSignerEmails[index])) {
                  if (!errors.counterSignerEmails) {
                    errors.counterSignerEmails = {};
                  }
                  errors.counterSignerEmails[index] = 'Please enter a valid countersigner email';
                }
              });
              if (Object.keys(errors).length > 0) {
                return errors;
              }
            }
            setSubmitting(true);
            try {
              const { data: { count } } = await axios.post(
                documentRequestSignaturesApiUrl(params.orgAlias, documentId),
                { blocking: 1, counterSignerEmails, userIds: [params.userId] },
              );
              if (count === 0) {
                toastr.warning(
                  'None requested', 'This user already has a pending signature for that contract',
                );
              } else {
                toastr.success(
                  'Well Done!', "Successfully added the contract to the user's onboarding",
                );
                reloadAssignments();
                reloadProfile();
              }
              onClose();
              setSubmitting(false);
            } catch (err) {
              setSubmitting(false);
              return parseAxiosErrorForFinalForm(err);
            }
          }}
          render={({ form, handleSubmit, submitError }) => {
            if (!formRef.current) {
              // this is a bit hacky, but as of react-final-form v6, using `<Form ref` is
              // broken https://github.com/final-form/react-final-form/issues/483
              formRef.current = form;
            }
            const { documentId } = form.getState().values;
            const document = documentId && documentOptions.find(o => o.value === documentId);
            return (
              <form onSubmit={handleSubmit}>
                <DocumentSelectField
                  allowCountersigners
                  isMulti={false}
                  name="documentId"
                  showSelected
                  sublabel={`Do you require invited team members to review and agree to any legal
                    documents before obtaining access to the organization?`}
                />
                {typeof document?.countersignersCount === 'number' && (
                  new Array(document.countersignersCount).fill('').map((_, index) => (
                    <TextInputField
                      key={index}
                      label={`Countersigner ${index + 1}`}
                      name={`counterSignerEmails[${index}]`}
                      required
                      type="email"
                    />
                  ))
                )}
                {submitError && <div className="clearfix mt-3 text-danger">{submitError}</div>}
              </form>
            );
          }}
        />
      </ModalConfirm>
    </div>
  );
};

ProfileOnboardingWidgets.GetComponentName = () => 'ProfileOnboardingWidgets';

ProfileOnboardingWidgets.propTypes = {
  parentComponentName: PropTypes.string.isRequired,
};

export default ProfileOnboardingWidgets;
