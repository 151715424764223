import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import ModalConfirmFinalForm from 'core/assets/js/components/ModalConfirmFinalForm.jsx';
import { modalCloseAC, getIsModalOpen, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import { parseAxiosErrorForFinalForm } from 'core/assets/js/lib/utils';
import { reOpenTaskAssignmentDS } from 'projects/assets/js/data-services/tasks';
import { projectTaskSpec } from 'projects/assets/js/lib/objectSpecs';

export const MODAL_ID = 'task-re-open-modal';

const TaskReOpenModal = ({ componentName, task }) => {
  const isOpen = useSelector(state => getIsModalOpen(state, MODAL_ID));
  const { assignment } = useSelector(state => getModalPayload(state, MODAL_ID)) || {};
  const { orgAlias, id: projectId, taskId } = useParams();
  const dispatch = useDispatch();

  if (!assignment?.allowedActions?.canReOpen) {
    return null;
  }

  const onClose = () => dispatch(modalCloseAC());

  return (
    <ModalConfirmFinalForm
      confirmLabel="Re-open"
      FormContentComponent={({ renderProps }) => {
        const { submitError, submitting } = renderProps;
        return (
          <>
            <p>
              {`Are you sure you want to re-open the "${task.title}" assignment for `}
              {`${assignment.user.profile.name}?`}
            </p>
            <p>If the task is currently completed, it will be set back to in progress.</p>
            <TextAreaField
              disabled={submitting}
              label="Message"
              name="message"
              sublabel="Optional reason why you are re-opening this assignment"
            />
            {submitError && <div className="clearfix mt-3 text-danger">{submitError}</div>}
          </>
        );
      }}
      formProps={{
        onSubmit: async values => { // eslint-disable-line consistent-return
          try {
            await dispatch(reOpenTaskAssignmentDS({
              componentName, orgAlias, participantId: assignment.user.id, projectId, taskId, values,
            }));
            onClose();
          } catch (err) {
            return parseAxiosErrorForFinalForm(err);
          }
        },
      }}
      heading="Do you want to re-open this assignment?"
      onClose={onClose}
      open={isOpen}
    />
  );
};

TaskReOpenModal.propTypes = {
  componentName: PropTypes.string.isRequired,
  task: projectTaskSpec.isRequired,
};

export default TaskReOpenModal;
