import PropTypes from 'prop-types';
import React from 'react';

import SkillList from 'core/assets/js/components/SkillList.jsx';
import { ICON } from 'core/assets/js/constants';
import { pluralize } from 'core/assets/js/lib/utils';
import { SKILL_IDS_MATCH } from 'skills/assets/js/constants';
import { skillSpec } from 'skills/assets/js/lib/objectSpecs';

const AddProvidersSkillsQuickFilter = ({ formRef, handleQueryUpdate, isTask, query, skills }) => {
  const skillsLength = skills.length;
  if (
    skillsLength === 0
    // Don't show if the filters match OR with the project/task skills
    || (
      query.skillIdsMatch === SKILL_IDS_MATCH.OR
      && Array.isArray(query.skillIds)
      && skills.map(s => s.id).every(skillId => query.skillIds.includes(skillId))
    )
  ) {
    return null;
  }
  return (
    <div
      className={[
        'd-flex flex-column flex-lg-row align-items-start align-items-lg-center',
        'add-members-skills',
      ].join(' ')}
    >
      <span className="mr-4 mb-4">
        <b>{skillsLength}</b>
        {` ${pluralize('skill', skillsLength)} required for this ${isTask ? 'task' : 'project'}:`}
      </span>
      <SkillList
        collapsible
        inline
        maxShownItems={3}
        skills={skills}
        wrapperClassName="mb-4"
      />
      <span
        className="imitate-link ml-0 ml-lg-5 mb-4"
        onClick={() => {
          handleQueryUpdate({
            skillIds: skills.map(s => s.id), skillIdsMatch: SKILL_IDS_MATCH.OR,
          });
          if (formRef.current) {
            const { change } = formRef.current;
            change('skillIds', skills.map(s => ({ label: s.label, value: s.id })));
            change('skillIdsMatch', SKILL_IDS_MATCH.OR);
          }
        }}
      >
        <i className={`${ICON.FILTERS} mr-3`} />
        Apply to filters
      </span>
    </div>
  );
};

AddProvidersSkillsQuickFilter.propTypes = {
  formRef: PropTypes.object.isRequired,
  handleQueryUpdate: PropTypes.func.isRequired,
  isTask: PropTypes.bool,
  onApply: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  skills: PropTypes.arrayOf(skillSpec),
};

AddProvidersSkillsQuickFilter.defaultProps = {
  isTask: false,
  skills: [],
};

export default AddProvidersSkillsQuickFilter;
