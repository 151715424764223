import moment from 'moment';
import { isEqual } from 'lodash';
import cronParser from 'cron-parser';
import { getDatetime } from 'core/assets/js/lib/utils';
import { validateCronString } from 'finance/assets/js/lib/utils';

/**
 * Parses a date (string, Date, etc.) to a moment object with zeroed time information
 *
 * @param {string|Date} date - an arbitrary date
 * @returns {moment} a moment instance of the input date with zeroed time
 */
const _parseDate = (date = getDatetime()) => {
  if (moment.isMoment(date) && !date.isValid()) {
    throw new Error('date is invalid');
  }
  const parsed = moment(date);
  if (!parsed.isValid()) {
    throw new Error('date is invalid');
  }
  return parsed.startOf('day');
};

const MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const WEEKDAY_NAMES = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const toOrdinal = (number) => {
  const s = ['th', 'st', 'nd', 'rd'];
  return number + (s[(number - 20) % 10] || s[number] || s[0]);
};

const _joinArray = (inputs, lastSeparator = 'and') => {
  if (inputs.length === 0) {
    return '';
  }
  if (inputs.length === 1) {
    return inputs[0].toString();
  }
  const last = inputs.pop();
  return `${inputs.join(', ')} ${lastSeparator} ${last}`;
};

/** A class wrapping 'cron-parser' and providing an interface for its functionality */
class CronFrequency {
  /**
   * Validates a cron expression string
   *
   * @param {string} cronString - a cron expression string
   * @throws Will throw an error if the expression is not valid
   */
  static validate(cronString) {
    const isValid = validateCronString(cronString);
    if (!isValid) {
      throw new Error(`cron string '${cronString}' is invalid`);
    }
  }

  static _assertSupported(cronString) {
    const iter = cronParser.parseExpression(cronString);

    const { second, minute, hour, dayOfMonth, dayOfWeek } = iter.fields;
    if (!isEqual(second, [0]) || !isEqual(minute, [0]) || !isEqual(hour, [0])) {
      throw new Error(`unsupported cron string ${cronString}`);
    }
    const hasCustomDayOfWeek = dayOfWeek.length < 7;
    const hasCustomDayOfMonth = dayOfMonth.length < 31;
    if (hasCustomDayOfMonth && hasCustomDayOfWeek) {
      throw new Error(`having both custom days of week and custom days of month is unsupported for cron string ${cronString}`);
    }
  }

  /**
   * Parses a cron expression string into a cron-parser CronExpression
   *
   * @param {string} cronString - a cron expression string
   * @param {Date} now - an override for the current date
   * @returns {Object} a cron-parser CronExpression object
   */
  static _parse(cronString, { now = getDatetime() } = {}) {
    CronFrequency.validate(cronString);
    CronFrequency._assertSupported(cronString);
    const currentDate = _parseDate(now).toDate();
    const options = { currentDate, iterator: true };
    const iter = cronParser.parseExpression(cronString, options);
    return iter;
  }

  /**
   * Given a cron expression and a date, it returns the previous matching date
   *
   * @param {string} cronString - a cron expression string
   * @param {Date} now - an override for the current date
   * @param {boolean} inclusive - whether 'now' will be considered as a match
   * @returns {moment} the previous matching date
   */
  static getPrevDate(cronString, { now = getDatetime(), inclusive = false } = {}) {
    if (CronFrequency.isMatchingDate(cronString, now) && inclusive) {
      return _parseDate(now);
    }
    const iter = CronFrequency._parse(cronString, { now });
    return moment(iter.prev().value.toDate());
  }

  /**
   * Given a cron expression and a date, it returns the next matching date
   *
   * @param {string} cronString - a cron expression string
   * @param {Date} now - an override for the current date
   * @param {boolean} inclusive - whether 'now' will be considered as a match
   * @returns {moment} the next matching date
   */
  static getNextDate(cronString, { now = getDatetime(), inclusive = false } = {}) {
    if (CronFrequency.isMatchingDate(cronString, now) && inclusive) {
      return _parseDate(now);
    }
    const iter = CronFrequency._parse(cronString, { now });
    return moment(iter.next().value.toDate());
  }

  /**
   * Given a cron expression and a date, it returns whether the date matches the expression
   *
   * @param {string} cronString - a cron expression string
   * @param {Date} date -the date to test
   * @returns {boolean} whether the date matches the expression
   */
  static isMatchingDate(cronString, date) {
    const currentDate = _parseDate(date);
    const yesterday = moment(currentDate).subtract(1, 'day');
    const iter = CronFrequency._parse(cronString, { now: yesterday });
    const nextOfYesterday = moment(iter.next().value.toDate());
    return currentDate.isSame(nextOfYesterday);
  }

  static toHumanizedString(cronString) {
    const iter = CronFrequency._parse(cronString, { now: '2024-01-01' });
    const { dayOfMonth, month, dayOfWeek } = iter.fields;

    const monthString = month.length === 12 ? 'each month' : _joinArray(month.map(m => MONTH_NAMES[m - 1]));
    const dayOfWeekString = dayOfWeek.length >= 7 ? '' : `each ${_joinArray(dayOfWeek.map(d => WEEKDAY_NAMES[d]))}`;
    const dayOfMonthString = dayOfMonth.length === 31 ? 'any day' : _joinArray(dayOfMonth.map(toOrdinal));

    if (!dayOfWeekString) {
      return `the ${dayOfMonthString} of ${monthString}`;
    }
    if (month.length !== 12) {
      return `${dayOfWeekString} of ${monthString}`;
    }
    return `${dayOfWeekString}`;
  }
}

export default CronFrequency;
