/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import TDToggleWithConfirmationModal from 'core/assets/js/components/TDToggleWithConfirmationModal.jsx';
import {
  modalOpenAC, getIsModalOpen, modalCloseAC,
} from 'core/assets/js/ducks/modalLauncher';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { BS_STYLE } from 'core/assets/js/constants';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { ONBOARDING_REVIEW } from 'organizations/assets/js/constants';

const PROVIDER_VIEW_ENABLE_MODAL_ID = 'enable-provider-view';
const PROVIDER_VIEW_DISABLE_MODAL_ID = 'disable-provider-view';
const REVIEW_VIEW_ENABLE_MODAL_ID = 'enable-review-view';
const REVIEW_VIEW_DISABLE_MODAL_ID = 'disable-review-view';
const ONBOARDING_REVIEW_ENABLE_MODAL_ID = 'enable-onboarding-review';
const ONBOARDING_REVIEW_DISABLE_MODAL_ID = 'disable-onboarding-review';
const MEMBERS_ACCESS_TEAM_PAGE_ENABLE_MODAL_ID = 'members-access-team-page-enable-modal';
const MEMBERS_ACCESS_TEAM_PAGE_DISABLE_MODAL_ID = 'members-access-team-page-disable-modal';

const ProviderViewEnableModalBody = () => (
  <React.Fragment>
    <p>
      By enabling this option your providers have access to the provider
      list or other providers&#39; profile page in your organisation.
    </p>
    <p className="mb-4">
      Are you sure you want to activate the &#39;Allow full visibility of your
      provider list&#39; option?
    </p>
  </React.Fragment>
);

const ProviderViewDisableModalBody = () => (
  <React.Fragment>
    <p>
      Deactivating this option means that only managers will have access to the
      provider list or other providers&#39; profile page in your organisation.
    </p>
    <p className="mb-4">
      Are you sure you want to deactivate the &#39;Allow full visibility of
      your provider list&#39; option?
    </p>
  </React.Fragment>
);

const ReviewViewEnableModalBody = () => (
  <React.Fragment>
    <p>
      By enabling this option your providers have access to the average user
      rating of other providers and all reviews left by your managers to other providers.
    </p>

    <p className="mb-4">
      Are you sure you want to activate the &#39;Allow full visibility of
      your user reviews to all providers&#39; option?
    </p>
  </React.Fragment>
);

const ReviewViewDisableModalBody = () => (
  <React.Fragment>
    <p>
      Deactivating this option means that only managers will have access
      to the average rating of other providers and all reviews left by
      your managers to other providers.
    </p>
    <p className="mb-4">
      Are you sure you want to deactivate the &#39;Allow full visibility
      of your user reviews to all providers&#39; option?
    </p>
  </React.Fragment>
);

const OnboardingReviewEnableModalBody = () => (
  <React.Fragment>
    <p>
      Activating this options means that only higher managers - e.g the
      Organisation Owner and Financial Controllers - will be able to review
      user onboarding submissions. The rest of your managers will still
      be able to view onboarding submissions but not review them.
    </p>
    <p className="mb-4">
      Are you sure you want to activate the &#39;Allow only higher
      managers to review onboarding submissions&#39; option?
    </p>
  </React.Fragment>
);

const OnboardingReviewDisableModalBody = () => (
  <React.Fragment>
    <p>
      Deactivating this options means that all managers will be
      able to review user onboarding submissions.
    </p>
    <p className="mb-4">
      Are you sure you want to deactivate the &#39;Allow only
      higher managers to review onboarding submissions&#39; option?
    </p>
  </React.Fragment>
);

const MembersAccessTeamPageEnableModalBody = () => (
  <>
    <p>
      By default, allow all project members to interact and collaborate with each other by giving
      them access to the project team members page, when creating a new project.
    </p>
    <p className="mb-4">Are you sure you want to activate this setting?</p>
  </>
);

const MembersAccessTeamPageDisableModalBody = () => (
  <>
    <p>
      By deactivating this setting, when creating a new project, by default only the project
      managers will be able to view the full list of project members. The providers will not be able
      to see or interact with any other project member. They will only interact with a project
      manager when the latter assigns them to a task.
    </p>
    <p className="mb-4">Are you sure you want to deactivate this setting?</p>
  </>
);

class OrgPrivacySettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
    };

    this.handleProvidersViewChange = this.handleProvidersViewChange.bind(this);
    this.handleReviewViewChange = this.handleReviewViewChange.bind(this);
    this.handleSaveProvidersView = this.handleSaveProvidersView.bind(this);
    this.handleSaveReviewView = this.handleSaveReviewView.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleOnboardingReviewChange = this.handleOnboardingReviewChange.bind(this);
    this.handleSaveOnboardingReview = this.handleSaveOnboardingReview.bind(this);
    this.handleSaveInstantApproval = this.handleSaveInstantApproval.bind(this);
  }

  getProvidersViewNewValue() {
    const { organization } = this.props;
    return !organization.should_provider_view_other_providers;
  }

  getReviewViewNewValue() {
    const { organization } = this.props;
    return !organization.should_provider_view_other_providers_review;
  }

  getOnboardingReviewNewValue() {
    const { organization: { onboarding_review: onboardingReview } } = this.props;
    const { HIGH_MANAGERS_ONLY, ALL_MANAGERS } = ONBOARDING_REVIEW;
    return onboardingReview === HIGH_MANAGERS_ONLY ? ALL_MANAGERS : HIGH_MANAGERS_ONLY;
  }

  getInstantApprovalNewValue() {
    const { organization } = this.props;
    return !organization.should_high_manager_manage_instant_approval;
  }

  handleProvidersViewChange() {
    // set the new value to be the opposite of the current value
    const { dispatch } = this.props;
    const newValue = this.getProvidersViewNewValue();
    dispatch(modalOpenAC(newValue
      ? PROVIDER_VIEW_ENABLE_MODAL_ID
      : PROVIDER_VIEW_DISABLE_MODAL_ID,
    ));
  }

  handleReviewViewChange() {
    // set the new value to be the opposite of the current value
    const { dispatch } = this.props;
    const newValue = this.getReviewViewNewValue();
    dispatch(modalOpenAC(newValue
      ? REVIEW_VIEW_ENABLE_MODAL_ID
      : REVIEW_VIEW_DISABLE_MODAL_ID,
    ));
  }

  handleOnboardingReviewChange() {
    // set the new value to be the opposite of the current value
    const { dispatch } = this.props;
    const { HIGH_MANAGERS_ONLY } = ONBOARDING_REVIEW;
    const newValue = this.getOnboardingReviewNewValue();
    dispatch(modalOpenAC(newValue === HIGH_MANAGERS_ONLY
      ? ONBOARDING_REVIEW_ENABLE_MODAL_ID
      : ONBOARDING_REVIEW_DISABLE_MODAL_ID,
    ));
  }

  handleSaveOnboardingReview() {
    return this.submitChanges({
      onboarding_review: this.getOnboardingReviewNewValue(),
    });
  }

  handleSaveProvidersView() {
    return this.submitChanges({
      should_provider_view_other_providers: this.getProvidersViewNewValue(),
    });
  }

  handleSaveReviewView() {
    return this.submitChanges({
      should_provider_view_other_providers_review: this.getReviewViewNewValue(),
    });
  }

  handleSaveInstantApproval() {
    return this.submitChanges({
      should_high_manager_manage_instant_approval: this.getInstantApprovalNewValue(),
    });
  }

  async submitChanges(payload) {
    const { onSettingsChanged } = this.props;

    this.setState({ isSubmitting: true });
    await onSettingsChanged(payload);
    this.handleModalClose();
    this.setState({ isSubmitting: false });
  }

  handleModalClose() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  render() {
    const {
      dispatch,
      isDisableAllowTeamMembersAccessTeamPageOpen,
      isDisableOnboardingReviewOpen,
      isDisableProvidersViewOpen,
      isDisableReviewViewOpen,
      isEnableAllowTeamMembersAccessTeamPageOpen,
      isEnableOnboardingReviewOpen,
      isEnableProvidersViewOpen,
      isEnableReviewViewOpen,
      onSettingsChanged,
      organization: {
        roles_enabled: isRolesEnabled,
        allow_providers_access_team_page: allowProvidersAccessTeamPage,
        onboarding_review: onboardingReview,
        providers_can_see_managers: providersCanSeeManagers,
        should_provider_view_other_providers: shouldProviderViewOthers,
        should_provider_view_other_providers_review: shouldProviderViewOthersReview,
        show_availability: showAvailability,
        message_hub_enabled: messageHub,
      },
    } = this.props;

    const onlyHigherManagerCanReviewOnboarding = onboardingReview
      === ONBOARDING_REVIEW.HIGH_MANAGERS_ONLY;

    const { isSubmitting } = this.state;

    return (
      <div className="settings-row">
        <h3 className="heading-block">People</h3>
        <div className="d-flex flex-container--standalone align-items-center">
          <div className="settings-row__content">
            <label>Allow full visibility of your provider list</label>
            <p className="discreet">
              By activating this option your providers are able to see your full list of providers
            </p>
          </div>

          <div className="settings-row__cta">
            <TDSwitch
              selected={shouldProviderViewOthers}
              onClick={this.handleProvidersViewChange}
            />
          </div>
        </div>

        <TDToggleWithConfirmationModal
          containerClassName="px-3"
          disableHeader="Providers can see the managers list"
          DisableModalBody={() => (
            <>
              <p>
                By deactivating this setting, providers will not be able to see the
                organization&apos;s managers list.
              </p>
              <p className="mb-4">Are you sure you want to deactivate this setting?</p>
            </>
          )}
          enableHeader="Providers cannot see the managers list"
          EnableModalBody={() => (
            <>
              <p>
                {"By activating this setting, providers will be able to see the organization's "}
                managers list.
              </p>
              <p className="mb-4">Are you sure you want to activate this setting?</p>
            </>
          )}
          label="Allow full visibility of your manager list"
          modalId="enable-providers-seeing-managers-list"
          onToggled={selected => onSettingsChanged({ providers_can_see_managers: selected })}
          popOverContent="By activating this option your providers are able to see your full list of managers"
          popOverTitle="Allow full visibility of your manager list"
          selected={providersCanSeeManagers}
          sublabel="By activating this option your providers are able to see your full list of managers"
        />

        <TDToggleWithConfirmationModal
          containerClassName="px-3"
          disableHeader="Everyone can see global messaging"
          DisableModalBody={() => (
            <>
              <p>
                Deactivating this option means that Managers and Providers will
                not be able to communicate with each other within the TalentDesk.
              </p>
              <p className="mb-4">
                Are you sure you want to deactivate the
                &#39;Enable Global Messaging&#39; option?
              </p>
            </>
          )}
          enableHeader="No one can see global messaging"
          EnableModalBody={() => (
            <>
              <p>
                By activating Global Messaging, your Managers and Providers will be able to
                communicate with each other within the TalentDesk platform via either 1-on-1
                or Group messages.
              </p>
              <p className="mb-4">
                Are you sure you want to activate the
                &#39;Enable Global Messaging&#39; option?
              </p>
            </>
          )}
          label="Enable Global Messaging"
          modalId="enable-message-hub"
          onToggled={selected => onSettingsChanged({ message_hub_enabled: selected })}
          selected={messageHub}
          sublabel={`
            By activating Global Messaging, your Managers and Providers will be able
            to communicate with each other within the TalentDesk platform via either
            1-on-1 or Group messages
          `}
        />

        <h3 className="heading-block">Reviews</h3>
        <div className="d-flex flex-container--standalone align-items-center">
          <div className="settings-row__content">
            <label>Allow full visibility of user reviews to all providers</label>
            <p className="discreet">
              By activating this option your providers are able to see
              the reviews left by your managers to other providers
            </p>
          </div>

          <div className="settings-row__cta">
            <TDSwitch
              selected={shouldProviderViewOthersReview}
              onClick={this.handleReviewViewChange}
            />
          </div>
        </div>

        {
          /*
           * Hide when roles & permissions feature is enabled as it will be
           * ignored by submissions access control.
          */
        }
        { !isRolesEnabled && (
          <div className="d-flex flex-container--standalone align-items-center pl-3 pr-3">
            <div className="settings-row__content">
              <label>Allow only higher managers to review onboarding submissions</label>
              <p className="discreet">
                By activating this option only higher managers of your organisation will be
                able to review onboarding submissions
              </p>
            </div>
            <div className="settings-row__cta">
              <TDSwitch
                selected={onlyHigherManagerCanReviewOnboarding}
                onClick={this.handleOnboardingReviewChange}
              />
            </div>
          </div>
        )}

        <h3 className="heading-block">Projects</h3>
        <div className="d-flex flex-container--standalone align-items-center">
          <div className="settings-row__content">
            <label>Allow all project team members to access the project team page</label>
            <p className="discreet">
              By activating this option both managers and providers can view the project team
              members page of a project
            </p>
          </div>

          <div className="settings-row__cta">
            <TDSwitch
              selected={allowProvidersAccessTeamPage}
              onClick={() => {
                dispatch(modalOpenAC(allowProvidersAccessTeamPage
                  ? MEMBERS_ACCESS_TEAM_PAGE_DISABLE_MODAL_ID
                  : MEMBERS_ACCESS_TEAM_PAGE_ENABLE_MODAL_ID,
                ));
              }}
            />
          </div>
        </div>

        <h3 className="heading-block">Availability</h3>
        <TDToggleWithConfirmationModal
          containerClassName="pb-3 px-3"
          enableHeader="Show the availability feature in this organization"
          EnableModalBody={() => (
            <>
              <p>
                By activating this setting, when users have this organization selected, they will
                see the availability feature.
              </p>
              <p className="mb-4">Are you sure you want to activate this setting?</p>
            </>
          )}
          disableHeader="Hide the availability feature in this organization"
          DisableModalBody={() => (
            <>
              <p>
                By deactivating this setting, when users have this organization selected, they will
                not see the availability feature.
              </p>
              <p className="mb-4">Are you sure you want to deactivate this setting?</p>
            </>
          )}
          label="Show the availability feature in this organization"
          modalId="show-availability"
          onToggled={selected => onSettingsChanged({ show_availability: selected })}
          selected={showAvailability}
          sublabel={(
            'By activating this option, when users have this organization selected, they will see '
            + 'the availability feature'
          )}
        />

        <ModalSimple
          heading="Visibility of your provider list is restricted"
          onClose={this.handleModalClose}
          open={isEnableProvidersViewOpen}
          footer={[
            <TDButton
              key={2}
              className="mr-4 float-right"
              label="Cancel"
              disabled={isSubmitting}
              onClick={this.handleModalClose}
            />,
            <TDButton
              key={1}
              className="float-right"
              label="Activate"
              variant={BS_STYLE.PRIMARY}
              disabled={isSubmitting}
              onClick={this.handleSaveProvidersView}
            />,
          ]}
        >
          <ProviderViewEnableModalBody />
        </ModalSimple>

        <ModalSimple
          heading="Full visibility of your provider list is enabled"
          onClose={this.handleModalClose}
          open={isDisableProvidersViewOpen}
          footer={[
            <TDButton
              key={2}
              className="mr-4 float-right"
              label="Cancel"
              disabled={isSubmitting}
              onClick={this.handleModalClose}
            />,
            <TDButton
              key={1}
              className="float-right"
              label="Deactivate"
              variant={BS_STYLE.DANGER}
              disabled={isSubmitting}
              onClick={this.handleSaveProvidersView}
            />,
          ]}
        >
          <ProviderViewDisableModalBody />
        </ModalSimple>

        <ModalSimple
          heading="Visibility of user reviews is restricted"
          onClose={this.handleModalClose}
          open={isEnableReviewViewOpen}
          footer={[
            <TDButton
              key={2}
              className="mr-4 float-right"
              label="Cancel"
              disabled={isSubmitting}
              onClick={this.handleModalClose}
            />,
            <TDButton
              key={1}
              className="float-right"
              label="Activate"
              variant={BS_STYLE.PRIMARY}
              disabled={isSubmitting}
              onClick={this.handleSaveReviewView}
            />,
          ]}
        >
          <ReviewViewEnableModalBody />
        </ModalSimple>

        <ModalSimple
          heading="Full visibility of provider reviews is enabled"
          onClose={this.handleModalClose}
          open={isDisableReviewViewOpen}
          footer={[
            <TDButton
              key={2}
              className="mr-4 float-right"
              label="Cancel"
              disabled={isSubmitting}
              onClick={this.handleModalClose}
            />,
            <TDButton
              key={1}
              className="float-right"
              label="Deactivate"
              variant={BS_STYLE.DANGER}
              disabled={isSubmitting}
              onClick={this.handleSaveReviewView}
            />,
          ]}
        >
          <ReviewViewDisableModalBody />
        </ModalSimple>

        <ModalSimple
          heading="Onboarding submission reviewal by higher managers is disabled"
          onClose={this.handleModalClose}
          open={isEnableOnboardingReviewOpen}
          footer={[
            <TDButton
              key={2}
              className="mr-4 float-right"
              label="Cancel"
              disabled={isSubmitting}
              onClick={this.handleModalClose}
            />,
            <TDButton
              key={1}
              className="float-right"
              label="Activate"
              variant={BS_STYLE.PRIMARY}
              disabled={isSubmitting}
              onClick={this.handleSaveOnboardingReview}
            />,
          ]}
        >
          <OnboardingReviewEnableModalBody />
        </ModalSimple>
        <ModalSimple
          heading="Onboarding submission reviewal by higher managers is enabled"
          onClose={this.handleModalClose}
          open={isDisableOnboardingReviewOpen}
          footer={[
            <TDButton
              key={2}
              className="mr-4 float-right"
              label="Cancel"
              disabled={isSubmitting}
              onClick={this.handleModalClose}
            />,
            <TDButton
              key={1}
              className="float-right"
              label="Deactivate"
              variant={BS_STYLE.DANGER}
              disabled={isSubmitting}
              onClick={this.handleSaveOnboardingReview}
            />,
          ]}
        >
          <OnboardingReviewDisableModalBody />
        </ModalSimple>

        <>
          {[
            {
              content: <MembersAccessTeamPageEnableModalBody />,
              heading: 'Access to the project team is restricted to managers only',
              open: isEnableAllowTeamMembersAccessTeamPageOpen,
              submit: () => this.submitChanges({ allow_providers_access_team_page: true }),
              submitLabel: 'Activate',
              submitVariant: BS_STYLE.PRIMARY,
            },
            {
              content: <MembersAccessTeamPageDisableModalBody />,
              heading: 'Access to the project team is open to all project members',
              open: isDisableAllowTeamMembersAccessTeamPageOpen,
              submit: () => this.submitChanges({ allow_providers_access_team_page: false }),
              submitLabel: 'Deactivate',
              submitVariant: BS_STYLE.DANGER,
            },
          ].map(props => (
            <ModalSimple
              footer={[
                <TDButton
                  key={2}
                  className="mr-4 float-right"
                  label="Cancel"
                  disabled={isSubmitting}
                  onClick={() => this.handleModalClose()}
                />,
                <TDButton
                  key={1}
                  className="float-right"
                  label={props.submitLabel}
                  variant={props.submitVariant}
                  disabled={isSubmitting}
                  onClick={() => props.submit()}
                />,
              ]}
              heading={props.heading}
              key={props.heading}
              onClose={() => this.handleModalClose()}
              open={props.open}
            >
              {props.content}
            </ModalSimple>
          ))}
        </>
      </div>
    );
  }
}

OrgPrivacySettings.propTypes = {
  organization: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  isEnableProvidersViewOpen: PropTypes.bool,
  isDisableProvidersViewOpen: PropTypes.bool,
  onSettingsChanged: PropTypes.func.isRequired,
  isEnableReviewViewOpen: PropTypes.bool,
  isDisableReviewViewOpen: PropTypes.bool,
  isEnableOnboardingReviewOpen: PropTypes.bool,
  isDisableOnboardingReviewOpen: PropTypes.bool,
  isEnableAllowTeamMembersAccessTeamPageOpen: PropTypes.bool,
  isDisableAllowTeamMembersAccessTeamPageOpen: PropTypes.bool,
};

OrgPrivacySettings.defaultProps = {
  isEnableProvidersViewOpen: false,
  isDisableProvidersViewOpen: false,
  isEnableReviewViewOpen: false,
  isDisableReviewViewOpen: false,
  isEnableOnboardingReviewOpen: false,
  isDisableOnboardingReviewOpen: false,
  isEnableAllowTeamMembersAccessTeamPageOpen: false,
  isDisableAllowTeamMembersAccessTeamPageOpen: false,
};

const mapStateToProps = state => ({
  isEnableProvidersViewOpen: getIsModalOpen(state, PROVIDER_VIEW_ENABLE_MODAL_ID),
  isDisableProvidersViewOpen: getIsModalOpen(state, PROVIDER_VIEW_DISABLE_MODAL_ID),
  isEnableReviewViewOpen: getIsModalOpen(state, REVIEW_VIEW_ENABLE_MODAL_ID),
  isDisableReviewViewOpen: getIsModalOpen(state, REVIEW_VIEW_DISABLE_MODAL_ID),
  isEnableOnboardingReviewOpen: getIsModalOpen(state, ONBOARDING_REVIEW_ENABLE_MODAL_ID),
  isDisableOnboardingReviewOpen: getIsModalOpen(state, ONBOARDING_REVIEW_DISABLE_MODAL_ID),
  isEnableAllowTeamMembersAccessTeamPageOpen: getIsModalOpen(
    state,
    MEMBERS_ACCESS_TEAM_PAGE_ENABLE_MODAL_ID,
  ),
  isDisableAllowTeamMembersAccessTeamPageOpen: getIsModalOpen(
    state,
    MEMBERS_ACCESS_TEAM_PAGE_DISABLE_MODAL_ID,
  ),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgPrivacySettings);
