// @param peopleType: (managers|providers|invitees)
import { prepareQueryString } from 'core/assets/js/lib/utils';
import { USER_TYPE } from 'core/constants';
import { CREATE_GROUP_TYPE, DISCOVER_TALENTS_TABS, PEOPLE_TYPE } from 'people/assets/js/constants';

export const orgPeopleListUrl = (orgAlias, peopleType) => (
  `/${orgAlias}/team/${peopleType}`
);
export const orgPeopleProvidersUrl = orgAlias => (
  orgPeopleListUrl(orgAlias, PEOPLE_TYPE.PROVIDERS)
);
export const orgPeopleManagersUrl = orgAlias => (
  orgPeopleListUrl(orgAlias, PEOPLE_TYPE.MANAGERS)
);
export const orgPeopleDiscoverUrl = (orgAlias, tabName = DISCOVER_TALENTS_TABS.TALENTS) => (
  `/${orgAlias}/discover/overview/${tabName}`
);
export const orgPeopleDiscoverFreelancersUrl = orgAlias => (
  `/${orgAlias}/discover/freelancers`
);
export const orgPeopleDiscoverDetailUrl = (orgAlias, talentId) => (
  `/${orgAlias}/discover/freelancers/${talentId}`
);
export const orgPeopleInviteesUrl = orgAlias => (
  orgPeopleListUrl(orgAlias, 'invitees')
);
export const orgUserProfileUrl = (orgAlias, userType, userId, tabName, targetRateId) => {
  const qs = prepareQueryString({ targetRateId });
  const urlSuffix = !tabName ? qs : `/${tabName}${qs}`;
  let _userType = ':userType';

  if (userType !== ':userType') {
    _userType = userType === USER_TYPE.PROVIDER ? PEOPLE_TYPE.PROVIDERS : PEOPLE_TYPE.MANAGERS;
  }

  return `/${orgAlias}/people/${_userType}/${userId}${urlSuffix}`;
};

export const orgPeopleInviteUrl = (orgAlias, peopleType) => (
  `/${orgAlias}/people/invite-${peopleType}`
);

export const orgPeopleDeactivateUrl = (orgAlias, peopleType, userId) => (
  `/${orgAlias}/people/${peopleType}/${userId}/deactivate`
);

// user groups

export const userGroupsUrl = orgAlias => (
  `/${orgAlias}/people/user-groups`
);

export const userGroupCreateUrl = (orgAlias, type = CREATE_GROUP_TYPE.SELECT) => (
  `/${orgAlias}/people/user-group/create/${type}`
);

export const userGroupEditUrl = (orgAlias, userGroupId) => (
  `/${orgAlias}/people/user-group/${userGroupId}/edit`
);

export const userGroupManageUrl = (orgAlias, userGroupId) => (
  `/${orgAlias}/people/user-group/${userGroupId}`
);

// ----------------------------- API URLS -----------------------------

export const fetchProviderApiUrl = (orgAlias, userId, url = '') => (
  `${url}/api/${orgAlias}/people/providers/${userId}`
);
export const fetchProviderAnalyticsApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/people/providers/analytics`
);
export const fetchManagerApiUrl = (orgAlias, userId, url = '') => (
  `${url}/api/${orgAlias}/people/managers/${userId}`
);
export const fetchManagerOwnsApiUrl = (orgAlias, userId) => (
  `/api/${orgAlias}/people/managers/${userId}/owner-of`
);
export const fetchNotesApiUrl = (orgAlias, userId, url = '') => (
  `${url}/api/${orgAlias}/people/providers/${userId}/notes`
);
export const postNoteApiUrl = (orgAlias, userId, url = '') => (
  `${url}/api/${orgAlias}/people/providers/${userId}/notes`
);
export const editNoteApiUrl = (noteId, orgAlias, userId, url = '') => (
  `${url}/api/${orgAlias}/people/providers/${userId}/notes/${noteId}`
);
export const deleteNoteApiUrl = (noteId, orgAlias, userId, url = '') => (
  `${url}/api/${orgAlias}/people/providers/${userId}/notes/${noteId}`
);
export const fetchManagerBudgetApiUrl = (orgAlias, userId, url = '') => (
  `${url}/api/${orgAlias}/people/managers/${userId}/budgets`
);
export const fetchActiveManagerBudgetApiUrl = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/people/managers/budgets`
);
export const editManagerBudgetApiUrl = (orgAlias, userId, url = '') => (
  `${url}/api/${orgAlias}/people/managers/${userId}/edit-budget`
);
export const fetchOrgManagerOptions = (orgAlias, url = '') => (
  `${url}/api/${orgAlias}/people/managers-options`
);
// @param peopleType: (managers|providers|invitees)
export const orgPeopleListApiUrl = ({ orgAlias, peopleType }, url = '') => {
  if (!peopleType) {
    return `${url}/api/${orgAlias}/people`;
  }
  return `${url}/api/${orgAlias}/people/${peopleType}`;
};

export const orgPeopleListCsvApiUrl = (orgAlias, peopleType, url = '') => (
  `${url}/api/${orgAlias}/people/${peopleType}/csv`
);
export const userProjectsApiUrl = (orgAlias, userId, url = '') => (
  `${url}/api/${orgAlias}/people/providers/${userId}/projects`
);

export const orgInviteePendingCountApiUrl = ({ orgAlias }, url = '') => (
  `${url}/api/${orgAlias}/people/managers/invitee-pending-count`
);
export const resendInvitationUrl = ({ orgAlias, invitationId }, url = '') => (
  `${url}/api/${orgAlias}/people/managers/invitees/${invitationId}/reinvite`
);
export const cancelInvitationUrl = ({ orgAlias, invitationId }, url = '') => (
  `${url}/api/${orgAlias}/people/managers/invitees/${invitationId}/cancel`
);

const _reviewsUrl = (params, url) => {
  if (!params.orgAlias) {
    throw new Error(`[_reviewsUrl] Invalid orgAlias (${params.orgAlias})`);
  }
  if (!params.userId && !params.projId) {
    throw new Error(`[_reviewsUrl] Define either userId (${params.userId}) to get all provider reviews, or projId (${params.projId}) to get all project reviews, or both`);
  }
  const baseUrl = `${url}/api/${params.orgAlias}/people/providers`;
  const projectUri = params.projId ? `/${params.projId}` : '';
  const reviewUrl = `reviews${projectUri}?ownReviews=${params.ownReviews || ''}`;

  return params.userId
    ? `${baseUrl}/${params.userId}/${reviewUrl}`
    : `${baseUrl}/${reviewUrl}`;
};

export const fetchProviderReviewsApiUrl = (orgAlias, userId, url = '') => (
  _reviewsUrl({ orgAlias, userId }, url)
);

export const fetchProjectReviewsApiUrl = (orgAlias, projId, url = '') => (
  _reviewsUrl({ orgAlias, projId, ownReviews: 1 }, url)
);

export const postReviewApiUrl = (orgAlias, userId, projectId, url = '') => (
  `${url}/api/${orgAlias}/people/providers/${userId}/reviews/${projectId}`
);

// Documents
export const userDocumentsApiUrl = ({ orgAlias, userType, userId }, url = '') => (
  `${url}/api/${orgAlias}/people/${userType}/${userId}/document-assignments`
);

// Rate Adjustments
export const orgPeopleProvidersRateAdjustmentUrl = (orgAlias, userId, url = '') => (
  `${url}${orgPeopleProvidersUrl(orgAlias)}?userId=${userId}`
);
export const orgPeopleManagersRateAdjustmentUrl = (orgAlias, userId, url = '') => (
  `${url}${orgPeopleManagersUrl(orgAlias)}?userId=${userId}`
);

// Time-off
export const userTimeOffApiUrl = ({ orgAlias, userType, userId }, url = '') => (
  `${url}/api/${orgAlias}/people/${userType}/${userId}/time-off`
);

// User groups
export const userGroupsListApiUrl = ({ orgAlias }) => (`/api/${orgAlias}/people/user-groups`);
export const userGroupOptionsApiUrl = orgAlias => (`/api/${orgAlias}/people/user-group-options`);
export const userGroupApiUrl = ({ orgAlias, userGroupId }) => (`/api/${orgAlias}/people/user-group/${userGroupId}`);
export const userGroupCreateApiUrl = ({ orgAlias }) => (`/api/${orgAlias}/people/user-group/create`);
export const userGroupEditApiUrl = ({ orgAlias, userGroupId }) => (`/api/${orgAlias}/people/user-group/${userGroupId}/edit`);
export const userGroupDeleteApiUrl = ({ orgAlias, userGroupId }) => (`/api/${orgAlias}/people/user-group/${userGroupId}/delete`);
export const userGroupAddMembersApiUrl = ({ orgAlias, userGroupId }) => (`/api/${orgAlias}/people/user-group/${userGroupId}/add-members`);
export const userGroupRemoveMemberApiUrl = (orgAlias, userGroupId, userId) => (
  `/api/${orgAlias}/people/user-group/${userGroupId}/member/${userId}/remove`
);
export const userGroupMembersListApiUrl = ({ orgAlias, userGroupId }) => (`/api/${orgAlias}/people/user-group/${userGroupId}/members`);

export const orgPeopleMessagingUrl = orgAlias => (
  `/${orgAlias}/messaging`
);

export const activateUserCardApiUrl = (orgAlias, userId) => (
  `/api/${orgAlias}/people/${userId}/activate`
);

export const deactivateUserCardApiUrl = (orgAlias, userId) => (
  `/api/${orgAlias}/people/${userId}/deactivate`
);

export const promoteToManagerApiUrl = (orgAlias, userId) => (
  `/api/${orgAlias}/people/providers/${userId}/promote`
);

export const demoteToProviderApiUrl = (orgAlias, userId) => (
  `/api/${orgAlias}/people/managers/${userId}/demote`
);

export const toggleEmploymentStatusApiUrl = (orgAlias, userId) => (
  `/api/${orgAlias}/people/${userId}/toggle-employment-status`
);

export const getGuestChatsUrl = (token) => (
  `/api/people/chat/guests/${token}`
);

export const getGuestChatUrl = (token, chatId) => (
  `/api/people/chat/guests/${token}/${chatId}`
);

export const getGuestChatMessagesUrl = (token, chatId) => (
  `/api/people/chat/guests/${token}/${chatId}/messages`
);

export const postGuestChatUrl = (token) => (
  `/api/people/chat/guests/${token}`
);

export const updateGuestChatReadUrl = (chatId, token) => (
  `/api/people/chat/guests/${token}/${chatId}/read`
);

export const postChatMessageUrl = ({ orgAlias }) => (
  `/api/${orgAlias}/people/chat`
);

export const getChatByEmailsUrl = ({ orgAlias }) => (
  `/api/${orgAlias}/people/chat-by-email`
);

export const getChatByManagerUrl = ({ orgAlias }) => (
  `/api/${orgAlias}/people/chat-by-manager`
);


export const getOnboardingManagerUrl = ({ orgAlias }) => (
  `/api/${orgAlias}/people/onboarding-manager`
);

export const getChatMessagesUrl = ({ orgAlias, chatId }) => (
  `/api/${orgAlias}/people/chat/${chatId}/messages`
);

export const updateChatReadUrl = ({ orgAlias, chatId }) => (
  `/api/${orgAlias}/people/chat/${chatId}/read`
);

export const getChatUnreadCountUrl = ({ orgAlias }) => (
  `/api/${orgAlias}/people/chat/unread-count`
);

export const postCreateChatUrl = ({ orgAlias }) => (
  `/api/${orgAlias}/people/chat/create`
);

export const getChatsUrl = ({ orgAlias, queryString = '' }) => (
  `/api/${orgAlias}/people/chat${queryString}`
);

export const getChatUrl = ({ orgAlias, chatId }) => (
  `/api/${orgAlias}/people/chat/${chatId}`
);

export const addParticipantsChatUrl = ({ orgAlias, chatId }) => (
  `/api/${orgAlias}/people/chat/${chatId}/add`
);

export const updateInvoiceCapApiUrl = (orgAlias, userId) => (
  `/api/${orgAlias}/people/${userId}/invoice-cap`
);


export const getProfileOnboardingInfo = (orgAlias, userId) => (
  `/api/${orgAlias}/people/${userId}/onboarding-info`
);
