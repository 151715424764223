
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import { CURRENCY_SYMBOL } from 'core/assets/js/constants';
import { getListState } from 'core/assets/js/ducks/list';
import { formatDate, renderDuration } from 'core/assets/js/lib/utils';
import { financeWorksheetViewUrl } from 'finance/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { fetchUsedTimeTrackerListDS } from 'projects/assets/js/data-services/list';

const TimeTrackerArchived = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const componentName = TimeTrackerArchived.GetComponentName();

  const activeOrg = useSelector(selectActiveOrg);
  const { isLoading, items, pagination } = useSelector(state => getListState(state, componentName));

  useEffect(() => {
    dispatch(fetchUsedTimeTrackerListDS({
      componentName, orgAlias: activeOrg.alias, querystring: search,
    }));
  }, [search]);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Table
        cols={[
          {
            dataFormat: startTime => formatDate(startTime),
            key: 'startTime',
            label: 'Day',
          },
          {
            dataFormat: (_, workTimeBlock) => workTimeBlock.task?.project?.title,
            key: 'taskId',
            label: 'Project',
          },
          {
            dataFormat: (_, workTimeBlock) => workTimeBlock.task?.title,
            key: 'taskId',
            label: 'Task',
          },
          { key: 'description', label: 'Description' },
          {
            dataFormat: (_, workTimeBlock) => {
              let value = `${formatDate(workTimeBlock.startTime, 'HH:mm', true)} - `;
              if (workTimeBlock.endTime) {
                value += formatDate(workTimeBlock.endTime, 'HH:mm', true);
              }
              return value;
            },
            key: 'startTime',
            label: 'Start - end',
          },
          {
            dataFormat: (_, workTimeBlock) => renderDuration(
              workTimeBlock.startTime, workTimeBlock.endTime,
            ),
            key: 'startTime',
            label: 'Duration',
          },
          {
            dataFormat: (_, workTimeBlock) => (
              <NumberTpl
                prefix={CURRENCY_SYMBOL[workTimeBlock.worksheetItem.worksheetItemCurrency]}
                value={workTimeBlock.amount}
              />
            ),
            key: 'amount',
            label: 'Earned',
          },
          {
            dataFormat: (_, workTimeBlock) => workTimeBlock.worksheetItem && (
              <Link
                to={financeWorksheetViewUrl(
                  activeOrg.alias, workTimeBlock.worksheetItem.worksheetId,
                )}
              >
                {workTimeBlock.worksheetItem.worksheetId}
              </Link>
            ),
            key: 'worksheetItemId',
            label: 'Worksheet',
          },
        ]}
        items={items}
      />
      <TDPagination {...pagination} />
    </>
  );
};

TimeTrackerArchived.GetComponentName = () => 'TimeTrackerArchived';

export default TimeTrackerArchived;
