import { LAYOUTS, PROJECT_TABS } from 'core/assets/js/constants';
import { TIME_TRACKER_TABS } from 'projects/assets/js/constants';

// Views
import ProjectCreateView from 'projects/assets/js/ProjectCreateView.jsx';
import ProjectEditView from 'projects/assets/js/ProjectEditView.jsx';
import ProjectListView from 'projects/assets/js/ProjectListView.jsx';
import ProjectInvitationListView from 'projects/assets/js/ProjectInvitationListView.jsx';
import ProjectOpportunitiesListView from 'projects/assets/js/ProjectOpportunitiesListView.jsx';
import ProjectApplicationView from 'projects/assets/js/ProjectApplicationView.jsx';
import ProjectApplicationEditView from 'projects/assets/js/ProjectApplicationEditView.jsx';
import ProjectApplicationFormView from 'projects/assets/js/ProjectApplicationFormView.jsx';
import ProjectView from 'projects/assets/js/ProjectView.jsx';
import ProjectRequestBudgetView from 'projects/assets/js/ProjectRequestBudgetView.jsx';
import ProjectAddWorksheetView from 'projects/assets/js/ProjectAddWorksheetView.jsx';
import ProjectClaimExpenseView from 'projects/assets/js/ProjectClaimExpenseView.jsx';
import ProjectCreateTaskView from 'projects/assets/js/ProjectCreateTaskView.jsx';
import ProjectTaskView from 'projects/assets/js/ProjectTaskView.jsx';
import MyTasksListView from 'projects/assets/js/MyTasksListView.jsx';
import ProjectEditTaskView from 'projects/assets/js/ProjectEditTaskView.jsx';
import ProjectWorksheetImportView from 'projects/assets/js/ProjectWorksheetImportView.jsx';
import TimeTrackerView from 'projects/assets/js/TimeTrackerView.jsx';
import {
  myTasksListUrl,
  projectListUrl,
  projectInvitationsUrl,
  projectOpportunitiesUrl,
  projectOpportunitiesApplyUrl,
  projectApplicationUrl,
  projectApplicationEditUrl,
  projectCreateUrl,
  projectEditUrl,
  projectRequestBudgetUrl,
  projectSubmitWorksheetUrl,
  projectClaimExpenseUrl,
  projectCreateTaskUrl,
  projectViewTaskUrl,
  projectEditTaskUrl,
  projectViewUrl,
  projectWorksheetImportUrl,
  timeTrackerUrl,
} from 'projects/urls';

const projectsRouteConfig = [
  {
    path: myTasksListUrl(':orgAlias'),
    component: MyTasksListView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: projectListUrl(':orgAlias'),
    component: ProjectListView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: projectInvitationsUrl(':orgAlias'),
    component: ProjectInvitationListView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: projectOpportunitiesUrl(':orgAlias'),
    component: ProjectOpportunitiesListView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: projectOpportunitiesApplyUrl(':orgAlias', ':projectId'),
    component: ProjectApplicationFormView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireProvider: true,
  },
  {
    path: projectApplicationUrl(':orgAlias', ':projectId', ':applicationId'),
    component: ProjectApplicationView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: projectApplicationEditUrl(':orgAlias', ':projectId', ':applicationId'),
    component: ProjectApplicationEditView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: projectCreateUrl(':orgAlias'),
    component: ProjectCreateView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    path: projectRequestBudgetUrl(':orgAlias', ':id'),
    component: ProjectRequestBudgetView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    path: projectSubmitWorksheetUrl(':orgAlias', ':id'),
    component: ProjectAddWorksheetView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: projectClaimExpenseUrl(':orgAlias', ':id'),
    component: ProjectClaimExpenseView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: projectEditTaskUrl(':orgAlias', ':id', ':taskId'),
    component: ProjectEditTaskView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    path: projectViewTaskUrl(':orgAlias', ':id', ':taskId', ''),
    component: ProjectTaskView,
    exact: false,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: projectCreateTaskUrl(':orgAlias', ':id'),
    component: ProjectCreateTaskView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    path: projectViewUrl(':orgAlias', ':id', `:tab(${Object.values(PROJECT_TABS).join('|')})?`),
    component: ProjectView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: projectWorksheetImportUrl(':orgAlias', ':id', ':step'),
    component: ProjectWorksheetImportView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireAnyRole: true,
  },
  {
    path: projectEditUrl(':orgAlias', ':id'),
    component: ProjectEditView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    component: TimeTrackerView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: timeTrackerUrl(':orgAlias', `:tab(${Object.values(TIME_TRACKER_TABS).join('|')})?`),
    requireProvider: true,
  },
];

export default projectsRouteConfig;
