import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { isArray, pick, startCase } from 'lodash';
import queryString from 'query-string';

import { ORDERING_DIRECTION } from 'core/assets/js/constants';
import { USER_EMPLOYMENT_TYPE, USER_EMPLOYMENT_TYPE_LABEL } from 'people/assets/js/constants';
import SelectableListFilterField from 'core/assets/js/components/FilterFields/SelectableListFilterField.jsx';
import AutoSuggestField from 'core/assets/js/components/ReduxFormFields/AutoSuggestField.jsx';
import SearchForm from 'core/assets/js/components/SearchForm.jsx';

class TaskManagerSearch extends React.Component {
  constructor(props) {
    super(props);
    this.getSearchSpec = this.getSearchSpec.bind(this);
    this.getInitialValues = this.getInitialValues.bind(this);
  }

  getInitialValues() {
    const { location } = this.props;
    const searchSpec = this.getSearchSpec();


    const filtersList = [];
    searchSpec.filters.forEach((f) => {
      // Add all paramNames if is array
      if (isArray(f.paramName)) {
        f.paramName.forEach(paramName => filtersList.push(paramName));
      }

      filtersList.push(f.paramName);
    });

    let values = queryString.parse(location.search);
    values = pick(values, ['kw', 'ordering'].concat(filtersList));

    return values;
  }

  getSearchSpec() {
    const employmentTypeOptions = Object.values(USER_EMPLOYMENT_TYPE).map(type => ({
      text: startCase(USER_EMPLOYMENT_TYPE_LABEL[type]),
      value: type,
    }));

    const filters = [
      {
        label: 'Employment Type',
        paramName: 'employmentType',
        fieldComponent: SelectableListFilterField,
        multiple: false,
        options: employmentTypeOptions,
      },
    ];

    const spec = {
      defaultOrdering: {
        direction: ORDERING_DIRECTION.DESC,
        sortBy: 'created_at',
      },
      orderingOptions: [
        { text: 'Join Date', value: 'created_at' },
        { text: 'Last Name', value: 'last_name' },
      ],
      searchTerm: {
        placeholder: 'Search managers',
        paramName: 'kw',
        component: AutoSuggestField,
      },
      filters,
    };

    return spec;
  }

  render() {
    const { onFiltersToggle, filtersOpen } = this.props;

    return (
      <SearchForm
        name="taskManagers"
        initialValues={this.getInitialValues()}
        searchSpec={this.getSearchSpec()}
        onFiltersToggle={onFiltersToggle}
        filtersOpen={filtersOpen}
      />
    );
  }
}

TaskManagerSearch.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
};

export default withRouter(TaskManagerSearch);
