export const HOMEPAGE_URL = 'https://talentdesk.io';
export const mainGuestUrl = () => '/welcome';
export const privacyUrl = (anchor = '') => {
  const hash = anchor ? `#${anchor}` : '';
  return `https://talentdesk.io/legal/privacy-policy${hash}`;
};
export const knowledgebaseUrl = () => 'https://www.talentdesk.io/knowledge-base';
export const knowledgebaseTicketUrl = () => 'https://www.talentdesk.io/knowledge-base/kb-tickets/new';
export const tncUrl = () => 'https://talentdesk.io/legal/tnc';
export const enterpriseAgreementUrl = () => 'https://talentdesk.io/legal/enterprise-agreement';
export const aboutusUrl = () => 'https://talentdesk.io/about';

export const uploaderOrgPath = (orgId) => {
  if (orgId) {
    return `organizations/org-${orgId}`;
  }
  return 'organizations';
};

export const uploaderCompanyPath = (userId) => {
  if (userId) {
    return `companies/user-${userId}`;
  }
  return 'companies';
};

export const uploaderInterviewPath = (orgId, interviewId, submissionId) => {
  if (orgId && interviewId && submissionId) {
    return `interviews/org-${orgId}/interview-${interviewId}/submission-${submissionId}`;
  }
  if (orgId && interviewId) {
    return `interviews/org-${orgId}/interview-${interviewId}`;
  }
  if (orgId) {
    return `interviews/org-${orgId}`;
  }
  return 'interviews';
};

export const uploaderOnDemandInvoicePath = (orgId, id) => {
  if (orgId && id) {
    return `on-demand-invoices/org-${orgId}/billable-${id}`;
  }
  if (orgId) {
    return `on-demand-invoices/org-${orgId}`;
  }
  return 'on-demand-invoices';
};

export const uploaderAccountingReportPath = () => (
  'accounting-reports/'
);

export const uploaderETLPath = () => (
  'etl-exports/'
);

export const uploaderInvoicesExportsPath = () => (
  'invoices-exports/'
);

export const uploaderNotesPath = (orgId, userId) => {
  if (userId && orgId) {
    return `notes/attachments/org-${orgId}/${userId}`;
  }
  if (orgId) {
    return `notes/attachments/org-${orgId}`;
  }
  return 'notes/attachments';
};

export const uploaderContactsCsvPath = orgId => `contacts/org-${orgId}/csv/`;

export const uploaderExperiencePath = userId => `experience/user-${userId}`;

export const uploaderContactPath = (orgId, contactId) => {
  if (contactId && orgId) {
    return `contacts/org-${orgId}/contact-${contactId}`;
  }
  if (orgId) {
    return `contacts/org-${orgId}`;
  }
  return 'contacts';
};

export const uploaderContactAttachmentsPath = (orgId, contactId) => {
  if (contactId && orgId) {
    return  `contacts/org-${orgId}/contact-${contactId}/attachments`;
  }
  if (orgId) {
    return `contacts/org-${orgId}/attachments`;
  }
  return 'contacts/attachments';
};

export const uploaderProjectExpensesPath = (orgId, projectId) => (
  `projects/org-${orgId}/proj-${projectId}/expenses`
);

export const uploaderProjectTasksPath = (orgId, projectId) => (
  `projects/org-${orgId}/proj-${projectId}/expenses`
);

export const uploaderProjectWorksheetsPath = (orgId, projectId) => (
  `projects/org-${orgId}/proj-${projectId}/worksheets`
);

export const uploaderProFormaInvoicesPath = orgId => `org-${orgId}/proforma-invoices`;

// Api
export const errorReportApiUrl = () => '/api/error-report';

export const googlePlaceApiUrl = (type) => {
  return `https://maps.googleapis.com/maps/api/place/${type}/json`;
};

export const uploaderProjectWorksheetsCsvPath = (orgId, projectId) => `projects/org-${orgId}/proj-${projectId}/csv/`;

export const uploaderFilePath = key => `files/${key}`;

export const importFilePath = orgId => `imports/org-${orgId}`;

export const referralProgramUrl = () => 'https://www.talentdesk.io/referral-program';
