/* eslint no-underscore-dangle: off */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { change, Field, reduxForm, formValueSelector } from 'redux-form';
import moment from 'moment';
import 'react-dates/initialize';
import { DayPickerSingleDateController } from 'react-dates';
import { timeOffUrl } from 'accounts/urls';

import SelectField from 'core/assets/js/components/ReduxFormFields/SelectField.jsx';
import InputField, { INPUT_TYPE } from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { API_DATE_FORMAT, BS_STYLE } from 'core/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';

const FORM_NAME = 'timeOff-form';

class TimeOffForm extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
  }

  render() {
    const { activeOrg, handleSubmit, history, submitting, periodStart, periodEnd } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <div className="rounded shadow-sm bg-gray mb-4">
          <div className="row mx-0 justify-content-center flex-column-reverse flex-md-row">
            <div className="form-container bg-white worksheet-form col pt-3 pb-5">
              <div className="row">
                <div className="col-12 col-md-6">
                  <Field
                    name="reason"
                    component={SelectField}
                    optionsMapping={[
                      { text: 'Annual leave', value: 'Annual leave' },
                      { text: 'Busy in a project', value: 'Busy in a project' },
                      { text: 'Low Availability', value: 'Low Availability' },
                      { text: 'Non-working Day', value: 'Non-working Day' },
                      { text: 'Unavailable', value: 'Unavailable' },
                      { text: 'Vacation', value: 'Vacation' },
                    ]}
                    label="Reason"
                    sele
                    defaultOptionText="Select"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6">
                  <Field
                    name="periodStart"
                    component={InputField}
                    type="date"
                    label="From"
                    rest={{
                      timeFormat: false,
                    }}
                    disablePastDates
                  />
                </div>

                <div className="col-12 col-md-6">
                  <Field
                    // Force component to remount so that it shows the proper initialVisibleMonth
                    key={moment(periodStart, API_DATE_FORMAT).format()}
                    name="periodEnd"
                    component={InputField}
                    type="date"
                    label="To"
                    viewDate={periodStart ? moment(periodStart).toDate() : undefined}
                    rest={{
                      timeFormat: false,
                    }}
                    isValid={
                      current => current.isSameOrAfter(periodStart
                        ? moment(periodStart, API_DATE_FORMAT)
                        : moment(),
                      )
                    }
                  />
                </div>

                <div className="col-12">
                  <p className="discreet font-smaller mt-n3">
                    Managers will be notified that during the above period you
                    wont&apos;t be able for work.
                  </p>
                </div>
                <div className="col-12">
                  <Field
                    name="description"
                    type={INPUT_TYPE.TEXTAREA}
                    component={InputField}
                    label="Description"
                  />
                </div>
              </div>
            </div>

            <div className="date-range-wrapper col px-0 flex-grow-0 calendar-wrapper--read-only">
              <DayPickerSingleDateController
                withPortal={false}
                // Force component to remount so that it shows the proper initialVisibleMonth
                key={moment(periodStart, API_DATE_FORMAT).format()}
                noBorder
                hideKeyboardShortcutsPanel
                focused
                initialVisibleMonth={() => {
                  if (periodStart) {
                    return moment(periodStart, API_DATE_FORMAT);
                  }

                  return moment();
                }}
                isDayHighlighted={(date) => {
                  if (!periodStart || !periodEnd) {
                    return false;
                  }

                  return (
                    moment(date).isSameOrAfter(
                      moment(periodStart, API_DATE_FORMAT),
                      'day',
                    )
                    && moment(date).isSameOrBefore(
                      moment(periodEnd, API_DATE_FORMAT),
                      'day',
                    )
                  );
                }}
              />
            </div>
          </div>
        </div>

        <div className="mt-4 text-right button-container">
          <TDButton
            type="button"
            className="btn btn-lg btn-default"
            disabled={submitting}
            label="Cancel"
            onClick={() => {
              history.push(timeOffUrl(activeOrg.alias));
            }}
          />

          <TDButton
            type="submit"
            variant={BS_STYLE.PRIMARY}
            disabled={submitting}
            label="Schedule days off"
          />
        </div>
      </form>
    );
  }
}
TimeOffForm.propTypes = {
  activeOrg: orgSpec.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  periodStart: PropTypes.string,
  periodEnd: PropTypes.string,
};
TimeOffForm.defaultProps = {
  periodStart: '',
  periodEnd: '',
};

const WorksheetFormRedux = reduxForm({
  form: FORM_NAME,
})(TimeOffForm);

const selector = formValueSelector(FORM_NAME);

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
  periodStart: selector(state, 'periodStart'),
  periodEnd: selector(state, 'periodEnd'),
});

const mapDispatchToProps = dispatch => ({
  dispatch,
  change,
});

const WorksheetFormReduxConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorksheetFormRedux);

export default withRouter(WorksheetFormReduxConnected);
