import { NODE_ENV, ENV_PRODUCTION } from 'core/assets/js/config/settings';
import systemSettings from 'core/assets/js/config/system-public';

export const SYSTEM = systemSettings[NODE_ENV];

export const PUBLIC_WEBSITE_URL = 'https://www.talentdesk.io/';

export const USER_ACTION = {
  ACCEPT: 'accept',
  APPROVE: 'approve',
  CANCEL: 'cancel',
  CONFIRM: 'confirm',
  DECLINE: 'decline',
  REJECT: 'reject',
  MARK_PAID: 'markpaid',
  CHANGE_NUMBER: 'change-number',
  VOID: 'void',
};

export const USER_ACTION_VALUES = Object.values(USER_ACTION);

export const VIEW_TYPES = {
  TABLE: 'table',
  GRID: 'grid',
};

export const PEOPLE_LIST_VIEW_TYPE_KEY = 'people-list-view-type';

export const USER_TYPE = {
  ORG_CREATOR: 1,
  FIN_CONTROLLER: 2,
  MANAGER: 3,
  PROVIDER: 4,
};

export const USER_TYPE_VALUES = Object.values(USER_TYPE);

export const USER_TYPE_LABEL = {
  [USER_TYPE.ORG_CREATOR]: 'Organisation Owner',
  [USER_TYPE.FIN_CONTROLLER]: 'Financial Controller',
  [USER_TYPE.MANAGER]: 'Manager',
  [USER_TYPE.PROVIDER]: 'Provider',
};

export const USER_CARD_STATUS = {
  PENDING: 1,
  PENDING_APPROVAL: 2,
  AMEND_REQUESTED: 3,
  APPROVED: 4,
  REJECTED: 5,
  DEACTIVATED: 6,
  PENDING_MANAGER_ANSWERS: 7,
};

export const USER_CARD_STATUS_VALUES = Object.values(USER_CARD_STATUS);

export const USER_CARD_STATUS_LABEL = {
  [USER_CARD_STATUS.PENDING]: 'Pending onboarding form',
  [USER_CARD_STATUS.PENDING_MANAGER_ANSWERS]: 'Pending manager answers',
  [USER_CARD_STATUS.PENDING_APPROVAL]: 'Pending approval',
  [USER_CARD_STATUS.AMEND_REQUESTED]: 'Onboarding form amend requested',
  [USER_CARD_STATUS.APPROVED]: 'Approved',
  [USER_CARD_STATUS.REJECTED]: 'Rejected',
  [USER_CARD_STATUS.DEACTIVATED]: 'Deactivated',
};

export const USER_CARD_STATUS_CLASS = {
  [USER_CARD_STATUS.PENDING]: 'pending',
  [USER_CARD_STATUS.PENDING_MANAGER_ANSWERS]: 'pending',
  [USER_CARD_STATUS.PENDING_APPROVAL]: 'pending',
  [USER_CARD_STATUS.AMEND_REQUESTED]: 'pending',
  [USER_CARD_STATUS.APPROVED]: 'accepted',
  [USER_CARD_STATUS.REJECTED]: 'rejected',
  [USER_CARD_STATUS.DEACTIVATED]: 'deactivated',
};

export const UTM_KEYS = {
  SOURCE: 'utm_source',
  CAMPAIGN: 'utm_campaign',
  MEDIUM: 'utm_medium',
  TERM: 'utm_term',
  CONTENT: 'utm_content',
  REFERER: 'utm_referer',
  HSPAGEID: 'utm_hspageid',
  HSTK: 'utm_hstk',
};

export const ACCEPTED_UTM_KEYS = [
  UTM_KEYS.SOURCE,
  UTM_KEYS.CAMPAIGN,
  UTM_KEYS.MEDIUM,
  UTM_KEYS.TERM,
  UTM_KEYS.CONTENT,
  UTM_KEYS.REFERER,
  UTM_KEYS.HSPAGEID,
  UTM_KEYS.HSTK,
];

export const REQ_TYPE = {
  GET: 0,
  POST: 1,
  PUT: 2,
  DELETE: 3,
};

export const MULTISELECT_TYPE = {
  SIMPLE: 'icon-simple',
  SKILL: 'icon-skill',
  MEMBER: 'icon-member',
  LANGUAGE: 'icon-language',
};

export const MULTISELECT_VARIANT = {
  DEFAULT: 'default',
  COMPACT: 'COMPACT',
};

export const TIMELINE_ITEM_TYPES = {
  DEFAULT: 'default',
  EMPHASIZE: 'emphasize',
  APPROVED: 'approved',
  REJECTED: 'rejected',
  PENDING: 'pending',
};

export const LOGO = {
  DEFAULT: '/img/talentdesk_logo.svg',
  WHITE: '/img/talentdesk_logo-white.svg',
  SMALL: '/img/talentdesk_logo_small.svg',
};
export const PPH_LOGO = {
  DEFAULT: '/img/pph_logo.png',
};

export const CUSTOM_ICONS = {
  ARROW_DOTTED_LEFT: '/img/arrow-dotted-left.svg',
  ARROW_DOTTED_RIGHT: '/img/arrow-dotted-right.svg',
  BREADCRUMBS: '/img/ic_breadcrumb.svg',
  BREADCRUMBS_ACTIVE: '/img/ic_breadcrumb_active.svg',
  ESIGN: '/img/esign.svg',
  FILE_CHECKBOX: '/img/file-checkbox.svg',
  QUICKBOOKS: '/img/quickbooks.svg',
  TALENTDESK: '/img/talentdesk_logo_small.svg',
  XERO: '/img/xero.svg',
};

export const TABS_STYLING_VARIATION = {
  DEFAULT: 1,
  SIMPLE: 2,
  PILLS: 3,
};

export const TOASTR_SETTINGS = {
  POSITION: 'top-right',
  POSITION_CENTER: 'top-center',
  TIMEOUT: 8000,
  TRANSITION_IN: 'bounceIn',
  TRANSITION_OUT: 'fadeOut',
};

export const TOASTR_DEFAULT_PROPS = {
  position: TOASTR_SETTINGS.POSITION,
  timeOut: TOASTR_SETTINGS.TIMEOUT,
  transitionIn: TOASTR_SETTINGS.TRANSITION_IN,
  transitionOut: TOASTR_SETTINGS.TRANSITION_OUT,
};

export const SOCIAL_ICONS = {
  LINKEDIN: 'fab fa-linkedin',
  FACEBOOK: 'fab fa-facebook-square',
  GIHUB: 'fab fa-github',
  TWITTER: 'fab fa-twitter',
  DRIBBBLE: 'fab fa-dribbble',
  BEHANCE: 'fab fa-behance',
  INSTAGRAM: 'fab fa-instagram',
  YOUTUBE: 'fab fa-youtube',
};

export const ICON = {
  ADD: 'fal fa-plus',
  ADDRESS_CARD: 'fal fa-address-card',
  ADD_CIRCLE: 'fal fa-plus-circle',
  ARROWS_ROTATE: 'fal fa-arrows-rotate',
  ARROW_DOWN: 'fal fa-chevron-down',
  ARROW_LEFT: 'fal fa-arrow-left',
  ARROW_LEFT_LONG: 'fal fa-long-arrow-left',
  ARROW_RIGHT: 'fal fa-arrow-right',
  ARROW_RIGHT_ARROW_RIGHT_DUOTONE: 'fa-duotone fa-arrow-right-arrow-left',
  ARROW_RIGHT_2: 'fal fa-arrow-right',
  ARROW_RIGHT_EMPHASIS: 'fas fa-chevron-right',
  ARROW_RIGHT_LONG: 'fal fa-long-arrow-right',
  ARROW_UP: 'fal fa-chevron-up',
  ARROW_UP_RIGHT_FROM_SQUARE: 'fal fa-arrow-up-right-from-square',
  ATTACHMENT: 'fal fa-paperclip',
  BALLOT_CHECK_DUOTONE: 'fa-duotone fa-ballot-check',
  BAN: 'fad fa-ban',
  BANK: 'fal fa-university',
  BAR_PROGRESS_DUOTONE: 'fa-duotone fa-bars-progress',
  BOLD: 'fas fa-bold',
  BOLT_DUOTONE: 'fas fa-bolt',
  BOOK: 'fal fa-book',
  BRACKETS: 'fas fa-brackets-square',
  BRIEFCASE_DUOTONE: 'fa-duotone fa-briefcase',
  BROWSER: 'fal fa-browser',
  BUILDING: 'fal fa-building',
  BUILDING_DUOTONE: 'fa-duotone fa-building',
  BULLSEYE_ARROW_DUOTONE: 'fa-duotone fa-bullseye-arrow',
  CALCULATOR: 'fa fa-calculator',
  CALCULATOR_DUOTONE: 'fa-duotone fa-calculator',
  CALCULATOR_SIMPLE_SOLID: 'fa-sharp fa-solid fa-calculator-simple',
  CALENDAR: 'fal fa-calendar',
  CALENDAR_ALT: 'fal fa-calendar-alt',
  CALENDAR_DUOTONE: 'fa-duotone fa-calendar',
  CAMERA_DUOTONE: 'fa-duotone fa-camera-retro',
  CARET_DOWN: 'fal fa-caret-down',
  CARET_UP: 'fal fa-caret-up',
  CHART_BAR: 'fas fa-chart-bar',
  CIRCLE: 'fal fa-circle',
  CIRCLE_CHECK: 'fal fa-circle-check',
  CIRCLE_HALF_STROKE: 'fal fa-circle-half-stroke',
  CIRCLE_PLUS: 'fal fa-circle-plus',
  CHECKBOX: 'fal fa-check-square',
  CHECKMARK: 'fal fa-check',
  CHECKMARK_SOLID: 'fas fa-check',
  CHECKMARK_CIRCLE: 'fal fa-check-circle',
  CHECKMARK_DUOTONE: 'fad fa-check-double',
  CHECK_CIRCLE: 'fal fa-circle-check',
  CHEVRON_DOWN: 'fal fa-chevron-down',
  CHEVRON_LEFT: 'fal fa-chevron-left',
  CHEVRON_RIGHT: 'fal fa-chevron-right',
  CHEVRON_RIGHT_EMPHASIS: 'fas fa-chevron-right',
  CHEVRON_UP: 'fal fa-chevron-up',
  CIRCLE_CHECVRON_RIGHT: 'fal fa-circle-chevron-right',
  CIRCLE_INFO: 'fal fa-circle-info',
  CLIPBOARD_LIST_CHECK: 'fa-duotone fa-clipboard-list-check',
  CLIPBOARD_LIST_CHECK_DUOTONE: 'fa-duotone fa-clipboard-list-check',
  CLOCK: 'fal fa-clock',
  CLOCK_DUOTONE: 'fad fa-clock',
  CLOCK_SOLID: 'fas fa-clock',
  CODE: 'fa-duotone fa-code',
  CODE_SOLID: 'fas fa-code',
  COG: 'fal fa-cog',
  COINS: 'fal fa-coins',
  COINS_DUOTONE: 'fa-duotone fa-coins',
  COLLAPSE: 'fal fa-compress-alt',
  COMMENT: 'fal fa-comment',
  COMMENTS: 'fal fa-comments',
  COMMENTS_DUOTONE: 'fa-duotone fa-comments',
  COPY: 'fas fa-copy',
  CREDIT_CARD: 'fal fa-credit-card',
  CROSS: 'fal fa-times',
  CROSS_CIRCLE: 'fal fa-times-circle',
  CROSS_SOLID: 'fas fa-times',
  CROWN: 'fas fa-crown',
  CSV: 'fal fa-file-csv',
  DASHBOARD: 'fal fa-grip-horizontal',
  DOUBLE_DRAG_HANDLE: 'fas fa-grip-dots-vertical',
  DOWNLOAD: 'fal fa-cloud-download',
  DOWNLOAD_CIRCLE: 'fal fa-arrow-circle-down',
  DRAG_HANDLE: 'fal fa-ellipsis-v',
  DROPDOWN: 'fal fa-chevron-square-down',
  EDIT: 'fal fa-pencil',
  ELLIPSIS: 'fas fa-ellipsis-h',
  EMAIL: 'fal fa-at',
  ENVELOPE_INVITE: 'fal fa-envelope-open-text',
  ENVELOPE_OPEN_DOLLAR: 'fal fa-envelope-open-dollar',
  ENVELOPE_OPEN_DUOTONE: 'fad fa-envelope-open-text',
  ERROR: 'fal fa-times-circle',
  EXCHANGE: 'fal fa-exchange',
  EXPAND: 'fal fa-expand-alt',
  EXPORT: 'fal fa-file-export',
  EYE_SLASH: 'fa fa-eye-slash',
  FEEDBACK: 'fal fa-comment-alt',
  FILES: 'fal fa-photo-video',
  FILE_CERTIFICATE_DUOTONE: 'fa-duotone fa-file-certificate',
  FILE_CHART_LINE: 'fal fa-file-chart-line',
  FILE_CHART_PIE_DUOTONE: 'fa-duotone fa-file-chart-pie',
  FILE_DOCUMENT: 'fal fa-file-alt',
  FILE_DUOTONE: 'fa-duotone fa-file',
  FILE_INVOICE_DUOTONE: 'fa-duotone fa-file-invoice',
  FILE_SIGNATURE_DUOTONE: 'fa-duotone fa-file-signature',
  FILTERS: 'fal fa-filter',
  FINANCE: 'fal fa-calculator-alt',
  GEAR_DUOTONE: 'fa-duotone fa-gear',
  FOLDERS_DUOTONE: 'fa-duotone fa-folders',
  GLOBE: 'fal fa-globe',
  GRAPH: 'fal fa-chart-bar',
  GRID: 'fal fa-grid-2',
  GRID_HORIZONTAL: 'fa-duotone fa-grid-horizontal',
  HAND_DUOTONE: 'fa-duotone fa-hand',
  HAND_HOLDING_DOLLAR_DUOTONE: 'fa-duotone fa-hand-holding-dollar',
  HAND_HOLDING_USD: 'fas fa-hand-holding-usd',
  HEADING: 'fas fa-heading',
  HEART: 'fal fa-heart',
  HEART_FILLED: 'fas fa-heart',
  HOME: 'fal fa-home',
  HOURGLASS_END: 'fal fa-hourglass-end',
  HOURGLASS_END_DUOTONE: 'fad fa-hourglass-end',
  HOURGLASS_START: 'fal fa-hourglass-start',
  HOUSE_LAPTOP_DUOTONE: 'fad fa-house-laptop',
  IMPORT_FILE: 'fal fa-file-import',
  IMPORT_FILE_DUOTONE: 'fad fa-file-import',
  INFO: 'fal fa-question-circle',
  INFO_2: 'fal fa-info-circle',
  INVITE: 'fal fa-user-plus',
  INVOICE: 'fal fa-file-invoice',
  INVOICE_DUOTONE: 'fa-duotone fa-file-invoice',
  ITALIC: 'fas fa-italic',
  KEY: 'fal fa-key',
  KEY_DUOTONE: 'fa-duotone fa-key',
  LAPTOP_CODE_DUOTONE: 'fa-duotone fa-laptop-code',
  LAPTOP_HOUSE: 'fal fa-laptop-house',
  LIGHTBULB_DOLLAR_DUOTONE: 'fa-duotone fa-lightbulb-dollar',
  LIGHTBULB_ON_DUOTONE: 'fa-duotone fa-lightbulb-on',
  LINK: 'fal fa-link',
  LINK_SOLID: 'fas fa-link',
  LIST: 'fal fa-list',
  LIST_CHECK_DUOTONE: 'fa-duotone fa-list-check',
  LIST_DROPDOWN: 'fal fa-list-dropdown',
  LOCK_SOLID: 'fa fa-lock',
  LOGOUT: 'fal fa-sign-out',
  MAP_MARKER_CHECK: 'fal fa-map-marker-check',
  MAP_PIN: 'fas fa-map-marker-alt',
  MAP_PIN_ALT: 'fal fa-map-pin',
  MEGAPHONE_DUOTONE: 'fa-duotone fa-megaphone',
  MONEY_BILL: 'fal fa-money-bill',
  MONEY_CHECK_DUOTONE: 'fa-duotone fa-money-check',
  MONEY_BILL_1_DUOTONE: 'fa-duotone fa-money-bill-1',
  MENU: 'fal fa-bars',
  MUSIC_DUOTONE: 'fa-duotone fa-music',
  NOTE: 'fal fa-sticky-note',
  NOTIFICATIONS: 'fal fa-bell',
  NUMBER_ONE: 'fas fa-1',
  OBJECT_GROUP: 'fal fa-object-group',
  OLIST: 'fas fa-list-ol',
  PAINT_BRUSH_DUOTONE: 'fa-duotone fa-paintbrush-pencil',
  PDF_DUOTONE: 'fad fa-file-pdf',
  PEN: 'fal fa-pen',
  PEN_LINE_DUOTONE: 'fad fa-pen-line',
  PEN_RULER_DUOTONE: 'fad fa-pen-ruler',
  PEN_RULER_LIGHT: 'fal fa-pen-ruler',
  PEOPLE: 'fa fa-user-circle',
  PEOPLE_CLOCK: 'fal fa-user-clock',
  PEOPLE_NINJA: 'fas fa-user-ninja',
  PEOPLE_TIE: 'fas fa-user-tie',
  PERCENTAGE: 'fal fa-percentage',
  PERCENTAGE_ALT: 'fal fa-badge-percent',
  PERCENTAGE_DUOTONE: 'fa-duotone fa-percent',
  PHONE: 'fal fa-phone',
  PHONE_ALT: 'fal fa-phone-alt',
  PIN: 'fal fa-thumbtack',
  PIN_SOLID: 'fas fa-thumbtack',
  PLAY: 'fas fa-play',
  POPUP_WINDOW: 'fal fa-window-restore',
  PRINT: 'fal fa-print',
  PROFILE: 'fal fa-id-badge',
  PROJECTS: 'fal fa-tasks-alt',
  PROJECT_APPLICATIONS: 'fal fa-clipboard-user',
  QUESTION_CIRCLE: 'fal fa-circle-question',
  RADIO: 'fal fa-circle',
  RECEIPT_DUOTONE: 'fa-duotone fa-receipt',
  REMOVE: 'fal fa-minus',
  REMOVE_CIRCLE: 'fal fa-minus-circle',
  RESEND: 'fal fa-redo',
  ROCKET: 'fal fa-rocket',
  SCALE: 'fal fa-scale-balanced',
  SCALE_DUOTONE: 'fa-duotone fa-scale-balanced',
  SEARCH: 'fal fa-search',
  SETTINGS: 'fal fa-toggle-on',
  SIGNATURE: 'fal fa-signature',
  SLASH_FORWARD: 'fa-solid fa-slash-forward',
  SORTING_ALPHA_ASC: 'fas fa-sort-alpha-down',
  SORTING_ALPHA_DESC: 'fas fa-sort-alpha-down-alt',
  SORTING_ASC: 'fas fa-sort-amount-down-alt',
  SORTING_DESC: 'fas fa-sort-amount-up-alt',
  SORTING_NUM_ASC: 'fas fa-sort-numeric-up-alt',
  SORTING_NUM_DESC: 'fas fa-sort-numeric-down-alt',
  STAR: 'fal fa-star',
  STARS: 'fal fa-stars',
  STAR_FILLED: 'fas fa-star',
  STOP: 'fas fa-stop',
  STRIKE_THROUGH: 'fas fa-strikethrough',
  SUCCESS: 'fal fa-check-circle',
  TAG: 'fal fa-tag',
  TABLE: 'fal fa-table',
  TASKS: 'fal fa-tasks',
  TEXT: 'fas fa-text',
  TEXTFIELD: 'fal fa-font',
  TEXT_BLOB: 'fal fa-align-left',
  THUMBSUP: 'fal fa-thumbs-up',
  THUMBSUP_SOLID: 'fas fa-thumbs-up',
  TIMER: 'fal fa-timer',
  TIMER_DUOTONE: 'fa-duotone fa-timer',
  TIMES: 'fal fa-times',
  TIMES_CIRCLE: 'fal fa-times-circle',
  TOGGLE_DROPDOWN: 'fas fa-ellipsis-v',
  TOOLBOX_DUOTONE: 'fa-duotone fa-toolbox',
  TRANSPORTER: 'fad fa-transporter-2',
  TYPEWRITER_DUOTONE: 'fa-duotone fa-typewriter',
  ULIST: 'fas fa-list-ul',
  UNWATCH: 'fas fa-eye-slash',
  USD_CIRCLE: 'fal fa-usd-circle',
  USER: 'fal fa-user-alt',
  USERS: 'fal fa-users',
  USERS_SOLID: 'fas fa-users',
  USER_CHECK_DUOTONE: 'fa-duotone fa-user-check',
  USER_CIRCLE_DUOTONE: 'fa-duotone fa-circle-user',
  USER_CLOCK: 'fal fa-user-clock',
  USER_CROWN: 'fas fa-user-crown',
  USER_CROWN_DUOTONE: 'fa-duotone fa-user-crown',
  USER_DUOTONE: 'fa-duotone fa-user',
  USER_GEAR_DUOTONE: 'fa-duotone fa-user-gear',
  USER_GEAR_SOLID: 'fa-solid fa-user-gear',
  USER_LIGHT: 'fal fa-user',
  USER_SOLID: 'fa-solid fa-user',
  USER_TAG: 'fal fa-user-tag',
  USER_TAG_DUOTONE: 'fa-duotone fa-user-tag',
  USER_TIE_SOLID: 'fa-solid fa-user-tie',
  USERS_DUOTONE: 'fa-duotone fa-users',
  USERS_GEAR_DUOTONE: 'fa-duotone fa-users-gear',
  USERS_MEDICAL_DUOTONE: 'fa-duotone fa-users-medical',
  VIDEO: 'fal fa-film',
  VIEW: 'fal fa-eye',
  WALLET: 'fal fa-wallet',
  WALLET_DUOTONE: 'fa-duotone fa-wallet',
  WARNING: 'fal fa-exclamation-triangle',
  WARNING_CIRCLE: 'fal fa-exclamation-circle',
  WATCH: 'fas fa-eye',
  ...SOCIAL_ICONS,
};

export const BS_SIZE = {
  DEFAULT: '',
  LARGE: 'lg',
  MEDIUM: 'md',
  SMALL: 'sm',
  XSMALL: 'xs',
};

export const BS_STYLE = {
  DANGER: 'danger',
  DEFAULT: 'default',
  INFO: 'info',
  LIGHT: 'light',
  LINK: 'link',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SUCCESS: 'success',
  WARNING: 'warning',
  WISE: 'wise',
};

export const BUTTON_STYLE = {
  [BS_STYLE.DEFAULT]: `btn-${BS_STYLE.DEFAULT}`,
  [BS_STYLE.DANGER]: `btn-${BS_STYLE.DANGER}`,
  [BS_STYLE.LINK]: `btn-${BS_STYLE.LINK}`,
  [BS_STYLE.PRIMARY]: `btn-${BS_STYLE.PRIMARY}`,
  [BS_STYLE.SECONDARY]: `btn-${BS_STYLE.SECONDARY}`,
  [BS_STYLE.SUCCESS]: `btn-${BS_STYLE.SUCCESS}`,
  [BS_STYLE.WARNING]: `btn-${BS_STYLE.WARNING}`,
  [BS_STYLE.WISE]: `btn-${BS_STYLE.WISE}`,
};

export const BS_TOOLTIP_PLACEMENT = {
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
  AUTO: 'auto',
};

export const BS_DROPDOWN_PLACEMENT = {
  DOWN: 'down',
  LEFT: 'left',
  RIGHT: 'right',
  UP: 'up',
};

const currentYear = new Date().getFullYear();

export const ORDERING_DIRECTION = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const POPPER_PLACEMENT = {
  AUTO: 'auto',
  BOTTOM: 'bottom',
  BOTTOM_END: 'bottom-end',
  RIGHT: 'right',
  RIGHT_END: 'right-end',
  RIGHT_START: 'right-start',
  TOP_END: 'top-end',
  TOP_LEFT: 'top-left',
};

export const DEFAULT_METATAGS = {
  title: 'Freelancer & Contractor Management System - TalentDesk.io',
  url: 'https://talentdesk.io/',
  description: 'The right freelancer & contractor management software mitigates risks and reduces unnecessary admin and stress. Automate global payments & customize your onboarding processes to save time now!',
  keywords: 'freelancer management platform, TalentDesk.io, FMS',
  imgUrl: 'https://app.talentdesk.io/img/talentdesk_platform_og_share.png',
  copyright: `Copyright TalentDesk.io ${currentYear}. All Rights Reserved.`,
  twitter: '@TalentDeskIO',
};

/*
  This is used as enum values for the following database columns:

  - documents.currency
  - invoice_items.currency
  - invoice_items.service_order_currency
  - invoices.currency
  - profiles.currency
  - rate_adjustments.currency
  - rates.currency
  - service_order_items.service_order_item_currency
  - service_orders.service_order_currency
  - subscriptions.currency
  - task_assignments.currency
*/
export const CURRENCY = {
  AED: 'aed',
  AUD: 'aud',
  BGN: 'bgn',
  BRL: 'brl',
  CAD: 'cad',
  CHF: 'chf',
  CLP: 'clp',
  COP: 'cop',
  CZK: 'czk',
  DKK: 'dkk',
  EUR: 'eur',
  GBP: 'gbp',
  HKD: 'hkd',
  HRK: 'hrk',
  HUF: 'huf',
  IDR: 'idr',
  INR: 'inr',
  JPY: 'jpy',
  MXN: 'mxn',
  MYR: 'myr',
  NOK: 'nok',
  NZD: 'nzd',
  PHP: 'php',
  PLN: 'pln',
  RON: 'ron',
  SEK: 'sek',
  SGD: 'sgd',
  TRY: 'try',
  TWD: 'twd',
  USD: 'usd',
  ZAR: 'zar',
  ZMW: 'zmw',
};

export const CURRENCY_VALUES = Object.values(CURRENCY);

export const BANK_CURRENCY = {
  AED: 'aed',
  ARS: 'ars',
  AUD: 'aud',
  BGN: 'bgn',
  BRL: 'brl',
  CAD: 'cad',
  CHF: 'chf',
  CLP: 'clp',
  CNY: 'cny',
  COP: 'cop',
  CZK: 'czk',
  DKK: 'dkk',
  EGP: 'egp',
  EUR: 'eur',
  FJD: 'fjd',
  GBP: 'gbp',
  GEL: 'gel',
  GHS: 'ghs',
  HKD: 'hkd',
  HRK: 'hrk',
  HUF: 'huf',
  IDR: 'idr',
  ILS: 'ils',
  INR: 'inr',
  JPY: 'jpy',
  KES: 'kes',
  KRW: 'krw',
  LKR: 'lkr',
  MAD: 'mad',
  MXN: 'mxn',
  MYR: 'myr',
  NGN: 'ngn',
  NOK: 'nok',
  NPR: 'npr',
  NZD: 'nzd',
  PEN: 'pen',
  PHP: 'php',
  PLN: 'pln',
  RON: 'ron',
  RUB: 'rub',
  SAR: 'sar',
  SEK: 'sek',
  SGD: 'sgd',
  THB: 'thb',
  TRY: 'try',
  TWD: 'twd',
  UAH: 'uah',
  USD: 'usd',
  VND: 'vnd',
  ZAR: 'zar',
  ZMW: 'zmw',
};

/*
  This is used as enum values for the following database columns:

  - invoice_items.organization_currency
  - organizations.currency
  - organizations.invoicing_target_currency
  - organizations.invoicing_system_currency
  - projects.currency
  - service_order_items.organization_currency
  - service_orders.organization_currency

  Also used when populating currency_pair_history
*/
export const ORGANIZATION_CURRENCY = {
  AUD: 'aud',
  CAD: 'cad',
  EUR: 'eur',
  GBP: 'gbp',
  HKD: 'hkd',
  INR: 'inr',
  MYR: 'myr',
  NZD: 'nzd',
  USD: 'usd',
  JPY: 'jpy',
  ZAR: 'zar',
  SGD: 'sgd',
};

export const CURRENCY_LABEL = {
  [BANK_CURRENCY.AED]: 'AED',
  [BANK_CURRENCY.ARS]: 'ARS',
  [BANK_CURRENCY.AUD]: 'AUD',
  [BANK_CURRENCY.BGN]: 'BGN',
  [BANK_CURRENCY.BRL]: 'BRL',
  [BANK_CURRENCY.CAD]: 'CAD',
  [BANK_CURRENCY.CHF]: 'CHF',
  [BANK_CURRENCY.CLP]: 'CLP',
  [BANK_CURRENCY.COP]: 'COP',
  [BANK_CURRENCY.CNY]: 'CNY',
  [BANK_CURRENCY.CZK]: 'CZK',
  [BANK_CURRENCY.DKK]: 'DKK',
  [BANK_CURRENCY.EGP]: 'EGP',
  [BANK_CURRENCY.EUR]: 'EUR',
  [BANK_CURRENCY.FJD]: 'FJD',
  [BANK_CURRENCY.GBP]: 'GBP',
  [BANK_CURRENCY.GEL]: 'GEL',
  [BANK_CURRENCY.GHS]: 'GHS',
  [BANK_CURRENCY.HKD]: 'HKD',
  [BANK_CURRENCY.HRK]: 'HRK',
  [BANK_CURRENCY.HUF]: 'HUF',
  [BANK_CURRENCY.IDR]: 'IDR',
  [BANK_CURRENCY.ILS]: 'ILS',
  [BANK_CURRENCY.INR]: 'INR',
  [BANK_CURRENCY.JPY]: 'JPY',
  [BANK_CURRENCY.KES]: 'KES',
  [BANK_CURRENCY.KRW]: 'KRW',
  [BANK_CURRENCY.LKR]: 'LKR',
  [BANK_CURRENCY.MAD]: 'MAD',
  [BANK_CURRENCY.MXN]: 'MXN',
  [BANK_CURRENCY.MYR]: 'MYR',
  [BANK_CURRENCY.NGN]: 'NGN',
  [BANK_CURRENCY.NOK]: 'NOK',
  [BANK_CURRENCY.NPR]: 'NPR',
  [BANK_CURRENCY.NZD]: 'NZD',
  [BANK_CURRENCY.PEN]: 'PEN',
  [BANK_CURRENCY.PHP]: 'PHP',
  [BANK_CURRENCY.PLN]: 'PLN',
  [BANK_CURRENCY.RON]: 'RON',
  [BANK_CURRENCY.RUB]: 'RUB',
  [BANK_CURRENCY.SAR]: 'SAR',
  [BANK_CURRENCY.SEK]: 'SEK',
  [BANK_CURRENCY.SGD]: 'SGD',
  [BANK_CURRENCY.THB]: 'THB',
  [BANK_CURRENCY.TRY]: 'TRY',
  [BANK_CURRENCY.TWD]: 'TWD',
  [BANK_CURRENCY.UAH]: 'UAH',
  [BANK_CURRENCY.USD]: 'USD',
  [BANK_CURRENCY.VND]: 'VND',
  [BANK_CURRENCY.ZAR]: 'ZAR',
  [BANK_CURRENCY.ZMW]: 'ZMW',
};

export const CURRENCY_SYMBOL = {
  [BANK_CURRENCY.AED]: 'Dhs',
  [BANK_CURRENCY.ARS]: 'ARS$',
  [BANK_CURRENCY.AUD]: 'AU$',
  [BANK_CURRENCY.BGN]: 'лв.',
  [BANK_CURRENCY.BRL]: 'R$',
  [BANK_CURRENCY.CAD]: 'c$',
  [BANK_CURRENCY.CHF]: 'fr.',
  [BANK_CURRENCY.CLP]: 'CLP$',
  [BANK_CURRENCY.CNY]: '¥',
  [BANK_CURRENCY.COP]: 'COL$',
  [BANK_CURRENCY.CZK]: 'Kč',
  [BANK_CURRENCY.DKK]: 'kr.',
  [BANK_CURRENCY.EGP]: 'E£',
  [BANK_CURRENCY.EUR]: '€',
  [BANK_CURRENCY.FJD]: 'FJ$',
  [BANK_CURRENCY.GBP]: '£',
  [BANK_CURRENCY.GEL]: '₾',
  [BANK_CURRENCY.GHS]: 'GH₵',
  [BANK_CURRENCY.HKD]: 'HK$',
  [BANK_CURRENCY.HRK]: 'kn',
  [BANK_CURRENCY.HUF]: 'Ft',
  [BANK_CURRENCY.IDR]: 'Rp',
  [BANK_CURRENCY.ILS]: '₪',
  [BANK_CURRENCY.INR]: '₹',
  [BANK_CURRENCY.JPY]: '¥',
  [BANK_CURRENCY.KES]: 'KSh',
  [BANK_CURRENCY.KRW]: '₩',
  [BANK_CURRENCY.LKR]: 'Rs',
  [BANK_CURRENCY.MAD]: 'DH',
  [BANK_CURRENCY.MXN]: 'MX$',
  [BANK_CURRENCY.MYR]: 'RM',
  [BANK_CURRENCY.NGN]: '₦',
  [BANK_CURRENCY.NOK]: 'kr',
  [BANK_CURRENCY.NPR]: 'रू',
  [BANK_CURRENCY.NZD]: 'NZ$',
  [BANK_CURRENCY.PEN]: 'S/',
  [BANK_CURRENCY.PHP]: '₱',
  [BANK_CURRENCY.PLN]: 'zł',
  [BANK_CURRENCY.RON]: 'L',
  [BANK_CURRENCY.RUB]: '₽',
  [BANK_CURRENCY.SAR]: 'SR',
  [BANK_CURRENCY.SEK]: 'kr',
  [BANK_CURRENCY.SGD]: 'S$',
  [BANK_CURRENCY.THB]: '฿',
  [BANK_CURRENCY.TRY]: '₺',
  [BANK_CURRENCY.TWD]: 'NT$',
  [BANK_CURRENCY.UAH]: '₴',
  [BANK_CURRENCY.USD]: '$',
  [BANK_CURRENCY.VND]: '₫',
  [BANK_CURRENCY.ZAR]: 'R',
  [BANK_CURRENCY.ZMW]: 'ZMW',
};

export const CURRENCY_BY_SYMBOL = Object.entries(CURRENCY_SYMBOL).reduce(
  (acc, [currency, symbol]) => {
    acc[symbol] = currency;
    return acc;
  },
  {},
);

export const CURRENCIES_WITHOUT_DECIMALS = [
  CURRENCY.JPY,
];

export const CURRENCY_DESCRIPTION = {
  [BANK_CURRENCY.AED]: 'United Arab Emirates dirham',
  [BANK_CURRENCY.ARS]: 'Argentine peso',
  [BANK_CURRENCY.AUD]: 'Australian dollar',
  [BANK_CURRENCY.BGN]: 'Bulgarian lev',
  [BANK_CURRENCY.BRL]: 'Brazilian real',
  [BANK_CURRENCY.CAD]: 'Canadian dollar',
  [BANK_CURRENCY.CHF]: 'Swiss franc',
  [BANK_CURRENCY.CLP]: 'Chilean peso',
  [BANK_CURRENCY.CNY]: 'Chinese renminbi',
  [BANK_CURRENCY.COP]: 'Colombian peso',
  [BANK_CURRENCY.CZK]: 'Czech koruna',
  [BANK_CURRENCY.DKK]: 'Danish krone',
  [BANK_CURRENCY.EGP]: 'Egyptian pound',
  [BANK_CURRENCY.EUR]: 'Euro',
  [BANK_CURRENCY.FJD]: 'Fijian Dollar',
  [BANK_CURRENCY.GBP]: 'Pound sterling',
  [BANK_CURRENCY.GEL]: 'Georgian lari',
  [BANK_CURRENCY.GHS]: 'Ghanaian cedi',
  [BANK_CURRENCY.HKD]: 'Hong Kong dollar',
  [BANK_CURRENCY.HRK]: 'Croatian kuna',
  [BANK_CURRENCY.HUF]: 'Hungarian forint',
  [BANK_CURRENCY.IDR]: 'Indonesian rupiah',
  [BANK_CURRENCY.ILS]: 'Israeli shekel',
  [BANK_CURRENCY.INR]: 'Indian rupee',
  [BANK_CURRENCY.JPY]: 'Japanese yen',
  [BANK_CURRENCY.KES]: 'Kenyan shilling',
  [BANK_CURRENCY.KRW]: 'South Korean won',
  [BANK_CURRENCY.LKR]: 'Sri Lankan rupee',
  [BANK_CURRENCY.MAD]: 'Moroccan dirham',
  [BANK_CURRENCY.MXN]: 'Mexican peso',
  [BANK_CURRENCY.MYR]: 'Malaysian ringgit',
  [BANK_CURRENCY.NGN]: 'Nigerian naira',
  [BANK_CURRENCY.NOK]: 'Norwegian krone',
  [BANK_CURRENCY.NPR]: 'Nepalese rupee',
  [BANK_CURRENCY.NZD]: 'New Zealand dollar',
  [BANK_CURRENCY.PEN]: 'Peruvian sol',
  [BANK_CURRENCY.PHP]: 'Philippine peso',
  [BANK_CURRENCY.PLN]: 'Polish złoty',
  [BANK_CURRENCY.RON]: 'Romanian leu',
  [BANK_CURRENCY.RUB]: 'Russian ruble',
  [BANK_CURRENCY.SAR]: 'Saudi Arabian Riyal',
  [BANK_CURRENCY.SEK]: 'Swedish krona',
  [BANK_CURRENCY.SGD]: 'Singapore dollar',
  [BANK_CURRENCY.THB]: 'Thai baht',
  [BANK_CURRENCY.TRY]: 'Turkish lira',
  [BANK_CURRENCY.TWD]: 'New Taiwan dollar',
  [BANK_CURRENCY.UAH]: 'Ukrainian hryvnia',
  [BANK_CURRENCY.USD]: 'United States dollar',
  [BANK_CURRENCY.VND]: 'Vietnamese dong',
  [BANK_CURRENCY.ZAR]: 'South African rand',
  [BANK_CURRENCY.ZMW]: 'Zambian Kwacha',
};

export const CURRENCY_TO_COUNTRY_CODE = {
  [BANK_CURRENCY.AED]: 'AE',
  [BANK_CURRENCY.ARS]: 'AR',
  [BANK_CURRENCY.AUD]: 'AU',
  [BANK_CURRENCY.BGN]: 'BG',
  [BANK_CURRENCY.BRL]: 'BR',
  [BANK_CURRENCY.CAD]: 'CA',
  [BANK_CURRENCY.CHF]: 'CH',
  [BANK_CURRENCY.CLP]: 'CL',
  [BANK_CURRENCY.CNY]: 'CN',
  [BANK_CURRENCY.COP]: 'CO',
  [BANK_CURRENCY.CZK]: 'CZ',
  [BANK_CURRENCY.DKK]: 'DK',
  [BANK_CURRENCY.EGP]: 'EG',
  [BANK_CURRENCY.EUR]: 'EU',
  [BANK_CURRENCY.FJD]: 'FJ',
  [BANK_CURRENCY.GBP]: 'GB',
  [BANK_CURRENCY.GEL]: 'GE',
  [BANK_CURRENCY.GHS]: 'GH',
  [BANK_CURRENCY.HKD]: 'HK',
  [BANK_CURRENCY.HRK]: 'HR',
  [BANK_CURRENCY.HUF]: 'HU',
  [BANK_CURRENCY.IDR]: 'ID',
  [BANK_CURRENCY.ILS]: 'IL',
  [BANK_CURRENCY.INR]: 'IN',
  [BANK_CURRENCY.JPY]: 'JP',
  [BANK_CURRENCY.KES]: 'KE',
  [BANK_CURRENCY.KRW]: 'KR',
  [BANK_CURRENCY.LKR]: 'LK',
  [BANK_CURRENCY.MAD]: 'MA',
  [BANK_CURRENCY.MXN]: 'MX',
  [BANK_CURRENCY.MYR]: 'MY',
  [BANK_CURRENCY.NGN]: 'NG',
  [BANK_CURRENCY.NOK]: 'NO',
  [BANK_CURRENCY.NPR]: 'NP',
  [BANK_CURRENCY.NZD]: 'NZ',
  [BANK_CURRENCY.PEN]: 'PE',
  [BANK_CURRENCY.PHP]: 'PH',
  [BANK_CURRENCY.PLN]: 'PL',
  [BANK_CURRENCY.RON]: 'RO',
  [BANK_CURRENCY.RUB]: 'RU',
  [BANK_CURRENCY.SAR]: 'SA',
  [BANK_CURRENCY.SEK]: 'SE',
  [BANK_CURRENCY.SGD]: 'SG',
  [BANK_CURRENCY.THB]: 'TH',
  [BANK_CURRENCY.TRY]: 'TR',
  [BANK_CURRENCY.TWD]: 'TW',
  [BANK_CURRENCY.UAH]: 'UA',
  [BANK_CURRENCY.USD]: 'US',
  [BANK_CURRENCY.VND]: 'VN',
  [BANK_CURRENCY.ZAR]: 'ZA',
  [BANK_CURRENCY.ZMW]: 'ZM',
};

// Note, any currency listed here will not be available in the list of selectable
// currencies generated by BANK_CURRENCY_LABEL_DESCRIPTION_SELECT
export const CURRENCY_BLACKLIST = [BANK_CURRENCY.NGN];

export const CURRENCY_SYMBOL_SELECT = Object.values(CURRENCY).map(c => ({
  value: c, text: CURRENCY_SYMBOL[c],
}));

export const ORGANIZATION_CURRENCY_SYMBOL_LABEL_SELECT = Object.values(ORGANIZATION_CURRENCY)
  .map(c => ({ value: c, text: `${CURRENCY_LABEL[c]} (${CURRENCY_SYMBOL[c]})` }));

export const BANK_CURRENCY_LABEL_DESCRIPTION_SELECT = Object
  .values(BANK_CURRENCY)
  .filter(c => !CURRENCY_BLACKLIST.includes(c))
  .map(c => ({
    value: c, text: `${CURRENCY_LABEL[c]} - ${CURRENCY_DESCRIPTION[c]}`,
  }));

export const BANK_CURRENCY_SYMBOL_LABEL_SELECT = Object.values(BANK_CURRENCY).map(c => ({
  value: c, text: `${CURRENCY_LABEL[c]} (${CURRENCY_SYMBOL[c]})`,
}));

export const LAYOUTS = {
  DEFAULT: 'LayoutDefault',
  ERROR: 'LayoutError',
  NAKED: 'LayoutNaked',
  PLAIN: 'LayoutPlain',
  PLAIN_SOLO: 'LayoutPlainSolo',
  WITH_HEADER: 'LayoutWithHeader',
};

export const MAX_UPLOAD_FILES = 12;

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
export const MIME_TYPES = {
  DOCUMENTS: [
    'application/pdf',
    'application/excel',
    'application/x-excel',
    'application/x-msexcel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain',
    'text/richtext',
    'application/vnd.ms-excel',
  ],
  IMAGES: [
    'image/bmp',
    'image/png',
    'image/pjpeg',
    'image/jpeg',
    'image/tiff',
    'image/x-tiff',
    'image/gif',
  ],
  PDF_ALLOWED_IMAGES: [
    'image/png',
    'image/jpeg',
  ],
  VIDEOS: [
    'video/x-msvideo',
    'video/mp4',
    'video/mpeg',
    'video/3gpp',
    'video/ogg',
    'video/H264',
    'video/x-ms-wmv',
    'video/x-flv',
    'video/quicktime',
    'image/avif',
  ],
  AUDIO: [
    'audio/mpeg',
    'audio/mp3',
  ],
  COMPRESSED: [
    'application/zip',
    'application/vnd.rar',
    'application/x-7z-compressed',
  ],
};

export const SEARCH_FILTER_TYPES = {
  RANGE: 'range',
  RATING: 'rating',
  SELECT: 'select',
  TEXT: 'text',
  DATE: 'date',
  DATERANGE: 'date-range',
};

export const PASSWORD_STRENGTH_LEVELS = {
  WEAK: {
    THRESHOLD: 10,
    LABEL: 'Weak',
    ICON: ICON.WARNING,
    CLASSNAME: 'danger',
    SHOW_TEXT: true,
  },
  OK: {
    THRESHOLD: 16,
    LABEL: 'OK',
    ICON: ICON.WARNING,
    CLASSNAME: 'primary',
    SHOW_TEXT: true,
  },
  VERY_STRONG: {
    THRESHOLD: 18,
    LABEL: 'Strong',
    ICON: ICON.SUCCESS,
    CLASSNAME: 'success',
    SHOW_TEXT: false,
  },
};

export const PASSWORD_STRENGTH_REWARD_POINTS = {
  SPECIAL_CHARACTER: 2,
  NUMBER: 2,
  LOWER_CASE: 2,
  UPPER_CASE: 2,
  MINIMUM_CHARS: 5,
  COMMON_PASSWORD: 5,
};

export const PASSWORD_MIN_CHARS = 6;

export const OTHER_VALUE = -1;

export const PAGE_SIZE = 10;

export const PROJECT_TABS = {
  DASHBOARD: 'dashboard',
  TEAM: 'team',
  WORKSHEETS: 'worksheets',
  EXPENSES: 'expenses',
  TASKS: 'tasks',
  PURCHASE_ORDERS: 'purchase-orders',
  ACTIVITY: 'activity',
  APPLICATIONS: 'applications',
};

export const PROFILE_TABS = {
  DOCUMENTS: 'documents',
  EXPERIENCE: 'experience',
  NOTES: 'notes',
  ONBOARDING: 'onboarding',
  PAYMENT_DETAILS: 'payment-details',
  PROJECTS: 'projects',
  RATES: 'rates',
  REVIEWS: 'reviews',
  TAX_INFORMATION: 'tax-information',
};

export const TASK_TABS = {
  DASHBOARD: 'dashboard',
  MANAGERS: 'managers',
  DISCUSSION: 'discussion',
  DELIVERABLES: 'deliverables',
  WORKSHEETS: 'worksheets',
};

export const COOKIE_MAX_AGE = 7 * 24 * 60 * 60 * 1000; // 7 days

export const DATE_FORMAT_DEFAULT = 'DD MMM YYYY';
export const DATE_FORMAT_FULL_MONTH_NAME = 'DD MMMM YYYY';
export const DATE_FORMAT_NO_DAY = 'MMM YYYY';
export const DATE_FORMAT_NO_YEAR = 'MMMM DD';
export const TIME_FORMAT = 'HH:mm:ss';
export const DATETIME_FORMAT_DEFAULT = 'YYYY-MM-DD HH:mm:ss';
export const DATETIME_FORMAT_HUMAN_FRIENDLY = 'DD MMM YYYY HH:mm:ss';
export const DATETIME_FORMAT_HUMAN_FRIENDLY_2 = 'DD MMM YYYY, HH:mm';
export const API_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_FORMAT_CHARTS = '%d %b %y';
export const DATETIME_FORMAT_ISO = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

export const customerSupportEmail = 'support@talentdesk.io';

export const ACCESS_CONTROL_ALLOWED_ACTIONS = {
  DOCUMENT: 'document',
  RATE_ADJUSTMENT: 'rate_adjustment',
  WORKSHEET: 'worksheet',
  PROFILE: 'profile',
  USER_CARD: 'usercard',
  PROFORMA_INVOICE: 'pro_forma_invoice',
  PROJECT: 'project',
  PROJECT_APPLICATION: 'project_application',
  PROJECT_MEMBER: 'project_member',
  PURCHASE_ORDER: 'purchase_order',
  EXPENSE: 'expense',
  INVOICE: 'invoice',
  TASK: 'task',
  TASK_ATTACHMENT: 'task_attachment',
  TASK_ASSIGNMENT: 'task_assignment',
};

export const LOG_LEVEL = {
  INFO: 'info',
  DEBUG: 'debug',
  ERROR: 'error',
  WARN: 'warn',
};

export const LOG_LEVEL_PRIORITY = {
  [LOG_LEVEL.DEBUG]: 0,
  [LOG_LEVEL.INFO]: 1,
  [LOG_LEVEL.WARN]: 2,
  [LOG_LEVEL.ERROR]: 3,
};

export const MEMBER_SEARCH_TARGET = {
  PROJECTS: 'projects',
  MANAGERS: 'managers',
  MANAGERS_STRICT: 'managers-strict',
  MANAGERS_WITH_BUDGET: 'managers_with_budget',
  PROVIDERS: 'providers',
  PROJECT_PROVIDERS: 'project-providers',
  TASK_ASSIGNMENT_ELIGIBLES: 'task-assignment-eligibles',
};

export const MULTISELECT_TARGET = {
  ...MEMBER_SEARCH_TARGET,
  SKILLS: 'skills',
  LANGUAGES: 'languages',
  TASKS: 'tasks',
};

export const DATE_DURATION = {
  CURRENT_MONTH: 1,
  THREE_MONTHS: 3,
  SIX_MONTHS: 6,
  ONE_YEAR: 12,
  FROM_START: 0,
};

export const DATE_DURATION_API_KEY = {
  [DATE_DURATION.CURRENT_MONTH]: 'currentMonth',
  [DATE_DURATION.THREE_MONTHS]: 'threeMonths',
  [DATE_DURATION.SIX_MONTHS]: 'sixMonths',
  [DATE_DURATION.ONE_YEAR]: 'oneYear',
  [DATE_DURATION.FROM_START]: 'fromStart',
};

export const DATE_DURATION_LABEL = {
  [DATE_DURATION.CURRENT_MONTH]: 'Current month',
  [DATE_DURATION.THREE_MONTHS]: 'Last three months',
  [DATE_DURATION.SIX_MONTHS]: 'Last six months',
  [DATE_DURATION.ONE_YEAR]: 'Last one year',
  [DATE_DURATION.FROM_START]: 'Start of project',
};

// Possible values of `event.key` in event handling
export const EVENT_KEY = {
  BACKSPACE: 'Backspace',
  ENTER: 'Enter',
  TAB: 'Tab',
  ESCAPE: 'Escape',
};

export const REACT_SELECT_STYLES = {
  control: (styles, state) => {
    const { isFocused } = state;
    return ({
      ...styles,
      borderRadius: '3px',
      boxShadow: 'none',
      borderColor: isFocused ? '#52C2CC' : '#e0e4e5',
      backgroundColor: 'white',
      ':hover': {
        borderColor: '#52C2CC',
      },
    });
  },
  option: (provided, state) => {
    const { isSelected, isFocused } = state;
    let bgColor = '#fff';

    if (isFocused) {
      bgColor = '#f7f8f9';
    }
    if (isSelected) {
      bgColor = '#EDF0F2';
    }

    return ({
      ...provided,
      backgroundColor: bgColor,
      color: '#333',
      padding: 8,
      ':active': {
        backgroundColor: '#e0e4e5',
      },
      ':hover': {
        backgroundColor: '#e0e4e5',
        cursor: 'pointer',
      },
    });
  },
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: '#eaeaea',
      color: 'black',
      cursor: 'pointer',
    },
  }),
  menuPortal: base => ({
    ...base, zIndex: 9999,
  }),
};

export const REACT_SELECT_SKILL_MULTISELECT = {
  ...REACT_SELECT_STYLES,
  multiValueLabel: styles => ({
    ...styles,
    backgroundColor: 'transparent',
    border: '1px solid rgba(21,132,198,0.1)',
    color: '#505050',
    fontSize: '13px',
    lineHeight: '16px',
  }),
  multiValueRemove: () => ({
    display: 'none',
  }),
  multiValue: styles => ({
    ...styles,
    backgroundColor: 'transparent',
  }),
  menuList: style => ({
    ...style,
    '.btn-vote, .badge': {
      display: 'none',
    },
  }),
};

export const MODAL_SIZES = {
  SMALL: 'sm',
  LARGE: 'lg',
  EXTRA_LARGE: 'xl',
  DEFAULT: '',
};

export const IMG_SIZE = {
  ICON: 16,
  XSMALL: 30,
  SMALL: 36,
  SMEDIUM: 48,
  MEDIUM: 60,
  LARGE: 100,
  XLARGE: 150,
  XXLARGE: 250,
};

export const USER_STATUS = {
  ACTIVE: 1,
  DEACTIVATED: 2,
  DELETED: 3,
};

export const USER_STATUS_LABEL = {
  [USER_STATUS.ACTIVE]: 'Active',
  [USER_STATUS.DEACTIVATED]: 'Deactivated',
  [USER_STATUS.DELETED]: 'Deleted',
};

export const ERROR_REPORT_TYPE = {
  GENERAL: 0,
  PAYMENTS: 1,
};

export const ERROR_REPORT_EMAIL = {
  [ERROR_REPORT_TYPE.GENERAL]: NODE_ENV === ENV_PRODUCTION ? 'dev-team@talentdesk.io' : 'info_at_talentdesk.io@mailinator.com',
  [ERROR_REPORT_TYPE.PAYMENTS]: NODE_ENV === ENV_PRODUCTION ? 'dev-team@talentdesk.io' : 'info_at_talentdesk.io@mailinator.com',
};

export const WORKSHEET_AUTO_SAVE_STATES = {
  SAVING: 'Saving changes...',
  SAVED: 'All changes saved',
};

export const AUTO_SAVE_INTERVAL_TIME = 30 * 1000; // 30 sec

export const START_OF_TIME = '2017-01-01';

export const USER_CARD_DEACTIVATE_COUNT_ITEMS = {
  EXPENSES: 'expenses',
  PRO_FORMA_INVOICES: 'proFormaInvoices',
  PROJECT_MEMBERSHIPS: 'projectsMemberships',
  PROJECTS: 'projects',
  PURCHASE_ORDERS: 'purchaseOrders',
  TASKS: 'tasks',
  TASK_ASSIGNMENTS: 'taskAssignments',
  WORKSHEETS: 'worksheets',
};

export const USER_CARD_DEACTIVATE_COUNT_ITEM_LABELS = {
  [USER_CARD_DEACTIVATE_COUNT_ITEMS.EXPENSES]: 'Expenses',
  [USER_CARD_DEACTIVATE_COUNT_ITEMS.PRO_FORMA_INVOICES]: 'Proforma invoices',
  [USER_CARD_DEACTIVATE_COUNT_ITEMS.PROJECT_MEMBERSHIPS]: 'Project memberships',
  [USER_CARD_DEACTIVATE_COUNT_ITEMS.PROJECTS]: 'In progress projects',
  [USER_CARD_DEACTIVATE_COUNT_ITEMS.PURCHASE_ORDERS]: 'Budget requests',
  [USER_CARD_DEACTIVATE_COUNT_ITEMS.TASKS]: 'In progress tasks',
  [USER_CARD_DEACTIVATE_COUNT_ITEMS.TASK_ASSIGNMENTS]: 'In progress task assignments',
  [USER_CARD_DEACTIVATE_COUNT_ITEMS.WORKSHEETS]: 'Worksheets',
};

export const BOOTSTRAP_BREAKPOINTS = {
  SMALL: 576,
  MEDIUM: 768,
  LARGE: 992,
  EXTRA_LARGE: 1200,
  EXTRA_EXTRA_LARGE: 1400,
};

export const NAVBAR_SECTIONS = {
  SETTINGS: 'settings',
};

export const HS_FORM_ID_REG_EX = /hb-form-id-([a-zA-Z0-9_-]+)/;

export const FILE_UPLOAD_MAXIMUM_SIZE_BYTES = 100 * 1024 * 1024; // 100mb

export const URLS_WITHOUT_CHAT = ['/discover'];

export const TITLES = ['Dr', 'Miss', 'Mr', 'Mrs', 'Ms'];

export const GENDERS = ['female', 'male'];

export const SLACK_CHANNELS = {
  AML_CHECK: 'aml-checks',
  AOR_ONBOARDING: 'aor-onboarding',
  AOR_HIGH_RISK_RESPONSES: 'aor-high-risk-responses',
};

export const TEXT_AREA_DEFAULT_HEIGHT = 146;
