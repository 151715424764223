export const CLIENT_IDS = {
  APARTMENT_THERAPY: 485,
  ATRAIN: 44,
  BERLITZ: 351,
  BERLITZ_EU: 395,
  EUROMONITOR: 91,
  LC: 120,
  LEADING_EDUCATORS: 525,
  MEMRISE: 123,
  OBAN: 59,
  OUTSIDEMAGAZINE: 83,
  PURECONTENT: 62,
  SCHOOLKIT: 74,
  SEMINAR_CO: 526,
  VEEVA: 490,
  WISE_PUBLISHING_INC: 461,
  FIVESTONE_STUDIOS: 524,
};

export const CALENDAR_TYPE = {
  NONE: 0,
  DATE_PICKER: 1,
  DATE_RANGE_PICKER: 2,
};

export const DATA_REPORTS = {
  BUDGET_SPENT_PER_USER: 1,
  MONTHLY_BUDGET_SPENT_PER_PROJECT: 2,
  MONTHLY_BUDGET_SPENT_PER_PROJECT_AND_PROVIDER: 10,
  MONTHLY_BUDGET_SPENT_PER_PROJECT_AND_USER_OBAN: 11,
  MONTHLY_BUDGET_SPENT_PER_PROJECT_AND_USER_ATRAIN: 12,
  MONTHLY_BUDGET_SPENT_PER_PROJECT_AND_USER_WISE: 190,
  USAGE_REPORT_INVOICE_DATE_EUROMONITOR: 13,
  USAGE_REPORT_OUTSIDEMAGAZINE: 17,
  TASK_ASSIGNMENT_BUDGET_USAGE_REPORT_OUTSIDEMAGAZINE: 18,
  USAGE_REPORT_VEEVA: 19,
  MONTHLY_ACTIVE_PROJECTS: 20,
  ACTIVE_PROVIDERS: 21,
  ACTIVE_PROVIDERS_EUROMONITOR: 22,
  ACTIVE_PROVIDERS_OUTSIDEMAGAZINE: 23,
  ACTIVE_PROVIDERS_OBAN: 25,
  PROVIDERS_BERLITZ: 26,
  INVOICE_BREAKDOWN_EUROMONITOR: 30,
  EXPERT_BALANCE_STATEMENT_EUROMONITOR: 31,
  BALANCE_STATEMENT: 32,
  INVOICE_SAP_LC: 33,
  INVOICE_APARTMENT_THERAPY: 34,
  MONTHLY_TASK_ASSIGNMENTS: 40,
  CONTACTS_EUROMONITOR: 50,
  SALES_TAX: 70,
  SERVICE_ORDERS_PENDING_INVOICING: 80,
  ACTIVE_PROVIDERS_RATES_SCHOOLKIT: 180,
  ACTIVE_PROVIDERS_SCHOOLKIT: 181,
  USAGE_INCLUDING_RAISED_REPORT_EUROMONITOR: 90,
  USAGE_REPORT_TASK_DEADLINE_EUROMONITOR: 91,
  DOCUMENT_CONFIRMATIONS: 100,
  EXPORT_MANAGERS: 110,
  PROVIDERS_REPORT_VEEVA: 120,
  PROVIDERS_PROJECTS_REPORT_VEEVA: 121,
  PROVIDER_PAYMENTS_REPORT_VEEVA: 130,
  TD_PAYMENTS_REPORT_VEEVA: 140,
  TIME_TRACKER_USAGE_DETAILS: 150,
  MEMRISE_TASK_WORKSHEETS: 160,
  POSSIBLE_DUPLICATE_WORKSHEETS_VEEVA: 170,
  ACTIVE_TASK_ASSIGNEES: 'active-task-assignees',
  USAGE_REPORT_LEADING_EDUCATORS: 'usage-report-leading-educators',
  PROVIDERS_FIVESTONE_STUDIOS: 'providers-fivestone-studios',
};
