import { LAYOUTS } from 'core/assets/js/constants';

// Views
import ManagersBudgetView from 'finance/assets/js/ManagersBudgetView.jsx';
import ProjectsBudgetView from 'finance/assets/js/ProjectsBudgetView.jsx';
import PurchaseOrdersView from 'finance/assets/js/PurchaseOrdersView.jsx';
import PurchaseOrderView from 'finance/assets/js/PurchaseOrderView.jsx';
import ManagerBudgetBreakdownView from 'finance/assets/js/ManagerBudgetBreakdownView.jsx';
import WorksheetCreateView from 'finance/assets/js/WorksheetCreateView.jsx';
import WorksheetsView from 'finance/assets/js/WorksheetsView.jsx';
import WorksheetView from 'finance/assets/js/WorksheetView.jsx';
import WorksheetEditView from 'finance/assets/js/WorksheetEditView.jsx';
import ExpensesListView from 'finance/assets/js/ExpensesListView.jsx';
import ExpenseCreateView from 'finance/assets/js/ExpenseCreateView.jsx';
import ExpenseView from 'finance/assets/js/ExpenseView.jsx';
import InvoicesView from 'finance/assets/js/InvoicesView.jsx';
import InvoiceView from 'finance/assets/js/InvoiceView.jsx';
import ExpenseEditView from 'finance/assets/js/ExpenseEditView.jsx';
import ExpenseOnDemandInvoiceView from 'finance/assets/js/ExpenseOnDemandInvoiceView.jsx';
import WorksheetOnDemandInvoiceView from 'finance/assets/js/WorksheetOnDemandInvoiceView.jsx';
import * as urls from 'finance/urls';
import {
  BILLING_TABS, ON_DEMAND_INVOICE_STEP, US_TAX_FILING_TABS_VALUES,
} from 'finance/assets/js/constants';
import ProFormaInvoiceCreateView from 'finance/assets/js/ProFormaInvoiceCreateView.jsx';
import ProFormaInvoiceEditView from 'finance/assets/js/ProFormaInvoiceEditView.jsx';
import ProFormaInvoicesView from 'finance/assets/js/ProFormaInvoicesView.jsx';
import ProFormaInvoiceView from 'finance/assets/js/ProFormaInvoiceView.jsx';
import BillingView from 'finance/assets/js/BillingView.jsx';
import USTaxFilingsView from 'finance/assets/js/USTaxFilingsView.jsx';
import USTaxFormReviewView from 'finance/assets/js/USTaxFormReviewView.jsx';

const financeRouteConfig = [
  {
    path: urls.financeManagersBudgetUrl(':orgAlias'),
    component: ManagersBudgetView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    path: urls.financeManagersBudgetBreakdownUrl(':orgAlias', ':id'),
    component: ManagerBudgetBreakdownView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    path: urls.financeProjectsBudgetUrl(':orgAlias'),
    component: ProjectsBudgetView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    path: urls.financePurchaseOrderListUrl(':orgAlias'),
    component: PurchaseOrdersView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    path: urls.financePurchaseOrderViewUrl(':orgAlias', ':id'),
    component: PurchaseOrderView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    path: urls.financeWorksheetsUrl(':orgAlias'),
    component: WorksheetsView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: urls.financeWorksheetsCreateUrl(':orgAlias'),
    component: WorksheetCreateView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: urls.financeWorksheetViewUrl(':orgAlias', ':id'),
    component: WorksheetView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: urls.financeWorksheetEditUrl(':orgAlias', ':worksheetId'),
    component: WorksheetEditView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: urls.financeWorksheetOnDemandInvoiceUrl(':orgAlias', ':id', `:step(${Object.values(ON_DEMAND_INVOICE_STEP).join('|')})`),
    component: WorksheetOnDemandInvoiceView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: urls.financeExpenseOnDemandInvoiceUrl(':orgAlias', ':id', `:step(${Object.values(ON_DEMAND_INVOICE_STEP).join('|')})`),
    component: ExpenseOnDemandInvoiceView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: urls.financeExpensesUrl(':orgAlias'),
    component: ExpensesListView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: urls.financeExpensesCreateUrl(':orgAlias'),
    component: ExpenseCreateView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: urls.financeExpenseViewUrl(':orgAlias', ':id'),
    component: ExpenseView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: urls.financeExpenseEditUrl(':orgAlias', ':expenseId'),
    component: ExpenseEditView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: urls.financeInvoicesUrl(':orgAlias'),
    component: InvoicesView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: urls.financeInvoiceViewUrl(':orgAlias', ':id'),
    component: InvoiceView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    component: ProFormaInvoiceCreateView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: urls.financeProFormaInvoiceCreateUrl(':orgAlias'),
    requireUserIsApprovedOrgMember: true,
  },
  {
    component: ProFormaInvoiceEditView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: urls.financeProFormaInvoiceEditUrl(':orgAlias', ':id'),
  },
  {
    path: urls.financeProFormaInvoicesUrl(':orgAlias'),
    component: ProFormaInvoicesView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: urls.financeProFormaInvoiceViewUrl(':orgAlias', ':id'),
    component: ProFormaInvoiceView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: urls.billingViewUrl(':orgAlias', `:tab(${Object.values(BILLING_TABS).join('|')})`),
    component: BillingView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireOrgCreator: true,
    requireFinController: true,
  },
  {
    path: urls.finance1099FilingsUrl(':orgAlias', `:tab(${US_TAX_FILING_TABS_VALUES.join('|')})?`),
    component: USTaxFilingsView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: urls.financeReviewUSTaxFormUrl(':orgAlias', ':userUSTaxFormId'),
    component: USTaxFormReviewView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
];

export default financeRouteConfig;
