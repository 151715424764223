import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { expenseSpec, worksheetSpec, proFormaInvoiceSpec } from 'finance/assets/js/lib/objectSpecs';
import {
  SERVICE_ORDER_STATUS, SERVICE_ORDER_TIMELINE_ITEM_VARIANT,
} from 'projects/assets/js/constants';
import { BS_TOOLTIP_PLACEMENT, ICON, TIMELINE_ITEM_TYPES } from 'core/assets/js/constants';
import { REPORT_TYPES, REPORT_TYPES_LABEL } from 'finance/assets/js/constants';
import { lineBreakToBr } from 'core/assets/js/lib/utils-jsx';
import Timeline from 'core/assets/js/components/Timeline.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';

const ReportAuditTrail = ({ heading, report, reportType }) => {
  const [open, setOpen] = useState(false);

  let statusText = !report.isDelayed ? report.status || '' : (
    <TDElementWithTooltip
      placement={BS_TOOLTIP_PLACEMENT.BOTTOM}
      tooltipMsg={[
        `The ${REPORT_TYPES_LABEL[reportType]} will not`,
        ` be processed prior to ${report.processAt}`,
      ].join('')}
    >
      <span>
        <span className="mr-2">{report.status}</span>
        <i className={`${ICON.CLOCK}`} />
      </span>
    </TDElementWithTooltip>
  );
  if (!report.isDelayed) {
    if
    ([SERVICE_ORDER_STATUS.APPROVED, SERVICE_ORDER_STATUS.REJECTED].includes(report.statusCode)
      && report.reviewedAt
    ) {
      statusText += ` on ${report.reviewedAt}`;
    } else if (report.statusCode === SERVICE_ORDER_STATUS.CONFIRMED && report.confirmedAt) {
      statusText += ` on ${report.confirmedAt}`;
    } else if (report.statusCode === SERVICE_ORDER_STATUS.CANCELLED && report.lastChangeAt) {
      statusText += ` on ${report.lastChangeAt}`;
    }
  }

  const auditTrailItems = (report.auditTrail || []).map((entry, id) => {
    const date = moment(entry.date);

    return {
      id,
      variant: SERVICE_ORDER_TIMELINE_ITEM_VARIANT[entry.status] || TIMELINE_ITEM_TYPES.EMPHASIZE,
      header: null,
      timestamp: date.fromNow(),
      timestampTitle: date.format('LLL'),
      author: entry.by,
      content: !entry.message ? '' : (
        <p>
          <strong>Message: </strong>
          <br />
          {lineBreakToBr(entry.message)}
        </p>
      ),
      footer: (
        <span className={`float-right status status--${entry.status_label}`}>
          {entry.status_label}
        </span>
      ),
    };
  });

  return (
    <Fragment>
      <div className="audit-trail-open imitate-link" onClick={() => setOpen(true)}>
        {statusText}
      </div>
      <ModalSimple
        body={(
          <Timeline
            key="timeline"
            scrollMode
            items={auditTrailItems}
          />
        )}
        heading={`${heading} audit trail`}
        onClose={() => setOpen(false)}
        open={open}
      />
    </Fragment>
  );
};

ReportAuditTrail.propTypes = {
  heading: PropTypes.string.isRequired,
  report: PropTypes.oneOfType([expenseSpec, worksheetSpec, proFormaInvoiceSpec]).isRequired,
  reportType: PropTypes.oneOf([
    REPORT_TYPES.EXPENSE, REPORT_TYPES.WORKSHEET, REPORT_TYPES.PRO_FORMA_INVOICE,
  ]).isRequired,
};

export default ReportAuditTrail;
