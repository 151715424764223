import PropTypes from 'prop-types';
import React from 'react';

import StatusColFormat from 'finance/assets/js/components/StatusColFormat.jsx';
import {
  IMPORT_STATUS, IMPORT_STATUS_CLASS, IMPORT_STATUS_LABEL,
} from 'importer/assets/js/constants';

const ImportStatus = ({ status }) => {
  const statusLabel = IMPORT_STATUS_LABEL[status];
  return (
    <StatusColFormat
      statusLabel={statusLabel}
      statusClass={IMPORT_STATUS_CLASS[status]}
      title={statusLabel}
    />
  );
};

ImportStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(IMPORT_STATUS)).isRequired,
};

export default ImportStatus;
