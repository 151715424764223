import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';
import { FORM_ERROR } from 'final-form';
import { omit } from 'lodash';
import Big from 'big.js';

import { CURRENCY_SYMBOL, USER_TYPE, TASK_TABS, BS_STYLE, BS_SIZE } from 'core/assets/js/constants';
import { userCardSpec, orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { projectTaskSpec, taskAssignmentSpec, taskAllowedActionsSpec } from 'projects/assets/js/lib/objectSpecs';
import { getDisplayRate } from 'rates/assets/js/lib/utils';
import { projectTaskAssignmentUrl, projectViewTaskUrl } from 'projects/urls';
import { removeTaskMemberDS, projectUpdateTaskAssignmentCapDS } from 'projects/assets/js/data-services/tasks';
import { routerMatchSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { orgUserProfileUrl } from 'people/urls';
import { TASK_ACTION } from 'projects/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { fetchTimeTrackerUnusedRecordsDS } from 'projects/assets/js/data-services/view';
import { WorkTimeBlockTable } from 'projects/assets/js/components/timeTracker/TimeTrackerUnused.jsx';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import { modalOpenAC, modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import TaskParticipantAvatar from 'core/assets/js/components/DiscussionBoard/TaskParticipantAvatar.jsx';
import TaskAssignmentProgressWidget from 'projects/assets/js/components/widgets/TaskAssignmentProgressWidget.jsx';
import TaskAssignmentModal, { getTaskAssignmentModalId } from 'projects/assets/js/components/TaskAssignmentModal.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple';
import { MODAL_ID as RE_OPEN_MODAL_ID } from 'projects/assets/js/components/TaskReOpenModal.jsx';

const getConfirmationModalId = ({ id: userId }) => (
  `user-confirm-removal-${userId}`
);

export const getComponentName = (userId) => (
  `task-assignment-card-${userId}`
);

const TaskAssignmentCard = ({
  activeUserCard,
  taskAllowedActions,
  assignment,
  history,
  pendingActionsCount,
  rootComponentName,
  task,
  task: { ownerId },
  match: { params: { orgAlias, id: projectId, taskId } },
  isRemovalConfirmationOpen,
  dispatch,
  taskAssignmentAnalytics,
  taskRelatedWorkTimeBlocks,
  activeOrg,
  isTaskAssignmentCapModalOpen,
  isLastCard,
}) => {
  const {
    user,
    user: { id: userId, profile },
    rate,
    rate_unit: rateUnit,
    currency,
    currencySymbol,
    allowedActions: {
      canCancel,
      canProposeNewTaskRate,
      canRemoveFromTask,
      canReOpen,
      canSetTaskAssignmentCap,
      canUpdateProgress,
      canViewProgress,
    },
    rateMaxBillingQuantity,
  } = assignment;
  const [openModalTaskRateAdjustment, setOpenModalTaskRateAdjustment] = useState(false);

  const loadTimeBlocks = async () => dispatch(fetchTimeTrackerUnusedRecordsDS({
    componentName: getComponentName(assignment.userId),
    orgAlias,
    userId: assignment.userId,
  }));

  useEffect(() => {
    if (isRemovalConfirmationOpen) {
      loadTimeBlocks();
    }
  }, [isRemovalConfirmationOpen]);

  const handleOpenUserProfile = () => history.push(
    orgUserProfileUrl(orgAlias, USER_TYPE.PROVIDER, userId),
  );
  const taskAssignmentCapModalId = getTaskAssignmentModalId(userId);
  const handleOpenEditTaskAssignment = () => {
    dispatch(modalOpenAC(taskAssignmentCapModalId, {
      rateMaxBillingQuantity, rate, rateUnit,
      currency, assigneeUserId: userId,
    }));
  };

  const confirmationModalId = getConfirmationModalId(user);

  const handleConfirmUserRemoval = async () => {
    dispatch(modalOpenAC(confirmationModalId));
  };
  const handleConfirmRemovalModalClose = () => (
    dispatch(modalCloseAC())
  );
  const onRemoveTaskMemberConfirmation = async ({ deleteWorkTimeBlocks = false } = { }) => (
    dispatch(removeTaskMemberDS({
      orgAlias, projectId, taskId, userId, componentName: rootComponentName, deleteWorkTimeBlocks,
    }))
  );

  const updateTaskAssignmentCap = async (values, assigneeUserId) => {
    try {
      await dispatch(projectUpdateTaskAssignmentCapDS({
        orgAlias,
        projectId,
        taskId,
        assigneeUserId,
        values,
        componentName: rootComponentName,
      }));
      toastr.success('Well Done!', 'Task assignment cap updated successfully.');
      return dispatch(modalCloseAC(taskAssignmentCapModalId));
    } catch (err) {
      const errorData = err.errors;

      if (errorData._error) {
        toastr.error('Oh Snap!', errorData._error);
      }

      return {
        [FORM_ERROR]: errorData._error || 'Oops! Something went wrong. Please try again',
        ...omit(errorData || {}, '_error', '_meta'),
      };
    }
  };
  const handleAdjustRate = () => {
    if (canProposeNewTaskRate?.value) {
      history.push(
        projectViewTaskUrl(
          orgAlias, projectId, taskId, TASK_TABS.DISCUSSION, TASK_ACTION.PROPOSE_TASK_RATE, userId,
        ),
      );
    } else {
      setOpenModalTaskRateAdjustment(true);
    }
  };

  const {
    isManager,
    isOwner,
    canMessageAssignees,
    canAccessPublicMessages,
    canMessageOwner,
  } = taskAllowedActions;

  const actions = [];
  const isAssigneeThemself = userId === activeUserCard.user.id;
  const canViewProviders = (
    isManager || activeOrg.should_provider_view_other_providers
  );

  let discussionBoardUrl = null;
  if (canMessageAssignees && !isAssigneeThemself) {
    discussionBoardUrl = projectViewTaskUrl(
      orgAlias, projectId, taskId, TASK_TABS.DISCUSSION, null, userId,
    );
  } else if (canAccessPublicMessages) {
    discussionBoardUrl = projectViewTaskUrl(orgAlias, projectId, taskId, TASK_TABS.DISCUSSION);
  } else if (canMessageOwner) {
    discussionBoardUrl = projectViewTaskUrl(
      orgAlias, projectId, taskId, TASK_TABS.DISCUSSION, null, ownerId,
    );
  }

  if (discussionBoardUrl) {
    actions.push(
      <Dropdown.Item
        key={`view-discussionboard-${taskId}`}
        onClick={() => history.push(discussionBoardUrl)}
      >
        View task discussion
      </Dropdown.Item>,
    );
  }

  if (isAssigneeThemself || canViewProviders) {
    actions.push(
      <Dropdown.Item
        key={`view-profile-${userId}`}
        onClick={handleOpenUserProfile}
      >
        View profile
      </Dropdown.Item>,
    );
  }

  actions.push(
    <Dropdown.Item
      key={`adjust-agreed-rate-${userId}`}
      onClick={handleAdjustRate}
    >
      Adjust agreed rate
    </Dropdown.Item>,
  );


  if (canSetTaskAssignmentCap) {
    actions.push(
      <Dropdown.Item
        key={`edit-task-assignment-${userId}`}
        onClick={handleOpenEditTaskAssignment}
      >
        Edit assignment cap
      </Dropdown.Item>,
    );
  }

  if (canReOpen) {
    actions.push(
      <Dropdown.Item
        key={`reopen-task-assignment-${userId}`}
        onClick={() => dispatch(modalOpenAC(RE_OPEN_MODAL_ID, { assignment }))}
      >
        Re-open
      </Dropdown.Item>,
    );
  }

  if (canRemoveFromTask) {
    actions.push(
      <Dropdown.Divider key={`dropdown-divider-${userId}`} />,
      <Dropdown.Item
        key={`remove-user-${userId}`}
        onClick={handleConfirmUserRemoval}
        className="text-danger"
      >
        Remove from task
      </Dropdown.Item>,
    );
  }

  if (canCancel) {
    actions.push(
      <Dropdown.Divider key={`dropdown-divider-${userId}`} />,
      <Dropdown.Item
        key={`cancel-invitation-${userId}`}
        onClick={() => history.push([
          projectTaskAssignmentUrl(orgAlias, projectId, taskId, userId),
          `?action=${TASK_ACTION.CANCEL_ASSIGNMENT_INVITATION}`,
        ].join(''))}
        className="text-danger"
      >
        Cancel invitation
      </Dropdown.Item>,
    );
  }

  const hasUnusedTimeBlocks = taskRelatedWorkTimeBlocks && taskRelatedWorkTimeBlocks.length > 0;

  return (
    <div className="task-assignment-card d-flex align-items-center py-4">
      {isTaskAssignmentCapModalOpen && (
        <TaskAssignmentModal
          onSubmit={updateTaskAssignmentCap}
          userId={userId}
        />
      )}
      <TaskParticipantAvatar
        className="mr-4"
        user={user}
        assignment={assignment}
        allowedActions={taskAllowedActions}
        pendingActionsCount={pendingActionsCount}
      />

      <div className="task-assignment-card__user-info">
        <div className="d-flex">
          <p className="text-dark mb-0 task-assignment-card__user-name">
            {profile.name}
          </p>

          {actions.length > 0 && (
            <TDDropButton
              className="ml-auto ml-sm-0"
              data-testid="task-assignment-card-actions"
              stopPropagation
              placement={isLastCard ? 'top-end' : 'bottom-end'}
            >
              {actions}
            </TDDropButton>
          )}
        </div>

        <p data-testid="task-assignment-card-job-title" className="text-muted small mb-2">
          {profile.jobTitle}
          {(isManager || isOwner || isAssigneeThemself) && (
            <React.Fragment>
              <span className="text-muted mx-2">
                &bull;
              </span>
              {getDisplayRate(rate, rateUnit, { currency: currencySymbol })}
            </React.Fragment>
          )}
        </p>

        {(canUpdateProgress || canViewProgress) && (
          <TaskAssignmentProgressWidget
            task={task}
            assignment={assignment}
            rootComponentName={rootComponentName}
            className="mb-0 d-block task-assignment-card__progress"
            taskAssignmentAnalytics={taskAssignmentAnalytics}
          />
        )}
      </div>

      {canRemoveFromTask && (
        <>
          <ModalConfirm
            data-testid="remove-user-confirm"
            open={isRemovalConfirmationOpen}
            onClose={handleConfirmRemovalModalClose}
            onConfirm={() => onRemoveTaskMemberConfirmation()}
            confirmStyle={BS_STYLE.DANGER}
            confirmLabel="Remove user"
            heading={`Remove ${profile.name} from the task?`}
            size={BS_SIZE.LARGE}
            onSecondaryAction={() => onRemoveTaskMemberConfirmation({
              deleteWorkTimeBlocks: true,
            })}
            secondaryActionLabel={hasUnusedTimeBlocks ? 'Delete all tracked times & Remove user' : null}
            secondaryActionStyle={BS_STYLE.DANGER}
          >
            { hasUnusedTimeBlocks && (
              <>
                <TDSystemMessage
                  type={BS_STYLE.WARNING}
                  className="mb-4"
                  title="This provider has unpaid work on this Task "
                >
                  <div className="text-dark">
                    <p>
                      {`${profile.name} currently has time-tracked work that needs to be added to `}
                      {'a Worksheet before you can remove them from the Task. '}
                      <strong>By continuing this action</strong>
                      {' the following actions will occur automatically:'}
                    </p>
                    <ol>
                      <li>
                        If the Provider is actively tracking time on the Task, their time tracker
                        will be stopped and the time record saved.
                      </li>
                      <li>
                        All time-tracked work on this Task will be converted to a Worksheet for
                        approval (or auto-approved if it’s within their Invoice Cap).
                      </li>
                    </ol>
                  </div>
                </TDSystemMessage>

                <p>
                  {`Here is ${profile.name}'s time-tracked work for the task:`}
                </p>

                <WorkTimeBlockTable
                  readOnly
                  workTimeBlocks={taskRelatedWorkTimeBlocks}
                />

                <div className="time-tracker-total p-4 d-flex justify-content-end mb-5">
                  <div className="label">Total amount</div>
                  <div className="amount">
                    <NumberTpl
                      prefix={CURRENCY_SYMBOL[task?.assignments?.[0]?.currency]}
                      value={taskRelatedWorkTimeBlocks.reduce(
                        (accumulator, { amount }) => Big(accumulator).plus(amount || 0), 0)
                        .toFixed(2)}
                    />
                  </div>
                </div>
              </>
            )}

            <p>
              {`If you remove ${profile.name} from the task, they won’t be able to track time,
              send messages, collaborate, submit deliverables or raise Worksheets for this task.`}
            </p>
            <p>{`Are you sure you want to remove ${profile.name} from the task?`}</p>
          </ModalConfirm>
        </>
      )}

      <ModalSimple
        heading="Adjust agreed rate"
        open={openModalTaskRateAdjustment}
        body={(
          <TDSystemMessage
            type={BS_STYLE.DANGER}
            title="You can’t adjust agreed rate!"
            className="mb-4"
          >
            {canProposeNewTaskRate?.reasonLabel}
          </TDSystemMessage>
        )}
        onClose={() => setOpenModalTaskRateAdjustment(false)}
      />
    </div>
  );
};

TaskAssignmentCard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  task: projectTaskSpec.isRequired,
  assignment: taskAssignmentSpec.isRequired,
  rootComponentName: PropTypes.string.isRequired,
  taskAllowedActions: taskAllowedActionsSpec.isRequired,
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  activeUserCard: userCardSpec.isRequired,
  pendingActionsCount: PropTypes.number,
  isRemovalConfirmationOpen: PropTypes.bool,
  taskAssignmentAnalytics: PropTypes.arrayOf(PropTypes.object),
  taskRelatedWorkTimeBlocks: PropTypes.arrayOf(PropTypes.object),
  activeOrg: orgSpec.isRequired,
  isTaskAssignmentCapModalOpen: PropTypes.bool,
  isLastCard: PropTypes.bool,
};

TaskAssignmentCard.defaultProps = {
  pendingActionsCount: 0,
  isRemovalConfirmationOpen: false,
  taskAssignmentAnalytics: [],
  isTaskAssignmentCapModalOpen: false,
  isLastCard: false,
  taskRelatedWorkTimeBlocks: [],
};

const mapStateToProps = (state, { assignment, task }) => {
  const assignmentUser = assignment && assignment.user;
  const taskRelatedWorkTimeBlocks = state.view?.[getComponentName(assignment.userId)]?.item
    ?.projects?.find(p => p.id === task.projectId)
    ?.workTimeBlocks?.filter(tb => tb.taskId === task.id);

  return {
    activeOrg: selectActiveOrg(state),
    isRemovalConfirmationOpen: assignmentUser && (
      getIsModalOpen(state, getConfirmationModalId(assignmentUser))
    ),
    isTaskAssignmentCapModalOpen: assignmentUser && (
      getIsModalOpen(state, getTaskAssignmentModalId(assignmentUser.id))
    ),
    taskRelatedWorkTimeBlocks,
  };
};
const mapDispatchToProps = dispatch => ({ dispatch });

const TaskAssignmentCardConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskAssignmentCard);

export default withRouter(TaskAssignmentCardConnected);
