import React from 'react';
import PropTypes from 'prop-types';
import { Range } from 'rc-slider';

import { reduxInputSpec } from 'core/assets/js/lib/objectSpecs';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';


const DEFAULT_RANGE = {
  min: 1,
  max: 1500,
};

/**
 * Display the range of rates - min/max
 *
 * Displays percentages and amounts differently
 *
 * @param props
 * @returns {RateRangeLabel.props.defaultLabel|*|*}
 * @constructor
 */
const RateRangeLabel = (props) => {
  const { defaultLabel, displayDecimals, range, value, symbol } = props;
  const decimals = displayDecimals ? 2 : 0;

  if (!range || !range.max) {
    return defaultLabel;
  }

  const isPercentage = symbol === '%';
  let min = range.min;
  let max = range.max;

  if (value && range.max) {
    min = isPercentage
      ? value.min * 100
      : value.min;
    max = isPercentage
      ? value.max * 100
      : value.max;
  }
  const prefix = !isPercentage ? symbol : null;
  const suffix = isPercentage ? symbol : null;

  return (
    <React.Fragment>
      <NumberTpl
        value={min}
        prefix={prefix}
        suffix={suffix}
        decimals={decimals}
      />
      {' '}
      -
      {' '}
      <NumberTpl
        value={max}
        prefix={prefix}
        suffix={suffix}
        decimals={decimals}
      />
    </React.Fragment>
  );
};
RateRangeLabel.propTypes = {
  defaultLabel: PropTypes.string.isRequired,
  displayDecimals: PropTypes.bool,
  range: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  symbol: PropTypes.string.isRequired,
};
RateRangeLabel.defaultProps = {
  displayDecimals: false,
  value: null,
  range: DEFAULT_RANGE,
};

class RangeFilterField extends React.Component {
  constructor(props) {
    super(props);
    const { initialValues, filter } = props;
    const initialValue = initialValues[filter.paramName] || {};

    this.displayDecimals = filter.step % 1 !== 0;
    this.handleChange = this.handleChange.bind(this);

    if (initialValue && initialValue.max) {
      this.handleChange([initialValue.min, initialValue.max]);
    }
  }

  handleChange(newValue) {
    const { input } = this.props;

    const _value = newValue ? JSON.stringify({
      min: newValue[0],
      max: newValue[1],
    }) : null;

    input.onChange(_value);
  }

  render() {
    const { filter, input } = this.props;
    const value = input.value && JSON.parse(input.value);
    const { label, min, max, step, symbol } = filter;

    return (
      <React.Fragment>
        <h4>{label}</h4>

        <div className="form-control-container">
          <div className="row">
            <div className="col-12">
              <RateRangeLabel
                defaultLabel={label}
                displayDecimals={this.displayDecimals}
                symbol={symbol}
                value={value}
                range={{ min, max }}
              />

              <Range
                onChange={this.handleChange}
                min={min}
                max={max}
                step={step}
                defaultValue={[min, max]}
                value={value ? [value.min, value.max] : [min, max]}
              />
            </div>
          </div>
        </div>

        <input
          {...input}
          type="hidden"
          className="form-control"
        />
      </React.Fragment>
    );
  }
}

RangeFilterField.propTypes = {
  filter: PropTypes.object,
  input: reduxInputSpec.isRequired,
  initialValues: PropTypes.object.isRequired,
  isPercentage: PropTypes.bool,
  value: PropTypes.object,
};
RangeFilterField.defaultProps = {
  filter: {},
  isPercentage: false,
  value: null,
};

export default RangeFilterField;
