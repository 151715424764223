import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { map, pick } from 'lodash';
import queryString from 'query-string';

import {
  STATUS_LABEL as INVITATION_STATUS_LABEL,
  TYPE_LABEL as INVITATION_TYPE_LABEL,
  PROJECT_INVITATIONS_TYPE_DEFAULT_FILTER,
} from 'invitations/assets/js/constants';
import SearchForm from 'core/assets/js/components/SearchForm.jsx';
import SelectableListFilterField from 'core/assets/js/components/FilterFields/SelectableListFilterField.jsx';
import { ORG_INVITEES_STATUS_DEFAULT_FILTER } from 'people/assets/js/constants';

class ProjectInvitationSearch extends React.Component {
  constructor(props) {
    super(props);
    this.getSearchSpec = this.getSearchSpec.bind(this);
    this.getInitialValues = this.getInitialValues.bind(this);
  }

  getInitialValues() {
    const { location } = this.props;
    const searchSpec = this.getSearchSpec();
    const filtersList = map(searchSpec.filters, 'paramName');

    let values = queryString.parse(location.search);
    values = pick(values, ['kw', 'ordering'].concat(filtersList));

    return values;
  }

  getSearchSpec() { // eslint-disable-line class-methods-use-this
    // Prepare Status filter options
    const statusOptions = ORG_INVITEES_STATUS_DEFAULT_FILTER
      .map(status => ({
        text: INVITATION_STATUS_LABEL[status],
        value: status,
      }));

    // Prepare Type filter options
    const typeOptions = PROJECT_INVITATIONS_TYPE_DEFAULT_FILTER
      .map(status => ({
        text: INVITATION_TYPE_LABEL[status],
        value: status,
      }));

    const searchSpec = {
      searchTerm: false,
      filters: [{
        label: 'Type',
        paramName: 'type',
        fieldComponent: SelectableListFilterField,
        options: typeOptions,
        multiple: true,
      }, {
        label: 'Status',
        paramName: 'status',
        fieldComponent: SelectableListFilterField,
        options: statusOptions,
        multiple: true,
      }],
    };
    return searchSpec;
  }

  render() {
    const { onFiltersToggle, filtersOpen } = this.props;
    return (
      <SearchForm
        name="project-invitations"
        initialValues={this.getInitialValues()}
        searchSpec={this.getSearchSpec()}
        onFiltersToggle={onFiltersToggle}
        filtersOpen={filtersOpen}
      />
    );
  }
}

ProjectInvitationSearch.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
};

export default withRouter(ProjectInvitationSearch);
