import { pick } from 'lodash';
import moment from 'moment';
import { assertDate } from 'core/assets/js/lib/utils';
import { API_DATE_FORMAT } from 'core/assets/js/constants';

class BillingProcess {
  static fromDeadline(billingDeadline) {
    assertDate(billingDeadline);

    // billing date is the last millisecond before the billing deadline
    // so, if billing deadline is 01-07-2018 00:00:00.000,
    // billing date should be 30-06-2018 23:59:59.999
    const billingDate = moment(billingDeadline).subtract(1, 'millisecond');
    return new BillingProcess({ billingDate });
  }

  static getDeadline(billingDate) {
    return moment(billingDate).add(1, 'day').startOf('day');
  }

  constructor(...args) {
    this.init(...args);
  }

  init({
    billingDate,
    isArbitrary,
  }) {
    if (!billingDate) {
      throw new Error('billing date');
    }
    // we need to format billingDate so it is saved as date format without time
    this.billingDate = moment(billingDate).format(API_DATE_FORMAT);
    this.billingDeadline = BillingProcess.getDeadline(this.billingDate);
    this.isArbitrary = !!isArbitrary;
    if (!isArbitrary) {
      this.validate();
    }
  }

  /**
   * Selects and returns specific properties loaded in the instance
   * @see DimBillingProcess -> registerDimension -> push function
   * these properties are saved in the details dim table column
   * @returns {Object}
   */
  serialize() {
    return {
      ...pick(this, [
        'billingDeadline', 'billingDate', 'isArbitrary',
      ]),
    };
  }

  getBillingDate() {
    const { billingDate } = this;
    return billingDate;
  }

  getBillingDeadline() {
    const { billingDeadline } = this;
    return billingDeadline;
  }

  getLastInclusiveDatetime() {
    const { billingDate } = this;
    return moment(billingDate).endOf('day');
  }

  /**
   * Validates if billing date is included in available invoicing days
   *
   * @deprecated since cron frequencies are now arbitrary,
   *             there is no validation the days of billing processes
   * @returns void
   */
  validate() {
    return true;
  }
}

export default BillingProcess;
