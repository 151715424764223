import React from 'react';
import PropTypes from 'prop-types';

import MoneyInputField from 'core/assets/js/components/FinalFormFields/MoneyInputField.jsx';
import { CURRENCY_SYMBOL, CURRENCY_VALUES } from 'core/assets/js/constants';
import { RATE_UNIT, RATE_UNIT_FORMAT } from 'rates/assets/js/constants';

const RateField = ({
  currency,
  currencySymbol: currencySymbolIn,
  label,
  max: maxIn,
  min: minIn,
  name,
  placeholder,
  required,
  unit,
  showLabel,
}) => {
  const isCommissionBased = unit === RATE_UNIT.COMMISSION;
  const currencySymbol = currency ? CURRENCY_SYMBOL[currency] : currencySymbolIn;
  const prefix = !isCommissionBased && !currency ? currencySymbol : null;
  const suffix = isCommissionBased ? '%' : null;
  const rateUnitFormat = RATE_UNIT_FORMAT[unit] || {};

  let min = minIn;
  let max = maxIn;
  if (rateUnitFormat?.limit) {
    ({ max, min } = rateUnitFormat.limit);
    if (typeof minIn === 'number' && minIn > min) {
      min = minIn;
    }
    if (typeof maxIn === 'number' && maxIn < max) {
      max = maxIn;
    }
  }

  return (
    <MoneyInputField
      currency={!isCommissionBased && currency}
      decimals={rateUnitFormat?.decimals}
      label={showLabel ? (label || rateUnitFormat?.title) : ''}
      max={max}
      min={min}
      name={name}
      placeholder={placeholder}
      prefix={prefix}
      required={required}
      step={rateUnitFormat?.limit?.step}
      suffix={suffix}
      validate={rateUnitFormat?.validate}
    />
  );
};

RateField.propTypes = {
  currency: PropTypes.oneOf(CURRENCY_VALUES),
  currencySymbol: PropTypes.oneOf([
    ...Object.values(CURRENCY_SYMBOL),
    '',
  ]),
  label: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  unit: PropTypes.oneOf(Object.values(RATE_UNIT)),
  showLabel: PropTypes.bool,
};

RateField.defaultProps = {
  currency: null,
  currencySymbol: null,
  label: null,
  max: null,
  min: null,
  name: 'rate_amount',
  placeholder: '',
  required: true,
  unit: RATE_UNIT.PER_HOUR,
  showLabel: true,
};

export default RateField;
