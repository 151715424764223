import PropTypes from 'prop-types';

import { userSpec } from 'accounts/assets/js/lib/objectSpecs';
import { orgSpec, userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { CURRENCY, CURRENCY_SYMBOL, CURRENCY_VALUES } from 'core/assets/js/constants';
import { RATE_UNIT } from 'rates/assets/js/constants';
import {
  PROJECT_MEMBER_STATUS,
  PROJECT_MEMBER_STATUS_LABEL,
  TASK_ASSIGNMENT_STATUS,
  TASK_ASSIGNMENT_STATUS_LABEL,
  TASK_CANNOT_EDIT_REASON,
  TASK_CANNOT_EDIT_REASON_LABEL,
  TASK_STATUS,
  TASK_STATUS_LABEL,
} from 'projects/assets/js/constants';
import { invitationSpec } from 'invitations/assets/js/lib/objectSpecs';

export const permissionsSpec = PropTypes.shape({
  canReview: PropTypes.bool,
  canView: PropTypes.bool,
  isBeingReviewed: PropTypes.bool,
  isFinController: PropTypes.bool,
  isOrgOwner: PropTypes.bool,
  isReviewer: PropTypes.bool,
});

export const projectBudgetBreakdown = {
  total: PropTypes.string,
  totalApproved: PropTypes.string,
  pending: PropTypes.string,
  used: PropTypes.string,
  available: PropTypes.string,
  refunded: PropTypes.string,
};

export const projectSchema = {
  attachments: PropTypes.array,
  brief: PropTypes.string,
  budget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  budgetBreakdown: PropTypes.shape(projectBudgetBreakdown),
  completed_at: PropTypes.string,
  po_assigned_at: PropTypes.string,
  created_at: PropTypes.string,
  currency: PropTypes.string,
  currency_symbol: PropTypes.string,
  deadline: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  org: orgSpec,
  owner_name: PropTypes.string,
  owner_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  payments_reviewer: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  reference: PropTypes.string,
  skills: PropTypes.array,
  started_at: PropTypes.string,
  status: PropTypes.number,
  status_label: PropTypes.string,
  title: PropTypes.string,
  updated_at: PropTypes.string,
  canViewProjectView: PropTypes.bool,
  application: PropTypes.object,
};

export const projectSpec = PropTypes.shape(projectSchema);

export const allowedActionsSpec = PropTypes.shape({
  shouldAddProviders: PropTypes.bool,
  shouldRequestBudget: PropTypes.bool,
  shouldStartProject: PropTypes.bool,
  shouldMarkProjectAsCompleted: PropTypes.bool,
  shouldApproveRaisedWorksheets: PropTypes.bool,
  shouldDoNothing: PropTypes.bool,
});

export const projectMemberSchema = {
  organizationId: PropTypes.number,
  projectId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  currency: PropTypes.oneOf(Object.values(CURRENCY)),
  currencySymbol: PropTypes.oneOf(Object.values(CURRENCY_SYMBOL)),
  status: PropTypes.oneOf(Object.values(PROJECT_MEMBER_STATUS)),
  statusLabel: PropTypes.oneOf(Object.values(PROJECT_MEMBER_STATUS_LABEL)),
  rate: PropTypes.string,
  rateUnit: PropTypes.oneOf(Object.values(RATE_UNIT)),
  userCard: userCardSpec.isRequired,
  invitation: invitationSpec,
};

export const projectMemberSpec = PropTypes.shape(projectMemberSchema);

export const projectMemberAnalyticsSchema = {
  avatar: PropTypes.string,
  firstName: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  organizationId: PropTypes.number,
  projectId: PropTypes.number,
  userId: PropTypes.number,
  userRole: PropTypes.object,
};

export const projectMemberAnalyticsSpec = PropTypes.shape(projectMemberAnalyticsSchema);

export const projectAllowedActionsSchema = {
  canViewBudgets: PropTypes.bool,
  canViewProjectDetails: PropTypes.bool,
  canEditProject: PropTypes.bool,
  canAddProviders: PropTypes.bool,
  canAddManagers: PropTypes.bool,
  canRequestBudget: PropTypes.bool,
  canStartProject: PropTypes.bool,
  canMarkAsCompleted: PropTypes.bool,
  canTransferOwnership: PropTypes.bool,
  hasAcceptedProviders: PropTypes.bool,
  isOwner: PropTypes.bool,
};
export const projectAllowedActionsSpec = PropTypes.shape(projectAllowedActionsSchema);

export const projectMemberAllowedActionsSchema = {
  isMember: PropTypes.bool,
  canViewRates: PropTypes.bool,
  canViewProjectDetails: PropTypes.bool,
};
export const projectMemberAllowedActionsSpec = PropTypes.shape(projectMemberAllowedActionsSchema);

export const worksheetAllowedActionsSchema = {
  canCreate: PropTypes.bool,
  canManageAnyWorksheet: PropTypes.bool,
  shouldCreateForCurrentPeriod: PropTypes.bool,
};
export const worksheetAllowedActionsSpec = PropTypes.shape(worksheetAllowedActionsSchema);

export const expenseAllowedActionsSchema = {
  canClaim: PropTypes.bool,
  canManageAnyExpense: PropTypes.bool,
};
export const expenseAllowedActionsSpec = PropTypes.shape(expenseAllowedActionsSchema);

export const taskAllowedActionsSchema = {
  canCreate: PropTypes.bool,
  canViewTask: PropTypes.bool,
  canEditTask: PropTypes.shape({
    reason: PropTypes.oneOf([null, ...Object.values(TASK_CANNOT_EDIT_REASON)]),
    reasonLabel: PropTypes.oneOf([null, ...Object.values(TASK_CANNOT_EDIT_REASON_LABEL)]),
    value: PropTypes.bool,
  }),
  canArchiveTask: PropTypes.bool,
  canUpdateProgress: PropTypes.bool,
  canViewProjectTasks: PropTypes.bool,
  canEditTaskItems: PropTypes.bool,
  isOwner: PropTypes.bool,
  isManager: PropTypes.bool,
};
export const taskAllowedActionsSpec = PropTypes.shape(taskAllowedActionsSchema);

export const taskAssignmentSchema = {
  user: userSpec,
  status: PropTypes.oneOf(Object.values(TASK_ASSIGNMENT_STATUS)),
  statusLabel: PropTypes.oneOf(Object.values(TASK_ASSIGNMENT_STATUS_LABEL)),
  rate: PropTypes.string,
  rate_unit: PropTypes.oneOf(Object.values(RATE_UNIT)),
  currency: PropTypes.oneOf(Object.values(CURRENCY)),
  invitedAt: PropTypes.string,
  joinedAt: PropTypes.string,
  abandonedAt: PropTypes.string,
};

export const taskInfoSchema = {
  totalItemsCount: PropTypes.number,
  attachmentsCount: PropTypes.number,
  deliverablesCount: PropTypes.number,
};
export const taskInfoSpec = PropTypes.shape(taskInfoSchema);

export const taskAssignmentSpec = PropTypes.shape(taskAssignmentSchema);

export const taskItemSchema = {
  id: PropTypes.number,
  completed: PropTypes.bool,
  description: PropTypes.string,
};

export const taskAttachmentSchema = {
  id: PropTypes.number,
  attachment: PropTypes.object,
  isMainAttachment: PropTypes.bool,
  isDeliverable: PropTypes.bool,
  isPinned: PropTypes.bool,
  createdAt: PropTypes.string,
  allowedActions: PropTypes.object,
};

export const taskAttachmentSpec = PropTypes.shape(taskAttachmentSchema);

export const taskParticipantSchema = {
  user: userSpec,
  assignment: taskAssignmentSpec,
  isOwner: PropTypes.bool,
  isAssignee: PropTypes.bool,
  isManager: PropTypes.bool,
  isDeleted: PropTypes.bool,
};

export const taskParticipantSpec = PropTypes.shape(taskParticipantSchema);

export const worksheetItemSchema = {
  title: PropTypes.string,
  description: PropTypes.string,
  quantity: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rate_unit: PropTypes.oneOf(Object.values(RATE_UNIT)),
  rate_amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rate_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  // In case of project work, task_id is not present.
  task_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export const worksheetItemSpec = PropTypes.shape(worksheetItemSchema);

export const projectTaskSchema = {
  accessControl: PropTypes.shape(taskAllowedActionsSchema),
  assignment: PropTypes.shape(taskAssignmentSchema),
  deleted: PropTypes.bool,
  description: PropTypes.string,
  id: PropTypes.number,
  items: PropTypes.arrayOf(PropTypes.shape(taskItemSchema)),
  owner: userSpec,
  previousOwner: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  status: PropTypes.oneOf(Object.values(TASK_STATUS)),
  statusLabel: PropTypes.oneOf(Object.values(TASK_STATUS_LABEL)),
  title: PropTypes.string,
  worksheetAccessControl: PropTypes.shape(taskAllowedActionsSchema),
};

export const projectTaskSpec = PropTypes.shape(projectTaskSchema);

export const projectTaskThinSchema = {
  id: PropTypes.number,
  title: PropTypes.string,
};

export const projectApplicationSchema = {
  id: PropTypes.number,
  orgId: PropTypes.number,
  projectId: PropTypes.number,
  userId: PropTypes.number,
  reviewed: PropTypes.bool,
  reviewer: userSpec,
  message: PropTypes.string,
  attachments: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.number])),
  status: PropTypes.number,
  statusLabel: PropTypes.string,
  statusReason: PropTypes.string,
  createdAt: PropTypes.string,
  reviewedAt: PropTypes.string,
};

export const projectApplicationSpec = PropTypes.shape(projectApplicationSchema);

export const proFormaInvoiceAllowedActionsSchema = {
  canCreate: PropTypes.bool,
  canManageAnyWorksheet: PropTypes.bool,
  shouldCreateForCurrentPeriod: PropTypes.bool,
};
export const proFormaInvoiceAllowedActionsSpec = PropTypes.shape(
  proFormaInvoiceAllowedActionsSchema,
);

export const workTimeBlockSpec = PropTypes.shape({
  allowedActions: PropTypes.shape({}),
  createdAt: PropTypes.string.isRequired,
  endTime: PropTypes.string,
  id: PropTypes.number.isRequired,
  organizationId: PropTypes.number.isRequired,
  startTime: PropTypes.string.isRequired,
  task: projectTaskSpec,
  taskId: PropTypes.number,
  updatedAt: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  worksheetItem: worksheetItemSpec,
  worksheetItemId: PropTypes.number,
});

export const timeTrackerTaskOptionSpec = PropTypes.shape({
  currency: PropTypes.oneOf(CURRENCY_VALUES),
  label: PropTypes.string,
  rateAmount: PropTypes.string,
  value: PropTypes.number,
});
