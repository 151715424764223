import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { Form } from 'react-final-form';
import PropTypes from 'prop-types';
import { OnChange } from 'react-final-form-listeners';

import { worksheetSpec } from 'finance/assets/js/lib/objectSpecs';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_STYLE, MIME_TYPES } from 'core/assets/js/constants';
import { SERVICE_ORDER_TYPE } from 'projects/assets/js/constants';
import { INVOICING_MODE, ON_DEMAND_INVOICE_STEP } from 'finance/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import {
  financeWorksheetOnDemandInvoiceUrl, financeExpenseOnDemandInvoiceUrl,
  financeWorksheetsUrl, financeExpensesUrl,
} from 'finance/urls';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import PaymentReferencePreview from 'settings/assets/js/components/PaymentReferencePreview.jsx';
import FileUploaderField from 'core/assets/js/components/FinalFormFields/FileUploaderField.jsx';
import { uploaderOnDemandInvoicePath } from 'core/urls';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { fetchViewDS } from 'core/assets/js/ducks/view';
import { settingsPaymentTermsApiUrl } from 'settings/urls';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import { objectToBase64, parseJson } from 'core/assets/js/lib/utils';
import WorksheetsTable from 'finance/assets/js/components/WorksheetsTable.jsx';

const validateInvoiceNumber = (value) => {
  if (!value) {
    return 'field is required';
  }
  const match = value.match(/(.*?)(\d+)$/);
  if (!match) {
    return 'value must end in a number';
  }
  return null;
};

const BillableOnDemandInvoiceInit = (
  { report, history, match: { params: { orgAlias, id } }, item: settings },
) => {
  const isWorksheet = report.type === SERVICE_ORDER_TYPE.WORKSHEET;
  const type = isWorksheet ? 'worksheet' : 'expense';
  const nextUrl = isWorksheet
    ? financeWorksheetOnDemandInvoiceUrl : financeExpenseOnDemandInvoiceUrl;
  const backUrl = isWorksheet ? financeWorksheetsUrl : financeExpensesUrl;
  const isDirect = report?.org?.invoicing_mode === INVOICING_MODE.DIRECT;
  const isInbound = report?.org?.invoicing_mode === INVOICING_MODE.VIA;
  const [paymentReference, setPaymentReference] = useState(settings.next_custom_number || '');
  return (
    <Form
      onSubmit={(values) => {
        const nextInvoiceNumber = values.next_custom_number;
        const parsedAttachments = values.attachments_metadata
          ? parseJson(values.attachments_metadata) : null;
        const query = `?info=${objectToBase64({
          parsedAttachments,
          nextInvoiceNumber,
        })}`;
        return (
          history.push({
            pathname: nextUrl(
              orgAlias, id, ON_DEMAND_INVOICE_STEP.PREVIEW,
            ),
            search: query,
          }));
      }}
      initialValues={{ next_custom_number: paymentReference }}
      render={({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit} className="p-0 mt-4">
            <Card>
              <Card.Body>
                <div className="row">
                  <div className="col-12">
                    <TDSystemMessage
                      title="Raise invoice"
                      type={BS_STYLE.INFO}
                      className="mb-4"
                    >
                      <p>
                        Raise an invoice for your work that was approved via the
                        {' '}
                        {type}
                        {' '}
                        with reference number
                        {' '}
                        {`${report?.projectReference}_${report?.id}`}
                        {' '}
                        .
                        You can optionally select a custom invoice number and you attach your own
                        invoice in pdf format.
                      </p>
                      { isInbound && (
                        <p>
                          The submitted invoice will be processed and paid in the
                          next payment cycle, which is usually weekly, fortnightly or
                          monthly depending
                          on your client preferences.
                        </p>
                      )}
                      { isDirect && (
                        <p>
                          Once you submit your invoice your client will be notified so
                          that they can process
                          it and arrange its payment as per your agreement.
                        </p>
                      )}
                    </TDSystemMessage>
                    <div className="row mt-3">
                      <div className="col-12 mb-2">
                        <TextInputField
                          name="next_custom_number"
                          required
                          label="Your invoice number"
                          sublabel="Set your own invoice number"
                          validate={val => validateInvoiceNumber(val)}
                        />
                        <OnChange name="next_custom_number">
                          {(value, previous) => {
                            if (value !== previous) {
                              setPaymentReference(value);
                            }
                          }}
                        </OnChange>
                        <PaymentReferencePreview
                          paymentReference={paymentReference}
                        />
                      </div>
                      <div className="col-12 mb-4">
                        <div className="finance-report__table-wrapper finance-report__table-wrapper--list finance-report__table-wrapper--responsive">
                          <WorksheetsTable
                            currency={report.organizationCurrencySymbol}
                            rateUnit={report.rateUnit}
                            list={[report]}
                            emptyText="No worksheets found"
                            isWorksheetView
                            isInvoiceOnDemandView
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <FileUploaderField
                          name="attachments_metadata"
                          type="text"
                          label="Attach your own invoice"
                          sublabel="You can optionally assist the invoice automatically generated by TalentDesk.io with your own invoice in pdf format. Make sure that the invoice numbers in both invoices match."
                          maxFiles={1}
                          acceptFiles={[
                            ...MIME_TYPES.DOCUMENTS,
                            ...MIME_TYPES.IMAGES,
                          ]}
                          path={uploaderOnDemandInvoicePath(orgAlias, id)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
            <div className="row mt-5 ">
              <div className="col-12 text-right">
                <TDButton
                  variant={BS_STYLE.DEFAULT}
                  label="Cancel"
                  onClick={() => history.push(backUrl(orgAlias))}
                />
                <TDButton
                  variant={BS_STYLE.PRIMARY}
                  type="submit"
                  disabled={submitting}
                  label="Preview your invoice"
                />
              </div>
            </div>
          </form>
        );
      }}
    />
  );
};

BillableOnDemandInvoiceInit.propTypes = {
  report: worksheetSpec,
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  item: PropTypes.object,
};

BillableOnDemandInvoiceInit.defaultProps = {
  report: {},
  item: {},
};

const BillableOnDemandInvoiceInitConnected = withTDApiConnected({
  fetchData: ({
    dispatch, params, url, authedAxios, componentName,
  }) => dispatch(fetchViewDS({
    url: settingsPaymentTermsApiUrl(url),
    params,
    authedAxios,
    componentName,
  })),
  storeKey: 'BillableOnDemandInvoiceInit',
  duck: 'view',
  skeletonComponent: SettingsPageSkeleton,
})(BillableOnDemandInvoiceInit);

export default BillableOnDemandInvoiceInitConnected;
