import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { ON_DEMAND_INVOICE_STEP } from 'finance/assets/js/constants';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import BillableOnDemandInvoiceInit from 'finance/assets/js/components/invoicing/BillableOnDemandInvoiceInit.jsx';
import BillableOnDemandInvoicePreview from 'finance/assets/js/components/invoicing/BillableOnDemandInvoicePreview.jsx';
import { SERVICE_ORDER_TYPE } from 'projects/assets/js/constants';
import { financeWorksheetOnDemandInvoiceUrl, financeExpenseOnDemandInvoiceUrl } from 'finance/urls';

const BillableOnDemandInvoiceStep = ({
  history, orgAlias, step, report,
}) => {
  const isWorksheet = report.type === SERVICE_ORDER_TYPE.WORKSHEET;
  const defaultUrl = isWorksheet
    ? financeWorksheetOnDemandInvoiceUrl
    : financeExpenseOnDemandInvoiceUrl;
  switch (step) {
    case ON_DEMAND_INVOICE_STEP.INIT:
      return (
        <BillableOnDemandInvoiceInit
          report={report}
        />
      );
    case ON_DEMAND_INVOICE_STEP.PREVIEW:
      return (
        <BillableOnDemandInvoicePreview
          billableType={report.type}
        />
      );
    default:
      history.push(
        defaultUrl(orgAlias, report.id),
      );
      return null;
  }
};

BillableOnDemandInvoiceStep.propTypes = {
  history: routerHistorySpec.isRequired,
  report: PropTypes.object,
  orgAlias: PropTypes.string.isRequired,
  step: PropTypes.string.isRequired,
};

BillableOnDemandInvoiceStep.defaultProps = {
  report: {},
};

export default withRouter(BillableOnDemandInvoiceStep);
