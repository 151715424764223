import React from 'react';
import PropTypes from 'prop-types';

import { rateSpec } from 'rates/assets/js/lib/objectSpecs';
import TDList from 'core/assets/js/components/TDList.jsx';
import RateCard from 'rates/assets/js/components/RateCard.jsx';


const RateList = ({ editInModal, listComponentName, onEdit, rates }) => (
  rates.length > 0 ? (
    <TDList
      listClassName="rates-list"
      items={rates}
      cardItem={{
        component: RateCard,
        props: {
          editInModal,
          listComponentName,
          onEdit,
        },
      }}
    />
  ) : (
    <div className="py-2 px-2">
      <i className="discreet">No rates created yet.</i>
    </div>
  )
);

RateList.propTypes = {
  editInModal: PropTypes.bool,
  listComponentName: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  rates: PropTypes.arrayOf(PropTypes.shape(rateSpec)),
};

RateList.defaultProps = {
  editInModal: false,
  onEdit: () => {},
  rates: [],
};

export default RateList;
