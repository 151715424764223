import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import AttachmentsList from 'core/assets/js/components/AttachmentsList.jsx';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { followBackUrlIfPresent, determineReportEditUrl } from 'finance/assets/js/lib/utils';
import ProFormaInvoiceViewSkeleton from 'finance/assets/js/skeletons/ProFormaInvoiceViewSkeleton.jsx';
import { REVIEW_REQUEST_DAYS_INTERVAL } from 'people/assets/js/constants';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import {
  ACCESS_CONTROL_ALLOWED_ACTIONS as ALLOWED_ACTIONS, API_DATE_FORMAT,
  BS_STYLE, ICON, USER_TYPE,
} from 'core/assets/js/constants';
import {
  SERVICE_ORDER_PENDING_STATUSES,
  SERVICE_ORDER_STATUS,
  SERVICE_ORDER_STATUS_LABEL,
  SERVICE_ORDER_TYPE,
} from 'projects/assets/js/constants';
import { proFormaInvoiceSpec } from 'finance/assets/js/lib/objectSpecs';
import { ON_DEMAND_INVOICE_STEP, REPORT_TYPES } from 'finance/assets/js/constants';
import { getViewState, getViewStateExtras } from 'core/assets/js/ducks/view';
import { fetchFinanceProFormaInvoiceDS } from 'finance/assets/js/data-services/view';
import {
  financeProFormaInvoiceApproveDS,
  financeProFormaInvoiceConfirmDS,
  financeProFormaInvoiceRejectDS,
  financeProFormaInvoiceVoidDS,
  financeProFormaInvoiceRequestAmendmentDS,
} from 'finance/assets/js/data-services/form';
import { refreshFinancePendingCountsDS } from 'core/assets/js/ducks/pendingCount';
import { proFormaInvoiceAllowedActionsSpec } from 'projects/assets/js/lib/objectSpecs';
import ReportViewLayout from 'finance/assets/js/components/ReportViewLayout.jsx';
import ReportLogo from 'finance/assets/js/components/ReportLogo.jsx';
import ReportDetails from 'finance/assets/js/components/ReportDetails.jsx';
import ReportCompany from 'finance/assets/js/components/ReportCompany.jsx';
import ReportPerson from 'finance/assets/js/components/ReportPerson.jsx';
import ReportFooter from 'finance/assets/js/components/ReportFooter.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import ReasonForm from 'core/assets/js/components/ReasonForm.jsx';
import BillingPeriodWidget from 'finance/assets/js/components/BillingPeriodWidget.jsx';
import ReportInvoiceLinks from 'finance/assets/js/components/ReportInvoiceLinks.jsx';
import ReportAuditTrail from 'finance/assets/js/components/ReportAuditTrail.jsx';
import { profileSpec } from 'accounts/assets/js/lib/objectSpecs';
import BillableApproveForm from 'projects/assets/js/components/BillableApproveForm.jsx';
import BillableRequestAmendmentForm from 'projects/assets/js/components/BillableRequestAmendmentForm.jsx';
import ProFormaInvoiceCancelForm from 'projects/assets/js/components/ProFormaInvoiceCancelForm.jsx';
import withModalForm from 'core/assets/js/components/withModalForm.jsx';
import ReportFinancialEntity from 'finance/assets/js/components/ReportFinancialEntity.jsx';
import ReviewRequestItem from 'people/assets/js/components/ReviewRequestItem.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { financeProFormaInvoiceOnDemandInvoiceUrl } from 'finance/urls';
import OnDemandInvoiceFuturePeriodModal from 'finance/assets/js/components/invoicing/OnDemandInvoiceFuturePeriodModal.jsx';
import ExchangeRateInfoPopOver from 'core/assets/js/components/ExchangeRateInfoPopOver.jsx';
import FinancialEntity from 'finance/assets/js/lib/FinancialEntity';
import BillableVoid from 'finance/assets/js/components/BillableVoid.jsx';
import BillableVoidNotAllowed from 'finance/assets/js/components/BillableVoidNotAllowed.jsx';
import Table from 'core/assets/js/components/Table.jsx';
import RateAmount from 'rates/assets/js/components/RateAmount.jsx';

export const COMPONENT_NAME = 'ProFormaInvoiceView';

const ProFormaInvoiceView = ({
  match: { params: { orgAlias, id } }, match,
  organization, dispatch, history, report,
  allowedActions: {
    [ALLOWED_ACTIONS.PROFORMA_INVOICE]: proFormaInvoiceAllowedActions,
  },
}) => {
  const [showReviewRequest, setShowReviewRequest] = useState(false);
  const [isRaiseInvoiceModalOpen, setIsRaiseInvoiceModalOpen] = useState(false);

  /**
   * Pro-Forma Invoice Cancel - form submit success callback
   */
  const onSubmitSuccess = () => {
    dispatch(fetchFinanceProFormaInvoiceDS({
      componentName: COMPONENT_NAME,
      id,
      orgAlias,
    }));

    // Update finance pending counts.
    dispatch(refreshFinancePendingCountsDS({
      orgAlias,
    }));

    followBackUrlIfPresent(history);
  };

  const onAmendmentRequested = values => (
    dispatch(
      financeProFormaInvoiceRequestAmendmentDS({
        componentName: COMPONENT_NAME, id, orgAlias, values,
      }),
    )
  );

  const handleProFormaInvoiceVoidDS = async () => {
    await dispatch(
      financeProFormaInvoiceVoidDS({ componentName: COMPONENT_NAME, id, orgAlias }),
    );
  };

  const {
    canApproveAnyProFormaInvoice, canManageAnyProFormaInvoice,
  } = proFormaInvoiceAllowedActions || {};
  const { canBeVoided: canVoidProFormaInvoice } = report?.allowedActions || {};

  const canInvoiceProFormaInvoiceOnDemand = (
    report?.allowedActions?.canInvoiceProFormaInvoiceOnDemand
  );
  const canCreateOnDemandInvoices = report?.provider?.userCard
    ?.allowedActions?.canCreateOnDemandInvoices;
  const shouldInvoiceInLaterDate = report?.allowedActions
    ?.shouldInvoiceInLaterDate;

  const showCreateOnDemandInvoiceButton = (
    canInvoiceProFormaInvoiceOnDemand
    || (shouldInvoiceInLaterDate && canCreateOnDemandInvoices)
  );

  const status = SERVICE_ORDER_STATUS_LABEL[report.statusCode];
  const handleRaiseInvoiceClick = () => {
    if (shouldInvoiceInLaterDate) {
      setIsRaiseInvoiceModalOpen(true);
      return;
    }

    history.push(financeProFormaInvoiceOnDemandInvoiceUrl(
      match.params.orgAlias,
      match.params.id, ON_DEMAND_INVOICE_STEP.INIT,
    ));
  };
  const isEmployee = report.provider && report.provider.isEmployee;
  let fe = FinancialEntity.getEmpty();
  if (!isEmpty(report) && get(organization, 'id')) {
    fe = new FinancialEntity({ ...report.provider, orgId: organization.id });
  }

  const shouldRenderCompany = report.provider && report.provider.company
    && (report.provider.company.invoicable !== false);

  const canApproveProFormaInvoice = (
    SERVICE_ORDER_PENDING_STATUSES.includes(report.statusCode)
    && canApproveAnyProFormaInvoice
    && !!report.allowedActions && !!report.allowedActions.canBeApproved
  );
  const canConfirmProFormaInvoice = (
    SERVICE_ORDER_PENDING_STATUSES.includes(report.statusCode)
    && canManageAnyProFormaInvoice
    && !!report.allowedActions && !!report.allowedActions.canBeConfirmed
  );
  const canRejectProFormaInvoice = (
    SERVICE_ORDER_PENDING_STATUSES.includes(report.statusCode)
    && canManageAnyProFormaInvoice
    && !!report.allowedActions && !!report.allowedActions.canBeRejected
  );
  const canCancelProFormaInvoice = report && !!report.allowedActions
    && !!report.allowedActions.canBeCancelled;
  const canRequestProFormaInvoiceAmendment = report && !!report.allowedActions
    && !!report.allowedActions.canBeRequestedToBeAmended;

  let approveModalData = null;
  if (canApproveProFormaInvoice) {
    approveModalData = {
      key: 'proFormaInvoiceApproveForm',
      btnLabel: 'Approve',
      btnVariant: BS_STYLE.SUCCESS,
      btnIcon: ICON.CHECKMARK,
      btnClassName: 'text-success',
    };
    const ApproveForm = withModalForm({
      heading: 'Approve Proforma Invoice',
      confirmLabel: 'Approve',
      confirmStyle: BS_STYLE.SUCCESS,
      testId: 'pro-forma-invoice-view-approve-modal',
    })(BillableApproveForm);
    approveModalData.Form = (
      <ApproveForm
        billableType={SERVICE_ORDER_TYPE.PROFORMA_INVOICE}
        initialValues={{
          process_at: moment().format(API_DATE_FORMAT),
        }}
        isEmployee={isEmployee}
        onSubmit={values => (
          dispatch(financeProFormaInvoiceApproveDS({
            data: values,
            componentName: COMPONENT_NAME,
            orgAlias: match.params.orgAlias,
            documentId: match.params.id,
          }))
        )}
        onSubmitSuccess={() => {
          followBackUrlIfPresent(history);

          if (report.proFormaInvoicesApprovedCount % REVIEW_REQUEST_DAYS_INTERVAL === 0) {
            // We want to show the review request item in
            // the first approved pro-forma invoice or every 5 approved
            setShowReviewRequest(true);
          }
        }}
      />
    );
  }

  const ConfirmForm = withModalForm({
    heading: 'Confirm Proforma Invoice',
    confirmLabel: 'Confirm',
    confirmStyle: BS_STYLE.SUCCESS,
  })(ReasonForm);

  const confirmModalData = canConfirmProFormaInvoice ? ({
    key: 'proFormaInvoiceConfirmForm',
    btnLabel: 'Confirm',
    btnVariant: BS_STYLE.SUCCESS,
    btnIcon: ICON.CHECKMARK,
    btnClassName: 'text-success',
    Form: (
      <ConfirmForm
        onSubmit={values => (
          dispatch(financeProFormaInvoiceConfirmDS({
            data: values,
            componentName: COMPONENT_NAME,
            orgAlias: match.params.orgAlias,
            documentId: match.params.id,
          }))
        )}
        onSubmitSuccess={() => followBackUrlIfPresent(history)}
        question="Are you sure you want to confirm this Proforma Invoice?"
      />
    ),
  }) : null;

  const RejectForm = withModalForm({
    heading: 'Reject Proforma Invoice',
    confirmLabel: 'Reject',
    confirmStyle: BS_STYLE.DANGER,
  })(ReasonForm);

  const rejectModalData = !canRejectProFormaInvoice ? null : {
    key: 'proFormaInvoiceRejectForm',
    btnLabel: 'Reject',
    btnVariant: BS_STYLE.DANGER,
    btnIcon: ICON.CROSS,
    btnClassName: 'text-danger',
    Form: (
      <RejectForm
        onSubmit={values => (
          dispatch(financeProFormaInvoiceRejectDS({
            data: values,
            componentName: COMPONENT_NAME,
            orgAlias: match.params.orgAlias,
            documentId: match.params.id,
          }))
        )}
        onSubmitSuccess={() => followBackUrlIfPresent(history)}
        question="Are you sure you want to reject this Proforma Invoice?"
      />
    ),
  };

  const CancelForm = withModalForm({
    heading: 'Cancel Proforma Invoice',
    withFooter: false,
  })(ProFormaInvoiceCancelForm);

  const cancelModalData = !canCancelProFormaInvoice ? null : {
    key: 'ProFormaInvoiceCancelForm',
    btnLabel: 'Cancel Proforma Invoice',
    btnVariant: BS_STYLE.SECONDARY,
    btnClassName: 'text-danger',
    Form: (
      <CancelForm
        initialValues={{
          quantity: report.quantity,
          summary: report.summary,
          attachments: report.attachments,
        }}
        serviceOrder={report}
        orgAlias={match.params.orgAlias}
        onCancelSuccess={onSubmitSuccess}
        question="Are you sure you want to cancel this Proforma Invoice?"
      />
    ),
  };

  const providerName = report?.provider?.user?.profile
    ? `${report.provider.user.profile.name}’s`
    : 'the';

  const RequestAmendmentForm = withModalForm({
    heading: `Do you require any changes on ${providerName}'s Proforma Invoice?`,
    confirmLabel: 'Submit',
  })(BillableRequestAmendmentForm);

  const requestAmendmentModalData = !canRequestProFormaInvoiceAmendment ? null : {
    key: 'proFormaInvoiceRequestAmendmentForm',
    btnLabel: 'Request amendment',
    btnVariant: BS_STYLE.WARNING,
    btnIcon: ICON.EDIT,
    Form: (
      <RequestAmendmentForm
        initialValues={{}}
        onSubmit={onAmendmentRequested}
        onSubmitSuccess={() => followBackUrlIfPresent(history)}
        billable={report}
        billableLabel="Proforma Invoice"
      />
    ),
  };

  let voidModalData = null;
  if (canVoidProFormaInvoice) {
    const VoidProFormaInvoiceForm = withModalForm({
      heading: `Void Proforma Invoice #${report.id}`,
      confirmLabel: 'Void',
    })(BillableVoid);

    voidModalData = {
      key: 'proFormaInvoiceVoidForm',
      btnLabel: 'Void',
      btnVariant: BS_STYLE.WARNING,
      btnIcon: ICON.REMOVE_CIRCLE,
      Form: (
        <VoidProFormaInvoiceForm
          initialValues={{}}
          onSubmit={handleProFormaInvoiceVoidDS}
          onSubmitSuccess={() => followBackUrlIfPresent(history)}
          billableTypeLabel="Proforma Invoice"
        />
      ),
    };
  } else if (!canVoidProFormaInvoice && canManageAnyProFormaInvoice && report?.isInvoiced) {
    const VoidProFormaInvoiceIMpossibleForm = withModalForm({
      heading: `Void Proforma Invoice #${report.id}`,
      cancelLabel: 'Close',
      showConfirm: false,
    })(BillableVoidNotAllowed);

    voidModalData = {
      key: 'proFormaInvoiceVoidImpossible',
      disabled: true,
      btnLabel: 'Void',
      btnVariant: BS_STYLE.WARNING,
      btnIcon: ICON.REMOVE_CIRCLE,
      Form: (
        <VoidProFormaInvoiceIMpossibleForm
          billableTypeLabel="Proforma Invoice"
        />
      ),
    };
  }

  let invoiceInfo = <span>-</span>;
  if (Array.isArray(report.invoices) && report.invoices.length > 0) {
    invoiceInfo = (
      <ReportInvoiceLinks
        orgAlias={match.params.orgAlias}
        invoices={report.invoices}
      />
    );
  } else if (report.invoiceReferenceNumber) {
    invoiceInfo = <span>{report.invoiceReferenceNumber}</span>;
  }

  const billingPeriod = (
    <BillingPeriodWidget
      period={report.period}
      endDate={report.periodEnd}
      startDate={report.periodStart}
      id={report.id}
    />
  );

  const heading = `${report.id}`;
  const detailsRows = [
    { label: 'Invoice No', value: invoiceInfo },
    { label: 'Date', value: report.createdAt },
    { label: 'Billing Period', value: billingPeriod },
    {
      label: 'Status',
      value: (
        <ReportAuditTrail
          heading={heading}
          report={report}
          reportType={REPORT_TYPES.PRO_FORMA_INVOICE}
        />
      ),
    },
  ];

  const orgId = report.org && report.org.id;
  const userProfile = get(report, 'provider.user.profile');
  const userType = get(report, 'provider.userCard.userRole.ofType');
  const editUrl = report.org && determineReportEditUrl(report.org.unique_alias, report);
  const billableReference = `${report?.id}`;

  const actions = [
    rejectModalData,
    requestAmendmentModalData,
    cancelModalData,
    voidModalData,
  ];

  if (approveModalData || confirmModalData) {
    actions.unshift(approveModalData || confirmModalData);
  }

  if (showCreateOnDemandInvoiceButton) {
    actions.unshift({
      eventKey: 'raiseInvoice',
      label: 'Raise Invoice',
      onClick: () => handleRaiseInvoiceClick(),
      variant: BS_STYLE.PRIMARY,
    });
  }

  if (report.allowedActions && !!report.allowedActions.canBeEdited) {
    actions.unshift({
      eventKey: 'editProFormaInvoice',
      label: 'Edit',
      onClick: () => history.push(editUrl),
      variant: BS_STYLE.PRIMARY,
    });
  }

  return (
    <ReportViewLayout
      reportType={REPORT_TYPES.PRO_FORMA_INVOICE}
      actions={actions}
      heading={heading}
      status={status}
      report={report}
      reportActionRequest={(
        showReviewRequest
        && report.statusCode === SERVICE_ORDER_STATUS.APPROVED
        && userType === USER_TYPE.PROVIDER
          ? (
            <ReviewRequestItem
              profile={userProfile}
              orgId={orgId}
              userType={userType}
            />
          ) : null
      )}
    >

      <div className="finance-report__head row">
        {report.provider && (
          <ReportLogo
            className="col-12 col-md-6 col-lg-8 mb-4 mb-md-0"
            company={report.provider.company}
            profilePicFallback
            user={report.provider.user}
          />
        )}
        <div className="finance-report__meta col-12 col-md-6 col-lg-4">
          <ReportDetails rows={detailsRows} />
        </div>
      </div>

      <hr />

      {report.financialContext && (
        <div className="row mb-4">
          <div className="finance-report__provider col-12 col-md-8">
            <ReportFinancialEntity label="From" financialEntity={report.financialContext.ownerFE} />
          </div>
          <div className="finance-report__provider__details col-12 col-md-4">
            <ReportFinancialEntity label="Addressed to" financialEntity={report.financialContext.recipientFE} />
          </div>
        </div>
      )}

      <div className="row mb-4">
        <div className="finance-report__provider col-12">
          <div>
            {shouldRenderCompany && (
              <ReportCompany {...report.provider} />
            )}
            <div className="finance-report__provider__details">
              <h5>Personal Details</h5>
              <ReportPerson {...report.provider} financialEntity={fe} withJobTitle />
            </div>


            {report && report.attachments && report.attachments.length > 0 && (
              <div className="mt-5">
                <h5>Attachments</h5>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <AttachmentsList label={null} attachments={report.attachments} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="finance-report__table-wrapper finance-report__table-wrapper--list finance-report__table-wrapper--responsive">
        <Table
          cols={[
            { key: 'description', label: 'Work item' },
            {
              dataFormat: (rateAmount, item) => (
                <>
                  <RateAmount
                    amount={rateAmount}
                    symbol={item.proFormaInvoiceItemCurrencySymbol}
                    unit={item.rateUnit}
                  />
                  {item.organizationCurrency !== item.proFormaInvoiceItemCurrency && (
                    <ExchangeRateInfoPopOver
                      className="ml-2"
                      exchangeRate={item.exchangeRate}
                      exchangeRateDate={item.exchangeRateUpdatedAt}
                      exchangeRateService={item.proFormaInvoiceItemToOrganizationFxRateSource}
                      sourceCurrency={item.proFormaInvoiceItemCurrency}
                      targetAmount={item.organizationRateAmount}
                      targetCurrency={item.organizationCurrency}
                    />
                  )}
                </>
              ),
              key: 'proFormaInvoiceItemRateAmount',
              label: 'Rate',
              width: '165px',
            },
            { key: 'quantity', label: 'Quantity', width: '165px' },
            {
              dataFormat: (amount, item) => (
                <>
                  <NumberTpl
                    decimals={2}
                    prefix={item.proFormaInvoiceItemCurrencySymbol}
                    value={amount}
                  />
                  {item.organizationCurrency !== item.proFormaInvoiceItemCurrency && (
                    <ExchangeRateInfoPopOver
                      className="ml-2"
                      exchangeRate={item.exchangeRate}
                      exchangeRateDate={item.exchangeRateUpdatedAt}
                      exchangeRateService={item.proFormaInvoiceItemToOrganizationFxRateSource}
                      sourceCurrency={item.proFormaInvoiceItemCurrency}
                      targetAmount={item.organizationTotal}
                      targetCurrency={item.organizationCurrency}
                    />
                  )}
                </>
              ),
              key: 'proFormaInvoiceItemTotal',
              label: 'Amount',
              width: '165px',
              columnClassName: 'text-right',
              isMoney: true,
            },
          ]}
          items={report.items}
        />
        <div className="finance-report__totals">
          <div className="finance-report__summary">
            <div className="finance-report__summary-total-amount">
              <label>Total</label>
              <span>
                <NumberTpl
                  prefix={report.proFormaInvoiceCurrencySymbol}
                  value={report.proFormaInvoiceAmount}
                  decimals={2}
                />
                {report.organizationCurrency !== report.proFormaInvoiceCurrency && (
                  <ExchangeRateInfoPopOver
                    className="ml-2"
                    exchangeRate={report.exchangeRate}
                    exchangeRateDate={report.exchangeRateUpdatedAt}
                    exchangeRateService={report.proFormaInvoiceToOrganizationFxRateSource}
                    sourceCurrency={report.proFormaInvoiceCurrency}
                    targetAmount={report.organizationAmount}
                    targetCurrency={report.organizationCurrency}
                  />
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div data-testid="finance-report__summary">
        <h5>Summary</h5>
        <p>{report.summary}</p>
      </div>
      {report.financialContext && (
        <div className="finance-report__payment__terms mt-5">
          <span>
            Upon approval, the Proforma Invoice will be
            included in the next invoice and an additional
            {' '}
            {report.financialContext.vatPercent}
            % VAT will be applied to the amount
          </span>
        </div>
      )}
      <OnDemandInvoiceFuturePeriodModal
        billableProcessDate={report.processAt}
        billableReference={billableReference}
        billableType={SERVICE_ORDER_TYPE.PROFORMA_INVOICE}
        onClose={() => setIsRaiseInvoiceModalOpen(false)}
        open={isRaiseInvoiceModalOpen}
      />

      <div className="mt-4 finance-report__organization-footer-info">
        <ReportFooter owner={report.provider} />
      </div>

    </ReportViewLayout>
  );
};

ProFormaInvoiceView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  match: PropTypes.object.isRequired,
  organization: orgSpec.isRequired,
  profile: profileSpec,
  report: proFormaInvoiceSpec,
  allowedActions: PropTypes.shape({
    [ALLOWED_ACTIONS.PROFORMA_INVOICE]: proFormaInvoiceAllowedActionsSpec,
  }),
};

ProFormaInvoiceView.defaultProps = {
  allowedActions: {
    [ALLOWED_ACTIONS.PROFORMA_INVOICE]: {},
  },
  profile: {},
  report: {},
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProFormaInvoicesWithTDApi = withTDApiConnected({
  fetchData: ({
    dispatch, authedAxios, params, url,
  }) => (
    dispatch(fetchFinanceProFormaInvoiceDS({
      authedAxios,
      componentName: COMPONENT_NAME,
      id: params.id,
      orgAlias: params.orgAlias,
      url,
    }))
  ),
  duck: 'view',
  storeKey: COMPONENT_NAME,
  skeletonComponent: ProFormaInvoiceViewSkeleton,
})(ProFormaInvoiceView);

const mapStateToProps = (state, props) => {
  const viewState = getViewState(state, COMPONENT_NAME);
  return {
    allowedActions: getViewStateExtras(state, COMPONENT_NAME, 'accessControl'),
    match: props.match,
    organization: selectActiveOrg(state),
    profile: selectProfile(state),
    report: viewState.item,
  };
};
const ProFormaInvoiceViewConnect = connect(
  mapStateToProps, mapDispatchToProps,
)(ProFormaInvoicesWithTDApi);

export default withRouter(ProFormaInvoiceViewConnect);
