/* eslint react/no-multi-comp: "off" */ // eslint-disable-line
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getViewState } from 'core/assets/js/ducks/view';
import { getListState } from 'core/assets/js/ducks/list';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { INVOICING_MODE } from 'finance/assets/js/constants';
import BillingDetails from 'settings/assets/js/components/BillingDetails.jsx';
import BillingDetailsFormModal from 'settings/assets/js/components/BillingDetailsFormModal.jsx';
import { orgSpec, billingDetailsSpec } from 'organizations/assets/js/lib/objectSpecs';
import { fetchSetupStepsDS } from 'accounts/assets/js/ducks/account';
import {
  fetchBillingDetailsDS,
  fetchOrganizationDS,
  postBillingDetailsDS,
  postOrganizationDS,
  setDefaultBillingDetailsDS,
} from 'settings/assets/js/data-services/organizations';
import SettingsItem from 'core/assets/js/components/SettingsItem.jsx';
import PaymentTermsForm from 'settings/assets/js/components/PaymentTermsForm.jsx';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import OrgCustomSalesTaxMsgForm from 'settings/assets/js/components/OrgCustomSalesTaxMsgForm.jsx';
import TDToggleWithConfirmationModal from 'core/assets/js/components/TDToggleWithConfirmationModal.jsx';
import OrgInvoiceCapsSettings from 'settings/assets/js/components/OrgInvoiceCapsSettings.jsx';

export const COMPONENT_NAME = 'orgSettingsInvoices';

const OnDemandInvoicesDisableModalBody = () => (
  <React.Fragment>
    <p>
      By deactivating this setting your providers will not be able to manually
      issue an invoice for their submitted worksheets and / or expenses on approval.
      Invoices will be submitted automatically at the end of your payment cycle
      as set at organisation level.
    </p>
    <p className="mb-4">
      Are you sure you want to deactivate ?
    </p>
  </React.Fragment>
);

const OnDemandInvoicesEnableModalBody = () => (
  <React.Fragment>
    <p>
      By activating this setting your providers will be able to manually
      issue an invoice when their submitted worksheets and / or expenses have been approved.
    </p>
    <p className="mb-4">
      Are you sure you want to activate ?
    </p>
  </React.Fragment>
);

const OrgInvoiceSettingsTab = ({
  items,
  dispatch,
  organization,
  activeOrg,
}) => (
  <div className="rounded shadow-sm p-4 bg-white">
    {activeOrg.can_enable_invoice_caps && (
      <>
        <h3 className="heading-block">Invoice caps</h3>
        <TDToggleWithConfirmationModal
          containerClassName="pb-3 px-3"
          modalId="invoice-caps-enabled"
          label="Invoice caps"
          sublabel={[
            'Set a cap on the total value of Worksheets or Proforma Invoices that a user can submit',
            'in any given Billing Cycle or calendar period. The Invoice Caps must be applied on an',
            'individual basis to all users.',
          ].join(' ')}
          enableHeader="Activate using invoice caps with users"
          disableHeader="Deactivate using invoice caps with users"
          onToggled={selected => dispatch(postOrganizationDS(
            activeOrg.unique_alias,
            { invoice_caps_enabled: selected },
            COMPONENT_NAME,
          ))}
          selected={!!activeOrg.invoice_caps_enabled}
          EnableModalBody={() => (
            <>
              <p>
                By activating this setting, you will be able to give managers permission to set
                caps on the total value of Worksheets or Proforma Invoices that a user can submit in
                any given Billing Cycle or calendar period.
              </p>
              <p>Are you sure you want to activate?</p>
            </>
          )}
          DisableModalBody={() => (
            <>
              <p>
                By deactivating this setting, managers will not be able to set caps on the total
                value of Worksheets or Proforma Invoices that a user can submit in any given Billing
                Cycle or calendar period. Any existing caps will be ignored.
              </p>
              <p>Are you sure you want to deactivate?</p>
            </>
          )}
        />
        {activeOrg.invoice_caps_enabled && (
          <OrgInvoiceCapsSettings parentComponentName={COMPONENT_NAME} />
        )}
      </>
    )}
    <h3 className="heading-block">Provider Settings</h3>
    <SettingsItem
      label="Payment terms"
      preview={!parseInt(get(activeOrg, 'provider_grace_period', 0), 10)
        ? 'Use the payment terms set by the providers'
        : (
          <React.Fragment>
            The payment terms for your providers is now set to&nbsp;
            <b>{activeOrg.provider_grace_period}</b>
            &nbsp;days
          </React.Fragment>
        )}
      disclaimer="Set custom payment terms for all providers, or use per provider payment terms as set by them."
      cta={(
        <PaymentTermsForm
          heading="Set payment terms for all providers"
          includeComments={false}
          promptMsg={(
            <React.Fragment>
              <p>
                Enter custom payment terms which will be used in all provider invoices.&nbsp;
                By doing so the system overwrites the payment terms set by the providers&nbsp;
                via their invoicing settings.
              </p>
              <p>
                By leaving this field blank you they system will instead use the per&nbsp;
                provider payment terms as set by them via their invoicing settings.
              </p>
            </React.Fragment>
          )}
          showPreview={false}
          initialValues={{ grace_period: activeOrg.provider_grace_period, comments: '' }}
          onSubmit={values => dispatch(postOrganizationDS(
            activeOrg.unique_alias,
            { provider_grace_period: values.grace_period || 0 },
            COMPONENT_NAME,
          ))}
        />
      )}
    />
    <SettingsItem
      label="VAT/Sales tax guidelines"
      disclaimer="This message will show up to all organisation's providers when they are updating their sales tax setting"
      cta={(
        <OrgCustomSalesTaxMsgForm
          initialValues={{ custom_sales_tax_msg: organization.custom_sales_tax_msg }}
          onSubmit={values => dispatch(postOrganizationDS(
            organization.unique_alias,
            values,
            COMPONENT_NAME,
          ))}
        />
      )}
    />
    {activeOrg.invoicing_mode !== INVOICING_MODE.VIA && (
      <>
        <TDToggleWithConfirmationModal
          containerClassName="pb-3 px-3"
          enableHeader="Allow contractors to set their local sales tax"
          EnableModalBody={() => (
            <>
              <p>
                By activating this setting your contractors will be able to set the percentage of
                their local sales tax which will be charged in their invoice on top of the
                invoice&apos;s subtotal.
                You can also set your &quot;VAT/Sales tax guidelines&quot; in order to help them
                set the correct sales tax.
              </p>
              <p className="mb-4">Are you sure you want to activate this setting?</p>
            </>
          )}
          disableHeader="Prevent contractors from being able to set their local sales tax"
          DisableModalBody={() => (
            <>
              <p>
                By deactivating this setting your contractors will not be able to set the percentage
                of their local sales tax.
              </p>
              <p className="mb-4">Are you sure you want to deactivate this setting?</p>
            </>
          )}
          label="All contractors can set their local sales tax"
          modalId="enable-contractors-setting-sales-tax"
          onToggled={selected => dispatch(postOrganizationDS(
            activeOrg.unique_alias,
            { allow_providers_setting_sales_tax: selected },
            COMPONENT_NAME,
          ))}
          selected={!!activeOrg.allow_providers_setting_sales_tax}
          sublabel={(
            'Allow contractors to set the percentage of their local sales tax which will be charged'
            + ' in their invoice on top of the invoice\'s subtotal.'
          )}
        />
        {activeOrg.invoicing_mode === INVOICING_MODE.DIRECT && (
          <TDToggleWithConfirmationModal
            containerClassName="pb-3 px-3"
            enableHeader="Ask Contractors to set their local sales tax rate during their onboarding"
            EnableModalBody={() => (
              <>
                <p>
                  By activating this setting your contractors will be asked to set their sales tax
                  during their onbaording process.
                </p>
                <p className="mb-4">Are you sure you want to activate this setting?</p>
              </>
            )}
            disableHeader={(
              'Do not ask Contractors to set their local sales tax rate during their onboarding'
            )}
            DisableModalBody={() => (
              <>
                <p>
                  By deactivating this setting your contractors will not be asked to set their sales
                  tax during their onbaording process.
                </p>
                <p className="mb-4">Are you sure you want to deactivate this setting?</p>
              </>
            )}
            label="Ask Contractors to set their local sales tax rate during their onboarding"
            modalId="enable-contractors-sales-tax-get-started"
            onToggled={selected => dispatch(postOrganizationDS(
              activeOrg.unique_alias,
              { enable_sales_tax_get_started_step: selected },
              COMPONENT_NAME,
            ))}
            selected={!!activeOrg.enable_sales_tax_get_started_step}
            sublabel={(
              "When enabled, during each contractor's onboarding process, we will ask them to set "
              + 'their local sales tax rate. Disable this setting if you prefer that we not '
              + 'include this step during the onboarding process.'
            )}
          />
        )}
        <TDToggleWithConfirmationModal
          containerClassName="pb-3 px-3"
          modalId="providers-can-create-invoices-on-demand"
          label="All providers can generate invoices manually"
          sublabel="Allow providers to generate an invoice when a worksheet / expense is approved"
          enableHeader="Allow providers to generate invoices setting is deactivated"
          disableHeader="Allow providers to generate invoices setting is activated"
          onToggled={selected => dispatch(postOrganizationDS(
            activeOrg.unique_alias,
            { on_demand_invoices_enabled: selected },
            COMPONENT_NAME,
          ))}
          selected={!!activeOrg.on_demand_invoices_enabled}
          EnableModalBody={OnDemandInvoicesEnableModalBody}
          DisableModalBody={OnDemandInvoicesDisableModalBody}
        />
      </>
    )}

    <h3 className="heading-block">Billing Details</h3>

    { items.map((details) => {
      const isOrgBillingDetails = get(details, 'id') === get(organization, 'billing_details.id');
      const isDefault = get(details, 'id') === get(organization, 'invoicing_billing_details.id');
      return (
        <BillingDetails
          key={details.id}
          organization={organization}
          billingDetails={details}
          isOrgBillingDetails={isOrgBillingDetails}
          isDefault={isDefault}
          onBillingDetailsUpdated={async (values) => {
            const { error } = await dispatch(postBillingDetailsDS(
              organization.unique_alias, values, COMPONENT_NAME,
            ));
            await dispatch(fetchSetupStepsDS());
            if (!isEmpty(error)) {
              return error;
            }
            return null;
          }}
          onSetDefault={id => (
            dispatch(setDefaultBillingDetailsDS(
              organization.unique_alias, id, COMPONENT_NAME,
            ))
          )}
        />
      );
    })}

    { items.length === 1 && (
      <div className="text-center pt-5 pb-3">
        <p className="discreet">If the TalentDesk.io invoices are paid by a different entity, you can define alternate billing details.</p>
        <BillingDetailsFormModal
          form="billingDetails-new"
          triggerEl={(
            <span
              className="imitate-link"
            >
              Add new billing details
            </span>
          )}
          orgId={organization.id}
          onSubmit={async (values) => {
            const { error } = await dispatch(postBillingDetailsDS(
              organization.unique_alias, values, COMPONENT_NAME,
            ));
            await dispatch(fetchSetupStepsDS());
            if (!isEmpty(error)) {
              return error;
            }
            return null;
          }}
        />
      </div>
    )}
  </div>
);

OrgInvoiceSettingsTab.propTypes = {
  organization: orgSpec.isRequired,
  activeOrg: orgSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(billingDetailsSpec).isRequired,
};

const mapStateToProps = (state) => {
  const viewState = getViewState(state, COMPONENT_NAME);
  const listState = getListState(state, COMPONENT_NAME);
  return ({
    organization: viewState.item,
    billingDetailsList: listState.items,
    activeOrg: selectActiveOrg(state),
  });
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const OrgInvoiceSettingsTabConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrgInvoiceSettingsTab);

const WithTDApiConnectedOrgInvoiceSettingsTabConnected = withTDApiConnected({
  fetchData: ({ dispatch, params, url = '', authedAxios, componentName }) => (
    Promise.all([
      dispatch(fetchOrganizationDS(params, url, authedAxios, componentName)),
      dispatch(fetchBillingDetailsDS(params, url, authedAxios, componentName)),
    ])
  ),
  duck: 'list',
  storeKey: COMPONENT_NAME,
  skeletonComponent: SettingsPageSkeleton,
})(OrgInvoiceSettingsTabConnected);

export default withRouter(WithTDApiConnectedOrgInvoiceSettingsTabConnected);
