export const IMPORT_TYPE = {
  PROJECTS: 'projects',
  SKILLS: 'skills',
  TASKS: 'tasks',
  USERS: 'users',
  WORKSHEETS: 'worksheets',
};

export const IMPORT_TYPE_LABEL = {
  [IMPORT_TYPE.PROJECTS]: 'Projects',
  [IMPORT_TYPE.SKILLS]: 'Skills',
  [IMPORT_TYPE.TASKS]: 'Tasks',
  [IMPORT_TYPE.USERS]: 'Team',
  [IMPORT_TYPE.WORKSHEETS]: 'Worksheets',
};

export const IMPORT_TYPE_VALUES = Object.values(IMPORT_TYPE);

export const DISABLED_IMPORT_TYPES = [IMPORT_TYPE.PROJECTS, IMPORT_TYPE.TASKS];

export const IMPORT_TYPE_PROCESS_SEQUENTIALLY = [IMPORT_TYPE.SKILLS];

export const IMPORT_STATUS = {
  INPROGRESS: 'in-progress',
  COMPLETE: 'complete',
};

export const IMPORT_STATUS_VALUES = Object.values(IMPORT_STATUS);

export const IMPORT_STATUS_LABEL = {
  [IMPORT_STATUS.INPROGRESS]: 'In progress',
  [IMPORT_STATUS.COMPLETE]: 'Complete',
};

export const IMPORT_STATUS_CLASS = {
  [IMPORT_STATUS.INPROGRESS]: 'pending',
  [IMPORT_STATUS.COMPLETE]: 'approved',
};

export const IMPORT_ROW_STATUS = {
  PENDING: 'pending',
  INPROGRESS: 'in-progress',
  COMPLETE: 'complete',
  ERRORED: 'errored',
  CANCELLED: 'cancelled',
};

export const IMPORT_ROW_STATUS_VALUES = Object.values(IMPORT_ROW_STATUS);

export const IMPORT_ROW_STATUS_LABEL = {
  [IMPORT_ROW_STATUS.INPROGRESS]: 'In progress',
  [IMPORT_ROW_STATUS.COMPLETE]: 'Complete',
  [IMPORT_ROW_STATUS.ERRORED]: 'Errored',
};

export const IMPORT_COLUMNS = {
  [IMPORT_TYPE.WORKSHEETS]: [
    'Project reference',
    'Task reference',
    'User ID or email',
    'Claiming amount',
    'Task rate quantity',
    'Description',
    'Currency',
    'Summary',
    'Period start',
    'Period end',
  ],
  [IMPORT_TYPE.USERS]: [
    'Email',
    'First name',
    'Middle name',
    'Last name',
    'Full legal name',
    'Job title',
    'Date of birth',
    'Phone number',
    'Address',
    'Avatar',
    'Default rate currency',
    'Default rate amount',
    'Default rate unit',
    'Group names',
    'Project titles',
  ],
  [IMPORT_TYPE.SKILLS]: ['Skill name', 'Skill directory name'],
};

export const IMPORT_DATE_FORMAT = {
  ISO: 'YYYY-MM-DD',
  GB: 'DD/MM/YYYY',
  US: 'MM/DD/YYYY',
};

export const IMPORT_DATE_FORMAT_LABEL = {
  [IMPORT_DATE_FORMAT.ISO]: 'ISO format',
  [IMPORT_DATE_FORMAT.GB]: 'British format',
  [IMPORT_DATE_FORMAT.US]: 'US format',
};

export const IMPORT_VIEW_TABS = {
  DETAILS: 'details',
  CANCELLED: 'cancelled',
  ERRORS: 'errors',
};
