import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { viewDocumentAssignment } from 'core/assets/js/lib/utils-jsx';

const DocumentAssignmentViewDropdownItem = ({
  assignment, history, location, match: { params: { orgAlias } },
}) => {
  if (!assignment.allowedActions?.canView) {
    return null;
  }
  return (
    <Dropdown.Item
      key="view-document-assignment"
      onClick={e => {
        e.stopPropagation();
        viewDocumentAssignment(orgAlias, assignment, history, location.pathname);
      }}
    >
      View
    </Dropdown.Item>
  );
};

DocumentAssignmentViewDropdownItem.propTypes = {
  assignment: PropTypes.object.isRequired,
  history: routerHistorySpec.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
};

export default withRouter(DocumentAssignmentViewDropdownItem);
