import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown, Card } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';

import ContactDetailsCard from 'people/assets/js/components/ContactDetailsCard.jsx';
import EmploymentStatusIndicator from 'people/assets/js/components/EmploymentStatusIndicator.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import RatingScore from 'core/assets/js/components/RatingScore.jsx';
import SkillList from 'core/assets/js/components/SkillList.jsx';
import TaskManagersTab from 'projects/assets/js/components/taskTabs/TaskManagersTab.jsx';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import UserTypeIndicator from 'people/assets/js/components/UserTypeIndicator.jsx';
import { projectViewTaskUrl } from 'projects/urls';
import { BS_STYLE, IMG_SIZE, POPPER_PLACEMENT, API_DATE_FORMAT, TASK_TABS } from 'core/assets/js/constants';
import { formatDate } from 'core/assets/js/lib/utils';
import { removeTaskManagersDS } from 'projects/assets/js/data-services/tasks';
import { modalOpenAC, getIsModalOpen, modalCloseAC, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import { orgUserProfileUrl } from 'people/urls';
import { projectTaskSpec } from 'projects/assets/js/lib/objectSpecs';
import { providerUserCardSpec, userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { routerMatchSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { selectProfile } from 'accounts/assets/js/reducers/auth';

const TaskManagerCard = ({
  accessControl: { canManageTaskManagers },
  dispatch,
  location,
  history,
  isContactModalOpen,
  isLastItem,
  isRemoveModalOpen,
  item: taskManager,
  linkTarget,
  orgAlias,
  removeModalPayload,
  task,
  withActions,
}) => {
  const {
    id: taskManagerId,
    joinedAt,
    userCard: {
      user: { id, profile, profile: { skills }, email: userEmail },
      isEmployee,
      userRole: { ofType: userType },
      numReviews,
      rating,
      email: userCardEmail,
    },
  } = taskManager;

  const contactProviderModalId = `contact-member-modal-${taskManagerId}`;
  const removeMemberModalId = `remove-member-modal-${taskManagerId}`;

  const handleOpenContactModal = () => {
    dispatch(modalOpenAC(contactProviderModalId));
  };

  const handleCloseContactModal = () => {
    dispatch(modalCloseAC());
  };

  const handleOpenRemoveMemberModal = () => {
    dispatch(modalOpenAC(removeMemberModalId, { taskManager }));
  };

  const handleRemoveTaskManager = async () => {
    dispatch(removeTaskManagersDS({
      orgAlias,
      projectId: task.projectId,
      taskId: task.id,
      taskManagerId: removeModalPayload.taskManager.id,
      componentName: TaskManagersTab.GetComponentName(),
      querystring: location.search,
    }))
      .then(() => {
        history.push({
          pathname: projectViewTaskUrl(orgAlias, task.projectId, task.id, TASK_TABS.MANAGERS),
          search: `?dt=${moment().unix()}`, // Trigger list reload
        });
      });
  };

  const profileUrl = orgUserProfileUrl(orgAlias, userType, id);

  const email = userCardEmail || userEmail;

  return (
    <Card className="user-item">
      <Card.Body>
        <div className="row d-flex align-items-center position-relative">
          <div className="user-item__basic-info col-12 d-flex">
            <Link
              to={profileUrl}
              title={profile.name}
              target={linkTarget === '_self' ? undefined : linkTarget}
            >
              <ProfilePic
                url={profile.avatar}
                alt={profile.name}
                size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
              />
              <UserTypeIndicator
                userType={userType}
              />
            </Link>

            <div className="col-11 pl-3">
              <Link
                to={profileUrl}
                title={profile.name}
                target={linkTarget === '_self' ? undefined : linkTarget}
                className="user-item__title truncate"
              >
                {profile.name}
                <EmploymentStatusIndicator
                  userType={userType}
                  isEmployee={isEmployee}
                />
              </Link>

              <div className="user-item__extra discreet d-flex align-items-center col-auto px-0">
                <div className="truncate truncate--100">
                  {profile.jobTitle && (
                    <span className="mr-0" title={profile.jobTitle}>
                      {profile.jobTitle }
                    </span>
                  )}
                  {profile.jobTitle && joinedAt && (
                    <span className="mx-2">&bull;</span>
                  )}
                  {joinedAt && (
                    <span title={`Joined on ${formatDate(joinedAt)}`}>
                      <time dateTime={formatDate(joinedAt, API_DATE_FORMAT)}>
                        {`Joined on ${formatDate(joinedAt)}`}
                      </time>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-auto mt-3 mt-md-0 user-item__details ml-0 ml-md-auto">
            <RatingScore
              rating={rating}
              numReviews={numReviews}
            />
          </div>
        </div>
      </Card.Body>

      <Card.Footer className="bg-white d-flex flex-nowrap">
        <div className="col-10 pl-0">
          { skills && (
            <SkillList
              skills={skills}
              inline
              maxShownItems={7}
              modalId={`user-skills-${id}`}
            />
          )}
        </div>

        {withActions && (
          <TDDropButton
            className="ml-auto"
            data-testid="project-member-card-actions-dropdown"
            stopPropagation
            placement={isLastItem ? POPPER_PLACEMENT.TOP_END : POPPER_PLACEMENT.BOTTOM_END}
          >
            <Dropdown.Item
              eventKey="contact-user"
              onClick={handleOpenContactModal}
            >
              Contact
            </Dropdown.Item>


            {canManageTaskManagers && (
              <Dropdown.Item
                eventKey="remove-user"
                onClick={handleOpenRemoveMemberModal}
                className="text-danger"
              >
                Remove
              </Dropdown.Item>
            )}
          </TDDropButton>
        )}

        <ModalSimple
          open={isContactModalOpen}
          heading={`Contact ${profile.firstName}`}
          body={(
            <ContactDetailsCard
              email={email}
              profile={profile}
            />
          )}
          onClose={handleCloseContactModal}
        />

        <ModalConfirm
          body={(
            <>
              {'Are you sure you want to remove '}
              <strong>{taskManager?.userCard?.user?.profile?.name}</strong>
              ?
            </>
          )}
          confirmLabel="Remove"
          confirmStyle={BS_STYLE.DANGER}
          heading="Remove manager"
          onClose={handleCloseContactModal}
          onConfirm={handleRemoveTaskManager}
          open={isRemoveModalOpen}
        />
      </Card.Footer>
    </Card>
  );
};

TaskManagerCard.propTypes = {
  accessControl: PropTypes.object,
  activeUserCard: userCardSpec.isRequired,
  dispatch: PropTypes.func.isRequired,
  isContactModalOpen: PropTypes.bool,
  isLastItem: PropTypes.bool,
  isRemoveModalOpen: PropTypes.bool,
  item: providerUserCardSpec.isRequired,
  linkTarget: PropTypes.string,
  history: routerHistorySpec.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
  orgAlias: PropTypes.string.isRequired,
  removeModalPayload: PropTypes.object,
  task: projectTaskSpec,
  userId: PropTypes.number.isRequired,
  withActions: PropTypes.bool,
};

TaskManagerCard.defaultProps = {
  accessControl: {},
  isContactModalOpen: false,
  isLastItem: false,
  isRemoveModalOpen: false,
  linkTarget: '_self',
  removeModalPayload: null,
  task: null,
  withActions: true,
};

const mapStateToProps = (state, props) => {
  const userId = selectProfile(state).userId;
  let reviews = [];

  if (state.projects && state.projects.projectActive) {
    reviews = state.projects.projectActive.reviews || [];
  }

  return {
    activeUserCard: selectActiveUserCard(state),
    isContactModalOpen: getIsModalOpen(state, `contact-member-modal-${props.item.id}`),
    isRemoveModalOpen: getIsModalOpen(state, `remove-member-modal-${props.item.id}`),
    removeModalPayload: getModalPayload(state, `remove-member-modal-${props.item.id}`),
    reviews,
    userId,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const TaskManagerCardConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskManagerCard);

export default withRouter(TaskManagerCardConnected);
