import axios from 'core/assets/js/lib/tdAxios';
import { toastr } from 'react-redux-toastr';
import { SubmissionError } from 'redux-form';

import { httpErrorAC } from 'core/assets/js/ducks/errors';
import { REQ_TYPE, MEMBER_SEARCH_TARGET } from 'core/assets/js/constants';
import {
  extrasUpdateAC, viewFetchAC, viewFetchExtrasAC, viewUpdateAC, viewUpdateKeysAC,
} from 'core/assets/js/ducks/view';
import { pushDataDS } from 'core/assets/js/lib/dataServices';
import {
  projectAddManagersApiUrl,
  projectAddProvidersApiUrl,
  projectAddWorksheetApiUrl,
  projectApplicationCreateApiUrl,
  projectApplicationUpdateApiUrl,
  projectApplicationResendApiUrl,
  projectCancelExpenseApiUrl,
  projectCancelWorksheetApiUrl,
  projectChangeOwnerApiUrl,
  projectClaimExpenseApiUrl,
  projectClaimOwnershipApiUrl,
  projectCloneApiUrl,
  projectCreateApiUrl,
  projectInvitationCancelApiUrl,
  projectInvitationManageApiUrl,
  projectInvitationResendApiUrl,
  projectRemoveMemberApiUrl,
  projectRequestApiUrl,
  projectReturnFundsApiUrl,
  projectSelectPaymentsReviewerApiUrl,
  projectStateUpdateApiUrl,
  projectUpdateApiUrl,
  projectUpdateExpenseApiUrl,
  projectUpdateWorksheetApiUrl,
  projectLeaveApiUrl,
  projectArchiveApiUrl,
  projectUnarchiveApiUrl,
} from 'projects/urls';
import { INVITATION_ACTION } from 'invitations/assets/js/constants';
import { PROJECT_STATE_CHANGE } from 'projects/assets/js/constants';


export const createProjectDS = (orgAlias, values) => {
  if (!orgAlias) {
    throw new Error('[createProjectDS Error] Expected the "orgAlias" param');
  }

  return axios.post(projectCreateApiUrl(orgAlias), values);
};

export const updateProjectDS = (orgAlias, projectId, values) => {
  if (!orgAlias) {
    throw new Error('[updateProjectDS Error] Expected the "orgAlias" param');
  }
  if (!projectId) {
    throw new Error('[updateProjectDS Error] Expected a project id');
  }

  return axios.put(projectUpdateApiUrl(orgAlias, projectId), values);
};

export const updateProjectStateDS = (orgAlias, projId, action, componentName, values) => {
  if (!orgAlias || !projId) {
    throw new Error(`[updateProjectStateDS Error] Invalid orgAlias (${orgAlias}) or projId (${projId})`);
  }

  if (!Object.values(PROJECT_STATE_CHANGE).includes(action)) {
    throw new Error(`[updateProjectStateDS Error] Invalid action (${action})`);
  }

  return pushDataDS({
    reqType: REQ_TYPE.PUT,
    pushApiUrl: projectStateUpdateApiUrl(orgAlias, projId, action),
    values,
    pushDataAC: responseData => [
      viewUpdateAC(responseData.project, componentName),
      viewFetchExtrasAC(responseData.allowedActions, componentName, 'allowedActions'),
    ],
  });
};

export const createProjectApplicationDS = (orgAlias, projectId, values) => {
  if (!orgAlias) {
    throw new Error('[createProjectApplicationDS Error] Expected the "orgAlias" param');
  }

  return axios.post(projectApplicationCreateApiUrl(orgAlias, projectId), values);
};

export const updateProjectApplicationDS = (orgAlias, projectId, applicationId, values) => {
  if (!orgAlias) {
    throw new Error('[updateProjectApplicationDS Error] Expected the "orgAlias" param');
  }

  return axios.put(projectApplicationUpdateApiUrl(orgAlias, projectId, applicationId), values);
};

export const resendProjectApplicationDS = (orgAlias, projectId, applicationId, values) => {
  if (!orgAlias) {
    throw new Error('[resendProjectApplicationDS Error] Expected the "orgAlias" param');
  }

  return axios.put(projectApplicationResendApiUrl(orgAlias, projectId, applicationId), values);
};

const projectAddMembersDS = (orgAlias, projectId, values, target) => {
  const addMembersFn = target === MEMBER_SEARCH_TARGET.MANAGERS
    ? projectAddManagersApiUrl
    : projectAddProvidersApiUrl;

  return axios.post(addMembersFn(orgAlias, projectId), values);
};

export const projectAddManagersDS = (orgAlias, projectId, values, dispatch) => {
  return projectAddMembersDS(orgAlias, projectId, values, MEMBER_SEARCH_TARGET.MANAGERS, dispatch);
};

export const projectAddProvidersDS = (orgAlias, projectId, values, dispatch) => {
  return projectAddMembersDS(orgAlias, projectId, values, MEMBER_SEARCH_TARGET.PROVIDERS, dispatch);
};

export const projectSelectPaymentsReviewer = async (
  orgAlias,
  projectId,
  values,
  dispatch,
  componentName,
) => {
  try {
    const response = await axios.post(
      projectSelectPaymentsReviewerApiUrl(orgAlias, projectId),
      values,
    );
    if (response.data._error) {
      throw new SubmissionError(response.data);
    }
    dispatch(viewFetchAC(response.data.project, componentName));
    toastr.success(
      'Well Done!', `Payments reviewer ${values.user ? 'changed' : 'removed'} successfully.`,
    );
  } catch (error) {
    if (error.response.data._meta.isValidation) {
      throw new SubmissionError(error.response.data);
    }
    dispatch(httpErrorAC([error.response.data]));
  }
};

export const projectRequestBudgetDS = (orgAlias, projectId, values) => {
  if (!orgAlias || !projectId) {
    throw new Error(`[projectRequestBudgetDS Error] Invalid orgAlias (${orgAlias}) or projectId (${projectId})`);
  }

  return axios.post(projectRequestApiUrl(orgAlias, projectId), values);
};

export const projectReturnFundsDS = (orgAlias, projId, values, componentName) => {
  if (!orgAlias || !projId) {
    throw new Error(`Invalid orgAlias (${orgAlias}) or projId (${projId})`);
  }
  return pushDataDS({
    reqType: REQ_TYPE.PUT,
    values,
    pushApiUrl: projectReturnFundsApiUrl(orgAlias, projId),
    pushDataAC: responseData => viewUpdateAC(responseData.project, componentName),
  });
};


export const projectResendInvitationDS = (orgAlias, projectId, data) => {
  if (!orgAlias || !projectId) {
    throw new Error(
      '[projectResendInvitationDS Error] Expected the "orgAlias" and "projectId" params');
  }

  return axios.post(projectInvitationResendApiUrl(orgAlias, projectId), data);
};

export const projectCancelInvitationDS = (orgAlias, projectId, data) => {
  if (!orgAlias || !projectId) {
    throw new Error(
      '[projectCancelInvitationDS Error] Expected the "orgAlias" and "projectId" params',
    );
  }

  return axios.put(projectInvitationCancelApiUrl(orgAlias, projectId), data);
};

export const projectManageInvitationDS = (orgAlias, projectId, action, data) => {
  if (!orgAlias || !projectId) {
    throw new Error(
      '[projectManageInvitationDS Error] Expected the "orgAlias" and "projectId" params');
  }
  if (!Object.values(INVITATION_ACTION).includes(action)) {
    throw new Error(
      `[projectManageInvitationDS Error] Invalid action "${action}"`);
  }
  return axios.put(projectInvitationManageApiUrl(orgAlias, projectId, action), data);
};

export const projectAddWorksheetDS = (orgAlias, projectId, values) => {
  if (!orgAlias) {
    throw new Error('[projectAddWorksheetDS Error] Expected the "orgAlias" param');
  }
  if (!projectId) {
    throw new Error('[projectAddWorksheetDS Error] Expected a project id');
  }

  return axios.post(projectAddWorksheetApiUrl(orgAlias, projectId), values);
};

export const projectUpdateWorksheetDS = (
  { orgAlias, projectId, worksheetId, redirect }, values,
) => (
  dispatch => (
    dispatch(pushDataDS({
      reqType: REQ_TYPE.PUT,
      pushApiUrl: projectUpdateWorksheetApiUrl(orgAlias, projectId, worksheetId),
      values,
    })).then(() => {
      toastr.success('Well Done!', 'Worksheet updated successfully.');
      redirect();
    })
      .catch((data) => {
        return data.errors;
      })
  )
);

export const projectCancelWorksheetDS = ({ orgAlias, projectId, worksheetId }, values) => (
  dispatch => (
    dispatch(pushDataDS({
      reqType: REQ_TYPE.PUT,
      pushApiUrl: projectCancelWorksheetApiUrl(orgAlias, projectId, worksheetId),
      values,
    })).then((updated) => {
      toastr.success('Well Done!', 'Worksheet cancelled successfully.');
      return updated;
    })
      .catch((data) => {
        throw new SubmissionError(data.errors);
      })
  )
);

export const projectChangeOwnerDS = ({ orgAlias, projectId, values, componentName }) => (
  dispatch => (
    dispatch(pushDataDS({
      reqType: REQ_TYPE.PUT,
      pushApiUrl: projectChangeOwnerApiUrl(orgAlias, projectId),
      values,
      pushDataAC: responseData => (
        // Show the name of the new project owner
        viewFetchAC(responseData.project, componentName)
      ),
    }))
  )
);

export const projectRemoveMemberDS = ({ orgAlias, projectId, userId }) => (
  axios.delete(projectRemoveMemberApiUrl(orgAlias, projectId, userId))
);

export const projectLeaveDS = ({ orgAlias, projectId }) => (
  axios.delete(projectLeaveApiUrl(orgAlias, projectId))
);

export const projectClaimExpenseDS = (orgAlias, projectId, values) => (
  axios.post(projectClaimExpenseApiUrl(orgAlias, projectId), values)
);

export const projectUpdateExpenseDS = ({ orgAlias, projectId, expenseId, values = {} } = {}) => (
  dispatch => (
    dispatch(pushDataDS({
      reqType: REQ_TYPE.PUT,
      pushApiUrl: projectUpdateExpenseApiUrl(orgAlias, projectId, expenseId),
      values,
    })).then(() => {
      toastr.success('Well Done!', 'Expense updated successfully.');
    }).catch((data) => {
      throw new SubmissionError(data.errors);
    })
  )
);

export const projectArchiveDS = ({ orgAlias, projectId }) => (
  axios.delete(projectArchiveApiUrl(orgAlias, projectId)).then(() => {
    toastr.success('Well Done!', 'Project archived successfully.');
  })
);

export const projectUnarchiveDS = ({ orgAlias, projectId, componentName }) => (
  dispatch => (
    dispatch(
      pushDataDS({
        reqType: REQ_TYPE.PUT,
        pushApiUrl: projectUnarchiveApiUrl(orgAlias, projectId),
        pushDataAC: responseData => [
          viewUpdateKeysAC(responseData.project, ['archived', 'updated_at'], componentName),
          viewFetchExtrasAC(responseData.allowedActions, componentName, 'allowedActions'),
        ],
      }),
    ).then(() => {
      toastr.success('Well Done!', 'Project unarchived successfully.');
    })
  )
);

export const projectCancelExpenseDS = ({ orgAlias, projectId, expenseId, values }) => (
  dispatch => (
    dispatch(
      pushDataDS({
        reqType: REQ_TYPE.PUT,
        pushApiUrl: projectCancelExpenseApiUrl(orgAlias, projectId, expenseId),
        values,
      }),
    ).then((res) => {
      toastr.success('Well Done!', 'Expense cancelled successfully.');
      return res;
    })
  )
);

export const claimOwnershipDS = ({ orgAlias, projectId, rootComponentName }) => (
  dispatch => dispatch(pushDataDS({
    pushApiUrl: projectClaimOwnershipApiUrl(orgAlias, projectId),
    pushDataAC: responseData => ([
      viewUpdateAC(responseData.project, rootComponentName),
      extrasUpdateAC({ allowedActions: responseData.allowedActions }, rootComponentName),
    ]),
    reqType: REQ_TYPE.PUT,
  }))
);

export const cloneProjectDS = ({ orgAlias, projectId, values }) => (
  dispatch => dispatch(pushDataDS({
    pushApiUrl: projectCloneApiUrl(orgAlias, projectId),
    reqType: REQ_TYPE.POST,
    values,
  }))
);
