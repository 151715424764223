import PropTypes from 'prop-types';
import React from 'react';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDDropdown from 'core/assets/js/components/TDDropdown.jsx';
import { ICON } from 'core/assets/js/constants';

const TDDropChevronButton = ({ children, className }) => (
  <TDDropdown
    alignRight
    className={`drop-chevron${className ? ` ${className}` : ''}`}
    stopPropagation
    toggle={(
      <TDButton className="p-3"><i className={ICON.CHEVRON_DOWN} /></TDButton>
    )}
  >
    {children}
  </TDDropdown>
);

TDDropChevronButton.propTypes = {
  children: (
    PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired
  ),
  className: PropTypes.string,
};

TDDropChevronButton.defaultProps = {
  className: null,
};

export default TDDropChevronButton;
