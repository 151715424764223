import { ICON, SOCIAL_ICONS } from 'core/assets/js/constants';
import { NODE_ENV, ENV_DEVELOPMENT, RECAPTCHA_DISABLED_LOCALLY } from 'core/assets/js/config/settings';

export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_PROFILE_UPDATE = 'AUTH_PROFILE_UPDATE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

const ACCOUNT_SETUP_COMPONENT_NAME = 'AccountProvider';
const AVAILABILITY_WARNING_DAYS_THRESHOLD = 10;

const PROFILE_SETTINGS_TABS = {
  BASIC_INFORMATION: 'basic-information',
  CUSTOM_FIELDS: 'custom-fields',
  SOCIAL_PROFILES: 'social-profiles',
  EXPERIENCE: 'experience',
  SKILLS: 'skills',
  LANGUAGES: 'languages',
};

const SOCIAL_PROFILES = {
  BEHANCE: 'behance',
  DRIBBBLE: 'dribbble',
  FACEBOOK: 'facebook',
  GITHUB: 'github',
  INSTAGRAM: 'instagram',
  LINKEDIN: 'linkedin',
  TWITTER: 'twitter',
  YOUTUBE: 'youtube',
  WEBSITE: 'website',
  OTHER: 'other',
};

const SOCIAL_PROFILES_INFO = {
  [SOCIAL_PROFILES.BEHANCE]: {
    profileUrlPrefix: 'https://www.behance.net/',
    icon: SOCIAL_ICONS.BEHANCE,
    label: 'Behance',
  },
  [SOCIAL_PROFILES.DRIBBBLE]: {
    profileUrlPrefix: 'https://www.dribbble.com/',
    icon: SOCIAL_ICONS.DRIBBBLE,
    label: 'Dribbble',
  },
  [SOCIAL_PROFILES.FACEBOOK]: {
    profileUrlPrefix: 'https://www.facebook.com/',
    icon: SOCIAL_ICONS.FACEBOOK,
    label: 'Facebook',
  },
  [SOCIAL_PROFILES.GITHUB]: {
    profileUrlPrefix: 'https://www.github.com/',
    icon: SOCIAL_ICONS.GIHUB,
    label: 'Github',
  },
  [SOCIAL_PROFILES.INSTAGRAM]: {
    profileUrlPrefix: 'https://www.instagram.com/',
    icon: SOCIAL_ICONS.INSTAGRAM,
    label: 'Instagram',
  },
  [SOCIAL_PROFILES.LINKEDIN]: {
    profileUrlPrefix: 'https://www.linkedin.com/',
    icon: SOCIAL_ICONS.LINKEDIN,
    label: 'LinkedIn',
  },
  [SOCIAL_PROFILES.TWITTER]: {
    profileUrlPrefix: 'https://www.twitter.com/',
    icon: SOCIAL_ICONS.TWITTER,
    label: 'Twitter',
  },
  [SOCIAL_PROFILES.YOUTUBE]: {
    profileUrlPrefix: 'https://www.youtube.com/',
    icon: SOCIAL_ICONS.YOUTUBE,
    label: 'Youtube',
  },
  [SOCIAL_PROFILES.WEBSITE]: {
    profileUrlPrefix: '',
    icon: ICON.GLOBE,
    label: 'Website',
  },
  [SOCIAL_PROFILES.OTHER]: {
    profileUrlPrefix: '',
    icon: ICON.LINK,
    label: 'Other',
  },
};

const SOCIAL_PROFILES_OPTIONS = Object.values(SOCIAL_PROFILES).map(value => ({
  key: value,
  ...SOCIAL_PROFILES_INFO[value],
}));

export const EXPERIENCE_MAX_RESULTS = 100;

export {
  ACCOUNT_SETUP_COMPONENT_NAME,
  AVAILABILITY_WARNING_DAYS_THRESHOLD,
  PROFILE_SETTINGS_TABS,
  SOCIAL_PROFILES,
  SOCIAL_PROFILES_INFO,
  SOCIAL_PROFILES_OPTIONS,
};

// This is here, intead of being exported from the component, to prevent a circular dependency
export const GET_STARTED_SIDE_PANEL_MODAL_ID = 'get-started-side-panel';
export const GET_STARTED_COMPLETE_MODAL_ID = 'get-started-complete';
export const GET_STARTED_PENDING_APPROVAL_MODAL_ID = 'get-started-pending-approval';

export const STEP_HUBSPOT_KNOWLEDGEBASE_TAGS = {
  BillingDetailsStep: 'organization billing details',
  CompanyDetailsStep: 'company details',
  InterviewAmendStep: 'organization onboarding pending amendment',
  InterviewDraftStep: 'organization onboarding pending submission',
  InterviewPendingStep: 'organization onboarding pending review',
  PaymentMethodStep: 'payment method',
  ProfileFillStep: 'profile',
  ProfileInitialStep: 'profile',
  RateStep: 'organization rate',
  SalesTaxStep: 'organization sales tax',
  SubmissionPendingStep: 'organization onboarding pending submission',
  UserCardPaymentMethodStep: 'organization payment method',
};

export const STEP_HUBSPOT_KNOWLEDGEBASE_TAG_LABELS = {
  [STEP_HUBSPOT_KNOWLEDGEBASE_TAGS.BillingDetailsStep]: 'Organisation billing details',
  [STEP_HUBSPOT_KNOWLEDGEBASE_TAGS.CompanyDetailsStep]: 'Company details',
  [STEP_HUBSPOT_KNOWLEDGEBASE_TAGS.InterviewAmendStep]: 'Organisation onboarding pending amendment',
  [STEP_HUBSPOT_KNOWLEDGEBASE_TAGS.InterviewDraftStep]: 'Organisation onboarding pending submission',
  [STEP_HUBSPOT_KNOWLEDGEBASE_TAGS.InterviewPendingStep]: 'Organisation onboarding pending review',
  [STEP_HUBSPOT_KNOWLEDGEBASE_TAGS.PaymentMethodStep]: 'Payment method',
  [STEP_HUBSPOT_KNOWLEDGEBASE_TAGS.ProfileFillStep]: 'Profile',
  [STEP_HUBSPOT_KNOWLEDGEBASE_TAGS.RateStep]: 'Organisation rate',
  [STEP_HUBSPOT_KNOWLEDGEBASE_TAGS.SalesTaxStep]: 'Organisation sales tax',
  [STEP_HUBSPOT_KNOWLEDGEBASE_TAGS.UserCardPaymentMethodStep]: 'Organisation payment method',
};

export const useRecaptcha = !(NODE_ENV === ENV_DEVELOPMENT && RECAPTCHA_DISABLED_LOCALLY);

export const GUEST_CHAT_MODAL = 'chat-modal';

export const CLASSIFICATION_SUBMISSION_EVENT_TYPE = {
  PUBLISHED: 'published',
  RESET: 'reset',
  NOTE: 'note',
};

export const CLASSIFICATION_SUBMISSION_EVENT_TYPE_VALUES = Object.values(
  CLASSIFICATION_SUBMISSION_EVENT_TYPE,
);

export const CLASSIFICATION_SUBMISSION_EVENT_TYPE_LABEL = {
  [CLASSIFICATION_SUBMISSION_EVENT_TYPE.PUBLISHED]: 'Published',
  [CLASSIFICATION_SUBMISSION_EVENT_TYPE.RESET]: 'Reset',
  [CLASSIFICATION_SUBMISSION_EVENT_TYPE.NOTE]: 'Note',
};

export const CLASSIFICATION_SUBMISSION_RISK = {
  LOW: 'low',
  MODERATE: 'moderate',
  HIGH: 'high',
};

export const CLASSIFICATION_SUBMISSION_RISK_VALUES = Object.values(CLASSIFICATION_SUBMISSION_RISK);

export const CLASSIFICATION_SUBMISSION_RISK_LABELS = {
  [CLASSIFICATION_SUBMISSION_RISK.LOW]: 'Low',
  [CLASSIFICATION_SUBMISSION_RISK.MODERATE]: 'Moderate',
  [CLASSIFICATION_SUBMISSION_RISK.HIGH]: 'High',
};

export const CLASSIFICATION_SUBMISSION_RISK_DESCRIPTIONS = {
  [CLASSIFICATION_SUBMISSION_RISK.LOW]: 'Most likely a contractor',
  [CLASSIFICATION_SUBMISSION_RISK.MODERATE]: 'Might be an employee',
  [CLASSIFICATION_SUBMISSION_RISK.HIGH]: 'Most likely an employee',
};
