import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import {
  PERMISSIONS,
  PERMISSIONS_LABEL,
  PERMISSION_SUBGROUPS_LABEL,
  PERMISSIONS_DESCRIPTION,
  PERMISSION_SUBGROUPS_DESCRIPTION,
} from 'roles/assets/js/constants';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { modalCloseAC, modalOpenAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import { SUBGROUP_MODAL_ID } from 'roles/assets/js/components/PermissionsFinalFormField.jsx';
import { WINDOW } from 'core/assets/js/config/settings';

/**
 * Determines if a permission is available for an organization
 *
 * @param {Object} activeOrg
 * @param {String} permission
 * @returns {Boolean}
 */
const permissionIsAvailable = (activeOrg, permission) => {
  if (permission === PERMISSIONS.CAN_BULK_IMPORT_USERS && !activeOrg.bulk_import_users_enabled) {
    return false;
  }
  if (
    [PERMISSIONS.CAN_VIEW_1099_CONTRACTORS, PERMISSIONS.CAN_FILE_1099_REPORTS].includes(permission)
    && !activeOrg['1099_filings_enabled']
  ) {
    return false;
  }
  if (permission === PERMISSIONS.CAN_MANAGE_INVOICE_CAPS && !activeOrg.invoice_caps_enabled) {
    return false;
  }
  if (
    permission === PERMISSIONS.CAN_VIEW_CLASSIFICATION_QUESTIONNAIRE_SUBMISSIONS
    && !activeOrg.aor_classification_questionnaire_enabled
  ) {
    return false;
  }
  if (
    permission === PERMISSIONS.CAN_VIEW_PROVIDER_PAYMENT_DETAILS
    && !activeOrg.higher_managers_can_see_providers_payment_details
  ) {
    return false;
  }
  return true;
};


const PermissionSubgroupList = ({
  handleToggle,
  handleToggleAll,
  selectedPermissions,
  subgroup,
  isSystemRole,
}) => {
  /* eslint-disable no-restricted-globals, no-undef */
  const screenWidth = window.screen.width;
  const dispatch = useDispatch();
  const activeOrg = useSelector(selectActiveOrg);
  const permissionModalOpen = useSelector(state => getIsModalOpen(state, SUBGROUP_MODAL_ID));
  const permissionsLength = subgroup.permissions.length;

  const onScreenResize = () => {
    if (screenWidth <= 768) {
      dispatch(modalOpenAC(SUBGROUP_MODAL_ID));
    }
  };

  useEffect(() => {
    onScreenResize();
    WINDOW.addEventListener('resize', onScreenResize);
    return () => WINDOW.removeEventListener('resize', onScreenResize);
  }, []);

  const body = (
    <div className="m-4">
      <div className="subgroup-permissions__description mb-4">
        {PERMISSION_SUBGROUPS_DESCRIPTION[subgroup.id]}
      </div>
      {!isSystemRole && (
        <ul>
          <li
            className={(
              'subgroup-permissions__list-item d-flex align-items-center '
              + 'justify-content-between mb-4'
            )}
          >
            <div className="mr-4">
              <div className="subgroup-permissions__item-label">
                Enable all permissions
              </div>
            </div>
            <TDSwitch
              onClick={() => {
                handleToggleAll(subgroup.permissions);
              }}
              selected={subgroup.permissions.every(
                permission => selectedPermissions.includes(permission),
              )}
            />
          </li>
        </ul>
      )}
      <div className="subgroup-permissions__separator" />
      <ul>
        {subgroup.permissions.map((permission, index) => {
          if (!permissionIsAvailable(activeOrg, permission)) {
            return null;
          }

          return (
            <li
              className={(
                'subgroup-permissions__list-item d-flex align-items-center '
                + `justify-content-between${index < permissionsLength - 1 ?  ' mb-5' : ''}`
              )}
              key={permission}
            >
              <div className="subgroup-permissions__item-info">
                <div className="subgroup-permissions__item-label">
                  {PERMISSIONS_LABEL[permission]}
                </div>
                <div className="subgroup-permissions__item-description">
                  {PERMISSIONS_DESCRIPTION[permission]}
                </div>
              </div>
              <TDSwitch
                disabled={isSystemRole}
                onClick={() => {
                  handleToggle({ permission });
                }}
                selected={selectedPermissions.includes(permission)}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );

  return (
    <>
      {screenWidth >= 768 ? (
        <div className="subgroup-permissions__container ml-auto">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-title h4">
                {PERMISSION_SUBGROUPS_LABEL[subgroup.id]}
              </div>
            </div>
            {body}
          </div>
        </div>
      ) : (
        <ModalSimple
          body={body}
          noFooter
          heading={PERMISSION_SUBGROUPS_LABEL[subgroup.id]}
          onClose={() => dispatch(modalCloseAC(SUBGROUP_MODAL_ID))}
          open={permissionModalOpen}
        />
      )}
    </>
  );
};

PermissionSubgroupList.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  handleToggleAll: PropTypes.func.isRequired,
  selectedPermissions: PropTypes.array.isRequired,
  subgroup: PropTypes.object.isRequired,
  isSystemRole: PropTypes.bool,
};

PermissionSubgroupList.defaultProps = {
  isSystemRole: false,
};

export default PermissionSubgroupList;
