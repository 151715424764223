import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Form } from 'react-final-form';
import { Card } from 'react-bootstrap';
import { isNil, isEmpty, map, omit, pickBy, pick, isArray, fromPairs } from 'lodash';

import CustomFieldFilterRenderer from 'core/assets/js/components/CustomFieldFilterRenderer.jsx';
import OrderingDropDown from 'core/assets/js/components/OrderingDropDown.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { SEARCH_MAX_LENGTH } from 'search/assets/js/constants';
import { parseQueryStringParams, stringifyQueryParams } from 'core/assets/js/lib/utils';

class SearchFinalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearchInput: false,
    };

    this.resetFilter = this.resetFilter.bind(this);
    this.resetAllFilters = this.resetAllFilters.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    this.handleToggleSearchInput = this.handleToggleSearchInput.bind(this);
    this.handleCloseFilters = this.handleCloseFilters.bind(this);
    this.submitOrdering = this.submitOrdering.bind(this);
    this.submitFilters = this.submitFilters.bind(this);
    this.handleSelected = this.handleSelected.bind(this);
    this.countFilters = this.countFilters.bind(this);
    this.getFilterParams = this.getFilterParams.bind(this);
    this.getInitialValues = this.getInitialValues.bind(this);
    this.getQueryParams = this.getQueryParams.bind(this);
  }

  getFilterParams() {
    const { searchSpec } = this.props;
    const customFieldFilters = map(searchSpec.customFieldFilters, f => f.searchKey);
    const filterNames = map(
      searchSpec.filters.filter(flt => !flt.hidden && !flt.isOrdering && !!flt.paramName),
      f => f.paramName,
    );

    return [
      ...filterNames,
      ...customFieldFilters,
    ];
  }

  getFieldProps() {
    const { searchSpec: { searchTerm } } = this.props;

    if (!searchTerm) {
      return {};
    }

    const { kw } = this.getInitialValues();
    const inputFieldClass = ['input--text'];

    if (kw) {
      inputFieldClass.push('input-field--active');
    }

    const fieldProps = {
      className: inputFieldClass.join(' '),
      key: searchTerm.paramName,
      name: searchTerm.paramName,
      fetchSuggestions: searchTerm.fetchSuggestions,
      'data-testid': searchTerm['data-testid'],
      component: searchTerm.component,
      placeholder: searchTerm.placeholder || 'Type here to search',
    };

    // Avoid warnings like "property handleSelected is not available for InputField"
    if (searchTerm.component && searchTerm.component.name !== 'InputField') {
      fieldProps.handleSelected = this.handleSelected;
    }

    return fieldProps;
  }

  handleSelected(paramName, newValue) {
    this.submitFilters({ [paramName]: newValue });
  }

  handleToggleSearchInput() {
    const { showSearchInput } = this.state;
    this.setState({ showSearchInput: !showSearchInput });
  }

  handleCloseFilters() {
    const { onFiltersToggle } = this.props;

    onFiltersToggle(false);
  }

  getQueryParams() {
    const { location, query } = this.props;
    // If query is provided, query will be used instead of location
    if (query) {
      return query;
    }
    return parseQueryStringParams(location.search);
  }

  submitOrdering(ordering) {
    const { history, onFiltersChanged } = this.props;
    // Get existing query
    const query = this.getQueryParams();

    // Close filters if open.
    this.handleCloseFilters();

    if (onFiltersChanged) {
      return onFiltersChanged({
        ...query,
        ordering: JSON.stringify(ordering),
      });
    }

    return history.push({
      path: history.location.pathname,
      search: stringifyQueryParams({ ...query, ordering }),
    });
  }

  submitFilters(formValues) {
    const { history, onFiltersChanged, parseFilters } = this.props;
    const filterNames = this.getFilterParams();

    // Get existing query
    let query = this.getQueryParams();
    query = {
      // Remove search related params
      ...omit(query, filterNames),
      // Add search params if set
      ...pickBy(formValues, p => (!isNil(p) && p !== '' && p !== '{}')),
      // Reset pagination
      page: 1,
    };
    query = parseFilters(query);

    // If user deletes the kw input, make sure we update the query too.
    if (formValues && !formValues.kw && query && query.kw) {
      delete query.kw;
    }

    this.handleCloseFilters();

    if (onFiltersChanged) {
      return onFiltersChanged(query);
    }

    return history.push({
      path: history.location.pathname,
      search: stringifyQueryParams(query),
    });
  }

  resetAllFilters(change) {
    const {
      extraResetFilterParamNames, history, onFiltersChanged, onFiltersToggle,
    } = this.props;
    // Construct a list with filter names while resetting each one of them
    // if hidden filter exists then it will not be cleared when reset filters is clicked
    const filterNames = this.getFilterParams();

    if (!change) {
      throw new Error('You need to pass the `change` callback as an argument');
    }

    // Reset the input values of the fields
    filterNames.forEach(f => change(f, ''));

    const query = {
      // remove filter params from query
      ...omit(this.getQueryParams(), [...filterNames, ...extraResetFilterParamNames]),
      // Reset Pages
      page: 1,
    };

    if (onFiltersChanged) {
      onFiltersChanged(query);
    } else {
      // Update query string
      history.push({
        path: history.location.pathname, search: stringifyQueryParams(query),
      });
    }

    // Toggle filters form
    onFiltersToggle(false);
  }

  resetFilter(filter, change) {
    const { history, onFiltersChanged } = this.props;

    if (!change) {
      throw new Error('You need to pass the `change` callback as an argument');
    }

    change(filter.paramName, '');

    // remove filter params from query
    const query = {
      ...omit(this.getQueryParams(), filter.paramName),
      // Reset Pages
      page: 1,
    };

    if (onFiltersChanged) {
      onFiltersChanged(query);
    } else {
      // Update query string
      history.push({
        path: history.location.pathname, search: stringifyQueryParams(query),
      });
    }
  }

  countFilters() {
    let count = 0;

    const _query = this.getQueryParams();
    // Extract filters from query string.
    const filters = { ...pick(_query, this.getFilterParams()) };

    Object.keys(filters).forEach((key) => {
      if (
        filters[key] !== '{}'
        && !isNil(filters[key])
        && !(isArray(filters[key]) && filters[key].length === 0)
      ) {
        count += 1;
      }
    });

    return count;
  }

  renderFilters() {
    const { searchSpec: { filters, customFieldFilters } } = this.props;
    const filterFields = !isEmpty(filters) ? filters.filter(fltr => !fltr.hidden) : [];

    const initialValues = this.getInitialValues();

    const renderedFilters = filterFields.map((filter) => {
      const {
        fieldComponent: FieldComponent,
        fieldComponentProps = {},
        fieldName,
        inputClassName = undefined,
        isOrdering = false,
        paramName,
      } = filter;

      return (
        <div
          key={paramName}
          className={`search-filter-item mb-4
          ${isOrdering ? 'd-block col-12 d-md-none' : 'col-12 col-md-6'}`}
        >
          <FieldComponent
            name={fieldName || paramName}
            className={inputClassName}
            filter={filter}
            initialValues={initialValues}
            {...fieldComponentProps}
          />
        </div>
      );
    });

    (customFieldFilters || []).forEach(filter => (
      renderedFilters.push(
        <div
          key={`custom-filter-field-${filter.id}`}
          className="search-filter-item col-12 col-md-6 mb-4"
        >
          <CustomFieldFilterRenderer
            field={filter}
          />
        </div>,
      )
    ));

    return renderedFilters;
  }

  getInitialValues() {
    const { initialValues, location: { search }, searchSpec, query } = this.props;
    const { filters, customFieldFilters, searchTerm } = searchSpec;
    const fieldNames = [...filters];
    if (searchTerm) {
      fieldNames.push(searchTerm);
    }
    const searchParams = query || parseQueryStringParams(search);
    return {
      ...initialValues,
      ...fromPairs([
        ...(fieldNames || []).map(f => [f.paramName, searchParams[f.paramName]]),
        ...(customFieldFilters || []).map(cf => [cf.searchKey, searchParams[cf.searchKey]]),
      ]),
    };
  }

  render() {
    const { showSearchInput } = this.state;
    const {
      alwaysShowSearch,
      className,
      extraSearchElement,
      extraSearchRow,
      filtersOpen,
      formRef,
      maxLength,
      onFiltersToggle,
      positionExtraSearchElementBefore,
      searchSpec,
      searchContainerClass,
    } = this.props;

    const { searchTerm, orderingOptions, defaultOrdering } = searchSpec;
    const filtersCount = this.countFilters();
    const filterButtonClass = ['toggle-filters-button px-4'];

    const classNames = ['flex-grow-1 search-form'];

    if (className) {
      classNames.push(className);
    }

    if (showSearchInput) {
      classNames.push('search-open');
    }

    if (filtersOpen) {
      filterButtonClass.push('toggle-filters-button--open btn--active');
    }

    if (filtersCount > 0) {
      filterButtonClass.push('toggle-filters-button--active');
    }

    const { component: SearchComponent, ...searchComponentProps } = this.getFieldProps();

    if (SearchComponent) {
      classNames.push('col-auto');
    }

    let finalSearchContainerClass = searchContainerClass
      || 'col-12 col-md-auto mt-3 mt-md-0 ml-0 ml-md-3 px-0 position-relative';
    if (alwaysShowSearch || showSearchInput) {
      finalSearchContainerClass += ' d-block';
    } else {
      finalSearchContainerClass += ' d-none d-md-block kw-field';
    }

    const displayDesktopFiltersButton = (
      Array.isArray(searchSpec.filters) && searchSpec.filters.some(f => !f.isOrdering)
    );
    if (!displayDesktopFiltersButton) {
      filterButtonClass.push('d-block d-md-none');
    }

    return (
      <div className={classNames.join(' ')}>
        <Form
          initialValues={this.getInitialValues()}
          onSubmit={this.submitFilters}
          keepDirtyOnReinitialize
        >
          {({ handleSubmit, form }) => {
            const { submitting, values: { kw } } = form.getState();

            if (formRef && !formRef.current) {
              // this is a bit hacky, but as of react-final-form v6, using `<Form ref` is
              // broken https://github.com/final-form/react-final-form/issues/483
              formRef.current = form;
            }

            return (
              <form
                name="search-form"
                id="search-form"
                onSubmit={handleSubmit}
              >
                <div className="mb-4 d-flex flex-wrap align-items-center">
                  {positionExtraSearchElementBefore && extraSearchElement}
                  {orderingOptions && (
                    <OrderingDropDown
                      onOrderingChange={this.submitOrdering}
                      orderingOptions={orderingOptions}
                      defaultOrdering={defaultOrdering}
                    />
                  )}

                  {Array.isArray(searchSpec.filters) && searchSpec.filters.length > 0 && (
                    <TDButton
                      className={filterButtonClass.join(' ')}
                      label={(
                        <React.Fragment>
                          <i className={`${ICON.FILTERS}`} />
                          <span className="ml-0 ml-xl-2 d-none d-xl-inline-block">Filters</span>
                          {filtersCount > 0 && (
                            <React.Fragment>
                              {' '}
                              &bull;
                              {' '}
                              {filtersCount}
                            </React.Fragment>
                          )}
                        </React.Fragment>
                      )}
                      onClick={() => onFiltersToggle(!filtersOpen)}
                      variant={BS_STYLE.DEFAULT}
                    />
                  )}

                  {SearchComponent && (
                    <>
                      <TDButton
                        className={`px-4 ml-2 kw-field-toggle ${alwaysShowSearch ? 'd-none' : 'd-inline-block d-md-none '} ${showSearchInput ? 'btn--active' : ''}`}
                        variant={BS_STYLE.DEFAULT}
                        label={(
                          <i className={ICON.SEARCH} />
                        )}
                        onClick={this.handleToggleSearchInput}
                      />
                      {searchTerm && (
                        <div className={finalSearchContainerClass}>
                          <SearchComponent
                            maxLength={maxLength}
                            {...searchComponentProps}
                          />

                          { kw && (
                            <span
                              onClick={() => this.resetFilter(searchTerm, form.change)}
                              className={`${ICON.CROSS} clear-kw`}
                            />
                          )}

                          <TDButton
                            data-testid="search-button"
                            className="search-button"
                            type="submit"
                            variant={BS_STYLE.LINK}
                            disabled={submitting}
                            label={<span className={ICON.SEARCH} />}
                          />
                        </div>
                      )}
                    </>
                  )}
                  {!positionExtraSearchElementBefore && extraSearchElement}
                </div>
                {extraSearchRow}
                {filtersOpen && (
                  <Card className="search-filters-list">
                    <Card.Body className="d-flex flex-column justify-content-between">
                      <div className="row search-filters-container">
                        {this.renderFilters()}
                      </div>

                      <div className="text-right mt-5">
                        <TDButton
                          className="imitate-link"
                          variant={BS_STYLE.LINK}
                          disabled={submitting}
                          label="Clear filters"
                          onClick={() => this.resetAllFilters(form.change)}
                        />

                        <TDButton
                          type="submit"
                          variant={BS_STYLE.PRIMARY}
                          disabled={submitting}
                          label="Apply"
                        />
                      </div>
                    </Card.Body>
                  </Card>
                )}
              </form>
            );
          }}
        </Form>
      </div>
    );
  }
}

SearchFinalForm.propTypes = {
  alwaysShowSearch: PropTypes.bool,
  className: PropTypes.string,
  extraResetFilterParamNames: PropTypes.arrayOf(PropTypes.string),
  extraSearchElement: PropTypes.element,
  extraSearchRow: PropTypes.element,
  filtersOpen: PropTypes.bool,
  formRef: PropTypes.object,
  history: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  maxLength: PropTypes.number,
  onFiltersChanged: PropTypes.func,
  onFiltersToggle: PropTypes.func,
  parseFilters: PropTypes.func,
  positionExtraSearchElementBefore: PropTypes.bool,
  query: PropTypes.object,
  searchSpec: PropTypes.object.isRequired,
  searchContainerClass: PropTypes.string,
};

SearchFinalForm.defaultProps = {
  alwaysShowSearch: false,
  className: '',
  extraResetFilterParamNames: [],
  extraSearchElement: null,
  extraSearchRow: null,
  filtersOpen: false,
  formRef: null,
  maxLength: SEARCH_MAX_LENGTH,
  onFiltersChanged: null,
  onFiltersToggle: () => { },
  parseFilters: filters => filters,
  positionExtraSearchElementBefore: false,
  query: null,
  searchContainerClass: '',
};

export default withRouter(SearchFinalForm);
