import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import {
  addBackUrlIfLocationContains, financeWorksheetViewUrl,
  financeWorksheetEditUrl, financeWorksheetOnDemandInvoiceUrl,
} from 'finance/urls';
import { SERVICE_ORDER_TYPE } from 'projects/assets/js/constants';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { ON_DEMAND_INVOICE_STEP } from 'finance/assets/js/constants';
import OnDemandInvoiceFuturePeriodModal from 'finance/assets/js/components/invoicing/OnDemandInvoiceFuturePeriodModal.jsx';

const WorksheetItemActions = ({ history, orgAlias, worksheet, pathname, onCancelClick }) => {
  const showEditButton = worksheet?.allowedActions?.canBeEdited;
  const showCancelButton = worksheet?.allowedActions?.canBeCancelled;
  const canInvoiceWorksheetOnDemand = worksheet?.allowedActions?.canInvoiceWorksheetOnDemand;
  const canCreateOnDemandInvoices = worksheet?.provider?.userCard
    ?.allowedActions.canCreateOnDemandInvoices;
  const shouldInvoiceInLaterDate = worksheet?.allowedActions
    ?.shouldInvoiceInLaterDate;
  const showCreateOnDemandInvoiceButton = (
    canInvoiceWorksheetOnDemand
    || (shouldInvoiceInLaterDate && canCreateOnDemandInvoices)
  );
  const [isRaiseInvoiceModalOpen, openRaiseInvoiceModal] = useState(false);
  const billableReference = `${worksheet?.projectReference}_${worksheet?.id}`;
  const handleRaiseInvoiceClick = () => {
    if (shouldInvoiceInLaterDate) {
      openRaiseInvoiceModal(true);
      return;
    }
    history.push(financeWorksheetOnDemandInvoiceUrl(
      orgAlias, worksheet.id, ON_DEMAND_INVOICE_STEP.INIT,
    ));
  };
  return (
    <TDDropButton data-testid="worksheets-table-actions" stopPropagation>
      <Dropdown.Item
        eventKey="view-worksheet"
        data-testid="view-worksheet"
        onClick={(e) => {
          e.stopPropagation();
          history.push(
            addBackUrlIfLocationContains(
              financeWorksheetViewUrl(orgAlias, worksheet.id),
              history.location.pathname,
              '/projects/',
            ),
          );
        }}
      >
        View worksheet
      </Dropdown.Item>

      { showEditButton && (
        <Dropdown.Item
          eventKey="edit-worksheet"
          data-testid="edit-worksheet"
          onClick={(e) => {
            e.stopPropagation();
            history.push(financeWorksheetEditUrl(orgAlias, worksheet.id, pathname));
          }}
        >
          <span>Edit worksheet</span>
        </Dropdown.Item>
      )}

      { showCancelButton && (
        <Dropdown.Item
          eventKey="cancel-worksheet"
          data-testid="cancel-worksheet"
          onClick={onCancelClick}
        >
          <span className="text-secondary">Cancel worksheet</span>
        </Dropdown.Item>
      )}
      { showCreateOnDemandInvoiceButton && (
        <Dropdown.Item
          eventKey="raise-invoice"
          data-testid="raise-invoice"
          onClick={(e) => {
            e.stopPropagation();
            handleRaiseInvoiceClick();
          }}
        >
          <span className="text-secondary">Raise invoice</span>
        </Dropdown.Item>
      )}
      <OnDemandInvoiceFuturePeriodModal
        open={isRaiseInvoiceModalOpen}
        onClose={() => openRaiseInvoiceModal(false)}
        billableProcessDate={worksheet.processAt}
        billableType={SERVICE_ORDER_TYPE.WORKSHEET}
        billableReference={billableReference}
      />
    </TDDropButton>
  );
};

WorksheetItemActions.propTypes = {
  history: routerHistorySpec.isRequired,
  worksheet: PropTypes.object.isRequired,
  orgAlias: PropTypes.string.isRequired,
  pathname: PropTypes.string,
  onCancelClick: PropTypes.func.isRequired,
};

WorksheetItemActions.defaultProps = {
  pathname: null,
};

export default WorksheetItemActions;
