import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { toastr } from 'react-redux-toastr';

import { BS_STYLE } from 'core/assets/js/constants';
import { listRemoveItemAC } from 'core/assets/js/ducks/list';
import { modalOpenAC, modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import { RATE_CANNOT_DELETE_REASON_LABEL } from 'rates/assets/js/constants';
import { deleteRateDS } from 'rates/assets/js/data-services/rates';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';

export const _getModalId = rateId => `delete-modal-${rateId}`;

const _mapStateToProps = (state, props) => {
  const { rate } = props;
  const modalId = _getModalId(rate.id);

  return {
    modalId,
    isModalOpen: getIsModalOpen(state, modalId),
  };
};
const _mapDispatchToProps = dispatch => ({
  dispatch,
});


const Modal = ({ alias, dispatch, isModalOpen, listComponentName, orgAlias, rate }) => {
  const canDelete = !!rate.allowedActions?.canDelete?.value;
  const reason = rate.allowedActions?.canDelete?.reason;

  return (
    <ModalConfirm
      body={(
        <>
          {canDelete && (
            <>
              <p>
                Deleting this rate does not affect your active projects or tasks.
              </p>
              <p>
                Are you sure you want to delete this rate?
              </p>
            </>
          )}
          {!canDelete && (
            <>
              <p>You cannot delete this rate</p>
              {reason && (<p>{RATE_CANNOT_DELETE_REASON_LABEL[reason]}</p>)}
            </>
          )}
        </>
      )}
      confirmLabel="Delete rate"
      confirmStyle={BS_STYLE.DANGER}
      heading={`Delete rate "${alias}"`}
      onClose={() => dispatch(modalCloseAC())}
      onConfirm={async () => {
        try {
          const response = await dispatch(deleteRateDS(orgAlias, rate.id));

          toastr.success('Well Done!', `Your rate "${alias}" deleted`);

          // Update the list in redux store
          dispatch(listRemoveItemAC(rate.id, listComponentName));

          return response;
        } catch (err) {
          toastr.error('Oh Snap!', err._error || err.message);
          return null;
        }
      }}
      open={isModalOpen}
      showConfirmButton={canDelete}
    />
  );
};

Modal.propTypes = {
  alias: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
  listComponentName: PropTypes.string.isRequired,
  modalId: PropTypes.string.isRequired,
  orgAlias: PropTypes.string.isRequired,
  rate: PropTypes.shape(rateSpec).isRequired,
};

Modal.defaultProps = {
  isModalOpen: false,
};

const ModalConnected = connect(
  _mapStateToProps,
  _mapDispatchToProps,
)(Modal);

const DropdownItem = ({ dispatch, modalId }) => {
  return (
    <Dropdown.Item
      eventKey="delete"
      onClick={() => {
        dispatch(modalOpenAC(modalId));
      }}
    >
      Delete
    </Dropdown.Item>
  );
};

DropdownItem.propTypes = {
  dispatch: PropTypes.func.isRequired,
  modalId: PropTypes.string.isRequired,
  rate: PropTypes.shape(rateSpec).isRequired,
};

const DropdownOption = connect(
  _mapStateToProps,
  _mapDispatchToProps,
)(DropdownItem);

const RateDeleteOption = {
  DropdownOption,
  Modal: ModalConnected,
};

export default RateDeleteOption;
