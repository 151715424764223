import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_SIZE } from 'core/assets/js/constants';

class ModalSimple extends React.Component {
  constructor(props) {
    super(props);

    this.props = props;
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    const { onOpen } = this.props;
    onOpen();
  }

  handleCloseModal() {
    const { onClose } = this.props;
    onClose();
  }

  render() {
    const {
      'data-testid': dataTestId,
      backdrop,
      body,
      children,
      closeButton,
      enforceFocus,
      footer,
      fullWidth,
      heading,
      noFooter,
      noHeader,
      open,
      size,
    } = this.props;
    return (
      <div
        onClick={ev => ev.stopPropagation()}
        className="static-modal"
      >
        { open
          && (
            <Modal
              backdrop={backdrop}
              className={fullWidth ? 'modal--full-width' : ''}
              data-testid={dataTestId}
              enforceFocus={enforceFocus}
              onEnter={this.handleOpenModal}
              onHide={this.handleCloseModal}
              show={open}
              size={size}
            >
              { !noHeader && (
                <Modal.Header closeButton={closeButton}>
                  {heading && <Modal.Title>{heading}</Modal.Title>}
                </Modal.Header>
              )}
              <Modal.Body>
                {body || children}
              </Modal.Body>
              { !noFooter && (
                <Modal.Footer>
                  { footer || (
                    <TDButton
                      onClick={this.handleCloseModal}
                      label="Close"
                    />
                  )}
                </Modal.Footer>
              )}
            </Modal>
          )
        }
      </div>
    );
  }
}

ModalSimple.propTypes = {
  'data-testid': PropTypes.string,
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  body: PropTypes.node,
  children: PropTypes.node,
  closeButton: PropTypes.bool,
  enforceFocus: PropTypes.bool,
  footer: PropTypes.node,
  fullWidth: PropTypes.bool,
  heading: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  noFooter: PropTypes.bool,
  noHeader: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func,
  open: PropTypes.bool,
  size: PropTypes.string,
};

ModalSimple.defaultProps = {
  'data-testid': '',
  backdrop: true,
  body: null,
  children: null,
  closeButton: true,
  enforceFocus: true,
  footer: null,
  fullWidth: false,
  heading: null,
  noFooter: false,
  noHeader: false,
  onOpen: () => {},
  open: false,
  size: BS_SIZE.MEDIUM,
};

export default ModalSimple;
