import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

import { modalOpenAC, getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';


export const getModalId = rateId => `default-rate-modal-${rateId}`;

class Modal extends React.Component {
  constructor(props) {
    super(props);

    this.handleModalClose = this.handleModalClose.bind(this);
  }

  handleModalClose() {
    const { dispatch, modalId } = this.props;

    dispatch(modalCloseAC(modalId));
  }

  render() {
    const { isModalOpen, rate } = this.props;

    return (
      <ModalSimple
        body={(
          <>
            <p>
              Your default rate is displayed on your profile and in the providers list. You can
              create and use multiple rates in different units (for e.g. per hour, per word,
              per day etc.), however, your default rate unit will always match the rate unit set by
              the organisation to make search easier for managers.
            </p>
            <p>
              This rate also cannot be deleted, unless you set another default rate unit first.
            </p>
          </>
        )}
        heading={`"${rate.alias}" is your default rate`}
        onClose={this.handleModalClose}
        open={isModalOpen}
        footer={(
          <>
            <TDButton
              key={2}
              className="float-right"
              label="Close"
              onClick={() => {
                this.handleModalClose();
              }}
            />
          </>
        )}
      />
    );
  }
}

Modal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
  modalId: PropTypes.string.isRequired,
  rate: PropTypes.shape(rateSpec).isRequired,
};
Modal.defaultProps = {
  isModalOpen: false,
};
const _mapStateToProps = (state, props) => {
  const { rate } = props;
  const modalId = getModalId(rate.id);
  return {
    isModalOpen: getIsModalOpen(state, modalId),
    modalId,
  };
};
const _mapDispatchToProps = dispatch => ({
  dispatch,
});
const ModalConnected = connect(
  _mapStateToProps,
  _mapDispatchToProps,
)(Modal);


class DropdownItem extends React.Component {
  constructor(props) {
    super(props);

    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    const { dispatch, modalId } = this.props;

    dispatch(modalOpenAC(modalId));
  }

  render() {
    return (
      <React.Fragment>
        <Dropdown.Item
          className="text-info"
          eventKey="default"
          onClick={() => {
            this.openModal();
          }}
        >
          Default rate
        </Dropdown.Item>
      </React.Fragment>
    );
  }
}

DropdownItem.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
  modalId: PropTypes.string.isRequired,
  rate: PropTypes.shape(rateSpec).isRequired,
};
DropdownItem.defaultProps = {
  isModalOpen: false,
};

const DropdownItemConnected = connect(
  _mapStateToProps,
  _mapDispatchToProps,
)(DropdownItem);

const RateDefaultOption = {
  DropdownOption: DropdownItemConnected,
  Modal: ModalConnected,
};

export default RateDefaultOption;
