import { isEmpty, omit } from 'lodash';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import ElementWithPopOver from 'core/assets/js/components/ElementWithPopOver.jsx';
import ListActionsButton from 'core/assets/js/components/ListActionsButton.jsx';
import TDButtonGroup from 'core/assets/js/components/TDButtonGroup.jsx';
import TDDropChevronButton from 'core/assets/js/components/TDDropChevronButton.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import TDSwitch from 'core/assets/js/components/TDSwitch.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { ICON, VIEW_TYPES } from 'core/assets/js/constants';
import { getListState, getListStateExtras } from 'core/assets/js/ducks/list';
import { getPeopleListViewType, setPeopleListViewTypeAC } from 'core/assets/js/ducks/settings';
import FinanceTableSkeleton from 'finance/assets/js/skeletons/FinanceTableSkeleton.jsx';
import PeopleSearch from 'people/assets/js/components/PeopleSearch.jsx';
import PeopleGridListSkeleton from 'people/assets/js/components/skeletons/PeopleGridListSkeleton';
import TeamMembersGridListItem from 'people/assets/js/components/TeamMembersGridListItem.jsx';
import TeamMembersTableList from 'people/assets/js/components/TeamMembersTableList';
import { PEOPLE_TYPE } from 'people/assets/js/constants';

const PeopleList = ({
  addUsersComponent,
  componentName,
  exportFn,
  filtersOpen,
  getActionsDropDownItems,
  noUsersMessage,
  onFiltersToggle,
}) => {
  const params = useParams();
  const { peopleType } = params;
  const location = useLocation();
  const dispatch = useDispatch();

  const [showProgress, setShowProgress] = useState(false);

  const {
    extras: { rateLimits, userGroupNames } = {}, hasLoaded, items: people, pagination,
  } = useSelector(state => getListState(state, componentName));
  const customFieldFilters = useSelector(
    state => getListStateExtras(state, componentName, 'customFieldFilters'),
  );
  const hasOrgAccess = useSelector(getHasOrgAccess);
  const listViewType = useSelector(getPeopleListViewType);

  const isManager = hasOrgAccess({ requireManager: true });
  const canViewOnboardingProgress = peopleType === PEOPLE_TYPE.PROVIDERS && isManager;
  const isSearchResult = !isEmpty(omit(queryString.parse(location.search), ['page', 'ordering']));
  const isGrid = listViewType === VIEW_TYPES.GRID;
  const noResults = people.length === 0;

  const viewOnboardingProgressSwitch = (
    <>
      <TDSwitch
        className="mr-3"
        onClick={() => setShowProgress(!showProgress)}
        selected={showProgress}
      />
      <span className="mr-2">Show onboarding progress</span>
    </>
  );

  const setListViewType = newValue => dispatch(setPeopleListViewTypeAC(newValue));

  const listActions = [];
  if (exportFn) {
    listActions.push({ name: 'Export', onClick: exportFn });
  }
  if (canViewOnboardingProgress) {
    listActions.push({
      className: 'd-block d-xl-none',
      name: viewOnboardingProgressSwitch,
      onClick: e => e.stopPropagation(),
    });
  }

  const ActionsDropDownComponent = ({ item }) => {
    if (!getActionsDropDownItems) {
      return null;
    }
    const items = getActionsDropDownItems(item);
    if (!items) {
      return null;
    }
    const dropDownClassNames = ['team-grid-list-item__actions-drop-down'];
    if (listViewType === VIEW_TYPES.GRID) {
      dropDownClassNames.push('team-grid-list-item__actions-drop-down--fixed');
    }
    return (
      <TDDropChevronButton className={dropDownClassNames.join(' ')}>{items}</TDDropChevronButton>
    );
  };

  ActionsDropDownComponent.propTypes = {
    item: PropTypes.object.isRequired,
  };

  return (
    <>
      <div className="row">
        <PeopleSearch
          extraSearchElement={(
            <div className="d-flex align-items-center ml-auto">
              <>
                <div className="d-none d-xl-flex flex-nowrap align-items-center">
                  <TDButtonGroup
                    primaryButtonActive={isGrid}
                    primaryButton={(
                      <TDElementWithTooltip
                        delay={500}
                        tooltipMsg="Grid view"
                      >
                        <i className={`p-4 m-n4 ${ICON.GRID}`} />
                      </TDElementWithTooltip>
                    )}
                    primaryButtonAction={() => setListViewType(VIEW_TYPES.GRID)}
                    secondaryButton={(
                      <TDElementWithTooltip
                        delay={500}
                        tooltipMsg="Table view"
                      >
                        <i className={`p-4 m-n4 ${ICON.TABLE}`} />
                      </TDElementWithTooltip>
                    )}
                    secondaryButtonActive={!isGrid}
                    secondaryButtonAction={() => setListViewType(VIEW_TYPES.TABLE)}
                  />
                  {canViewOnboardingProgress && (
                    <div className="d-flex align-items-center ml-5">
                      {viewOnboardingProgressSwitch}
                      <ElementWithPopOver
                        placement="bottom-end"
                        popOverContent={`
                          By activating account progress, each provider's progress will be
                          displayed, allowing you to review them.
                        `}
                        popOverTitle="Account progress"
                      />
                    </div>
                  )}
                </div>
                {listActions.length > 0 && (
                  <ListActionsButton
                    options={listActions}
                    wrapperClassName={`d-block ml-4${!exportFn ? ' d-block d-xl-none' : ''}`}
                  />
                )}
              </>
            </div>
          )}
          peopleType={peopleType}
          rateLimits={rateLimits}
          onFiltersToggle={onFiltersToggle}
          filtersOpen={filtersOpen}
          isManager={isManager}
          customFieldFilters={customFieldFilters}
          userGroupNames={!Array.isArray(userGroupNames) ? null : userGroupNames.map(ugn => ({
            text: ugn.name, value: ugn.id.toString(),
          }))}
        />
      </div>
      {!filtersOpen && (
        <>
          {hasLoaded && (
            <>
              {noResults && (
                <>
                  {isSearchResult && (
                    <div className="empty-list-message text-center py-4">No results found</div>
                  )}
                  {!isSearchResult && (
                    <div
                      className={(
                        'd-flex flex-column align-items-center rounded shadow-sm p-4 bg-white '
                        + 'justify-content-center empty-list-message'
                      )}
                    >
                      <i className={ICON.USERS_DUOTONE} />
                      {noUsersMessage}
                      {addUsersComponent}
                    </div>
                  )}
                </>
              )}
              {!noResults && (
                <>
                  {!isGrid && (
                    <TeamMembersTableList
                      ActionsDropDownComponent={ActionsDropDownComponent}
                      people={people}
                      peopleType={peopleType}
                      showProgress={showProgress}
                    />
                  )}
                  {isGrid && (
                    <ul className="cards-grid-view">
                      {people.map(user => (
                        <TeamMembersGridListItem
                          ActionsDropDownComponent={ActionsDropDownComponent}
                          key={`grid-item-${user.id}`}
                          componentName={componentName}
                          peopleType={peopleType}
                          showProgress={showProgress}
                          item={user}
                        />
                      ))}
                    </ul>
                  )}
                  <TDPagination {...pagination} />
                </>
              )}
            </>
          )}
          {!hasLoaded && (
            <>
              {isGrid && <PeopleGridListSkeleton />}
              {!isGrid && <FinanceTableSkeleton />}
            </>
          )}
        </>
      )}
    </>
  );
};

PeopleList.propTypes = {
  addUsersComponent: PropTypes.element,
  componentName: PropTypes.string.isRequired,
  exportFn: PropTypes.func,
  filtersOpen: PropTypes.bool.isRequired,
  getActionsDropDownItems: PropTypes.func,
  noUsersMessage: PropTypes.string.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
};

PeopleList.defaultProps = {
  addUsersComponent: null,
  exportFn: null,
  getActionsDropDownItems: null,
};

export default withFilters(PeopleList);
