import { PROFILE_SETTINGS_TABS } from 'accounts/constants';

export const loginUrl = next => (
  next ? `/login?next=${encodeURIComponent(next)}` : '/login'
);

export const logoutUrl = next => (
  next ? `/accounts/logout?next=${encodeURIComponent(next)}` : '/logout'
);

export const signupUrl = next => (
  next ? `/signup?next=${encodeURIComponent(next)}` : '/signup'
);

export const signupInviteUrl = token => (
  `/signup/${token}`
);

export const signupCleanUrl = next => (
  next ? `/signup-clean?next=${encodeURIComponent(next)}` : '/signup-clean'
);

export const orgLoginUrl = (orgAlias, next) => (
  `/${orgAlias}${loginUrl(next)}`
);

export const orgSignupInviteUrl = (orgAlias, token) => (
  `/${orgAlias}${signupInviteUrl(token)}`
);

export const passwordForgotUrl = () => (
  '/password-forgot'
);
export const passwordResetUrl = token => (
  `/password-reset/${token}`
);
export const passwordCreateUrl = token => (
  `/password-create/${token}`
);
export const emailVerifyUrl = token => (
  `/email-verify/${token}`
);
export const signupVerifyUrl = () => (
  '/signup-verify/'
);
export const orgSignupSuccessfulUrl = (orgAlias) => (
  `/${orgAlias}/signup-successful/`
);
export const accountSettingsUrl = (orgAlias, url = '') => `${url}/${orgAlias}/settings`;

export const profileSettingsUrl = (orgAlias, tabName) => (
  `${accountSettingsUrl(orgAlias)}/profile/${tabName || PROFILE_SETTINGS_TABS.BASIC_INFORMATION}`
);

export const profileCreateExperienceUrl = orgAlias => (
  `${profileSettingsUrl(orgAlias, PROFILE_SETTINGS_TABS.EXPERIENCE)}/create`
);

export const profileEditExperienceUrl = (orgAlias, experienceId) => (
  `${profileSettingsUrl(orgAlias, PROFILE_SETTINGS_TABS.EXPERIENCE)}/${experienceId}/edit`
);

export const timeOffUrl = (orgAlias, url = '') => (
  `${accountSettingsUrl(orgAlias, url)}/time-off`
);

export const timeOffCreateUrl = (orgAlias, url = '') => (
  `${timeOffUrl(orgAlias, url)}/create`
);

export const timeOffEditUrl = (orgAlias, timeOffId, url = '') => (
  `${timeOffUrl(orgAlias, url)}/${timeOffId}/edit`
);

export const orgGetStartedUrl = orgAlias => `/${orgAlias}/get-started`;

export const classificationQuestionnaireUrl = orgAlias => (
  `/${orgAlias}/classification-questionnaire`
);

export const classificationQuestionnairesUrl = orgAlias => (
  `/${orgAlias}/classification-questionnaires`
);

export const classificationQuestionnaireIndividualUrl = (orgAlias, id) => (
  `/${orgAlias}/classification-questionnaire/${id}`
);

// Api
export const emailApiUrl = (url = '') => (
  `${url}/api/accounts/email`
);
export const changePasswordUrl = () => (
  '/api/accounts/change_password'
);
export const signupApiUrl = () => (
  '/api/accounts/signup'
);
export const signupCleanApiUrl = () => (
  '/api/accounts/signup-clean'
);
export const loginApiUrl = () => (
  '/api/accounts/login'
);
export const orgLoginApiUrl = orgAlias => (
  `/api/accounts/${orgAlias}/login`
);
export const logoutApiUrl = () => (
  '/api/accounts/logout'
);
export const refreshApiUrl = () => (
  '/api/accounts/refresh'
);
export const resendVerificationApiUrl = () => (
  '/api/accounts/resend-verification'
);
export const userCustomFieldsUpdateApiUrl = (orgAlias, userId, url = '') => (
  `${url}/api/${orgAlias}/accounts/custom-fields/${userId}`
);

export const profileGetTaxInformationApiUrl = orgAlias => `/api/${orgAlias}/profile/tax`;
export const profileUpdateTaxInformationApiUrl = profileGetTaxInformationApiUrl;

export const profileApiUrl = (url = '') => (
  `${url}/api/profile`
);

export const profileSocialApiUrl = (url = '') => (
  `${url}/api/profile/social`
);

export const profileSkillsApiUrl = (url = '') => (
  `${url}/api/profile/skills`
);

export const profileLanguagesApiUrl = (url = '') => (
  `${url}/api/profile/languages`
);

export const profileExperienceApiUrl = (userId, url = '') => (
  `${url}/api/profile/${userId ? `${userId}/` : ''}experience`
);

export const profileFetchExperienceApiUrl = (experienceId, url = '') => (
  `${url}/api/profile/experience/${experienceId}`
);

export const profileReorderExperienceApiUrl = (url = '') => `${url}/api/profile/experience/reorder`;

export const profileExperienceCreateApiUrl = (url = '') => (
  `${url}/api/profile/experience/create`
);

export const profileExperienceDeleteApiUrl = (experienceId, url = '') => (
  `${url}/api/profile/experience/${experienceId}/delete`
);

export const profileExperienceEditApiUrl = (experienceId, url = '') => (
  `${url}/api/profile/experience/${experienceId}/edit`
);

export const setupStepsApiUrl = orgAlias => (
  `/api/${orgAlias ? `${orgAlias}/` : ''}profile/setup`
);

export const timeOffItemApiUrl = (timeOffId, url = '') => (
  `${url}/api/profile/time-off/${timeOffId}`
);

export const timeOffListApiUrl = (url = '') => (
  `${url}/api/profile/time-off`
);

export const guestChatUrl = (token) => (
  `/chat/${token}`
);

export const updateLastActivityApiUrl = () => '/api/accounts/last-activity';

export const getClassificationQuestionnaireApiUrl = orgAlias => (
  `/api/accounts/${orgAlias}/classification-questionnaire`
);

export const submitClassificationQuestionnaireApiUrl = getClassificationQuestionnaireApiUrl;

export const classificationQuestionnairesApiUrl = orgAlias => (
  `/api/accounts/${orgAlias}/classification-questionnaires`
);

export const classificationQuestionnaireApiUrl = (orgAlias, id) => (
  `/api/accounts/${orgAlias}/classification-questionnaire/${id}`
);
