import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { withRouter } from 'react-router-dom';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import { API_DATE_FORMAT, BS_STYLE, CUSTOM_ICONS, DATE_FORMAT_DEFAULT, ICON } from 'core/assets/js/constants';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import { exporterInvoicesExportUrl } from 'exporter/urls';
import { INVOICE_CSV_TYPE, MAX_MONTHS_EXPORT_DURATION } from 'exporter/assets/js/constants';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';

const INVOICES_EXPORT_MODAL_ID = 'invoicesExportModalId';

class ExportInvoicesModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      csvType: INVOICE_CSV_TYPE.STANDARD,
    };
    this.closeInvoicesExportModal = this.closeInvoicesExportModal.bind(this);
    this.handleInvoicesExport = this.handleInvoicesExport.bind(this);
    this.handleRefineFilters = this.handleRefineFilters.bind(this);
    this.handleCsvTypeChange = this.handleCsvTypeChange.bind(this);
  }

  handleCsvTypeChange(e) {
    this.setState({ csvType: e.target.value });
  }

  closeInvoicesExportModal() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC(INVOICES_EXPORT_MODAL_ID));
  }

  handleRefineFilters() {
    const { onFiltersToggle } = this.props;
    this.closeInvoicesExportModal();
    onFiltersToggle(true);
  }

  handleInvoicesExport() {
    const { location, match: { params: { orgAlias } } } = this.props;
    const querystring = location.search;
    const { csvType } = this.state;
    WINDOW_OPEN(exporterInvoicesExportUrl({
      orgAlias,
      querystring,
      csvType,
    }));
    this.closeInvoicesExportModal();
  }

  render() {
    const { isInvoicesExportModalOpen, invoicesCount, dateLimits } = this.props;

    const periodStart = moment(dateLimits.minDate, API_DATE_FORMAT);
    const formattedStartPeriod = periodStart.format(DATE_FORMAT_DEFAULT);
    const periodEnd = moment(dateLimits.maxDate, API_DATE_FORMAT);
    const formattedEndPeriod = periodEnd.format(DATE_FORMAT_DEFAULT);
    const exceedsMaxPeriod = moment(periodEnd).diff(moment(periodStart), 'month', true) > MAX_MONTHS_EXPORT_DURATION;
    const hasNoInvoices = invoicesCount === 0;
    return (
      <ModalSimple
        data-testid="export-invoices-modal"
        heading="Export Invoices"
        open={isInvoicesExportModalOpen}
        onClose={this.closeInvoicesExportModal}
        footer={[
          <TDButton
            key={1}
            className="float-right"
            label="Export"
            disabled={exceedsMaxPeriod || hasNoInvoices}
            variant={BS_STYLE.PRIMARY}
            onClick={this.handleInvoicesExport}
          />,
          <TDButton
            key={2}
            className="mr-4 float-right"
            label="Cancel"
            onClick={this.closeInvoicesExportModal}
          />,
        ]}
      >
        <div className="export-invoices-modal">
          {exceedsMaxPeriod && (
            <TDSystemMessage
              data-testid="export-invoices-modal-system-message"
              type="danger"
              selectedIcon={ICON.WARNING_CIRCLE}
              title={(
                <div>
                  <div className="no-export-msg">{`You can export a maximum of ${MAX_MONTHS_EXPORT_DURATION} months worth of invoices.`}</div>
                  <a href="#" className="d-inline-block text-danger refine-filters mt-2" onClick={this.handleRefineFilters}>Click here to refine your filters.</a>
                </div>
              )}
              className="mb-4 border-0"
            />
          )}
          <div>
            <p>
              Want to join the dots between TalentDesk.io and your accounting software? No problem!
            </p>
            <div className="procedure-graphic m-5 d-flex justify-content-center align-items-center">
              <img src={CUSTOM_ICONS.QUICKBOOKS} alt="quicksbooks icon" className="quickbooks-icon" />
              <img src={CUSTOM_ICONS.ARROW_DOTTED_LEFT} alt="arrow right icon" className="arrow-left-icon mx-2" />
              <img src={CUSTOM_ICONS.TALENTDESK} alt="talentdesk icon" className="talentdesk-icon" />
              <img src={CUSTOM_ICONS.ARROW_DOTTED_RIGHT} alt="arrow right icon" className="arrow-right-icon mx-2" />
              <img src={CUSTOM_ICONS.XERO} alt="xero icon" className="xero-icon" />
            </div>
            <p className="mb-4">
              Export your invoice data in a CSV format and import it into your favourite accounting
              software like Xero or QuickBooks.
            </p>
            <div className="csv-explanation-section d-flex py-4 px-3 mb-4">
              <i className={`${ICON.CSV} csv-icon mr-4 align-self-start`} />
              <AreaCollapsible
                className="w-100"
                headingClassName="mb-3"
                containerElement="div"
                contentChildren={(
                  <p>
                    A comma separated values file is simply a text data file and is the easiest way
                    to mass transfer data from one software to another. Each line in the exported
                    CSV file corresponds to an invoice. The invoice data is separated by comma.
                  </p>
                )}
                headingChildren={(
                  <p className="csv-explanation-section-header mb-0">
                    What&apos;s a CSV file?
                  </p>
                )}
                toggleElementPlacement="right"
              />
            </div>
            <p className="mb-4">
              Based on the filtered results you have selected to export
              {' '}
              <span className="invoices-number font-weight-bolder">
                {invoicesCount}
              </span>
              {' '}
              invoices.
            </p>
            <p className="mb-4">
              Selected period:
              {' '}
              <span className="invoices-start-period font-weight-bolder">{formattedStartPeriod}</span>
              {' '}
              -
              {' '}
              <span className="invoices-end-period font-weight-bolder">{formattedEndPeriod}</span>
              .
            </p>
            <div className="form-group mb-0">
              <div className="sublabel mb-3">Select a Quickbooks or Xero compatible CSV file, or a standard one if you use any other accounting software</div>
              <select defaultValue={INVOICE_CSV_TYPE.STANDARD} className="form-control" onChange={this.handleCsvTypeChange}>
                <option value={INVOICE_CSV_TYPE.STANDARD}>Standard</option>
                <option value={INVOICE_CSV_TYPE.QUICKBOOKS}>Quickbooks</option>
                <option value={INVOICE_CSV_TYPE.XERO}>Xero</option>
              </select>
            </div>
          </div>
        </div>
      </ModalSimple>
    );
  }
}

ExportInvoicesModal.propTypes = {
  dateLimits: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  invoicesCount: PropTypes.number.isRequired,
  isInvoicesExportModalOpen: PropTypes.bool,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
};

ExportInvoicesModal.defaultProps = {
  isInvoicesExportModalOpen: false,
};

const mapStateToProps = (state) => {
  const isInvoicesExportModalOpen = getIsModalOpen(state, INVOICES_EXPORT_MODAL_ID);
  return {
    isInvoicesExportModalOpen,
  };
};

const ExportInvoiceModalConnect = connect(
  mapStateToProps,
)(ExportInvoicesModal);

export default withRouter(ExportInvoiceModalConnect);
