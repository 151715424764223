import Big from 'big.js';
import { isNil } from 'lodash';

import { calcRate } from 'finance/assets/js/lib/utils';
import { BANK_CURRENCY, CURRENCY_SYMBOL } from 'core/assets/js/constants';

/**
 * Enum for Money.cmp results.
 */
export const EComparison = {
  // note - do not reorder
  //        this should match Big.js internal comparison result,
  //        as we currently don't apply any conversion
  GT: 1,
  EQ: 0,
  LT: -1,
};

/**
 * Money.
 *
 * A math library for handling currency amounts.
 */
export default class Money {
  /**
   * Creates instance from cents
   *
   * Some 3rd parties keep their amounts in cent precision e.g. 120000USD to
   * denote 120.00USD. This function is an alternative to a constructor when
   * we have cents at hand
   *
   * @param {number|string} cents - the monetary amount in the given currency.
   * @param {BANK_CURRENCY} currency - the currency of the amount.
   * @return {Money} a money instance
   *
   */
  static fromCents(cents, currency) {
    const precision = Money._getPrecisionsForCurrency(currency);
    const amount = cents / (10 ** precision);

    return new Money(amount, currency);
  }

  static zero(currency) {
    return new Money(0, currency);
  }

  /**
   * Constructor
   *
   * Validate parameters, amount and currency.
   * @param {number|string} amount - the monetary amount in the given currency.
   * @param {BANK_CURRENCY} currency - the currency of the amount.
   */
  constructor(amount, currency) {
    const sanitisedCurrency = (currency || '').toLowerCase();
    if (!Object.values(BANK_CURRENCY).includes(sanitisedCurrency)) {
      throw new Error('currency is required, and recognised as a member of BANK_CURRENCY');
    }

    const sanitisedAmount = parseFloat(amount);
    if (isNaN(sanitisedAmount)) {
      throw new Error('amount is required, and must be valid');
    }

    // note ensure this._currency is set before using Big
    this._currency = sanitisedCurrency;
    this._configBig();
    this._amount = Big(sanitisedAmount).toNumber();
    this._restoreBig();
  }

  /**
   * Get currency for this money instance.
   * @return {BANK_CURRENCY} the currency of this money amount.
   */
  getCurrency() {
    return this._currency;
  }

  /**
   * Get currency symbol for this money instance.
   * @return {String} the currency symbol of this money amount.
   */
  getCurrencySymbol() {
    return CURRENCY_SYMBOL[this._currency];
  }

  /**
   * Ensure other amount is valid, and convert to a Money instance if required.
   *
   * By always returning a Money instance we can simplify the proceeding logic,
   * as it only needs the logic to handle a Money instance.
   *
   * When a number is passed, it'll assume that number is the same currency as this instance.
   *
   * When a Money instance is passed, it will ensure that the currencies match, as we can't
   * perform any operations in a reliable manner when they are in different currencies, ie how
   * do we add USD to JPY ?
   * @param {Money|number} otherAmount - other amount to sanitise.
   * @return {Money} sanitise money instance.
   */
  _sanitiseOtherAmount(otherAmount) {
    if (!(otherAmount instanceof Money)) {
      return new Money(otherAmount, this._currency);
    }

    if (this._currency !== otherAmount.getCurrency()) {
      throw new Error('can not perform action, currencies do not match');
    }

    return otherAmount;
  }

  static _getPrecisionsForCurrency(currency) {
    if (isNil(currency)) {
      throw new Error('currency is required');
    }
    // currency precision overrides for non decimal currencies
    const precisionForCurrencyLookUp = {
      [BANK_CURRENCY.JPY]: 0,
      [BANK_CURRENCY.KRW]: 0,
      // from Wise
      [BANK_CURRENCY.VND]: 0,
      [BANK_CURRENCY.KES]: 0,
      [BANK_CURRENCY.IDR]: 0,
    };

    // note - this could be done better with JS nullish coalescing operator
    //        `return precisionForCurrencyLookUp[this._currency] ?? 2`, however
    //        our current webpack build doesn't like this syntax :/
    const precision = precisionForCurrencyLookUp[currency];
    return (precision === undefined) ? 2 : precision;
  }

  /**
   * Determine the precision required to represent this instances currency.
   *
   * Most currencies will have a precision of 2, ie USD, GBP, EUR etc.
   * So, rather than recording all currencies with a precision of 2, lets only
   * record the exceptions and assume a precision of 2 when no exception is present.
   * @return {number} precision of this instances currency.
   */
  _getPrecisionsForCurrency() {
    if (!this._currency) {
      throw new Error('this._currency must be set before using _getPrecisionsForCurrency');
    }
    return Money._getPrecisionsForCurrency(this._currency);
  }

  /**
   * Configure Big.js to use the precision required by this instances currency.
   *
   * Lets also store the current Big.js state, so we can restore it before
   * returning to the calling code.
   */
  _configBig() {
    this._originalBigRM = Big.RM;
    this._originalBigDP = Big.DP;

    // RM - Rounding Method, 0 - down / 1 - half up / 2 - half even / 3 - up
    //      note - both 'half' will round to nearest neighbour, except when .5
    //             up - will round up when .5
    //             even - will round to nearest even neighbour when .5,
    //                    ie 7.5 rounds up because 8 is even, but 4.5 rounds down because 4 is even
    Big.RM = 1;
    // DP - number of decimal places, otherwise known as precision
    Big.DP = this._getPrecisionsForCurrency();
  }

  /**
   * Ensure Big.js is configured how we found it.
   *
   * As we've changed the Big config at the start of an operation
   * based on the currency of this instance.  We should return
   * its state as the calling code expects.
   */
  _restoreBig() {
    Big.RM = this._originalBigRM;
    Big.DP = this._originalBigDP;
  }

  /**
   * Divide this amount by another amount.
   * Returning the result in a new Money instance.
   *
   * Note the currencies must be the same, we can't multiply USD by JPY etc.
   *
   * @param {Money|number} otherAmount - another amount to divide this amount by.
   * @return {Money} a new Money instance with the amount divide by the other amount.
   */
  div(otherAmount) {
    const sanitisedOtherAmount = this._sanitiseOtherAmount(otherAmount);

    this._configBig();

    const result = new Money(
      Big(this._amount)
        .div(sanitisedOtherAmount.toNumber())
        .toNumber(),
      this._currency,
    );

    this._restoreBig();

    return result;
  }

  /**
   * Multiply this amount by another amount.
   * Returning the result in a new Money instance.
   *
   * Note the currencies must be the same, we can't multiply USD by JPY etc.
   *
   * @param {Money|number} otherAmount - another amount to multiply this amount by.
   * @return {Money} a new Money instance with the amounts multiplied.
   */
  mul(otherAmount) {
    const sanitisedOtherAmount = this._sanitiseOtherAmount(otherAmount);

    this._configBig();

    const result = new Money(
      Big(this._amount)
        .mul(sanitisedOtherAmount.toNumber())
        .toNumber(),
      this._currency,
    );

    this._restoreBig();

    return result;
  }

  /**
   * Add another amount to this amount.
   * Returning the result in a new Money instance.
   *
   * Note the currencies must be the same, we can't add USD to JPY etc.
   *
   * @param {Money|number} otherAmount - another amount to add to this amount.
   * @return {Money} a new Money instance with the amount added.
   */
  add(otherAmount) {
    const sanitisedOtherAmount = this._sanitiseOtherAmount(otherAmount);

    this._configBig();

    const result = new Money(
      Big(this._amount)
        .add(sanitisedOtherAmount.toNumber())
        .toNumber(),
      this._currency,
    );

    this._restoreBig();

    return result;
  }

  /**
   * Subtract another amount from this amount.
   * Returning the result in a new Money instance.
   *
   * Note the currencies must be the same, we can't subtract USD from JPY etc.
   *
   * @param {Money|number} otherAmount - another amount to subtract from this amount.
   * @return {Money} a new Money instance with the amount subtracted.
   */
  sub(otherAmount) {
    const sanitisedOtherAmount = this._sanitiseOtherAmount(otherAmount);

    this._configBig();

    const result = new Money(
      Big(this._amount)
        .sub(sanitisedOtherAmount.toNumber())
        .toNumber(),
      this._currency,
    );

    this._restoreBig();

    return result;
  }

  /**
   * Check how this amount differs from another currency value.
   * Is it equal to, greater than or less than.
   *
   * Note the currencies must be the same, we can't compare USD vs JPY.
   *
   * @param {Money|number} otherAmount - another value to compare against this value.
   * @return {EComparison} if this amount is equal, less than, or greater than the other value.
   */
  cmp(otherAmount) {
    const sanitisedOtherAmount = this._sanitiseOtherAmount(otherAmount);

    this._configBig();

    const result = Big(this._amount)
      .cmp(sanitisedOtherAmount.toNumber());

    this._restoreBig();

    return result;
  }

  /**
   * Check if this amount is equal to 0.
   *
   * @return {boolean} if this amount is equal to zero or not.
   */
  isZero() {
    this._configBig();

    const result = Big(this._amount)
      .eq(0);

    this._restoreBig();

    return result;
  }

  isNonZero() {
    return !this.isZero();
  }

  /**
   * Check if this amount is equal to another currency value.
   *
   * Note the currencies must be the same, we can't compare USD vs JPY.
   *
   * @param {Money|number} otherAmount - another value to compare against this value.
   * @return {boolean} if the two amounts are equal or not.
   */
  eq(otherAmount) {
    const sanitisedOtherAmount = this._sanitiseOtherAmount(otherAmount);

    this._configBig();

    const result = Big(this._amount)
      .eq(sanitisedOtherAmount.toNumber());

    this._restoreBig();

    return result;
  }

  /**
   * Check if this amount is greater than another currency value.
   *
   * Note the currencies must be the same, we can't compare USD vs JPY.
   *
   * @param {Money|number} otherAmount - another value to compare against this value.
   * @return {boolean} if this amount is greater than another currency value.
   */
  gt(otherAmount) {
    const sanitisedOtherAmount = this._sanitiseOtherAmount(otherAmount);

    this._configBig();

    const result = Big(this._amount)
      .gt(sanitisedOtherAmount.toNumber());

    this._restoreBig();

    return result;
  }

  /**
   * Check if this amount is greater than or equal to another currency value.
   *
   * Note the currencies must be the same, we can't compare USD vs JPY.
   *
   * @param {Money|number} otherAmount - another value to compare against this value.
   * @return {boolean} if this amount is greater than or equal to the other currency value.
   */
  gte(otherAmount) {
    const sanitisedOtherAmount = this._sanitiseOtherAmount(otherAmount);

    this._configBig();

    const result = Big(this._amount)
      .gte(sanitisedOtherAmount.toNumber());

    this._restoreBig();

    return result;
  }

  /**
   * Check if this amount is less than another currency value.
   *
   * Note the currencies must be the same, we can't compare USD vs JPY.
   *
   * @param {Money|number} otherAmount - another value to compare against this value.
   * @return {boolean} if this amount is less than another currency value.
   */
  lt(otherAmount) {
    const sanitisedOtherAmount = this._sanitiseOtherAmount(otherAmount);

    this._configBig();

    const result = Big(this._amount)
      .lt(sanitisedOtherAmount.toNumber());

    this._restoreBig();

    return result;
  }

  /**
   * Check if this amount is less than or equal to another currency value.
   *
   * Note the currencies must be the same, we can't compare USD vs JPY.
   *
   * @param {Money|number} otherAmount - another value to compare against this value.
   * @return {boolean} if this amount is less than or equal to the other currency value.
   */
  lte(otherAmount) {
    const sanitisedOtherAmount = this._sanitiseOtherAmount(otherAmount);

    this._configBig();

    const result = Big(this._amount)
      .lte(sanitisedOtherAmount.toNumber());

    this._restoreBig();

    return result;
  }

  /**
   * Absolute the currency amount, ie make it a positive value when if it's negative.
   * Returning the result in a new Money instance.
   *
   * @return {Money} a new Money instance with it's currency amount absolute.
   */
  abs() {
    this._configBig();

    const result = new Money(
      Big(this._amount).abs().toNumber(),
      this._currency,
    );

    this._restoreBig();

    return result;
  }

  /**
   * Convert the currency value to a JS string.
   * @return {string} currency value as a string.
   */
  toString({ withSymbol = false, humanizeAmount = false } = {}) {
    this._configBig();

    let result = Big(this._amount)
      .toFixed(this._getPrecisionsForCurrency());

    this._restoreBig();

    if (humanizeAmount) {
      result = result.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    if (withSymbol) {
      return `${CURRENCY_SYMBOL[this.getCurrency().toLowerCase()]}${result}`;
    }
    return result;
  }

  /**
   * Convert the currency value to a JS number.
   * @return {number} currency value as a number.
   */
  toNumber() {
    this._configBig();

    const result = Big(this._amount)
      .toNumber();

    this._restoreBig();

    return result;
  }

  /**
   * Convert to another currency
   *
   * Takes care of converting to arbitrary precision number, multiplying and
   * then converting to target currency precision
   *
   * @param {BANK_CURRENCY} targetCurrency - the currency to convert to
   * @param {Number} rate - the FX rate from original currency to target currency
   * @param {Number} reverseRate - the FX rate from target currency to original currency
   * @return {Money} a new instance of money with the converted amount/currency
   */
  convert(toCurrency, { rate, reverseRate } = {}) {
    if (!toCurrency) {
      throw new Error('toCurrency is required');
    }
    if (!rate && !reverseRate) {
      throw new Error('either rate or reverseRate is required');
    }
    if (rate && reverseRate) {
      throw new Error('only rate or reverseRate is permitted');
    }

    const targetPrecision = Money._getPrecisionsForCurrency(toCurrency);
    const value = rate
      ? Big(this.toNumber()).times(rate).toFixed(targetPrecision)
      : Big(this.toNumber()).div(reverseRate).toFixed(targetPrecision);

    return new Money(value, toCurrency);
  }

  convertsTo(toMoney, rate, {
    strict = true,
  } = {}) {
    const converted = this.convert(toMoney.getCurrency(), { rate });
    const reverse = toMoney.convert(this.getCurrency(), { reverseRate: rate });

    const forwardEquivalence = converted.toString() === toMoney.toString();
    const backwardEquivalence = reverse.toString() === this.toString();

    if (!forwardEquivalence && (!backwardEquivalence || strict)) {
      return false;
    }
    return true;
  }

  /**
   * Asserts a currency conversion is correct
   *
   * @param {Number} fromAmount - the original amount
   * @param {CURRENCY} fromCurrency - the currency of the original amount
   * @param {Number} rate - the FX rate from original currency to target currency
   * @param {Number} toAmount - the target amount
   * @param {CURRENCY} toCurrency - the currency of the target amount
   * @param {Boolean} strict - whether it reuires unidirectional equivalence or
   *   allows backwards equivalence
   */
  static assertConvertsTo(fromAmount, fromCurrency, rate, toAmount, toCurrency, {
    strict = true,
  } = {}) {
    const from = new Money(fromAmount, fromCurrency);
    const to = new Money(toAmount, toCurrency);
    if (!from.convertsTo(to, rate, { strict })) {
      const converted = from.convert(toCurrency, { rate });
      throw new Error(`amounts do not convert to each other (${from.toString()} ${fromCurrency.toUpperCase()} * ${rate} == ${converted.toString()} ${toCurrency.toUpperCase()} != ${toAmount} ${toCurrency.toUpperCase()})`);
    }
  }

  getRate(moneyInstance, opts) {
    const rate = calcRate(this.toString(), moneyInstance.toNumber(), opts).rate;
    Money.assertConvertsTo(
      this.toString(),
      this.getCurrency(),
      rate,
      moneyInstance.toString(),
      moneyInstance.getCurrency(),
    );
    return rate;
  }
}
