import {
  fromPairs,
  isArray,
  isDate,
  isEmpty,
  isNaN,
  isNil,
  isNull,
  isObject,
  isString,
} from 'lodash';
import moment from 'moment';

import { parseMinMaxFilter } from 'core/assets/js/lib/utils';
import { SEARCH_KEY_PREFIX, TYPE } from 'interviews/assets/js/constants';
import { START_OF_TIME, API_DATE_FORMAT } from 'core/assets/js/constants';
import Logger from 'core/assets/js/lib/Logger';

const logger = new Logger('deprecated');

const FILTER_RATE_MIN_DEFAULT = 0;
const FILTER_RATE_MAX_DEFAULT = 1500;
const NUMBER_REGEX = /^\d+(\.\d+)?$/;

/**
 * Dummy function to calculate the min, max and step of the rate search filter
 *
 * @param {Object} { rangeMin: Number, rangeMax: Number, isCommission: Boolean }
 * @returns {{min: number, max: number, step: number}}
 */
export const prepareRateLimit = ({ // eslint-disable-line
  rangeMin = FILTER_RATE_MIN_DEFAULT,
  rangeMax = FILTER_RATE_MAX_DEFAULT,
  isCommission,
} = {}) => { // eslint-disable-line
  let min = rangeMin || FILTER_RATE_MIN_DEFAULT;
  let max = rangeMax || FILTER_RATE_MAX_DEFAULT;
  if (typeof min === 'string') {
    if (NUMBER_REGEX.test(min)) {
      min = parseFloat(min);
    } else {
      throw Error(`rangeMin is not a valid number: "${rangeMin}"`);
    }
  }
  if (typeof max === 'string') {
    if (NUMBER_REGEX.test(max)) {
      max = parseFloat(max);
    } else {
      throw Error(`rangeMax is not a valid number: "${rangeMax}"`);
    }
  }
  let step = 1;

  if (isCommission) {
    min = 0.00;
    step = 0.01;
    if (isNaN(max) || max > 1) {
      max = 1;
    }
  } else if (max >= 100000) {
    step = 1000;
  } else if (max >= 10000) {
    step = 100;
  } else if (max >= 1000) {
    step = 10;
  } else if (max >= 50) {
    step = 5;
  }

  // RangeFilterField requirement: (max - min) should be a multiple of step
  if ((max - min) % step !== 0) {
    max += step - ((max - min) % step);
  }

  return {
    min,
    max,
    step,
  };
};

export const isValid = (value) => {
  if (isArray(value)) {
    if (isEmpty(value)) {
      logger.warn(new Error('passed empty array instead of null to filter'));
    }
    return !isEmpty(value);
  }
  if (isNull(value)) {
    logger.warn(new Error('passed null instead of undefined to filter'));
  }
  return !isNil(value);
};

export const isUndefined = value => typeof value === 'undefined';

export const isDefined = (value) => {
  if (isUndefined(value)) {
    return false;
  }
  if (isDate(value)) {
    return true;
  }
  if (isArray(value)) {
    return !isEmpty(value);
  }
  if (isObject(value)) {
    return !isEmpty(value);
  }
  return true;
};

export const parseArrayFilter = value => (
  isArray(value) ? value : [value]
);

export const parseDateFilter = (value) => {
  if (value) {
    if (isString(value)) {
      return parseDateFilter(JSON.parse(value));
    }
    if (!isObject(value)) {
      throw new Error('expecting date filter as { min: , max: }');
    }
    return parseMinMaxFilter({
      min: value.min ? moment(value.min, API_DATE_FORMAT).startOf('day') : null,
      max: value.max ? moment(value.max, API_DATE_FORMAT).endOf('day') : null,
    }, {
      defaults: {
        min: moment(START_OF_TIME),
        max: moment(),
      },
    });
  }
  return null;
};

export const isDateFilter = (value) => {
  if (!isObject(value) || (!value.min || !value.max)) {
    return false;
  }

  try {
    const minDate = moment(value.min, API_DATE_FORMAT);
    const maxDate = moment(value.max, API_DATE_FORMAT);
    return minDate.isValid() && maxDate.isValid();
  } catch (err) {
    return false;
  }
};

export const parseAmountFilter = (value) => {
  if (value) {
    if (isString(value)) {
      return parseAmountFilter(JSON.parse(value));
    }
    if (!isObject(value)) {
      throw new Error('expecting amount filter as { min: , max: }');
    }
    return parseMinMaxFilter(value, {
      defaults: { min: 0, max: 1000000 },
    });
  }
  return null;
};

/**
 * Returns the answers mapping for an elastic search listener
 *
 * @param {Model} instance the instance to get the answer mapping for
 */
export const getAnswersMappingForListener = (instance) => {
  if (!instance.answers) {
    return {};
  }

  const answers = fromPairs(instance.answers.map((answer) => {
    const serializedAnswer = answer.body;
    const variant = answer.dimQuestion.toVariant();

    if (
      !variant.searchKey
      // ElasticSearch can't index the filestack object, but there would be no value in having it
      // be searchable anyway
      || variant.type === TYPE.FILE
    ) {
      return null;
    }

    return [
      variant.searchKey, serializedAnswer,
    ];
  }).filter(
    pair => !!pair,
  ));

  return answers;
};

/**
 * Filters an objects and keeps only custom fields
 *
 * @param {Object} filters the custom field filters to parse
 * @returns {Object}
 */
export const parseCustomFields = filters => (
  fromPairs(Object.entries(filters).filter(([key]) => key.startsWith(SEARCH_KEY_PREFIX)))
);
