import PropTypes from 'prop-types';
import React from 'react';

import { CURRENCY_SYMBOL, CURRENCY_VALUES, ICON } from 'core/assets/js/constants';
import { RATE_UNIT_FORMAT, RATE_UNIT_VALUES } from 'rates/assets/js/constants';

const OpportunityRateGuide = ({ className, currency, maximum, minimum, rateUnit }) => {
  const classNames = ['opportunity-rate-guide', 'd-flex', 'align-items-center'];
  if (className) {
    classNames.push(className);
  }
  const currencySymbol = CURRENCY_SYMBOL[currency];
  const rateUnitFormat = RATE_UNIT_FORMAT[rateUnit];
  const formatAmount = amount => parseFloat(amount).toFixed(rateUnitFormat.decimals);
  return (
    <div className={classNames.join(' ')}>
      <i className={`${ICON.COINS_DUOTONE} fa-3x d-inline mr-5`} />
      <div className="d-flex flex-column">
        <b>
          {`${currencySymbol}${formatAmount(minimum)} - ${currencySymbol}${formatAmount(maximum)}`}
        </b>
        <span className="discreet">{rateUnitFormat.title}</span>
      </div>
    </div>
  );
};

OpportunityRateGuide.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.oneOf(CURRENCY_VALUES).isRequired,
  maximum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  minimum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  rateUnit: PropTypes.oneOf(RATE_UNIT_VALUES).isRequired,
};

OpportunityRateGuide.defaultProps = {
  className: null,
};

export default OpportunityRateGuide;
