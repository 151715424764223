import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { getListState, listAppendItemAC, listReplaceItemAC } from 'core/assets/js/ducks/list';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import PeopleRatesTabSkeleton from 'core/assets/js/components/Skeleton/PeopleRatesTabSkeleton.jsx';
import { fetchRatesDS } from 'rates/assets/js/data-services/rates';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';
import RateList from 'rates/assets/js/components/RateList.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, CURRENCY, ICON } from 'core/assets/js/constants';
import AllowAccessToManager from 'core/assets/js/components/AllowAccessToManager.jsx';
import RateCreateModal from 'rates/assets/js/components/RateCreateModal.jsx';
import { RATE_UNIT } from 'rates/assets/js/constants';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { PEOPLE_TYPE, PROFILE_VIEW_COMPONENT_NAME } from 'people/assets/js/constants';
import { fetchProviderDS } from 'people/assets/js/ducks/providers';
import { fetchManagerDS } from 'people/assets/js/ducks/managers';

const createRateModalId = 'peopleRateCreateModal';

class PeopleRatesTab extends React.Component {
  static FetchData({ dispatch, params, url, authedAxios, componentName }) {
    return dispatch(fetchRatesDS({
      orgAlias: params.orgAlias, userId: params.userId, url, authedAxios, componentName,
    }));
  }

  static GetComponentName() {
    return 'PeopleRatesTab';
  }

  constructor(props) {
    super(props);

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleEditSuccess = this.handleEditSuccess.bind(this);
    this.reloadProfileUserCard = this.reloadProfileUserCard.bind(this);
  }

  handleOpenModal() {
    const { dispatch } = this.props;

    dispatch(modalOpenAC(createRateModalId));
  }

  reloadProfileUserCard() {
    const { dispatch, params } = this.props;
    const ds = params.userType === PEOPLE_TYPE.PROVIDERS ? fetchProviderDS : fetchManagerDS;
    dispatch(ds({ componentName: PROFILE_VIEW_COMPONENT_NAME, params }));
  }

  handleEditSuccess(newRate) {
    const { dispatch } = this.props;

    if (newRate) {
      dispatch(listReplaceItemAC(newRate, PeopleRatesTab.GetComponentName()));
    }

    this.reloadProfileUserCard();
  }

  render() {
    const { currency, dispatch, orgAlias, params, rates, rateUnit } = this.props;

    const componentName = PeopleRatesTab.GetComponentName();

    return (
      <React.Fragment>
        <TDApiConnected
          duck="list"
          component={PeopleRatesTab}
          skeletonComponent={PeopleRatesTabSkeleton}
        >
          <div className="tab-content w-100 p-4">
            <RateList
              editInModal
              listComponentName={componentName}
              rates={rates}
              onEdit={this.handleEditSuccess}
            />
          </div>
        </TDApiConnected>
        <AllowAccessToManager>
          <RateCreateModal
            handleOpenModal={this.handleOpenModal}
            modalId={createRateModalId}
            orgAlias={orgAlias}
            onSubmitSuccess={(responseRate) => {
              dispatch(listAppendItemAC(responseRate, componentName));
              this.reloadProfileUserCard();
            }}
            initialValues={{
              currency,
              setRate: false,
              skipNotification: false,
              unit: rateUnit,
              target_user_id: params.userId,
            }}
          />
          <div className="mt-auto text-right pr-4 pt-4 pb-4">
            <TDButton
              className="ml-auto"
              variant={BS_STYLE.PRIMARY}
              onClick={() => { this.handleOpenModal(); }}
              btnIcon={ICON.EDIT}
              label="Propose rate"
            />
          </div>
        </AllowAccessToManager>
      </React.Fragment>
    );
  }
}

PeopleRatesTab.propTypes = {
  currency: PropTypes.oneOf(Object.values(CURRENCY)).isRequired,
  rateUnit: PropTypes.oneOf(Object.values(RATE_UNIT)).isRequired,
  dispatch: PropTypes.func.isRequired,
  orgAlias: PropTypes.string.isRequired,
  params: PropTypes.shape({
    orgAlias: PropTypes.string,
    tab: PropTypes.string,
    userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    userType: PropTypes.string,
  }).isRequired,
  rates: PropTypes.arrayOf(PropTypes.shape(rateSpec)).isRequired,
  viewerId: PropTypes.number.isRequired,
};

const mapStateToProps = (state, props) => {
  const listState = getListState(state, PeopleRatesTab.GetComponentName());
  const org = selectActiveOrg(state);

  return {
    currency: org.currency,
    rates: listState.items,
    params: props.match.params,
    reviews: state.providers.reviewList.items,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const PeopleReviewTabConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PeopleRatesTab);

export default withRouter(PeopleReviewTabConnect);
