import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { formatDate } from 'core/assets/js/lib/utils';
import { REPORT_TYPES } from 'finance/assets/js/constants';
import {
  expenseSpec, invoiceSpec, purchaseOrderSpec, worksheetSpec,
} from 'finance/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import {
  INVOICE_CAP_NOT_APPROVED_REASON,
  INVOICE_CAP_NOT_APPROVED_REASON_VALUES,
  SERVICE_ORDER_STATUS,
  SERVICE_ORDER_TYPE_LABEL,
} from 'projects/assets/js/constants';

const StatusReason = ({ item, itemType }) => {
  const { allowedActions = {} } = item;
  const typeName = SERVICE_ORDER_TYPE_LABEL[item.type];
  const activeOrg = useSelector(selectActiveOrg);

  if (allowedActions.canSeeAmendmentRequested && item.amendmentReason) {
    return (
      <TDSystemMessage
        data-testid="status-reason-amendment-requested"
        type={BS_STYLE.WARNING}
        title={`Amendments requested for this ${itemType}`}
        className="mb-4"
      >
        <p>
          Your manager requested some changes in the submitted
          {' '}
          {itemType}
          {' '}
          info. Here’s is what they said:
        </p>

        <blockquote>
          {item.amendmentReason}
        </blockquote>
      </TDSystemMessage>
    );
  }

  if (allowedActions.canSeeRejected && item.rejectionReason) {
    const reviewer = item.reviewer && item.reviewer.profile
      ? item.reviewer.profile.name
      : null;

    return (
      <TDSystemMessage
        data-testid="status-reason-rejected"
        type={BS_STYLE.DANGER}
        title={`This ${itemType} was rejected ${reviewer ? ` by ${reviewer}` : ''}`.trim()}
        className="mb-4"
      >
        <p>
          {`${reviewer || 'Your manager'} rejected this ${itemType}.`}
          {' '}
          Here’s is what they said:
        </p>

        <blockquote>
          {item.rejectionReason}
        </blockquote>
      </TDSystemMessage>
    );
  }

  if (allowedActions.canSeeCancelled && item.cancellationReason) {
    const owner = (
      item.provider && item.provider.user && item.provider.user.profile
        ? item.provider.user.profile.name
        : null
    );

    return (
      <TDSystemMessage
        data-testid="status-reason-cancelled"
        type={BS_STYLE.INFO}
        title={`This ${itemType} was cancelled ${owner ? ` by ${owner}` : ''}`.trim()}
        className="mb-4"
      >
        <p>
          {`This ${itemType} was cancelled${owner ? ` by ${owner}` : ''}.`}
          {' '}
          Here’s is what they said:
        </p>
        <blockquote>
          {item.cancellationReason}
        </blockquote>
      </TDSystemMessage>
    );
  }

  if (allowedActions.canSeeApproved && item.approvalReason) {
    const reviewerName = item.reviewer?.profile?.name;

    return (
      <TDSystemMessage
        data-testid="status-reason-approved"
        type={BS_STYLE.SUCCESS}
        title={`This ${itemType} was approved ${reviewerName ? ` by ${reviewerName}` : ''}`.trim()}
        className="mb-4"
      >
        <p>
          {`This ${itemType} was approved${reviewerName ? ` by ${reviewerName}` : ''}.`}
          {' '}
          Here’s is what they said:
        </p>
        <blockquote>
          {item.approvalReason}
        </blockquote>
      </TDSystemMessage>
    );
  }

  if (item.statusCode === SERVICE_ORDER_STATUS.NOT_ENOUGH_FUNDS) {
    return (
      <TDSystemMessage className="mb-4" title="Warning!" type={BS_STYLE.WARNING}>
        {`This ${typeName} was due to be auto-approved but it was not possible to do so as the `}
        amount exceeded the project budget. Please increase the project&apos;s budget and manually
        {` approve this ${typeName}.`}
      </TDSystemMessage>
    );
  }

  if (item.statusCode === SERVICE_ORDER_STATUS.APPROVED) {
    return null;
  }

  if (typeof item.invoiceCapInformation === 'string' && item.invoiceCapInformation.length > 0) {
    return (
      <TDSystemMessage
        className="mb-4"
        data-testid="status-reason-invoice-cap-information"
        title="Invoice cap information"
        type={BS_STYLE.WARNING}
      >
        {item.invoiceCapInformation}
      </TDSystemMessage>
    );
  }

  if (
    INVOICE_CAP_NOT_APPROVED_REASON_VALUES.includes(item.invoiceCapDetails?.notAutoApprovedReason)
  ) {
    const { currency: orgCurrency } = activeOrg;
    const {
      invoiceCapDetails: {
        cap,
        currentPeriodEnd,
        currentPeriodStart,
        currentUsage,
        notAutoApprovedReason,
        serviceOrderUsage,
      },
      isTimeTracker,
    } = item;
    const invoiceCap = <NumberTpl currency={orgCurrency} value={cap} />;
    const isOutsidePeriod = [
      INVOICE_CAP_NOT_APPROVED_REASON.OUTSIDE_CAP_PERIOD,
      INVOICE_CAP_NOT_APPROVED_REASON.EXCEEDS_CAP_AND_OUTSIDE_CAP_PERIOD,
    ].includes(notAutoApprovedReason);
    const usage = isOutsidePeriod ? serviceOrderUsage : currentUsage;
    const invoiceCapUsageExclusive = (
      <NumberTpl
        currency={orgCurrency}
        value={usage - parseFloat(item.organizationAmount)}
      />
    );
    const invoiceCapUsageInclusive = <NumberTpl currency={orgCurrency} value={usage} />;
    const invoiceCapPeriod = `${formatDate(currentPeriodStart)} - ${formatDate(currentPeriodEnd)}`;

    const getInvoiceCapInformationText = () => {
      switch (notAutoApprovedReason) {
        case INVOICE_CAP_NOT_APPROVED_REASON.EXCEEDS_CAP:
          if (isTimeTracker) {
            return (
              <>
                {`This ${typeName} was not auto-approved because it exceeded the user's `}
                {'invoice cap of '}
                {invoiceCap}
                .
              </>
            );
          }
          return (
            <>
              {`For this ${typeName} to be included in the next invoice it must be `}
              {`reviewed and manually approved. This ${typeName} exceeds the current `}
              {'invoice cap. A total of '}
              {invoiceCapUsageExclusive}
              {` has already been submitted in the current invoice cap. This ${typeName} would bring `}
              {'the total to '}
              {invoiceCapUsageInclusive}
              .
            </>
          );
        case INVOICE_CAP_NOT_APPROVED_REASON.OUTSIDE_CAP_PERIOD:
          if (isTimeTracker) {
            return (
              <>
                These time tracker records were created for dates that were outside of the
                {` organization's current invoice cap period (${invoiceCapPeriod}). Which is `}
                why it was not approved.
              </>
            );
          }
          return (
            <>
              {`This ${typeName} was submitted for a date range (${item.period}) outside of the `}
              {`current Invoice Cap Period date range (${invoiceCapPeriod}). For the Invoice Cap `}
              {'Period which these dates are for, a total of '}
              {invoiceCapUsageExclusive}
              {` has been submitted. For this ${typeName} to be included in the next invoice it must `}
              be reviewed and manually approved.
            </>
          );
        case INVOICE_CAP_NOT_APPROVED_REASON.EXCEEDS_CAP_AND_OUTSIDE_CAP_PERIOD:
          if (isTimeTracker) {
            return (
              <>
                These time tracker records were created for dates that were outside of the
                {` organization's current invoice cap period (${invoiceCapPeriod}) and exceeded `}
                {"the user's invoice cap of "}
                {invoiceCap}
                . Which is why it was not approved.
              </>
            );
          }
          return (
            <>
              {`This ${typeName} was submitted for a date range (${item.period}) outside of the `}
              {`current Invoice Cap Period date range (${invoiceCapPeriod}). Additionally, the `}
              {`${typeName} amount exceeds the user's invoice cap of `}
              {invoiceCap}
              {`. For this ${typeName} to be included in the next invoice it must be reviewed and `}
              manually approved.
            </>
          );
        case INVOICE_CAP_NOT_APPROVED_REASON.EXCEEDS_PROJECT_BUDGET:
          return (
            <>
              {`This ${typeName} was due to be auto-approved as it was within the Invoice Cap `}
              allowance, however, the amount exceeded the available project budget which
              prevented the approval. Once more budget has been given to the project this
              {` ${typeName} will need to be manually approved.`}
            </>
          );
        case INVOICE_CAP_NOT_APPROVED_REASON.NOT_ALLOWED_FOR_NON_TIME_TRACKER_WORKSHEETS:
          return (
            <>
              {`This ${typeName} has not been auto-approved because only ${typeName}s `}
              created using the Time Tracker can be auto-approved in this organization. For
              {` this ${typeName} to be included in the next invoice it must be reviewed and `}
              manually approved.
            </>
          );
        default:
          return null;
      }
    };

    return (
      <TDSystemMessage
        className="mb-4"
        data-testid="status-reason-invoice-cap-details"
        title="Invoice cap information"
        type={BS_STYLE.WARNING}
      >
        {getInvoiceCapInformationText()}
      </TDSystemMessage>
    );
  }

  return null;
};

StatusReason.propTypes = {
  item: PropTypes.oneOfType([
    expenseSpec, invoiceSpec, purchaseOrderSpec, worksheetSpec,
  ]).isRequired,
  itemType: PropTypes.oneOf([
    REPORT_TYPES.EXPENSE,
    REPORT_TYPES.INVOICE,
    REPORT_TYPES.PRO_FORMA_INVOICE,
    REPORT_TYPES.PURCHASE_ORDER,
    REPORT_TYPES.WORKSHEET,
  ]).isRequired,
};

export default StatusReason;
