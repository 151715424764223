import React from 'react';
import PropTypes from 'prop-types';

import { ICON } from 'core/assets/js/constants';

const ValueAdjustmentControl = ({
  onUpdate, value, step, suffix, min, max, rateBillingInfo,
}) => {
  const handleIncrease = () => (
    onUpdate(value + step)
  );

  const handleDecrease = () => (
    onUpdate(value - step)
  );

  const isIncreaseDisabled = (value + step) > max;
  const isDecreaseDisabled = (value - step) < min;

  return (
    <div className="value-adjustment-control">
      <button
        type="button"
        className="btn btn-link text-dark value-adjustment-control__knob"
        onClick={handleDecrease}
        disabled={isDecreaseDisabled}
      >
        <i className={ICON.REMOVE_CIRCLE} />
      </button>

      <span className="value-adjustment-control__value-wrapper">
        <span className="value-adjustment-control__value">
          {value}
        </span>
        {suffix && (
          <span className="value-adjustment-control__suffix">
            {suffix}
          </span>
        )}
      </span>

      <button
        type="button"
        className="btn btn-link text-dark value-adjustment-control__knob"
        onClick={handleIncrease}
        disabled={isIncreaseDisabled}
      >
        <i className={ICON.ADD_CIRCLE} />
      </button>
      {rateBillingInfo && (
        <span className="task-rate-billing-info">
          {rateBillingInfo}
        </span>
      )}
    </div>
  );
};

ValueAdjustmentControl.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  step: PropTypes.number,
  suffix: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  rateBillingInfo: PropTypes.string,
};

ValueAdjustmentControl.defaultProps = {
  suffix: '',
  step: 5,
  min: 0,
  max: 100,
  rateBillingInfo: '',
};

export default ValueAdjustmentControl;
