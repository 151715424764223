import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr } from 'react-redux-toastr';

import { getEffectiveTaskInfo } from 'projects/assets/js/lib/helpers';
import { projectTaskSpec, taskAssignmentSpec } from 'projects/assets/js/lib/objectSpecs';
import { projectUpdateTaskAssignmentProgressDS } from 'projects/assets/js/data-services/tasks';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import TaskProgressBar from 'projects/assets/js/components/TaskProgressBar.jsx';
import ValueAdjustmentControl from 'core/assets/js/components/ValueAdjustmentControl.jsx';
import { orgSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { RATE_UNIT_FORMAT } from 'rates/assets/js/constants';

const DEBOUNCE_TIMEOUT = 1000;

class TaskAssignmentProgressWidget extends React.Component {
  constructor(props) {
    super(props);

    const { assignment: { progress } } = this.props;
    this.state = { currentProgress: progress || 0 };
    this.debounce = null;

    this.setDebounce = this.setDebounce.bind(this);
    this.setProgress = this.setProgress.bind(this);
    this.updateProgress = this.updateProgress.bind(this);
  }

  componentWillUnmount() {
    clearTimeout(this.debounce);
  }

  setDebounce() {
    clearTimeout(this.debounce);
    this.debounce = setTimeout(this.updateProgress, DEBOUNCE_TIMEOUT);
  }

  setProgress(currentProgress) {
    this.setState({ currentProgress }, this.setDebounce);
  }

  async updateProgress() {
    const {
      dispatch,
      assignment: { user: { id: userId } },
      match: { params: { orgAlias, id: projectId } },
      task: { id: taskId },
      rootComponentName,
    } = this.props;

    const { currentProgress: progress } = this.state;
    const values = { progress };

    try {
      await dispatch(
        projectUpdateTaskAssignmentProgressDS({
          orgAlias,
          projectId,
          taskId,
          userId,
          values,
          componentName: rootComponentName,
        }),
      );
    } catch (err) {
      toastr.error(
        'Oh Snap!',
        'Refresh your page to check the latest updates. If the issue persists, contact our customer support via the live chat or email',
      );
    }
  }

  render() {
    const {
      activeOrg,
      assignment,
      assignment: { allowedActions: { canUpdateProgress, canViewProgress } },
      className,
      match: { params: { taskId } },
      task,
      taskAssignmentAnalytics,
    } = this.props;

    const { currentProgress } = this.state;
    const { label: statusLabel, statusClass, progress: perceivedProgress } = getEffectiveTaskInfo(
      activeOrg, task, { assignment },
    );
    const rateUnitFormat = RATE_UNIT_FORMAT[assignment.rate_unit];
    const rateBilledQuantity = taskAssignmentAnalytics.find(({
      user_id: userId,
    }) => userId === assignment.userId);

    const rateBillingInfo = assignment.rateMaxBillingQuantity
      ? `${rateBilledQuantity?.rateBilledQuantity || 0} ${rateUnitFormat.unit} of ${assignment.rateMaxBillingQuantity} ${rateUnitFormat.unit} billed`
      : null;
    return (
      <div className={`task-progress-update ${className}`}>
        <TaskProgressBar
          id={+taskId}
          progress={canUpdateProgress ? currentProgress : perceivedProgress}
          statusClass={statusClass}
          className="task-progress-bar--thick mb-2"
        />
        {canViewProgress && !canUpdateProgress && (
          <span className="task-progress-bar__status-label">
            {perceivedProgress ? `${perceivedProgress}%` : statusLabel}
          </span>
        )}
        {canViewProgress && canUpdateProgress && (
          <ValueAdjustmentControl
            min={0}
            max={100}
            step={5}
            value={currentProgress}
            suffix="%"
            onUpdate={this.setProgress}
            rateBillingInfo={rateBillingInfo}
          />
        )}
      </div>
    );
  }
}

TaskAssignmentProgressWidget.propTypes = {
  activeOrg: orgSpec.isRequired,
  assignment: taskAssignmentSpec.isRequired,
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  match: routerMatchContentsSpec.isRequired,
  rootComponentName: PropTypes.string.isRequired,
  task: projectTaskSpec.isRequired,
  taskAssignmentAnalytics: PropTypes.arrayOf(PropTypes.object),
};

TaskAssignmentProgressWidget.defaultProps = {
  className: '',
  taskAssignmentAnalytics: [],
};

const mapStateToProps = state => ({ activeOrg: selectActiveOrg(state) });
const mapDispatchToProps = dispatch => ({ dispatch });

const TaskAssignmentProgressWidgetConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskAssignmentProgressWidget);

export default withRouter(TaskAssignmentProgressWidgetConnected);
