import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import queryString from 'query-string';

import Breadcrumbs from 'core/assets/js/components/Breadcrumbs.jsx';
import FilePreview from 'core/assets/js/components/FilePreview.jsx';
import CheckboxField from 'core/assets/js/components/FinalFormFields/CheckboxField.jsx';
import DatePickerField from 'core/assets/js/components/FinalFormFields/DatePickerField.jsx';
import TextAreaField from 'core/assets/js/components/FinalFormFields/TextAreaField.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { WINDOW_OPEN } from 'core/assets/js/config/settings';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import RedirectRoute from 'core/assets/js/config/routes/RedirectRoute.jsx';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { getIsModalOpen, modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { viewResetAC } from 'core/assets/js/ducks/view';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { parseAxiosErrorForFinalForm } from 'core/assets/js/lib/utils';
import axios from 'core/assets/js/lib/tdAxios';
import { downloadFileApiUrl, loadFileApiUrl } from 'files/urls';
import {
  US_TAX_FILING_TABS, US_TAX_FORM_TYPE, US_TAX_FORM_TYPE_LABEL,
} from 'finance/assets/js/constants';
import {
  finance1099FilingsUrl,
  financeApproveUSTaxFormApiUrl,
  financeGetTaxFormApiUrl,
  financeRejectUSTaxFormApiUrl,
} from 'finance/urls';

const REJECT_MODAL_ID = 'us-tax-form-reject-modal';

const USTaxFormReviewView = ({
  location: { search }, match: { params: { orgAlias, userUSTaxFormId } },
}) => {
  const componentName = USTaxFormReviewView.GetComponentName();
  const { hasLoaded, item } = fetchDataHook({
    componentName, url: financeGetTaxFormApiUrl(orgAlias, userUSTaxFormId),
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const { backUrl } = queryString.parse(search);

  const formsListUrl = !hasLoaded ? null : finance1099FilingsUrl(
    orgAlias,
    item.type === US_TAX_FILING_TABS[US_TAX_FORM_TYPE['W-9'] ? 'W-9_FORMS' : 'W-8_FORMS'],
  );

  const goBack = () => {
    history.push(backUrl || formsListUrl);
    dispatch(viewResetAC(componentName));
  };

  const rejectModalIsOpen = useSelector(state => getIsModalOpen(state, REJECT_MODAL_ID));
  const closeModal = () => dispatch(modalCloseAC());

  const typeLabel = item.type && US_TAX_FORM_TYPE_LABEL[item.type];

  const rejectFormRef = useRef(null);

  useEffect(() => {
    if (rejectModalIsOpen && rejectFormRef.current) {
      // unset if the modal is opened, so the Form's render will set it to the correct
      // form instance
      rejectFormRef.current = null;
    }
  }, [rejectModalIsOpen]);

  // note - can NOT conditionally return before any hook :/
  if (!userUSTaxFormId) {
    return <RedirectRoute status={302} to={finance1099FilingsUrl(orgAlias)} />;
  }

  let initialValues = {};
  if (item.reviewDetails) {
    initialValues = {
      '1099FilingRequired': item.reviewDetails['1099FilingRequired'] ? [{ value: true }] : null,
      effectiveDate: item.reviewDetails.effectiveDate,
    };
  }

  return (
    <>
      <div className="content-header__wrapper">
        <div className="content-header content-header--with-breadcrumbs container">
          <div className="d-flex flex-wrap">
            <Breadcrumbs
              items={[
                { title: hasLoaded ? `${typeLabel} forms` : 'US tax forms', url: formsListUrl },
                {
                  title: hasLoaded
                    ? `Review ${typeLabel} form`
                    : `Review US tax form #${userUSTaxFormId}`,
                },
              ]}
            />
          </div>
        </div>
      </div>
      <section className="page page--us-tax-form-review p-5">
        <div className="container rounded shadow-sm bg-white py-5">
          {hasLoaded && (
            <div className="d-flex flex-column">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex flex-column">
                  <h3 className="my-0">{item.contractorName}</h3>
                  <p>{`Uploaded: ${item.createdAt}`}</p>
                </div>
                <TDButton
                  btnIcon={ICON.DOWNLOAD}
                  label="Download"
                  onClick={() => WINDOW_OPEN(downloadFileApiUrl(item.fileId))}
                  showIconOnLeft
                />
              </div>
              <Form
                initialValues={initialValues}
                onSubmit={async values => { // eslint-disable-line consistent-return
                  try {
                    await axios.post(
                      financeApproveUSTaxFormApiUrl(orgAlias, userUSTaxFormId),
                      {
                        '1099FilingRequired': (
                          Array.isArray(values['1099FilingRequired'])
                          && values['1099FilingRequired'].length > 0
                          && values['1099FilingRequired'][0].value === true
                        ),
                        effectiveDate: values.effectiveDate,
                      },
                    );
                    goBack();
                  } catch (err) {
                    return parseAxiosErrorForFinalForm(err);
                  }
                }}
                render={({ handleSubmit, submitting }) => (
                  <form className="d-flex flex-column" onSubmit={handleSubmit}>
                    <div className="container my-3 p-0">
                      <div className="row m-0">
                        <div className="col-12 col-md-6 d-flex flex-column pl-0">
                          <DatePickerField
                            name="effectiveDate"
                            label="Effective date"
                            labelPopOverContent={(
                              <>
                                {`You must set the date from which this ${typeLabel} document is `}
                                effective. This date value will be used as the start date from which
                                to start accumulating their income, unless it is before the start of
                                the tax year, in which case the Contractor&apos;s income will
                                accumulate from January 01.
                              </>
                            )}
                            required
                          />
                          <CheckboxField
                            className="mt-5"
                            name="1099FilingRequired"
                            options={[{ text: '1099 filing is required for this TIN', value: true }]}
                          />
                        </div>
                        <div className="col-12 col-md-6 d-flex flex-column pr-0 pl-0 pl-md-5 pt-5 pt-md-0">
                          <div>
                            Current TIN:
                            <span className="font-weight-bold">
                              {item.taxIdentificationNumber}
                            </span>
                          </div>
                          <div>
                            Date added:
                            <span className="font-weight-bold">
                              {item.taxIdentificationNumberUpdatedAt}
                            </span>
                          </div>
                          <div>{`Previous TIN: ${item.previousTaxIdentificationNumber}`}</div>
                        </div>
                      </div>
                    </div>
                    <FilePreview
                      className="mt-5"
                      mimeType="application/pdf"
                      url={loadFileApiUrl(item.fileId)}
                    />
                    <div
                      className="d-flex justify-content-end align-items-center py-5 form-buttons"
                    >
                      <TDButton
                        disabled={submitting}
                        label="Cancel"
                        onClick={goBack}
                      />
                      <TDButton
                        btnIcon={ICON.CHECKMARK}
                        disabled={submitting}
                        label="Approve"
                        showIconOnLeft
                        type="submit"
                        variant={BS_STYLE.SUCCESS}
                      />
                      <TDButton
                        btnIcon={ICON.CROSS}
                        disabled={submitting}
                        label="Reject"
                        onClick={() => dispatch(modalOpenAC(REJECT_MODAL_ID))}
                        showIconOnLeft
                        variant={BS_STYLE.DANGER}
                      />
                    </div>
                  </form>
                )}
              />
              <ModalConfirm
                closeOnConfirm={false}
                confirmButtonDisabled={rejectFormRef.current?.getState().submitting}
                confirmLabel="Reject"
                heading={`Reject ${typeLabel} form`}
                confirmStyle={BS_STYLE.DANGER}
                onClose={closeModal}
                onConfirm={() => rejectFormRef.current.submit()}
                open={rejectModalIsOpen}
              >
                <Form
                  onSubmit={async ({ reason }) => { // eslint-disable-line consistent-return
                    if (typeof reason !== 'string' || reason.length === 0) {
                      return { reason: 'You must provide a reason' };
                    }
                    try {
                      await axios.post(
                        financeRejectUSTaxFormApiUrl(orgAlias, userUSTaxFormId),
                        { reason },
                      );
                      closeModal();
                      goBack();
                    } catch (err) {
                      return parseAxiosErrorForFinalForm(err);
                    }
                  }}
                  render={({ form, handleSubmit }) => {
                    if (!rejectFormRef.current) {
                      // this is a bit hacky, but as of react-final-form v6, using `<Form ref` is
                      // broken https://github.com/final-form/react-final-form/issues/483
                      rejectFormRef.current = form;
                    }
                    return (
                      <form onSubmit={handleSubmit}>
                        <TextAreaField
                          label="Reason"
                          name="reason"
                          required
                          sublabel={`Explain why you want to reject this ${typeLabel} form`}
                        />
                      </form>
                    );
                  }}
                />
              </ModalConfirm>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

USTaxFormReviewView.GetComponentName = () => 'USTaxFormReviewView';

USTaxFormReviewView.propTypes = {
  location: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
};

export default withRouter(USTaxFormReviewView);
