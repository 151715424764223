/* eslint-disable react/no-multi-comp */
import axios from 'core/assets/js/lib/tdAxios';
import { isNumber } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Autosuggest from 'react-autosuggest';

import { connect } from 'react-redux';
import { skillSearchApiUrl } from 'skills/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';
import { reduxInputSpec } from 'core/assets/js/lib/objectSpecs';

class AutoSuggestField extends React.Component {
  constructor(props) {
    super(props);
    this.getSuggestions = this.getSuggestions.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
    this.filterTimeout = null;

    this.state = {
      suggestions: [],
    };
  }

  componentDidUpdate(prevProps) {
    const { input, initialkwvalue: prevInitialKwValue } = prevProps;
    const { initialkwvalue } = this.props;
    const { value } = input;

    // If there is a previously selected value and the parent passes undefined as a new value to
    // the filter, reset it
    if (
      (value && value !== '')
      && prevInitialKwValue
      && typeof initialkwvalue === 'undefined'
    ) {
      input.onChange(null);
      return {
        value: null,
      };
    }

    return null;
  }

  onChange(event, { newValue }) {
    const { input } = this.props;
    input.onChange(newValue || null);
  }


  onSuggestionsFetchRequested({ value }) {
    this.getSuggestions(value);
  }

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }

  onSuggestionSelected(event, { suggestionValue, suggestion }) {
    const { handleSelected, input } = this.props;
    handleSelected(input.name, suggestionValue, suggestion);
  }

  getSuggestions(userInput) {
    // Start searching after the 2nd letter
    if (!userInput || (userInput && userInput.trim().length < 2)) {
      this.setState({ suggestions: [] });
      return Promise.resolve([]);
    }

    const { activeOrg, urlGenerator } = this.props;

    return new Promise((resolve, reject) => {
      if (this.filterTimeout) {
        clearTimeout(this.filterTimeout);
      }

      this.filterTimeout = setTimeout(() => {
        clearTimeout(this.filterTimeout);

        const searchUrl = urlGenerator({
          term: userInput,
          orgAlias: activeOrg ? activeOrg.unique_alias : null,
        });

        return axios.get(searchUrl)
          .then(response => this.setState({ suggestions: response.data }))
          .catch(reject);
      }, 500);
    });
  }

  render() {
    const {
      input,
      label,
      sugestionsCount,
      className, meta: { touched, error },
      placeholder,
      maxLength,
      itemRenderer,
      valueGetter,
    } = this.props;
    const { suggestions } = this.state;
    const hasError = touched && error;
    const groupClassName = ['form-group'];

    if (hasError) {
      groupClassName.push('has-error');
    }

    return (
      <div className={groupClassName.join(' ')}>
        {label && <label htmlFor={input.name}>{label}</label>}

        <Autosuggest
          label={label}
          suggestions={suggestions.slice(0, sugestionsCount)}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          getSuggestionValue={valueGetter}
          renderSuggestion={itemRenderer}
          inputProps={{
            id: `fieldId-${input.name}`,
            placeholder,
            className: ['form-control', className].join(' '),
            ...input,
            onChange: this.onChange,
            // autosuggest seems not to be able to handle numbers as input values.
            value: isNumber(input.value) ? input.value.toString() : input.value,
            maxLength,
          }}
        />
        {hasError && <span className="help-block">{error}</span>}
      </div>
    );
  }
}

AutoSuggestField.propTypes = {
  activeOrg: PropTypes.object.isRequired,
  initialkwvalue: PropTypes.string,
  input: reduxInputSpec.isRequired,
  label: PropTypes.string,
  sugestionsCount: PropTypes.number,
  handleSelected: PropTypes.func,
  className: PropTypes.string,
  urlGenerator: PropTypes.func,
  itemRenderer: PropTypes.func,
  valueGetter: PropTypes.func,
  maxLength: PropTypes.number,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  placeholder: PropTypes.string,
};

AutoSuggestField.defaultProps = {
  className: '',
  handleSelected: () => {},
  initialkwvalue: undefined,
  label: '',
  maxLength: 50,
  meta: {
    touched: false,
    error: false,
  },
  sugestionsCount: 5,
  placeholder: 'Type here to search',
  urlGenerator: ({ term, orgAlias }) => skillSearchApiUrl({ term, orgAlias }),
  itemRenderer: suggestion => <div>{suggestion.label}</div>,
  valueGetter: suggestion => suggestion.label,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});

export default connect(mapStateToProps)(AutoSuggestField);
