import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import TaskReOpenModal from 'projects/assets/js/components/TaskReOpenModal.jsx';
import { projectSpec, projectTaskSpec, taskAssignmentSpec, taskAllowedActionsSpec } from 'projects/assets/js/lib/objectSpecs';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { TASK_TABS } from 'core/assets/js/constants';
import { PROJECT_STATUS, TASK_ACTION } from 'projects/assets/js/constants';
import { projectViewTaskUrl } from 'projects/urls';
import TaskAssignmentCard from 'projects/assets/js/components/TaskAssignmentCard.jsx';

const TaskAssigneesWidget = ({
  task,
  project,
  activeUserCard,
  allowedActions,
  assignments,
  rootComponentName,
  match: { params: { orgAlias, id: projectId, taskId } },
  taskAssignmentAnalytics,
}) => {
  if (!assignments.length && allowedActions.canAssignTask) {
    const assignUrl = projectViewTaskUrl(
      orgAlias, projectId, taskId, TASK_TABS.DISCUSSION, TASK_ACTION.ASSIGN,
    );

    return (
      <div className="small text-muted text-center py-5">
        <p className="mb-0">
          You haven’t assigned this task to anyone yet
        </p>

        <p className="mb-0">
          <Link to={assignUrl}>
            Assign to a provider
          </Link>
        </p>
      </div>
    );
  }

  if (!assignments.length && !allowedActions.canAssignTask && allowedActions.isManager
      && project.status === PROJECT_STATUS.POSTED) {
    return (
      <div className="small text-muted text-center py-5">
        <p className="mb-0">
          You will be able to assign providers to this task, once the project has started.
        </p>
      </div>
    );
  }

  return (
    <>
      {assignments.map((assignment, idx) => (
        <TaskAssignmentCard
          key={`task-assignment-${assignment.id}`}
          task={task}
          assignment={assignment}
          taskAllowedActions={allowedActions}
          activeUserCard={activeUserCard}
          rootComponentName={rootComponentName}
          taskAssignmentAnalytics={taskAssignmentAnalytics}
          isLastCard={idx === assignments.length - 1}
        />
      ))}
      <TaskReOpenModal componentName={rootComponentName} task={task} />
    </>
  );
};

TaskAssigneesWidget.propTypes = {
  task: projectTaskSpec.isRequired,
  project: projectSpec.isRequired,
  activeUserCard: userCardSpec,
  rootComponentName: PropTypes.string.isRequired,
  assignments: PropTypes.arrayOf(taskAssignmentSpec),
  allowedActions: taskAllowedActionsSpec.isRequired,
  match: routerMatchSpec.isRequired,
  taskAssignmentAnalytics: PropTypes.arrayOf(PropTypes.object),
};

TaskAssigneesWidget.defaultProps = {
  activeUserCard: {},
  assignments: [],
  taskAssignmentAnalytics: [],
};

const mapStateToProps = state => ({
  activeUserCard: selectActiveUserCard(state),
});

const mapDispatchToProps = dispatch => ({ dispatch });

const TaskAssigneesWidgetConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskAssigneesWidget);

export default withRouter(TaskAssigneesWidgetConnected);
