import React, { useEffect, useRef, useState } from 'react';
import { Form } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { useParams } from 'react-router-dom';
import { isEmail } from 'validator';

import RadioField from 'core/assets/js/components/FinalFormFields/RadioField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import UserAndGroupSearchField from 'core/assets/js/components/FinalFormFields/UserAndGroupSearchField.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import TDSystemMessage from 'core/assets/js/components/TDSystemMessage.jsx';
import { MODAL_SIZES } from 'core/assets/js/constants';
import { getIsModalOpen, getModalPayload, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import axios from 'core/assets/js/lib/tdAxios';
import { parseAxiosErrorForFinalForm } from 'core/assets/js/lib/utils';
import { documentRequestSignaturesApiUrl } from 'documents/urls';

export const MODAL_ID = 'document-request-signatures-modal';

const DocumentRequestSignaturesModal = () => {
  const isModalOpen = useSelector(state => getIsModalOpen(state, MODAL_ID));
  const modalPayload = useSelector(state => getModalPayload(state, MODAL_ID));
  const dispatch = useDispatch();
  const { orgAlias } = useParams();
  const onClose = () => dispatch(modalCloseAC());
  const [submitting, setSubmitting] = useState(false);

  const formRef = useRef(null);

  useEffect(() => {
    if (isModalOpen && formRef.current) {
      // unset if the modal is opened, so the Form's render will set it to the correct
      // form instance
      formRef.current = null;
    }
  }, [isModalOpen]);

  const { document } = modalPayload || {};

  return (
    <ModalConfirm
      closeOnConfirm={false}
      confirmButtonDisabled={submitting}
      data-testid="document-request-signatures-modal"
      confirmLabel="Request"
      heading={`Request signatures for ${document?.title}`}
      onClose={onClose}
      onConfirm={() => formRef.current.submit()}
      open={!!(isModalOpen && document)}
      size={MODAL_SIZES.LARGE}
    >
      <TDSystemMessage
        className="mb-5"
        title="Information"
        dismissible
      >
        <p>
          Once you request a signature from a user, they will receive a notification to sign the
          contract document. Signed documents will be listed in the user&apos;s Documents tab within
          their profile area.
        </p>
        <p>
          Signature requests can only be sent to users who have successfully completed their
          onboarding. If you would like to add or change a contract for a Provider that is still
          going through onboarding, navigate to the Onboarding tab within their profile page.
        </p>
      </TDSystemMessage>

      <Form
        // eslint-disable-next-line consistent-return
        onSubmit={async ({ blocking, counterSignerEmails, usersOrGroups }) => {
          const errors = {};
          if (!Array.isArray(usersOrGroups) || usersOrGroups.length === 0) {
            errors.usersOrGroups = 'Please select at least one user or group';
          }
          if (![0, 1].includes(blocking)) {
            errors.blocking = 'Please select a choice';
          }
          if (typeof document.countersignersCount === 'number') {
            new Array(document.countersignersCount).fill('').forEach((_, index) => {
              if (!Array.isArray(counterSignerEmails) || !isEmail(counterSignerEmails[index])) {
                if (!errors.counterSignerEmails) {
                  errors.counterSignerEmails = {};
                }
                errors.counterSignerEmails[index] = 'Please enter a valid countersigner email';
              }
            });
          }
          if (Object.keys(errors).length > 0) {
            return errors;
          }
          const userIds = [];
          const groupIds = [];
          usersOrGroups.forEach(entry => {
            if (entry.isUserCard) {
              userIds.push(entry.data.user.id);
            } else if (entry.isGroup) {
              groupIds.push(entry.data.id);
            }
          });
          setSubmitting(true);
          try {
            const { data: { count } } = await axios.post(
              documentRequestSignaturesApiUrl(orgAlias, document.id),
              { blocking, counterSignerEmails, groupIds, userIds },
            );
            if (count === 0) {
              toastr.warning('None requested', 'No signature requests were created');
            } else {
              toastr.success(
                'Well Done!',
                `We are creating ${count} signature requests, we will notify you of any errors`,
              );
            }
            onClose();
            setSubmitting(false);
          } catch (err) {
            setSubmitting(false);
            return parseAxiosErrorForFinalForm(err);
          }
        }}
        render={({ form, handleSubmit, submitError }) => {
          if (!formRef.current) {
            // this is a bit hacky, but as of react-final-form v6, using `<Form ref` is
            // broken https://github.com/final-form/react-final-form/issues/483
            formRef.current = form;
          }
          if (!document) {
            return null;
          }
          return (
            <form onSubmit={handleSubmit}>
              <UserAndGroupSearchField
                label="Select one or more users or groups"
                name="usersOrGroups"
              />
              {typeof document.countersignersCount === 'number' && (
                new Array(document.countersignersCount).fill('').map((_, index) => (
                  <TextInputField
                    key={index}
                    label={`Countersigner ${index + 1}`}
                    sublabel="Enter the email address of the person who should countersign the contract."
                    name={`counterSignerEmails[${index}]`}
                    required
                    type="email"
                  />
                ))
              )}
              <RadioField
                name="blocking"
                label="Can the users continue to use the platform before signing this contract?"
                options={[
                  {
                    sublabel: [
                      "They won't be blocked from using the platform and they can sign the ",
                      'contract from their Documents tab in their profile area.',
                    ].join(''),
                    text: 'Yes',
                    value: 0,
                  },
                  {
                    sublabel: [
                      'The user(s) will be blocked from accessing the platform until they have ',
                      'signed the contract and it has been reviewed.',
                    ].join(''),
                    text: 'No',
                    value: 1,
                  },
                ]}
              />
              {submitError && <div className="clearfix mt-3 text-danger">{submitError}</div>}
            </form>
          );
        }}
      />
    </ModalConfirm>
  );
};

DocumentRequestSignaturesModal.GetComponentName = () => 'DocumentRequestSignaturesModal';

export default DocumentRequestSignaturesModal;
