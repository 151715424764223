import React from 'react';
import PropTypes from 'prop-types';

import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { SERVICE_ORDER_TYPE_LABEL } from 'projects/assets/js/constants';
import { BS_SIZE } from 'core/assets/js/constants';

const OnDemandInvoiceFuturePeriodModal = ({
  open, billableType, billableProcessDate, onClose, billableReference,
}) => {
  const billableTypeFormatted = SERVICE_ORDER_TYPE_LABEL[billableType].toLowerCase();
  return (
    <ModalSimple
      open={open}
      heading={`An invoice cannot be raised for the ${billableTypeFormatted} ${billableReference}`}
      body={(
        <div>
          <p>
            {
              `You cannot raise an invoice for this
               ${billableTypeFormatted} prior to ${billableProcessDate},
               because the reviewer has set it to be processed after
               the aforementioned date.
              `
            }
          </p>
        </div>
      )}
      onClose={onClose}
      noFooter
      size={BS_SIZE.LARGE}
    />
  );
};

OnDemandInvoiceFuturePeriodModal.propTypes = {
  open: PropTypes.bool,
  billableType: PropTypes.number.isRequired,
  billableProcessDate: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  billableReference: PropTypes.string,
};

OnDemandInvoiceFuturePeriodModal.defaultProps = {
  open: false,
  onClose: () => {},
  billableReference: '',
};

export default OnDemandInvoiceFuturePeriodModal;
