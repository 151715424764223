import { ICON, USER_TYPE } from 'core/assets/js/constants';
import { INVOICE_STATUS } from 'finance/assets/js/constants';

export const REST_PATHS = { //eslint-disable-line
  list: '', create: '/create', edit: '/:id', show: '/:id/show', delete: '/:id/delete',
};

export const ADMIN_ALIAS = 'rattlecage';
export const ADMIN_URL = `/${ADMIN_ALIAS}`;
export const ADMIN_API_URL = `/api/${ADMIN_ALIAS}`;
export const ADMIN_BULK_API_URL = `/api/${ADMIN_ALIAS}/bulk`;

export const USER_ADMIN_ROLES = {
  ADMIN: 0,
  SUPER: 1,
};

export const USER_ADMIN_ROLES_LABEL = {
  [USER_ADMIN_ROLES.ADMIN]: 'admin',
  [USER_ADMIN_ROLES.SUPER]: 'super',
};

export const DIRECT_PPH_FILTER_KEY = 'direct-pph';
export const FAILING_KEY = 'failing';

// Values are used as enum for settings.name
export const SETTINGS = {
  SUBSCRIPTION_EXPIRATION_REMINDER: 'subscription_expiration_reminder',
  TRIAL_PERIOD_EXPIRATION_REMINDER: 'trial_period_expiration_reminder',
  TRIAL_PERIOD_LENGTH: 'trial_period_length',
  AUDIT_LICENCE_TOLERANCE: 'audit_licence_tolerance',
  INVOICING_TAX_METHOD: 'invoicing_tax_method',
  USE_WISE_OAUTH: 'use_wise_oauth',
  DB_PROFILING: 'db_profiling',
  ENABLE_DEFERRED_PAYMENTS_FEATURE: 'enable_deferred_payments_feature',
};

export const SETTINGS_DESCRIPTIONS = {
  [SETTINGS.SUBSCRIPTION_EXPIRATION_REMINDER]: 'This is how many days before an '
    + "organization's subscription expires, that a reminder email will be sent",
  [SETTINGS.TRIAL_PERIOD_EXPIRATION_REMINDER]: 'This is how many days before an '
  + "organization's trial period expires, that a reminder email will be sent",
  [SETTINGS.TRIAL_PERIOD_LENGTH]: "This is how many days a new organization's trial period will"
    + ' last for, when they are created',
  [SETTINGS.AUDIT_LICENCE_TOLERANCE]: 'This is how close an licence invoice total should be to'
    + " previous invoice totals as a percentage, invoices who's totals are different than this"
    + ' will be flagged for manual review.  This is expressed as a percentage, 1-100.',
  [SETTINGS.INVOICING_TAX_METHOD]: 'This is how we apply taxation in our invoices, per item or in the subtotal',
  [SETTINGS.USE_WISE_OAUTH]: 'Enable Wise OAuth, or disable and use the old style API token ( 0 to disable OAuth, any non zero value to enable )',
  [SETTINGS.DB_PROFILING]: 'Enable DB Profiling, ( note server needs to restart for settings to have effect).',
  [SETTINGS.ENABLE_DEFERRED_PAYMENTS_FEATURE]: 'Enable Deferred Payments feature (a.k.a Instant payouts)',
};

export const SETTINGS_VALUES = Object.values(SETTINGS);

// This is used as the enum for organizations.codat_integrations_status
export const CODAT_INTEGRATIONS_STATUS = {
  ACTIVE: 'active',
  DISABLED: 'disabled',
  HIDDEN: 'hidden',
};

export const CODAT_INTEGRATIONS_STATUS_LABEL = {
  [CODAT_INTEGRATIONS_STATUS.ACTIVE]: 'Active',
  [CODAT_INTEGRATIONS_STATUS.DISABLED]: 'Disabled',
  [CODAT_INTEGRATIONS_STATUS.HIDDEN]: 'Hidden',
};

export const USER_ROLE_ICONS = {
  [USER_TYPE.ORG_CREATOR]: ICON.USER_GEAR_SOLID,
  [USER_TYPE.MANAGER]: ICON.USER_TIE_SOLID,
  [USER_TYPE.PROVIDER]: ICON.USER_SOLID,
  [USER_TYPE.FIN_CONTROLLER]: ICON.CALCULATOR_SIMPLE_SOLID,
};

export const ORG_BILLING_ANALYTICS_CURRENCIES = {
  SYSTEM_CURRENCY: 0,
  ORG_CURRENCY: 1,
};

export const INVOICE_STATUS_STYLES = {
  [INVOICE_STATUS.CANCELLED]: '#999999',
  [INVOICE_STATUS.EXECUTED]: '#3ABC4E',
  [INVOICE_STATUS.PAID]: '#60DC35',
  [INVOICE_STATUS.PREVIEW]: '#999999',
  [INVOICE_STATUS.RAISED]: '#1584C6',
  [INVOICE_STATUS.VOID]: '#999999',
  [INVOICE_STATUS.BAD_DEBT]: '#990000',
};

export const BANK_ACCOUNT_TABS = {
  ALL: 'all',
  SYSTEM: 'system',
};

export const BANK_ACCOUNT_TABS_LABEL = {
  [BANK_ACCOUNT_TABS.ALL]: 'Bank accounts',
  [BANK_ACCOUNT_TABS.SYSTEM]: 'System bank accounts',
};

export const SETTINGS_TABS = {
  GENERAL: 'general',
  MAPPING: 'mapping',
};

export const SETTINGS_TABS_LABEL = {
  [SETTINGS_TABS.GENERAL]: 'General',
  [SETTINGS_TABS.MAPPING]: 'Mapping',
};

export const MAX_ORGANIZATIONS_FETCHED = 1000;
export const MAX_INVOICES_FETCHED = 100;

export const ORGANIZATION_FEATURE_USAGE_TABS = {
  E_SIGNATURES: 'e_signatures',
  TIN_MATCHING: 'tin_matching',
  '1099_FILINGS': '1099_filings',
};

export const CLASSIFICATION_QUESTIONNAIRE_INTRO = [
  'These results are meant to offer some general guidance and are not to be relied upon in ',
  'determining whether or not you are classified as an employee or a contractor.',
].join('');

export const CLASSIFICATION_TABS = {
  RESPONSES: 'responses',
  QUESTIONNAIRE: 'questionnaire',
};

export const DEPLOYMENTS_STATUS = {
  ENABLED: '0',
  PAUSED: '1',
};

export const DEPLOYMENTS_STATUS_LABEL = {
  [DEPLOYMENTS_STATUS.ENABLED]: 'Running',
  [DEPLOYMENTS_STATUS.PAUSED]: 'Paused',
};

export const TRANSACTION_TABS = {
  DETAILS: 'details',
  DATA: 'data',
};

export const TRANSACTION_TABS_LABEL = {
  [TRANSACTION_TABS.DETAILS]: 'Details',
  [TRANSACTION_TABS.DATA]: 'Data',
};
