import { USER_TYPE, USER_TYPE_LABEL } from 'core/assets/js/constants';

export const SUBMISSION_STATUS = {
  DRAFT: 0,
  PENDING: 1,
  SUBMITTED: 10,
  APPROVED: 2,
  AMEND: 3,
  REJECTED: 4,
};

export const SUBMISSION_STATUS_LABEL = {
  [SUBMISSION_STATUS.DRAFT]: 'Draft',
  [SUBMISSION_STATUS.SUBMITTED]: 'Submitted',
  [SUBMISSION_STATUS.PENDING]: 'Pending approval',
  [SUBMISSION_STATUS.APPROVED]: 'Approved',
  [SUBMISSION_STATUS.AMEND]: 'Amend requested',
  [SUBMISSION_STATUS.REJECTED]: 'Rejected',
};

export const SUBMISSION_STATUS_CLASS = {
  [SUBMISSION_STATUS.DRAFT]: 'draft',
  [SUBMISSION_STATUS.SUBMITTED]: 'submitted',
  [SUBMISSION_STATUS.PENDING]: 'pending',
  [SUBMISSION_STATUS.APPROVED]: 'approved',
  [SUBMISSION_STATUS.AMEND]: 'amendment-requested',
  [SUBMISSION_STATUS.REJECTED]: 'rejected',
};

export const TYPE = {
  TEXT: 0,
  MONEY: 9,
  SELECT: 2,
  // @todo: Most probably it's not being used anywhere, the SELECT is used instead
  RADIO: 3,
  FILE: 7,
  // @todo: Most probably it's not being used anywhere, the ΤΕΧΤ is used instead with
  // payload.isTextArea = true
  TEXTAREA: 1,
  // @todo: Most probably it's not being used anywhere, the SELECT is used instead
  CHECKBOX: 4,
  DATE: 5,
  NUMBER: 6,
  YESNO: 8,
  MULTIFILE: 10,
  TEXT_BLOB: 11,
};

export const TYPE_STRING = {
  [TYPE.TEXT]: 'text',
  [TYPE.MONEY]: 'money',
  [TYPE.SELECT]: 'selection',
  [TYPE.RADIO]: 'radio',
  [TYPE.FILE]: 'attachment',
  [TYPE.TEXTAREA]: 'textarea',
  [TYPE.CHECKBOX]: 'checkbox',
  [TYPE.DATE]: 'date',
  [TYPE.NUMBER]: 'number',
  [TYPE.YESNO]: 'yes-no',
  [TYPE.MULTIFILE]: 'multifile',
  [TYPE.TEXT_BLOB]: 'text-blob',
};

export const MAX_TEMPLATES__RESULT_COUNT = 500;

export const TYPE_LABEL = {
  [TYPE.TEXT]: 'Text',
  [TYPE.TEXTAREA]: 'Text area',
  [TYPE.SELECT]: 'Dropdown',
  [TYPE.RADIO]: 'Radio selection',
  [TYPE.CHECKBOX]: 'Checkboxes',
  [TYPE.DATE]: 'Date',
  [TYPE.NUMBER]: 'Number',
  [TYPE.FILE]: 'File upload',
  [TYPE.YESNO]: 'Yes/No with multiple text lines',
  [TYPE.MONEY]: 'Money',
  [TYPE.MULTIFILE]: 'Multiple file upload',
  [TYPE.TEXT_BLOB]: 'TextBlob',
};

export const TYPE_COMMENT = Object.entries(TYPE).sort((a, b) => (
  a[1] - b[1]
)).map(([key, val]) => (
  `${val}: ${key.toLowerCase()}`
)).join(', ');

export const CUSTOM_FIELD_SELECT_OTHER_VALUE = -1;

export const CUSTOM_FIELD_PAYLOAD_DEFAULTS = {
  choices: [],
  choicesAutoIncrement: 0,
  attachment_types: [],
  mimetypes: [],
  multiple: false,
  attachments_dump: '[]',
  has_other: false,
  isTextArea: false,
  isRate: false,
  setExpiryDate: false,
  text: '',
  searchKey: '',
  visibleTo: Object.values(USER_TYPE),
};

export const SEARCH_KEY_PREFIX = 's_';
export const PATH_PREFIX = 'q_';

export const CUSTOM_FIELD_PAYLOAD_KEYS = Object.keys(CUSTOM_FIELD_PAYLOAD_DEFAULTS);

export const CANCEL_INV_ON_ONBOARDING_FORM_REMOVE_MSG = 'Your invitation is canceled because your onboarding form has been removed.';

export const MINIMUM_PENDING_INVITATIONS_TO_SHOW = 5;

// @todo: Rename to TEMPLATE_ENTITY_TYPE
export const FIELD_ENTITY_TYPE = {
  ONBOARDING_FORM: 0,
  USER: 1,
  PROJECT: 3,
  TASK: 4,
};

export const FIELD_ENTITY_TYPE_TO_URL_PARAM = {
  [FIELD_ENTITY_TYPE.ONBOARDING_FORM]: 'onboarding-forms',
  [FIELD_ENTITY_TYPE.USER]: 'users',
  [FIELD_ENTITY_TYPE.PROJECT]: 'projects',
  [FIELD_ENTITY_TYPE.TASK]: 'tasks',
};

export const ENTITY_TYPE_LABEL = {
  [FIELD_ENTITY_TYPE.ONBOARDING_FORM]: 'Onboarding form',
  [FIELD_ENTITY_TYPE.USER]: 'User',
  [FIELD_ENTITY_TYPE.PROJECT]: 'Project',
  [FIELD_ENTITY_TYPE.TASK]: 'Task',
};

export const CUSTOM_FIELD_VISIBLE_TO = {
  ALL_MANAGERS: [USER_TYPE.ORG_CREATOR, USER_TYPE.FIN_CONTROLLER, USER_TYPE.MANAGER],
  EVERYONE: [
    USER_TYPE.ORG_CREATOR,
    USER_TYPE.FIN_CONTROLLER,
    USER_TYPE.MANAGER,
    USER_TYPE.PROVIDER,
  ],
};

export const VISIBLE_TO_OPTIONS = Object.values(USER_TYPE).map(type => ({
  value: type,
  text: USER_TYPE_LABEL[type],
}));
