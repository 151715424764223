/* globals File */
import { countBy, isEmpty, sortBy } from 'lodash';
import { getCountries, parsePhoneNumber } from 'react-phone-number-input';
import urlLib from 'url';

import { downloadFileApiUrl, getFileApiUrl } from 'files/urls';
import axios from 'core/assets/js/lib/tdAxios';

/**
 *
 * @param {string} prefix The url that will be used as the basis for prefixing the second parameter
 * @param {string} url The url that needs modification
 * @returns {string}
 */
const addPrefixToUrl = (prefixUrl, url) => {
  const parsedUrl = urlLib.parse(url.trim());
  const parsedPrefixUrl = urlLib.parse(prefixUrl);

  const newUrl = {
    protocol: parsedPrefixUrl.protocol || parsedUrl.protocol,
    hostname: parsedPrefixUrl.hostname || parsedUrl.hostname,
    pathname: parsedUrl.pathname ? parsedUrl.pathname : '/',
  };
  const formattedUrl = urlLib.format(newUrl);
  // in cases like e.g url = www.test.com where no protocol is recognized we add http instead
  // so we can use the url on links
  if (!(/https:\/\/|http:\/\//.test(formattedUrl))) {
    return urlLib.format({
      protocol: 'http:',
      hostname: parsedUrl.pathname,
      pathname: '/',
    });
  }
  return formattedUrl;
};

export {
  // eslint-disable-next-line import/prefer-default-export
  addPrefixToUrl,
};

const stepsOrder = [
  'ClassificationQuestionnaireStep',
  'AMLStep',
  'SignAORContractStep',
  'DocumentStep',
  'InterviewStep',
  'ProfileFillStep',
  'CustomFieldsFillStep',
  'CompanyDetailsStep',
  'PaymentMethodStep',
  'UserCardPaymentMethodStep',
  'SalesTaxStep',
];

/**
 * Merge get started steps into a flat array
 *
 * @param {String} orgAlias
 * @param {Object} steps
 * @returns {Object[]}
 */
export const getMergedGetStartedSteps = (orgAlias, steps) => {
  const userSetupSteps = steps?.userSetupSteps || [];
  const userCardSetupSteps = steps?.userCardSetupSteps?.[orgAlias] || [];
  const _steps = [];
  [...userCardSetupSteps, ...userSetupSteps].forEach(step => {
    const position = stepsOrder.findIndex(name => name === step.name);
    if (!step.hasMultiple || !Array.isArray(step.extras.entries)) {
      _steps.push({ position, step });
      return;
    }
    step.extras.entries.forEach(entry => {
      _steps.push({ position, step: { ...entry, extras: {}, name: step.name } });
    });
  });
  return sortBy(_steps, 'position').map(({ step }) => step);
};

export const calculateAccountCompletePercentage = (steps, orgAlias) => {
  const allSteps = getMergedGetStartedSteps(orgAlias, steps);
  if (isEmpty(allSteps)) {
    return 100;
  }
  const byStatus = countBy(allSteps, s => s.completed);
  return parseInt((100 * byStatus.true || 0) / allSteps.length, 10);
};

/**
 * Determines if a user should be blocked in the platform by their incomplete get started steps
 *
 * @param {{ userSetupSteps: Object[], userCardSetupSteps: Object }} steps
 * @param {String} orgAlias
 * @returns {Boolean}
 */
export const getIsBlockedByGetStarted = (steps, orgAlias) => {
  const allSteps = getMergedGetStartedSteps(orgAlias, steps);
  if (isEmpty(allSteps)) {
    return false;
  }
  return allSteps.some(s => !s.completed && s.isBlocking);
};

/**
 * Download a file and construct a front-end JS File instance
 *
 * @param {Number} fileId
 * @returns {Promise<File>}
 */
export const downloadFile = async fileId => {
  const { data: { fileName, mimeType } } = await axios.get(getFileApiUrl(fileId));
  const { data: fileData } = await axios.get(downloadFileApiUrl(fileId), { responseType: 'blob' });
  return new File([fileData], fileName, { type: mimeType });
};

/**
 * From a user's profile, determine their phone number country
 *
 * @param {Object} [profile]
 * @returns {String|null}
 */
export const getPhoneDefaultCountry = profile => {
  let phoneDefaultCountry = null;
  if (profile?.phone) {
    const parsedPhoneNumber = parsePhoneNumber(profile.phone);
    if (parsedPhoneNumber?.country) {
      phoneDefaultCountry = parsedPhoneNumber.country;
    }
  }
  if (!phoneDefaultCountry) {
    const countryCode = profile?.address_components?.country_code;
    if (countryCode && getCountries().includes(countryCode)) {
      phoneDefaultCountry = countryCode;
    }
  }
  return phoneDefaultCountry;
};

/**
 * Format full name from profile
 *
 * @param {{ first_name: String, last_name: String, middle_name: String }} profile
 * @returns {String}
 */
export const formatFullName = profile => {
  let fullName = profile.first_name;
  if (profile.middle_name) {
    fullName += ` ${profile.middle_name}`;
  }
  return `${fullName} ${profile.last_name}`;
};
