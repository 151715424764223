import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import withStateModal, { modalStateSpec } from 'core/assets/js/components/withStateModal.jsx';
import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import PaymentReferencePreview from 'settings/assets/js/components/PaymentReferencePreview.jsx';
import { settingsBankAccountsApiUrl, settingsPaymentsSubPageUrl } from 'settings/urls';
import { BANK_ACCOUNT_TYPE, SETTINGS_PAYMENTS_TABS } from 'settings/assets/js/constants';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { fetchListDS } from 'core/assets/js/ducks/list';
import { bankAccountSpec } from 'settings/assets/js/lib/objectSpecs';
import { generatePaymentReference } from 'finance/assets/js/lib/utils';

const FORM_ID = 'invoiceNumberForm';

const validateInvoiceNumber = (value) => {
  if (!value) {
    return 'field is required';
  }
  const match = value.match(/(.*?)(\d+)$/);
  if (!match) {
    return 'value must end in a number';
  }
  return null;
};

const InvoiceNumberForm = ({
  handleSubmit, initialValues, items, modalState, orgAlias, submitting,
}) => {
  const [paymentReference, setPaymentReference] = useState(initialValues.next_custom_number || '');

  const filteredAccounts = useMemo(() => items
    .filter(item => item.bank_account_type !== BANK_ACCOUNT_TYPE.PAYPAL), [items]);

  return (
    <React.Fragment>
      <div className="d-inline">
        <span>
          <span onClick={modalState.open} className="imitate-link">Edit</span>
        </span>
      </div>
      <form onSubmit={handleSubmit}>
        <ModalSimple
          open={modalState.isOpen}
          heading="Next invoice number and payment reference"
          body={(
            <React.Fragment>
              <div className="row">
                <div className="col-12">
                  <Field
                    name="next_custom_number"
                    component={InputField}
                    validate={validateInvoiceNumber}
                    onChange={(e => setPaymentReference(e.target.value))}
                    label="Set your next invoice number"
                    sublabel="This number will appear on your next invoice"
                  />
                </div>
              </div>

              <p>Set a custom invoice number to calculate the relevant payment reference.</p>

              <PaymentReferencePreview
                paymentReference={paymentReference}
                showMessage={false}
                showTruncated={false}
                showCalculatedPaymentReference
              />

              <br />
              <div className="invoice-number-form-explanation-section py-4 px-3 mb-4">
                <AreaCollapsible
                  className="w-100"
                  headingClassName="mb-3"
                  containerElement="div"
                  contentChildren={(
                    <React.Fragment>
                      <p>
                        A payment reference is an alphanumeric text that accompanies each payment
                        made to you and appears on your bank statement for reconciliation purposes.
                      </p>

                      <p>
                        The payment reference that appears on your bank statement comes from any of
                        thefollowing sources and in this order of priority:
                      </p>
                      <p>
                        1. The &quot;Payee reference message&quot; that you optionally set when you
                        create a new payment method on TalentDesk.io. You can review your payment
                        methods
                        &nbsp;
                        <Link
                          to={(
                            settingsPaymentsSubPageUrl(
                              orgAlias, SETTINGS_PAYMENTS_TABS.PAYMENT_METHODS,
                            )
                          )}
                        >
                          here.
                        </Link>
                      </p>
                      <p>OR</p>
                      <p>
                        2. A system-calculated text which is based on the custom invoice number you
                        can optionally set in the above input field. It can be up to 10 characters
                        long excluding special characters.
                      </p>
                      <p>OR</p>
                      <p>
                        3. A unique invoice number assigned to you by TalentDesk.io in the case
                        where you haven&apos;t set a custom invoice number as per 2. above. It can
                        be up to 10 characters long excluding special characters.
                      </p>

                      <p><strong>Your payment reference on your next bank statement</strong></p>

                      <p>
                        Depending on the organisation that sends you a payment you can expect the
                        following payment reference to appear in your bank statement:
                      </p>

                      {filteredAccounts.length > 0 ? (
                        <ul data-testid="invoice-number-form-ref-list">
                          {filteredAccounts.map(item => (
                            <li key={item.id}>
                              <p>
                                &nbsp;
                                -
                                &nbsp;
                                {item.alias}
                                :
                                &nbsp;
                                {generatePaymentReference(
                                  item.custom_reference,
                                  paymentReference,
                                  initialValues.next_unique_number || '',
                                  item.currency,
                                ).paymentReference}
                              </p>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p>You currently have no payment methods set up.</p>
                      )}
                    </React.Fragment>
                  )}
                  headingChildren={(
                    <p>What is a payment reference?</p>
                  )}
                  toggleElementPlacement="right"
                />
              </div>
            </React.Fragment>
          )}
          onClose={modalState.close}
          footer={(
            <TDButton
              floatRight
              className="col-6 col-sm-4"
              type="submit"
              variant={BS_STYLE.SUCCESS}
              label="Save"
              onClick={handleSubmit}
              disabled={submitting}
            />
          )}
        />
      </form>
    </React.Fragment>
  );
};

InvoiceNumberForm.propTypes = {
  orgAlias: PropTypes.string.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  modalState: modalStateSpec.isRequired,
  initialValues: PropTypes.object.isRequired,
  items: PropTypes.arrayOf(bankAccountSpec),
};

InvoiceNumberForm.defaultProps = {
  items: [],
};

const InvoiceNumberFormConnect = withTDApiConnected({
  fetchData: ({
    dispatch, params, url, authedAxios, componentName,
  }) => dispatch(fetchListDS({
    url: settingsBankAccountsApiUrl(url),
    params,
    authedAxios,
    componentName,
  })),
  storeKey: FORM_ID,
  duck: 'list',
})(withStateModal(reduxForm({
  form: FORM_ID,
  onSubmitSuccess: (result, dispatch, props) => props.modalState.close(),
})(InvoiceNumberForm)));

export default InvoiceNumberFormConnect;
