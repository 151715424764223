import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import { Card, Dropdown } from 'react-bootstrap';
import moment from 'moment';

import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import ChatParticipantsList from 'people/assets/js/components/OrgMessagingView/ChatParticipantsList.jsx';
import TDDropdown from 'core/assets/js/components/TDDropdown.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import {
  BS_SIZE,
  BS_STYLE,
  DATETIME_FORMAT_HUMAN_FRIENDLY,
  IMG_SIZE,
  ICON,
} from 'core/assets/js/constants';
import {
  getListState,
  getListStateExtras,
  listFetchAC,
  listFetchExtrasAC,
  listPrependItemsAC,
  listUpdateItemAC,
  paginationFetchAC,
} from 'core/assets/js/ducks/list';
import { modalOpenAC, modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import Content from 'core/assets/js/layout/placeholder/Content.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import Root from 'core/assets/js/layout/placeholder/Root.jsx';
import { fetchDataDS } from 'core/assets/js/lib/dataServices';
import {
  extractPaginationFromHeaders,
  formatDate,
} from 'core/assets/js/lib/utils';
import {
  selectActiveOrg,
  selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import ChatRoomsListItemPic from 'people/assets/js/components/chat/ChatRoomsListItemPic.jsx';
import ChatRoomTitle from 'people/assets/js/components/chat/ChatRoomTitle.jsx';
import CreateChatModal from 'people/assets/js/components/OrgMessagingView/CreateChatModal.jsx';
import { CREATE_CHAT_MODAL_ID, ADD_PARTICIPANTS_MODAL_ID, PARTICIPANTS_LIST_MODAL_ID } from 'people/assets/js/constants';
import {
  postCreateChat,
  postChatMessage,
  updateChatRead,
  addAddParticipants,
} from 'people/assets/js/data-services/form';
import { getChatsUrl, getChatUrl, getChatMessagesUrl } from 'people/urls';
import ChatTDLive from 'accounts/assets/js/ChatTDLive.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import { isScrolledToTheTop } from 'people/assets/js/lib/utils';
import ChatParticipantsModal from 'people/assets/js/components/OrgMessagingView/ChatParticipantsModal';
import ProfileLinkDropDownItem from 'people/assets/js/components/chat/ProfileLinkDropDownItem';

const getSorted = (arrayToSort, objKey) => {
  const sortedArray = [...arrayToSort];
  sortedArray.sort(
    (d1, d2) => new Date(d2[objKey]).getTime() - new Date(d1[objKey]).getTime(),
  );
  return sortedArray;
};

const OrgMessagingView = () => {
  const dispatch = useDispatch();
  const [selectedChat, setSelectedChat] = useState(null);
  const activeOrg = useSelector((state) => selectActiveOrg(state));
  const [message, setMessage] = useState();
  const { items, pagination } = useSelector((state) =>
    getListState(state, OrgMessagingView.GetComponentName()),
  );
  const inputRef = useRef();
  const [newChatId, setNewChatId] = useState();
  const [newMessage, setNewMessage] = useState('');
  const listInnerRef = useRef();
  const chatListInnerRef = useRef();
  const activeUserCard = useSelector((state) => selectActiveUserCard(state));
  const itemsLength = items?.length;
  const isParticipantsModalOpen = useSelector((state) =>
    getIsModalOpen(state, PARTICIPANTS_LIST_MODAL_ID),
  );
  const [updatedChatListBool, setUpdatedChatListBool] = useState(false);
  const { search } = useLocation();

  const messagesPagination = useSelector((state) =>
    getListStateExtras(
      state,
      OrgMessagingView.GetComponentName(),
      'messagesPagination',
    ),
  );
  const ctaButtonItems = [
    {
      label: 'New Message',
      variant: BS_STYLE.PRIMARY,
      onClick: () => dispatch(modalOpenAC(CREATE_CHAT_MODAL_ID)),
    },
  ];

  const breadcrumbs = [
    {
      title: 'Messaging',
      url: null,
    },
  ];

  const getChats = (addItems = false, chatId) => {
    dispatch(
      fetchDataDS({
        fetchApiUrl: () => getChatsUrl({ orgAlias: activeOrg.alias, queryString: search }),
        fetchDataAC: (responseData) => {
          const updatedChatRooms = [
            ...(itemsLength && addItems ? items : []),
            ...responseData,
          ];
          if (chatId) {
            setSelectedChat(updatedChatRooms.find(chat => chat.id === chatId));
            inputRef?.current?.focus();
          }
          setUpdatedChatListBool(!updatedChatListBool);
          return listFetchAC(
            updatedChatRooms,
            OrgMessagingView.GetComponentName(),
          );
        },
        paginationAC: (headers) =>
          paginationFetchAC(headers, OrgMessagingView.GetComponentName()),
        querystring: addItems && pagination?.next ? pagination?.next : '',
      }),
    );
  };

  const handleMessageSubmit = async (userInput) => {
    dispatch(
      postChatMessage({
        orgAlias: activeOrg.alias,
        values: {
          message: userInput,
          chatId: selectedChat?.id,
        },
        pushDataAC: (responseData) => {
          const messages = [
            responseData.chatRoomMessage,
            ...selectedChat.chatMessages,
          ];
          setSelectedChat({
            ...selectedChat,
            chatMessages: messages,
          });
          return listUpdateItemAC(
            selectedChat?.id,
            {
              ...selectedChat,
              chatMessages: messages,
            },
            OrgMessagingView.GetComponentName(),
          );
        },
      }),
    );
  };

  const getMessages = (paginationReset = false) => {
    if (!selectedChat?.id) {
      return;
    }
    dispatch(
      fetchDataDS({
        fetchApiUrl: () =>
          getChatMessagesUrl({
            orgAlias: activeOrg.alias,
            chatId: selectedChat?.id,
          }),
        fetchDataAC: (responseData) => {
          const messages = [
            ...(selectedChat?.chatMessages?.length
              ? selectedChat.chatMessages
              : []),
            ...responseData,
          ];
          setSelectedChat({
            ...selectedChat,
            chatMessages: paginationReset ? responseData : messages,
          });

          return listUpdateItemAC(
            selectedChat?.id,
            {
              ...selectedChat,
              chatMessages: paginationReset ? responseData : messages,
            },
            OrgMessagingView.GetComponentName(),
          );
        },
        paginationAC: (headers) =>
          listFetchExtrasAC(
            extractPaginationFromHeaders(headers),
            OrgMessagingView.GetComponentName(),
            'messagesPagination',
          ),
        querystring:
          !paginationReset && messagesPagination?.next
            ? messagesPagination?.next
            : '',
      }),
    );
  };

  useEffect(() => {
    if (itemsLength?.length) {
      if (selectedChat) {
        const updatedSelectedChat = items.find(chat => chat.id === selectedChat?.id);
        if (updatedSelectedChat.chatRoomUsers.length !== selectedChat.chatRoomUsers.length) {
          setSelectedChat(updatedSelectedChat);
        }
      }
    }
  }, [items]);

  useEffect(() => {
    if (selectedChat?.id) {
      dispatch(
        listFetchExtrasAC(
          null,
          OrgMessagingView.GetComponentName(),
          'messagesPagination',
        ),
      );
      getMessages(true);
    }
  }, [selectedChat?.id, updatedChatListBool]);

  const groupSelectionByType = selectedItems => selectedItems
    .reduce((acc, item) => {
      const { data: { membersCount } } = item;
      const group = typeof membersCount === 'undefined' ? 'users' : 'groups';
      acc[group] = acc[group] ?? [];
      acc[group].push(item);
      return acc;
    }, {});

  const createChatroom = async ({ participants, title }, form) => {
    const groupedParticipants = groupSelectionByType(participants);
    const users = groupedParticipants?.users || [];
    const groups = groupedParticipants?.groups || [];

    dispatch(
      postCreateChat({
        orgAlias: activeOrg.alias,
        values: {
          title,
          users: users?.map(u => u.value),
          groups: groups?.map(g => g.value),
        },
        pushDataAC: (response) => {
          getChats(false, response.id);
          form.reset();
          return dispatch(modalCloseAC());
        },
      }),
    );
  };

  const addParticipants = async ({ participants }, form) => {
    const groupedParticipants = groupSelectionByType(participants);
    const users = groupedParticipants?.users || [];
    const groups = groupedParticipants?.groups || [];

    dispatch(
      addAddParticipants({
        orgAlias: activeOrg.alias,
        chatId: selectedChat?.id,
        values: {
          users: users?.map(u => u.value),
          groups: groups?.map(g => g.value),
        },
        pushDataAC: (response) => {
          getChats(false, response.id);
          form.reset();
          return dispatch(modalCloseAC());
        },
      }),
    );
  };

  const resetCounter = (chat) => {
    dispatch(
      updateChatRead({
        orgAlias: activeOrg.alias,
        chatId: chat?.id,
        pushDataAC: () => listUpdateItemAC(
          chat?.id,
          {
            ...chat,
            unreadCount: 0,
          },
          OrgMessagingView.GetComponentName(),
        ),
      }),
    );
  };

  useEffect(() => {
    if (activeOrg?.alias) {
      getChats();
    }
  }, [activeOrg?.alias, search]);

  const getChatRooms = () => {
    if (!itemsLength) {
      return [];
    }
    return items?.map((chat) => {
      return (
        <li
          className={'navbar__menuItem mt-0'}
          key={chat.id.toString()}
          onClick={() => {
            setSelectedChat(chat);
            resetCounter(chat);
          }}
        >
          <a
            className={`navbar__sectionItem p-4 d-flex align-items-center ${
              chat?.id === selectedChat?.id ? 'navbar__sectionItem-active' : ''
            } `}
          >
            <ChatRoomsListItemPic chat={chat} />

            <span className="truncate chat__text">
              {chat.title || chat?.chatRoomUsers?.filter(cu => cu.userId !== activeUserCard.user.id).map((c) => c.name).join(', ')}
            </span>
            {chat?.unreadCount > 0 && (
              <span className="badge text-center">{chat?.unreadCount}</span>
            )}
          </a>
        </li>
      );
    });
  };

  const getChatRoomMessages = () => {
    if (!Array.isArray(selectedChat?.chatMessages)) {
      return null;
    }
    const currentUser = selectedChat?.chatRoomUsers?.find(
      (c) => c.userId === activeUserCard.user.id,
    );
    return selectedChat?.chatMessages?.map((chatRoomMessage) => {
      if (chatRoomMessage.chatRoomUserId === currentUser?.id) {
        return (
          <div
            className="d-flex text-right justify-content-end mt-3"
            key={chatRoomMessage?.id}
          >
            <div>
              <p className="mb-1">
                <small>
                  {formatDate(
                    chatRoomMessage.createdAt,
                    DATETIME_FORMAT_HUMAN_FRIENDLY,
                  )}
                </small>
                <strong>{`  ${currentUser?.name}`}</strong>
              </p>
              <div className="chat__box-message p-3">
                {chatRoomMessage.message}
              </div>
            </div>
            <ProfilePic
              data-testid="contact-details-panel-profile-pic"
              url={currentUser?.avatar}
              alt={currentUser?.name}
              size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
              className="mx-3"
            />
          </div>
        );
      }
      const user = selectedChat?.chatRoomUsers?.find(
        (c) => c.id === chatRoomMessage.chatRoomUserId,
      );
      return (
        <div className="d-flex mt-3" key={chatRoomMessage?.id}>
          <ProfilePic
            data-testid="contact-details-panel-profile-pic"
            url={user?.avatar}
            alt={user?.name}
            size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
            className="mx-3"
          />
          <div>
            <p className="mb-1">
              <strong>{`${user?.name}  `}</strong>
              <small>
                {formatDate(
                  chatRoomMessage.createdAt,
                  DATETIME_FORMAT_HUMAN_FRIENDLY,
                )}
              </small>
            </p>
            <div className="chat__box-message p-3">
              {chatRoomMessage.message}
            </div>
          </div>
        </div>
      );
    });
  };

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      const isScrolled = isScrolledToTheTop(
        clientHeight,
        scrollHeight,
        scrollTop,
      );
      if (isScrolled && messagesPagination?.next) {
        getMessages();
      }
    }
  };

  useEffect(() => {
    if (!newMessage?.chatRoomMessage) {
      return;
    }
    const chatRoom = items.find(
      (c) => c.id === newMessage.chatRoomMessage.chatRoomId,
    );
    if (
      !chatRoom?.chatMessages?.some(
        (m) => m.id === newMessage?.chatRoomMessage?.id,
      )
    ) {
      const messages = [newMessage.chatRoomMessage];
      if (Array.isArray(chatRoom?.chatMessages) && chatRoom.chatMessages.length > 0) {
        messages.push(...chatRoom.chatMessages);
      }
      const sortedMessages = getSorted(messages, 'createdAt');
      if (selectedChat?.id === chatRoom?.id) {
        setSelectedChat({
          ...selectedChat,
          chatMessages: sortedMessages,
          unreadCount: selectedChat.unreadCount
            ? selectedChat.unreadCount + 1
            : 1,
        });
        dispatch(
          listUpdateItemAC(
            selectedChat?.id,
            {
              ...selectedChat,
              chatMessages: sortedMessages,
              updatedAt: moment().format(),
              unreadCount: selectedChat.unreadCount
                ? selectedChat.unreadCount + 1
                : 1,
            },
            OrgMessagingView.GetComponentName(),
          ),
        );
      }
      setMessage('');
    }
  }, [newMessage]);

  useEffect(() => {
    if (newChatId && selectedChat?.id !== newChatId) {
      dispatch(
        fetchDataDS({
          fetchApiUrl: () =>
            getChatUrl({ orgAlias: activeOrg.alias, chatId: newChatId }),
          fetchDataAC: (responseData) => {
            if (items.find((c) => c.id === newChatId)) {
              return listUpdateItemAC(
                newChatId,
                responseData,
                OrgMessagingView.GetComponentName(),
              );
            }
            return listPrependItemsAC(
              [{ ...responseData, unreadCount: 1 }],
              OrgMessagingView.GetComponentName(),
            );
          },
        }),
      );
    }
    setNewChatId(null);
  }, [newChatId]);

  const onChatListScroll = () => {
    if (chatListInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = chatListInnerRef.current;
      if (Math.round(scrollTop + clientHeight) >= scrollHeight && pagination?.next) {
        getChats(true);
      }
    }
  };

  const getEvents = () => {
    const chatEvents = {
      'event-chat-change': (data) => setNewChatId(data.chatRoomId),
    };
    if (itemsLength) {
      const existingChats = items.reduce((acc, cur) => {
        if (cur?.id) {
          return {
            ...acc,
            [`event-chat-add-${cur.id}`]: setNewMessage,
          };
        }
        return acc;
      }, {});
      return { ...chatEvents, ...existingChats };
    }
    return chatEvents;
  };

  const handleRead = () => {
    if (!Array.isArray(selectedChat?.chatMessages) || selectedChat.chatMessages.length === 0) {
      return;
    }
    const currentChatUser = selectedChat?.chatRoomUsers?.find(
      (u) => u.userEmail === activeUserCard?.user?.email,
    );
    if (!currentChatUser) {
      return;
    }
    dispatch(
      updateChatRead({
        orgAlias: activeOrg.alias,
        chatId: selectedChat?.id,
        values: { messageId: selectedChat.chatMessages[0].id },
        pushDataAC: () => {
          setSelectedChat({
            ...selectedChat,
            unreadCount: 0,
          });
          return listUpdateItemAC(
            selectedChat?.id,
            {
              ...selectedChat,
              unreadCount: 0,
            },
            OrgMessagingView.GetComponentName(),
          );
        },
      }),
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!message) {
      return;
    }
    handleMessageSubmit(message);
    setMessage('');
  };

  return (
    <React.Fragment>
      <ContentHeader
        breadcrumbs={breadcrumbs}
        ctaButtonItems={ctaButtonItems}
      />
      <div className="page page--people h-100">
        <div className="container h-100">
          <div className="org-messaging">
            {itemsLength === 0 && (
              <div className="d-flex flex-column justify-content-center align-items-center empty-list-message">
                <span className={ICON.COMMENTS_DUOTONE} />
                <p className="my-4">You have no messages</p>
                <TDButton
                  variant={BS_STYLE.PRIMARY}
                  label="New message"
                  onClick={() => dispatch(modalOpenAC(CREATE_CHAT_MODAL_ID))}
                />
              </div>
            )}

            {itemsLength > 0 && activeUserCard?.user?.email && (
              <ChatTDLive
                email={activeUserCard?.user?.email}
                events={getEvents()}
              >
                <div className="h-100 bg-white shadow-sm w-100 mb-4 overflow-hidden">
                  <Root flexContainer>
                    <div className={`navbar-col no-select h-100 chat ${selectedChat ? 'd-none' : 'd-flex'} d-md-flex justify-content-between`}>
                      <ul
                        className="chat__navbar overflow-auto"
                        onScroll={onChatListScroll}
                        ref={chatListInnerRef}
                      >
                        {getChatRooms()}
                      </ul>
                      <div className="d-flex align-items-center justify-content-center">
                        <TDPagination containerClassName="my-5 mx-0" {...pagination} />
                      </div>
                    </div>
                    <Content isColFlexContainer className={`${selectedChat ? 'd-flex' : 'd-none'} d-md-flex`}>
                      {selectedChat && (
                        <Card.Header className="chat__header font-weight-bolder d-flex align-items-center w-100">
                          <a className="d-inline d-md-none cursor-pointer" onClick={setSelectedChat}>
                            <i className={`${ICON.ARROW_LEFT_LONG}`} />
                          </a>
                          <ChatRoomTitle chat={selectedChat} />

                          <div className="col-auto ml-auto pr-0">
                            <TDDropdown
                              id="chat-dropdown"
                              noCaret
                              alignRight
                              toggle={(
                                <TDButton
                                  className="px-3 s"
                                  bsSize={BS_SIZE.SMALL}
                                  variant={BS_STYLE.DEFAULT}
                                >
                                  <span className={ICON.CHEVRON_DOWN} />
                                </TDButton>
                              )}
                            >
                              <Dropdown.Item
                                onClick={() => dispatch(modalOpenAC(ADD_PARTICIPANTS_MODAL_ID))}
                              >
                                Add participants
                              </Dropdown.Item>

                              {selectedChat?.chatRoomUsers?.length === 2 && (
                                <ProfileLinkDropDownItem chat={selectedChat} />
                              )}
                            </TDDropdown>
                          </div>
                        </Card.Header>
                      )}

                      <Card.Body
                        className="chat__body overflow-auto pt-4 d-flex flex-column-reverse"
                        onScroll={onScroll}
                        ref={listInnerRef}
                      >
                        {getChatRoomMessages()}
                      </Card.Body>

                      {selectedChat && (
                        <Card.Footer className="chat__footer p-4">
                          <form onSubmit={handleSubmit}>
                            <div className="form-group mb-0">
                              <input
                                data-testid="message-input"
                                type="text"
                                className="form-control"
                                placeholder="Start typing your message..."
                                value={message}
                                onFocus={handleRead}
                                disabled={!selectedChat}
                                ref={inputRef}
                                onChange={(e) => setMessage(e.target.value)}
                              />
                            </div>
                          </form>
                        </Card.Footer>
                      )}
                    </Content>
                    {selectedChat?.chatRoomUsers && (
                      <ChatParticipantsList
                        onAddParticipant={() => dispatch(modalOpenAC(ADD_PARTICIPANTS_MODAL_ID))}
                        participants={selectedChat.chatRoomUsers}
                      />
                    )}
                  </Root>
                </div>
              </ChatTDLive>
            )}
          </div>
        </div>
      </div>

      <CreateChatModal handleMessageSubmit={createChatroom} />
      <CreateChatModal modalId={ADD_PARTICIPANTS_MODAL_ID} handleMessageSubmit={addParticipants} />

      <ChatParticipantsModal
        participants={selectedChat?.chatRoomUsers}
        onClose={() => dispatch(modalCloseAC())}
        isModalOpen={isParticipantsModalOpen}
        openAddModal={() => {
          dispatch(modalCloseAC());
          dispatch(modalOpenAC(ADD_PARTICIPANTS_MODAL_ID));
        }}
      />
    </React.Fragment>
  );
};

OrgMessagingView.GetComponentName = () => 'OrgMessagingView';

export default withRouter(withFilters(OrgMessagingView));
