import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import {
  addBackUrlIfLocationContains, financeExpenseViewUrl,
  financeExpenseEditUrl, financeExpenseOnDemandInvoiceUrl,
} from 'finance/urls';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import { expenseSpec } from 'finance/assets/js/lib/objectSpecs';
import OnDemandInvoiceFuturePeriodModal from 'finance/assets/js/components/invoicing/OnDemandInvoiceFuturePeriodModal.jsx';
import { SERVICE_ORDER_TYPE } from 'projects/assets/js/constants';
import { ON_DEMAND_INVOICE_STEP } from 'finance/assets/js/constants';

const ExpenseItemActions = ({ history, orgAlias, expense, onCancel }) => {
  const showEditButton = expense?.allowedActions?.canBeEdited;
  const showCancelButton = expense?.allowedActions?.canBeCancelled;
  const canInvoiceExpenseOnDemand = expense?.allowedActions?.canInvoiceExpenseOnDemand;
  const canCreateOnDemandInvoices = expense?.provider?.userCard
    ?.allowedActions.canCreateOnDemandInvoices;
  const shouldInvoiceInLaterDate = expense?.allowedActions
    ?.shouldInvoiceInLaterDate;
  const showCreateOnDemandInvoiceButton = (
    canInvoiceExpenseOnDemand
    || (shouldInvoiceInLaterDate && canCreateOnDemandInvoices)
  );
  const [isRaiseInvoiceModalOpen, openRaiseInvoiceModal] = useState(false);
  const billableReference = `${expense?.projectReference}_${expense?.id}`;
  const handleRaiseInvoiceClick = () => {
    if (shouldInvoiceInLaterDate) {
      openRaiseInvoiceModal(true);
      return;
    }
    history.push(financeExpenseOnDemandInvoiceUrl(
      orgAlias, expense.id, ON_DEMAND_INVOICE_STEP.INIT,
    ));
  };
  const handleClick = (e, url) => {
    e.stopPropagation();
    history.push(url);
  };

  return (
    <TDDropButton stopPropagation>
      <Dropdown.Item
        eventKey="view-expense"
        data-testid="view-expense"
        onClick={e => handleClick(e,
          addBackUrlIfLocationContains(
            financeExpenseViewUrl(orgAlias, expense.id),
            history.location.pathname,
            '/projects/',
          ),
        )}
      >
        View expense
      </Dropdown.Item>

      {showEditButton && (
        <Dropdown.Item
          eventKey="edit-expense"
          data-testid="edit-expense"
          onClick={e => handleClick(e, financeExpenseEditUrl(orgAlias, expense.id))}
        >
          <span className="text-warning">Edit expense</span>
        </Dropdown.Item>
      )}

      {showCancelButton && (
        <Dropdown.Item
          eventKey="cancel-expense"
          data-testid="cancel-expense"
          onClick={onCancel}
        >
          <span className="text-secondary">Cancel expense</span>
        </Dropdown.Item>
      )}
      {showCreateOnDemandInvoiceButton && (
        <Dropdown.Item
          eventKey="raise-invoice"
          data-testid="raise-invoice"
          onClick={(e) => {
            e.stopPropagation();
            handleRaiseInvoiceClick();
          }}
        >
          <span className="text-secondary">Raise invoice</span>
        </Dropdown.Item>
      )}
      <OnDemandInvoiceFuturePeriodModal
        open={isRaiseInvoiceModalOpen}
        onClose={() => openRaiseInvoiceModal(false)}
        billableProcessDate={expense.processAt}
        billableType={SERVICE_ORDER_TYPE.EXPENSE}
        billableReference={billableReference}
      />
    </TDDropButton>
  );
};

ExpenseItemActions.propTypes = {
  history: routerHistorySpec.isRequired,
  expense: expenseSpec,
  orgAlias: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
};

ExpenseItemActions.defaultProps = {
  expense: {},
  orgAlias: '',
};

export default withRouter(ExpenseItemActions);
