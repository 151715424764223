import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import Big from 'big.js';

import { getIsModalOpen, getModalPayload, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, CURRENCY_SYMBOL } from 'core/assets/js/constants';
import InputNumberField from 'core/assets/js/components/FinalFormFields/InputNumberField.jsx';
import { RATE_UNIT_FORMAT } from 'rates/assets/js/constants';

const TASK_ASSIGNMENT_MODAL_ID = 'task-assignment-cap-modal';

export const getTaskAssignmentModalId = userId => (
  `${TASK_ASSIGNMENT_MODAL_ID}=${userId}`
);

class TaskAssignmentModal extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;

    this.handleModalClose = this.handleModalClose.bind(this);
    this.getMaximumBillable = this.getMaximumBillable.bind(this);
  }

  getMaximumBillable(rateMaxBillingQuantity) {
    const { rate, currency } = this.props;
    const maxBillingAmount = Big(rate).times(rateMaxBillingQuantity).toFixed(2);
    return `${CURRENCY_SYMBOL[currency]} ${maxBillingAmount}`;
  }

  handleModalClose() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  render() {
    const { open, rateUnit, rate, initialValues, onSubmit, assigneeUserId } = this.props;
    return (
      <div className="static-modal">
        <Form
          onSubmit={(values) => onSubmit(values, assigneeUserId)}
          initialValues={initialValues}
          render={({
            handleSubmit,
            values: {
              rate_max_billing_quantity: rateMaxBillingQuantity,
            },
            form: { getState },
          }) => {
            const { submitting } = getState();
            return (
              <form onSubmit={handleSubmit}>
                <ModalSimple
                  open={open}
                  onClose={this.handleModalClose}
                  heading="Edit task assignment cap"
                  body={(
                    <div>
                      <p>
                        If a cap is set the provider will not be able to submit a worksheet
                        claim more than the specified capped number of units
                      </p>
                      <div className="row mb-3">
                        <div className="col-8 col-md-10">
                          <InputNumberField
                            className="mb-0"
                            name="rate_max_billing_quantity"
                            placeholder="Capped value"
                            step="0.5"
                          />
                        </div>
                        <div className="col-4 col-md-2">
                          <p className="mt-3">
                            {RATE_UNIT_FORMAT[rateUnit]?.unit}
                          </p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-12">
                          <p className="info-pill bg-pale-gray">
                            Maximum billable
                            <span className="info-pill--value">
                              {rate && rateMaxBillingQuantity && (
                                this.getMaximumBillable(rateMaxBillingQuantity)
                              )}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  footer={[
                    <TDButton
                      key={1}
                      className="float-right"
                      label="Set task assignment cap"
                      variant={BS_STYLE.PRIMARY}
                      disabled={submitting || !rateMaxBillingQuantity}
                      onClick={handleSubmit}
                    />,
                  ]}
                />
              </form>
            );
          }}
        />
      </div>
    );
  }
}

TaskAssignmentModal.propTypes = {
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  rateUnit: PropTypes.number.isRequired,
  rate: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  assigneeUserId: PropTypes.number.isRequired,
};

TaskAssignmentModal.defaultProps = {
  open: false,
};

const mapStateToProps = (state, props) => {
  const { userId } = props;
  const modalId = getTaskAssignmentModalId(userId);
  const {
    rateMaxBillingQuantity, rateUnit, rate, currency, assigneeUserId,
  } = getModalPayload(state, modalId) || {};
  return {
    initialValues: {
      rate_max_billing_quantity: rateMaxBillingQuantity,
    },
    rateUnit,
    open: getIsModalOpen(state, modalId),
    rate,
    currency,
    assigneeUserId,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const TaskAssignmentModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskAssignmentModal);

export default TaskAssignmentModalConnected;
