import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';
import { fetchFinanceWorksheetDS } from 'finance/assets/js/data-services/view';
import { getViewState } from 'core/assets/js/ducks/view';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { financeWorksheetsUrl, financeWorksheetViewUrl } from 'finance/urls';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { worksheetSpec } from 'finance/assets/js/lib/objectSpecs';
import { projectListUrl, projectViewUrl } from 'projects/urls';
import BillableOnDemandInvoiceStep from 'finance/assets/js/components/invoicing/BillableOnDemandInvoiceStep.jsx';

class WorksheetOnDemandInvoiceView extends React.Component {
  static GetComponentName(step) {
    return `WorksheetOnDemandInvoiceView-${step}`;
  }

  render() {
    const { report, match: { params: { orgAlias, id, step } } } = this.props;
    const breadcrumbs = [
      {
        title: 'Projects',
        url: projectListUrl(orgAlias),
      },
      {
        title: report.projectTitle ? report.projectTitle : <RectangleSkeleton width="183" height="25" />,
        url: projectViewUrl(orgAlias, report.projectId),
      },
      {
        title: 'Worksheets',
        url: financeWorksheetsUrl(orgAlias),
      },
      {
        title: report.projectReference ? `${report?.projectReference}_${report?.id}` : <RectangleSkeleton width="183" height="25" />,
        url: financeWorksheetViewUrl(orgAlias, id),
      },
      {
        title: 'Raise invoice',
        url: null,
      },
    ];
    return (
      <>
        <ContentHeader breadcrumbs={breadcrumbs} />
        <div className="page page--on-demand-invoice">
          <div className="container">
            <TDApiConnected
              duck="view"
              storeKey={WorksheetOnDemandInvoiceView.GetComponentName(step)}
              fetchData={({ dispatch, params, url, authedAxios, componentName }) => (
                dispatch(fetchFinanceWorksheetDS({
                  orgAlias: params.orgAlias, id: params.id, url, componentName, authedAxios,
                }))
              )}
              blockingLoading
              skeletonComponent={SettingsPageSkeleton}
            >
              <BillableOnDemandInvoiceStep
                orgAlias={orgAlias}
                step={step}
                report={report}
              />
            </TDApiConnected>
          </div>
        </div>
      </>
    );
  }
}

WorksheetOnDemandInvoiceView.propTypes = {
  match: routerMatchSpec.isRequired,
  report: worksheetSpec,
};

WorksheetOnDemandInvoiceView.defaultProps = {
  report: {},
};

const mapStateToProps = (state, props) => {
  const { match: { params: { step } } } = props;
  const viewState = getViewState(state, WorksheetOnDemandInvoiceView.GetComponentName(step));
  return {
    report: viewState.item,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const WorksheetOnDemandInvoiceViewConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorksheetOnDemandInvoiceView);

export default withRouter(WorksheetOnDemandInvoiceViewConnect);
