/* eslint no-param-reassign: off */
import React from 'react';
import PropTypes from 'prop-types';
import { Nav } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { TABS_STYLING_VARIATION } from 'core/assets/js/constants';

function TabBar({ activeKey, tabSpec, handleTabSelect, className, variation, history,
  showCircleBadge }) {
  const tabs = tabSpec.map((t, idx) => {
    const badge = t.outstandingCount
      ? <span className={`ml-2 badge${showCircleBadge ? '' : ' badge--inline'}`}>{t.outstandingCount}</span>
      : null;
    idx += 1;
    return (
      <Nav.Item
        key={t.href || t.key || idx}
      >
        <Nav.Link
          onClick={() => {
            if (t.href) {
              history.push(t.href);
            }
          }}
          disabled={!!t.disabled}
          eventKey={t.key || idx}
        >
          {t.label}
          {badge}
        </Nav.Link>
      </Nav.Item>
    );
  });

  const navClassName = [className, 'd-flex flex-nowrap'];
  if (variation === TABS_STYLING_VARIATION.SIMPLE) {
    navClassName.push('nav-tabs--simple');
  } else if (variation === TABS_STYLING_VARIATION.PILLS) {
    navClassName.push('nav-tabs--pills');
  }

  return (
    <Nav
      activeKey={activeKey}
      variant="tabs"
      className={navClassName.join(' ')}
      onSelect={key => handleTabSelect(key)}
    >
      {tabs}
    </Nav>
  );
}

TabBar.propTypes = {
  activeKey: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  className: PropTypes.string,
  handleTabSelect: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  tabSpec: PropTypes.array.isRequired,
  variation: PropTypes.oneOf(Object.values(TABS_STYLING_VARIATION)),
  showCircleBadge: PropTypes.bool,
};
TabBar.defaultProps = {
  activeKey: -1,
  className: '',
  handleTabSelect: () => {},
  variation: TABS_STYLING_VARIATION.DEFAULT,
  showCircleBadge: false,
};

export default withRouter(TabBar);
