// Action types
export const MODAL_DATA_VIEW = {
  IS_LOADING: 'MODAL_DATA_VIEW_IS_LOADING',
  FETCH: 'MODAL_DATA_VIEW_FETCH',
  RESET: 'MODAL_DATA_VIEW_RESET',
};

// Reducer
const initialState = {
  item: {},
  isLoading: false,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_DATA_VIEW.IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case MODAL_DATA_VIEW.FETCH:
      return {
        ...state,
        item: action.item,
        isLoading: false,
      };
    case MODAL_DATA_VIEW.RESET:
      return initialState;
    default:
      return state;
  }
};

// Action creators
export const modalDataViewIsLoadingAC = bool => ({
  type: MODAL_DATA_VIEW.IS_LOADING,
  isLoading: bool,
});
export const modalDataViewFetchAC = item => ({
  type: MODAL_DATA_VIEW.FETCH,
  item,
});
export const modalDataViewResetAC = () => ({
  type: MODAL_DATA_VIEW.RESET,
});


export default reducer;
