import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { paginationSpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import { getListState, fetchListDS } from 'core/assets/js/ducks/list';
import TDList from 'core/assets/js/components/TDList.jsx';
import ProjectListSkeleton from 'core/assets/js/components/Skeleton/ProjectListSkeleton.jsx';
import { projectOpportunitiesListApiUrl, projectCreateUrl } from 'projects/urls';
import { projectApplicationSpec } from 'projects/assets/js/lib/objectSpecs';
import ProjectOpportunityCardItem from 'projects/assets/js/components/ProjectOpportunityCardItem.jsx';
import { BS_STYLE } from 'core/assets/js/constants';

class ProjectOpportunitiesListView extends React.Component {
  static FetchData({ dispatch, params, url, componentName, authedAxios, querystring }) {
    return Promise.all([
      dispatch(fetchListDS({
        authedAxios,
        componentName,
        querystring,
        url: projectOpportunitiesListApiUrl(params.orgAlias, url),
      })),
    ]);
  }

  static GetComponentName() {
    return 'ProjectOpportunitiesListView';
  }

  render() {
    const { opportunities, pagination, isManager, history, match } = this.props;
    const breadcrumbs = [
      {
        title: 'Opportunities',
        url: null,
      },
    ];

    let ctaButtonItems = null;
    if (isManager) {
      ctaButtonItems = [{
        label: 'New opportunity',
        onClick: () => history.push(projectCreateUrl(match.params.orgAlias, true)),
        variant: BS_STYLE.PRIMARY,
      }];
    }

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} ctaButtonItems={ctaButtonItems} />

        <div className="page page--projects">
          <div className="container">
            <TDApiConnected
              duck="list"
              component={this.constructor}
              loadingEnabled={false}
              skeletonComponent={ProjectListSkeleton}
            >
              <TDList
                pagination={pagination}
                items={opportunities}
                cardItem={{
                  component: ProjectOpportunityCardItem,
                  props: {
                    isManager,
                  },
                }}
                emptyListMessage="No project opportunities found."
              />
            </TDApiConnected>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
ProjectOpportunitiesListView.propTypes = {
  opportunities: PropTypes.arrayOf(projectApplicationSpec),
  isManager: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
  pagination: paginationSpec.isRequired,
};
ProjectOpportunitiesListView.defaultProps = {
  opportunities: [],
};

const mapStateToProps = (state, props) => {
  const listState = getListState(state, ProjectOpportunitiesListView.GetComponentName());
  return {
    isManager: getHasOrgAccess(state)({ requireManager: true }),
    opportunities: listState.items.projects,
    params: props.match.params,
    history: props.history,
    match: props.match,
    pagination: listState.pagination,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});
const ProjectOpportunitiesListViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectOpportunitiesListView);

export default withRouter(ProjectOpportunitiesListViewConnected);
