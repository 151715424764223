import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import SelectableListWrapper from 'core/assets/js/components/SelectableListWrapper.jsx';
import PeopleListSkeleton from 'core/assets/js/components/Skeleton/PeopleListSkeleton.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { getListState, fetchListDS } from 'core/assets/js/ducks/list';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import DocumentCard from  'documents/assets/js/components/DocumentCard.jsx';
import DocumentRequestSignaturesModal from 'documents/assets/js/components/DocumentRequestSignaturesModal.jsx';
import DocumentsSearch from 'documents/assets/js/components/DocumentsSearch.jsx';
import { documentSpec } from 'documents/assets/js/lib/objectSpecs';
import { documentCreateUrl, documentsApiUrl } from 'documents/urls';

const DocumentListView = ({
  dispatch,
  documents,
  filtersOpen,
  history,
  location: { search },
  match: { params: { orgAlias } },
  onFiltersToggle,
  searchActive,
}) => {
  const componentName = DocumentListView.GetComponentName();

  return (
    <>
      <ContentHeader
        breadcrumbs={[{ title: 'Contract Templates', url: null }]}
        ctaButtonItems={[{
          label: 'Create contract template',
          onClick: () => history.push(documentCreateUrl(orgAlias)),
          variant: BS_STYLE.PRIMARY,
        }]}
      />
      <div className="page page--documents">
        <div className="container">
          <SelectableListWrapper
            bulkActions={[]}
            cardItem={{ component: DocumentCard, props: { listComponentName: componentName } }}
            componentName={componentName}
            emptyListMessage="No contract templates found"
            fetchData={({ authedAxios }) => dispatch(fetchListDS({
              authedAxios,
              componentName,
              querystring: search,
              url: documentsApiUrl(orgAlias),
            }))}
            filtersOpen={filtersOpen}
            items={documents}
            searchComponent={searchActive && (
              <DocumentsSearch filtersOpen={filtersOpen} onFiltersToggle={onFiltersToggle} />
            )}
            selectable={false}
            skeletonComponent={PeopleListSkeleton}
          />
          <DocumentRequestSignaturesModal />
        </div>
      </div>
    </>
  );
};

DocumentListView.GetComponentName = () => 'DocumentListView';

DocumentListView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(documentSpec),
  filtersOpen: PropTypes.bool.isRequired,
  history: routerHistorySpec.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchContentsSpec.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  searchActive: PropTypes.bool.isRequired,
};

DocumentListView.defaultProps = {
  documents: [],
};

const mapStateToProps = (state, props) => {
  const listState = getListState(state, DocumentListView.GetComponentName(props.match.params));
  return {
    documents: listState.items,
    searchActive: listState.search.isActive,
  };
};

const DocumentListViewConnected = connect(
  mapStateToProps,
)(withFilters(DocumentListView));

export default withRouter(DocumentListViewConnected);
