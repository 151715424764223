import { pick } from 'lodash';
import React from 'react';
import { Form } from 'react-final-form';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import GeoSuggestField from 'core/assets/js/components/FinalFormFields/GeoSuggestField.jsx';
import SelectField from 'core/assets/js/components/FinalFormFields/SelectField.jsx';
import TextInputField from 'core/assets/js/components/FinalFormFields/TextInputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import { routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import axios from 'core/assets/js/lib/tdAxios';
import { parseAxiosErrorForFinalForm } from 'core/assets/js/lib/utils';
import {
  SIGNING_AUTHORITY_POSITION_LABEL,
  SIGNING_AUTHORITY_POSITION_VALUES,
  TAX_BANDITS_PHONE_NUMBER_ERROR,
} from 'finance/assets/js/constants';
import { validatePhoneNumberForTaxBandits } from 'finance/assets/js/lib/utils';
import {
  financeGetUSTaxCompanyDetailsApiUrl, financeUpdateUSTaxCompanyDetailsApiUrl,
} from 'finance/urls';

const SIGNING_AUTHORITY_POSITION_OPTIONS = SIGNING_AUTHORITY_POSITION_VALUES.map(value => ({
  text: SIGNING_AUTHORITY_POSITION_LABEL[value], value,
}));

const USTaxCompanyDetails = ({ match: { params: { orgAlias } } }) => {
  const { hasLoaded, item } = fetchDataHook({
    componentName: USTaxCompanyDetails.GetComponentName(),
    url: financeGetUSTaxCompanyDetailsApiUrl(orgAlias),
  });

  if (!hasLoaded) {
    return null;
  }

  return (
    <Form
      initialValues={pick(
        item,
        'address',
        'businessName',
        'contactName',
        'email',
        'employerIdentificationNumber',
        'phone',
        'signingAuthorityName',
        'signingAuthorityPhone',
        'signingAuthorityPosition',
        'tradingName',
      )}
      onSubmit={async values => { // eslint-disable-line consistent-return
        const errors = {};
        if (typeof values.businessName !== 'string' || values.businessName.length === 0) {
          errors.businessName = 'Business name is required';
        }
        if (
          typeof values.employerIdentificationNumber !== 'string'
          || !/^\d{9}$/.test(values.employerIdentificationNumber)
        ) {
          errors.employerIdentificationNumber = 'Employer identification number must be 9 digits';
        }
        if (!values.address) {
          errors.address = 'Address is required';
        }
        if (typeof values.email !== 'string' || values.email.length === 0) {
          errors.email = 'Email is required';
        }
        if (values.phone && !validatePhoneNumberForTaxBandits(values.phone)) {
          errors.phone = TAX_BANDITS_PHONE_NUMBER_ERROR;
        }
        if (
          values.signingAuthorityPhone
          && !validatePhoneNumberForTaxBandits(values.signingAuthorityPhone)
        ) {
          errors.signingAuthorityPhone = TAX_BANDITS_PHONE_NUMBER_ERROR;
        }
        if (Object.values(errors).length > 0) {
          return errors;
        }
        try {
          await axios.put(financeUpdateUSTaxCompanyDetailsApiUrl(orgAlias), values);
          toastr.success('Well Done!', '1099 filing company details updated');
        } catch (err) {
          return parseAxiosErrorForFinalForm(err);
        }
      }}
      render={({ handleSubmit, submitError, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="rounded shadow-sm p-4 bg-white mt-5">
            <h3>Business details</h3>
            <p>Your company business details</p>
            <TextInputField
              disabled={submitting}
              label="Business name"
              name="businessName"
              required
              sublabel="The name of the Business as registered with the IRS"
            />
            <TextInputField
              disabled={submitting}
              label="Trading name"
              name="tradingName"
              sublabel="Any trading name that the Business has"
            />
            <TextInputField
              disabled={submitting}
              label="EIN"
              name="employerIdentificationNumber"
              required
              sublabel="The Business Employer Identification Number"
            />
            <GeoSuggestField
              disabled={submitting}
              label="Address"
              name="address"
              required
              sublabel="The official address of the Business for tax filing purposes"
            />
            <h3>Business details</h3>
            <p>Your company contact details</p>
            <TextInputField
              disabled={submitting}
              label="Email"
              name="email"
              required
              sublabel="Email address of the Business to be used for 1099-related correspondence"
              type="email"
            />
            <TextInputField
              disabled={submitting}
              label="Contact name"
              name="contactName"
              sublabel="The name of the person in the Business that will handle 1099 related correspondence"
            />
            <TextInputField
              disabled={submitting}
              label="Phone"
              name="phone"
              sublabel="The phone number of the person in the Business that will handle 1099 related correspondence"
            />
            <h3>Signing authority</h3>
            <p>Details of the person authorised to sign the 1099 returns</p>
            <TextInputField
              disabled={submitting}
              label="Name"
              name="signingAuthorityName"
            />
            <TextInputField
              disabled={submitting}
              label="Phone"
              name="signingAuthorityPhone"
            />
            <SelectField
              disabled={submitting}
              label="Position"
              name="signingAuthorityPosition"
              optionsMapping={SIGNING_AUTHORITY_POSITION_OPTIONS}
            />
            {submitError && <span className="text-danger mt-3">{submitError}</span>}
          </div>
          <div className="d-flex justify-content-end mt-5">
            <TDButton
              disabled={submitting}
              label="Update"
              type="submit"
              variant={BS_STYLE.PRIMARY}
            />
          </div>
        </form>
      )}
    />
  );
};

USTaxCompanyDetails.GetComponentName = () => 'USTaxCompanyDetails';

USTaxCompanyDetails.propTypes = {
  match: routerMatchContentsSpec.isRequired,
};

export default withRouter(USTaxCompanyDetails);
