import React from 'react';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import AllowAccessToHigherManager from 'core/assets/js/components/AllowAccessToHigherManager.jsx';
import AllowAccessToManager from 'core/assets/js/components/AllowAccessToManager.jsx';
import ConvertToProviderModal, { getModalId as getDemoteModalId } from 'people/assets/js/components/ConvertToProviderModal.jsx';
import EditManagerBudget from 'people/assets/js/components/EditManagerBudget.jsx';
import PromoteProviderModal, { getModalId as promoteProviderModalId } from 'people/assets/js/components/PromoteProviderModal.jsx';
import ToggleEmploymentModal, { getModalId as getEmploymentModalId } from 'people/assets/js/components/ToggleEmploymentModal.jsx';
import UserToggleActivationModal, { getModalId as getActivationModalId } from 'people/assets/js/components/UserToggleActivationModal.jsx';
import ManagerDeactivateModal, { getModalId as getManagerDeactivateModalId } from 'people/assets/js/components/manager-deactivate/ManagerDeactivateModal.jsx';
import { MODAL_ID as MANAGE_USERS_GROUPS_MODAL_ID } from 'people/assets/js/components/ManageUsersGroupsModal.jsx';
import { CONTACT_MODAL_ID, RATE_ADJUSTMENT_MODAL_ID, MANAGE_BUDGET_MODAL_ID } from 'people/assets/js/PeopleListView.jsx';
import { USER_TYPE, USER_CARD_STATUS } from 'core/assets/js/constants';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { orgSettingsRatesCreateUrl } from 'rates/urls';
import { peopleTypeSpec } from 'people/assets/js/lib/objectSpecs';
import { selectActiveUserCard } from 'organizations/assets/js/reducers/organizations';
import { submissionUrl } from 'interviews/urls';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import { orgPeopleDeactivateUrl } from 'people/urls';

const TeamMemberActionsDropDownItems = ({
  item: {
    allowedActions: {
      canReactivate,
      canChangeRoleToProvider,
      canChangeToContractor,
      canChangeRoleToManager,
      canChangeToEmployee,
      canDeactivate,
    } = {},
    hasOutstandingItems,
    hasUnprocessedServiceOrders,
    id,
    rate,
    rateId,
    rateAdjustmentActions,
    status,
    submissions,
    user,
    user: {
      id: userId,
      email,
      profile,
      profile: { firstName },
    },
    userRole,
  },
  componentName,
  peopleType,
}) => {
  const { params: { orgAlias }, url  } = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();
  const activeUserCard = useSelector(state => selectActiveUserCard(state));

  // Rate adjustment actions
  const isProvider = userRole?.ofType === USER_TYPE.PROVIDER;
  const isTargetOrgCreator = userRole?.ofType === USER_TYPE.ORG_CREATOR;
  const isOrgCreator = activeUserCard.userRole.isOrgCreator;
  const isThemselves = activeUserCard.user && user && activeUserCard.user.id === user.id;
  const isApproved = status === USER_CARD_STATUS.APPROVED;
  const rateCanCreate = !rate;
  let rateAdjCanResponderAct = false;
  let rateAdjCanBeInteracted = false;
  if (rate && rateAdjustmentActions) {
    if (rateAdjustmentActions.canRespond || rateAdjustmentActions.canCancel) {
      rateAdjCanResponderAct = true;
    }

    if (isApproved && (rateAdjustmentActions.canInitiate || rateAdjCanResponderAct)) {
      rateAdjCanBeInteracted = true;
    }
  }

  const submission = submissions && submissions.length > 0 ? submissions[0] : null;
  const editUrl = submission ? submissionUrl(orgAlias, submission.id, '', url) : '';
  let showSubmissionLink = false;
  let submissionLinkTxt = null;

  if (submission && submission.allowedActions) {
    showSubmissionLink = submission.allowedActions.canView;
    submissionLinkTxt = `${submission.allowedActions.canReview ? 'Review' : 'View'} onboarding submission`;
  }

  if (rate && rateAdjustmentActions) {
    if (rateAdjustmentActions.canRespond || rateAdjustmentActions.canCancel) {
      rateAdjCanResponderAct = true;
    }

    if (isApproved && (rateAdjustmentActions.canInitiate || rateAdjCanResponderAct)) {
      rateAdjCanBeInteracted = true;
    }
  }

  const canToggleActivation = componentName && (canReactivate || canDeactivate);
  const canToggleEmploymentStatus = canChangeToContractor || canChangeToEmployee;

  const editManagerBudgetModalId = `edit-budget-id-${id}`;

  return (
    <>
      { showSubmissionLink && (
        <Dropdown.Item
          eventKey="submission"
          onClick={() => history.push(editUrl)}
        >
          { submissionLinkTxt }
        </Dropdown.Item>
      )}

      { peopleType === PEOPLE_TYPE.MANAGERS
        && (isOrgCreator || (isApproved && !isThemselves))
        && (
          <AllowAccessToHigherManager>
            <Dropdown.Item
              eventKey="editBudget"
              onClick={() => {
                dispatch(modalOpenAC(MANAGE_BUDGET_MODAL_ID, {
                  user,
                  id,
                }));
              }}
            >
              Manage Budget
            </Dropdown.Item>
          </AllowAccessToHigherManager>
        )
      }

      { rateAdjCanBeInteracted && (
        <AllowAccessToManager>
          <Dropdown.Item
            eventKey="2"
            onClick={() => {
              dispatch(modalOpenAC(RATE_ADJUSTMENT_MODAL_ID, {
                userCardId: userId,
                rateId,
              }));
            }}
          >
            { rateAdjustmentActions.canInitiate
              ? 'Request rate adjustment'
              : 'Pending rate adjustment'
            }
          </Dropdown.Item>
        </AllowAccessToManager>
      )}

      { (rateCanCreate && isThemselves && !isOrgCreator) && (
        <AllowAccessToManager>
          <Dropdown.Item
            eventKey="3"
            onClick={() => {
              history.push(orgSettingsRatesCreateUrl(orgAlias));
            }}
          >
            Create rate
          </Dropdown.Item>
        </AllowAccessToManager>
      )}

      <Dropdown.Item
        eventKey="contact"
        onClick={() => {
          dispatch(modalOpenAC(CONTACT_MODAL_ID, {
            profile,
            firstName,
            email,
          }));
        }}
      >
        Contact
      </Dropdown.Item>

      {canChangeRoleToProvider && (
        <Dropdown.Item
          eventKey="demote"
          onClick={() => {
            dispatch(modalOpenAC(getDemoteModalId(userId)));
          }}
        >
          Convert to provider
        </Dropdown.Item>
      )}

      {canChangeRoleToManager && (
        <Dropdown.Item
          eventKey="convert-to-manager"
          onClick={() => {
            dispatch(modalOpenAC(promoteProviderModalId(userId)));
          }}
        >
          Convert to manager
        </Dropdown.Item>
      )}

      {canToggleEmploymentStatus && (
        <Dropdown.Item
          eventKey="promote"
          onClick={() => {
            dispatch(modalOpenAC(getEmploymentModalId(userId)));
          }}
        >
          Convert to
          {` ${canChangeToContractor ? 'contractor' : 'employee'}`}
        </Dropdown.Item>
      )}

      {activeUserCard.userRole.isAnyManager && (
        <Dropdown.Item
          eventKey="manage-groups"
          onClick={() => dispatch(modalOpenAC(
            MANAGE_USERS_GROUPS_MODAL_ID, { userId, userName: profile.name },
          ))}
        >
          Manage groups
        </Dropdown.Item>
      )}

      {(canToggleActivation && !isTargetOrgCreator) && (
        <>
          <Dropdown.Divider />

          {isProvider && (
            <Dropdown.Item
              className={canDeactivate && 'text-danger'}
              eventKey="toggle-activation"
              onClick={() => {
                if (canDeactivate && hasOutstandingItems) {
                  history.push(orgPeopleDeactivateUrl(orgAlias, PEOPLE_TYPE.PROVIDERS, userId));
                  return;
                }
                dispatch(modalOpenAC(getActivationModalId(userId)));
              }}
            >
              {canDeactivate ? 'Deactivate' : 'Activate'}
            </Dropdown.Item>
          )}

          {!isProvider && (
            <Dropdown.Item
              className={canDeactivate && 'text-danger'}
              eventKey="toggle-activation"
              onClick={() => {
                if (isApproved) {
                  dispatch(modalOpenAC(getManagerDeactivateModalId(userId)));
                } else {
                  dispatch(modalOpenAC(getActivationModalId(userId)));
                }
              }}
            >
              {canDeactivate ? 'Deactivate' : 'Activate'}
            </Dropdown.Item>
          )}

        </>
      )}

      { (isOrgCreator || (!isThemselves)) && (
        <AllowAccessToHigherManager>
          <EditManagerBudget
            manager={user}
            managerId={id}
            orgAlias={orgAlias}
            form={`manager-budget-form-${id}`}
            modalId={editManagerBudgetModalId}
          />
        </AllowAccessToHigherManager>
      )}

      {canToggleActivation && (
        <UserToggleActivationModal
          activate={canReactivate}
          listViewComponentName={componentName}
          userId={userId}
          userName={profile.name}
          userType={peopleType === PEOPLE_TYPE.MANAGERS
            ? USER_TYPE.MANAGER
            : USER_TYPE.PROVIDER
          }
        />
      )}

      {canChangeRoleToProvider && (
        <ConvertToProviderModal
          listViewComponentName={componentName}
          userId={userId}
          userName={profile.name}
        />
      )}

      {canToggleEmploymentStatus && (
        <ToggleEmploymentModal
          cannotConvertToEmployee={canChangeToEmployee && hasUnprocessedServiceOrders}
          listViewComponentName={componentName}
          toContractor={canChangeToContractor}
          userId={userId}
          userName={profile.name}
        />
      )}

      {canChangeRoleToManager && (
        <PromoteProviderModal
          listViewComponentName={componentName}
          userId={userId}
          userName={profile.name}
        />
      )}

      {canDeactivate && (
        <div className="d-none">
          <ManagerDeactivateModal
            listViewComponentName={componentName}
            userEmail={email}
            userId={userId}
            userName={profile.name}
          />
        </div>
      )}
    </>
  );
};

TeamMemberActionsDropDownItems.propTypes = {
  componentName: PropTypes.string.isRequired,
  item: userCardSpec.isRequired,
  peopleType: peopleTypeSpec.isRequired,
};

export default TeamMemberActionsDropDownItems;
