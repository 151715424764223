import PropTypes from 'prop-types';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useParams,
  useLocation,
  useHistory,
} from 'react-router-dom';


import RolesTable from 'roles/assets/js/components/RolesTable.jsx';
import { rolesListApiUrl, rolesListUrl } from 'roles/urls';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import { modalCloseAC, getIsModalOpen, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import { fetchListDS } from 'core/assets/js/ducks/list';
import { deleteRoleDS } from 'roles/assets/js/data-services/role';

export const DELETE_MODAL_ID = 'delete-role-modal';

const DeleteRoleModal = ({ isFromEditPage }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const deleteModalOpen = useSelector(state => getIsModalOpen(state, DELETE_MODAL_ID));
  const deleteModalPayload = useSelector(state => getModalPayload(state, DELETE_MODAL_ID));
  const { orgAlias } = useParams();

  const handleRoleDelete = async () => {
    dispatch(deleteRoleDS({
      orgAlias,
      roleId: deleteModalPayload.id,
    }))
      .then(() => {
        if (isFromEditPage) {
          history.push(rolesListUrl(orgAlias));
        }

        dispatch(modalCloseAC(DELETE_MODAL_ID));
        dispatch(fetchListDS({
          componentName: RolesTable.GetComponentName(),
          querystring: location.search,
          url: rolesListApiUrl(orgAlias),
        }));
      });
  };

  return (
    <ModalConfirm
      cancelLabel="Cancel"
      confirmLabel="Delete"
      heading="Delete role"
      onClose={() => dispatch(modalCloseAC(DELETE_MODAL_ID))}
      onConfirm={handleRoleDelete}
      open={deleteModalOpen}
    >
      <p>
        {'Are you sure you want to delete '}
        <strong>{deleteModalPayload?.name}</strong>
        ?
      </p>
    </ModalConfirm>
  );
};

DeleteRoleModal.propTypes = {
  isFromEditPage: PropTypes.bool,
};
DeleteRoleModal.defaultProps = {
  isFromEditPage: false,
};

DeleteRoleModal.GetComponentName = () => 'DeleteRoleModal';

export default DeleteRoleModal;
