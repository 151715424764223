import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import {
  selectActiveOrg, selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import { fetchRatesDS, setRateAsDefaultDS } from 'rates/assets/js/data-services/rates';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';

const RateSetAsDefaultOption = ({ listComponentName, rate }) => {
  const dispatch = useDispatch();
  const { alias: orgAlias } = useSelector(selectActiveOrg);
  const { user: { id: userId } } = useSelector(selectActiveUserCard);
  const isOwner = userId === rate.user_id;
  return (
    <Dropdown.Item
      className="text-info"
      eventKey="default"
      onClick={async () => {
        try {
          await dispatch(setRateAsDefaultDS(orgAlias, rate.id));
          toastr.success(
            'Well Done!',
            `You have set "${rate.alias}" as ${isOwner ? 'your' : 'the'} new default rate`,
          );
          dispatch(fetchRatesDS({
            componentName: listComponentName,
            orgAlias,
            userId: isOwner ? null : rate.user_id,
          }));
        } catch (e) {
          toastr.error('Oh Snap!', e._error || e.message);
        }
      }}
    >
      Set as default rate
    </Dropdown.Item>
  );
};

RateSetAsDefaultOption.propTypes = {
  listComponentName: PropTypes.string.isRequired,
  rate: PropTypes.shape(rateSpec).isRequired,
};

export default RateSetAsDefaultOption;
