import React from 'react';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';
import { withRouter, Link } from 'react-router-dom';
import { Dropdown, Card } from 'react-bootstrap';

import { interviewPendingInvitationSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import {
  interviewAllowedActionsSpec, interviewQuestionSpec,
} from 'interviews/assets/js/lib/objectSpecs';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import { MINIMUM_PENDING_INVITATIONS_TO_SHOW } from 'interviews/assets/js/constants';


const InterviewCardItem = ({
  allowedActions,
  editUrl,
  history,
  inviteesUrl,
  name,
  onDelete,
  onDuplicate,
  onManageUrl,
  pendingInvitationCount,
  pendingInvitations,
  publicUrl,
  questions,
  url,
}) => (
  <React.Fragment>
    <div className="row">
      <div className="col-9">
        <h3 className="interview-list-item__name">
          <Link
            className="text-dark"
            to={url}
          >
            {name}
          </Link>
        </h3>
        <div>
          <span className="interview-list-item__meta">
            {pluralize('question', questions.length, true)}
            { publicUrl && allowedActions.canGenerateInviteLink && (
              <React.Fragment>
                <span className="separator">&bull;</span>
                {publicUrl}
              </React.Fragment>
            )}
          </span>
        </div>
      </div>

      <div className="col-3 text-right button-container">
        <TDDropButton data-testid="interview-card-item-actions">
          {allowedActions.canView && (
            <Dropdown.Item
              eventKey="1"
              onClick={() => history.push(url)}
            >
              Preview
            </Dropdown.Item>
          )}
          {allowedActions.canEdit && (
            <Dropdown.Item
              eventKey="2"
              onClick={() => history.push(editUrl)}
            >
              Edit
            </Dropdown.Item>
          )}
          {allowedActions.canCreate && (
            <Dropdown.Item
              eventKey="5"
              onClick={onDuplicate}
            >
              Duplicate
            </Dropdown.Item>
          )}
          {(
            (publicUrl && allowedActions.canRevokeInviteLink)
            || (!publicUrl && allowedActions.canGenerateInviteLink)
          ) && (
            <Dropdown.Item
              data-testid="interview-card-dropdown-invite-link"
              eventKey="4"
              onClick={onManageUrl}
            >
              Invite Link
            </Dropdown.Item>
          )}
          {allowedActions.canDelete && (
            <Dropdown.Item
              eventKey="3"
              onClick={onDelete}
            >
              Delete
            </Dropdown.Item>
          )}
        </TDDropButton>
      </div>
    </div>
    {pendingInvitationCount > 0 ? (
      <React.Fragment>
        <hr />
        <AreaCollapsible
          contentChildren={(
            <Card className="mt-4">
              <Card.Body>
                {pendingInvitations.map(invitation => (
                  <React.Fragment key={invitation.id}>
                    <p>{invitation.sentTo}</p>
                    <hr />
                  </React.Fragment>
                ))}
                <Link to={inviteesUrl}>
                  {
                    pendingInvitationCount > MINIMUM_PENDING_INVITATIONS_TO_SHOW
                      ? `+ ${pendingInvitationCount - MINIMUM_PENDING_INVITATIONS_TO_SHOW} more`
                      : 'View invitees'
                  }
                </Link>
              </Card.Body>
            </Card>
          )}
          headingChildren={(
            <span className="interview-list-item__pending_invitation_meta">
              {pluralize('Pending invitations', pendingInvitationCount, true)}
            </span>
          )}
          toggleElementPlacement="right"
          toggleBeforeCard
        />
      </React.Fragment>
    ) : null}
  </React.Fragment>

);
InterviewCardItem.propTypes = {
  allowedActions: interviewAllowedActionsSpec.isRequired,
  name: PropTypes.string.isRequired,
  questions: PropTypes.arrayOf(interviewQuestionSpec).isRequired,
  pendingInvitations: PropTypes.arrayOf(interviewPendingInvitationSpec).isRequired,
  url: PropTypes.string.isRequired,
  editUrl: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  publicUrl: PropTypes.string,
  inviteesUrl: PropTypes.string.isRequired,
  onManageUrl: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  pendingInvitationCount: PropTypes.number.isRequired,
};

InterviewCardItem.defaultProps = {
  publicUrl: null,
};

export default withRouter(InterviewCardItem);
