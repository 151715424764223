import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { API_DATE_FORMAT, ICON } from 'core/assets/js/constants';
import { formatDate, getDatetime, parseDate } from 'core/assets/js/lib/utils';

const DateRangePicker = ({
  className, endDate, initialVisibleDate, isOutsideRange, onChange, showSecondMonth, startDate,
}) => {
  const [visibleDate, setVisibleDate] = useState(initialVisibleDate);
  const secondMonth = parseDate(visibleDate).add(1, 'month').toDate();
  const [hoverEndDate, setHoverEndDate] = useState(null);

  const now = getDatetime();

  const parsedStartDate = startDate ? parseDate(startDate) : null;
  const apiFormatStartDate = startDate ? formatDate(parsedStartDate, API_DATE_FORMAT) : null;
  const parsedEndDate = endDate ? parseDate(endDate) : null;
  const apiFormatEndDate = endDate ? formatDate(parsedEndDate, API_DATE_FORMAT) : null;

  const renderMonth = (date, monthClassName) => {
    const year = date.getFullYear();
    const month = date.getMonth(); // January will be 0
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const rows = [[]];
    for (let i = 1; i < daysInMonth + 1; i += 1) {
      const weekDay = new Date(year, month, i).getDay();
      let previousWeekDay = null;
      if (i > 1) {
        previousWeekDay = new Date(year, month, i - 1).getDay();
      }
      if (previousWeekDay === 6 && weekDay === 0) {
        // We're starting a new week
        rows.push([]);
      }
      rows[rows.length - 1].push(i);
    }
    rows.forEach((row, rowIndex) => {
      const daysCount = row.length;
      if (daysCount < 7) {
        row[rowIndex === 0 ? 'unshift' : 'push'](...new Array(7 - daysCount).fill(null));
      }
    });
    return (
      <div className={`month d-flex flex-column${monthClassName ? ` ${monthClassName}` : ''}`}>
        <div className="week-days d-flex mb-3 w-100">
          {['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map(day => (
            <small className="text-center day" key={day}>{day}</small>
          ))}
        </div>
        <div className="days d-flex flex-column w-100">
          {rows.map((row, rowIndex) => (
            <div className="week d-flex" key={rowIndex}>
              {row.map((day, dayIndex) => {
                const classNames = ['day'];
                let parsedDate = null;
                let isDisabled = false;
                if (day) {
                  parsedDate = parseDate(date).date(day);
                  if (parsedDate.isAfter(now)) {
                    classNames.push('in-future');
                  }
                  isDisabled = isOutsideRange && isOutsideRange(parsedDate.toDate());
                  if (isDisabled) {
                    classNames.push('disabled');
                  }
                  const isStartDate = (
                    parsedStartDate
                    && apiFormatStartDate === formatDate(parsedDate, API_DATE_FORMAT)
                  );
                  if (isStartDate) {
                    classNames.push('start-date');
                  }
                  const isEndDate = (
                    parsedEndDate
                    && apiFormatEndDate === formatDate(parsedDate, API_DATE_FORMAT)
                  );
                  if (isEndDate) {
                    classNames.push('end-date');
                  }
                  if (
                    startDate
                    && !endDate
                    && !isStartDate
                    && parsedDate.isSameOrAfter(parsedStartDate)
                    && hoverEndDate
                    && parsedDate.isSameOrBefore(parseDate(hoverEndDate))
                  ) {
                    classNames.push('hover-selected');
                  }
                  if (
                    isStartDate || isEndDate || (
                      parsedStartDate
                      && parsedEndDate
                      && parsedDate.isSameOrAfter(parsedStartDate)
                      && parsedDate.isSameOrBefore(parsedEndDate)
                    )
                  ) {
                    classNames.push('selected');
                  }
                }
                return (
                  <div
                    className={classNames.join(' ')}
                    key={dayIndex}
                    onClick={() => {
                      if (!day || isDisabled) {
                        return;
                      }
                      const newDate = parsedDate.toDate();
                      let newStartDate = startDate;
                      let newEndDate = endDate;
                      if (!startDate) {
                        // We are setting the start date, for the first time
                        newStartDate = newDate;
                      }
                      if (startDate && !endDate) {
                        // We are setting the end date
                        newEndDate = newDate;
                        setHoverEndDate(null);
                      }
                      if (startDate && endDate) {
                        // We are changing the start date
                        newStartDate = newDate;
                        newEndDate = null;
                      }
                      onChange({ endDate: newEndDate, startDate: newStartDate });
                    }}
                    onMouseEnter={() => {
                      if (!day || isDisabled || !startDate || endDate) {
                        return;
                      }
                      setHoverEndDate(parsedDate.toDate());
                    }}
                  >
                    {day && <span>{day}</span>}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const classNames = ['date-range-picker', 'd-flex', 'flex-column'];
  if (secondMonth) {
    classNames.push('show-second-month');
  }
  if (typeof className === 'string' && className.length > 0) {
    classNames.push(className);
  }

  return (
    <div className={classNames.join(' ')}>
      <div className="header d-flex align-items-center w-100 py-3 mb-1">
        <i
          className={`${ICON.ARROW_LEFT_LONG} prev-month`}
          onClick={() => {
            setVisibleDate(parseDate(visibleDate).subtract(1, 'month').toDate());
            setHoverEndDate(null);
          }}
        />
        <i
          className={`${ICON.ARROW_RIGHT_LONG} next-month`}
          onClick={() => {
            setVisibleDate(parseDate(visibleDate).add(1, 'month').toDate());
            setHoverEndDate(null);
          }}
        />
        <div className="date w-100 w-md-50 font-weight-bold text-center text-uppercase">
          {formatDate(visibleDate, 'MMMM YYYY')}
        </div>
        {showSecondMonth && (
          <div className="date w-100 w-md-50 font-weight-bold text-center text-uppercase">
            {formatDate(secondMonth, 'MMMM YYYY')}
          </div>
        )}
      </div>
      <div className="months d-flex">
        {renderMonth(visibleDate)}
        {showSecondMonth && renderMonth(secondMonth, 'ml-5')}
      </div>
    </div>
  );
};

DateRangePicker.propTypes = {
  className: PropTypes.string,
  endDate: PropTypes.instanceOf(Date),
  initialVisibleDate: PropTypes.instanceOf(Date),
  isOutsideRange: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  showSecondMonth: PropTypes.bool,
  startDate: PropTypes.instanceOf(Date),
};

DateRangePicker.defaultProps = {
  className: null,
  isOutsideRange: null,
  endDate: null,
  initialVisibleDate: new Date(),
  showSecondMonth: true,
  startDate: null,
};

export default DateRangePicker;
