
import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { withRouter } from 'react-router-dom';

import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import PeopleList from 'people/assets/js/components/PeopleList.jsx';
import UserGroupCardSkeleton from 'core/assets/js/components/Skeleton/UserGroupCardSkeleton.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import { fetchListDS, getListState } from 'core/assets/js/ducks/list';
import { getIsModalOpen, getModalPayload, modalCloseAC, modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { getRequestState } from 'core/assets/js/ducks/requests';
import { fetchViewDS, getViewState } from 'core/assets/js/ducks/view';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { routerHistorySpec, routerMatchContentsSpec } from 'core/assets/js/lib/objectSpecs';
import UserGroupAddMembersPanel, { MODAL_ID as ADD_MEMBERS_TO_GROUP_MODAL_ID } from 'people/assets/js/components/UserGroupAddMembersPanel.jsx';
import UserGroupCard from 'people/assets/js/components/UserGroupCard.jsx';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import { userGroupAddMembersDS, userGroupRemoveMemberDS } from 'people/assets/js/data-services/list';
import {
  userGroupEditUrl, userGroupMembersListApiUrl, userGroupsUrl, userGroupApiUrl,
} from 'people/urls';

const REMOVE_MEMBER_FROM_GROUP_MODAL_ID = 'user-group-remove-member-modal';

class UserGroupManageView extends React.Component {
  static FetchData({ dispatch, authedAxios, componentName, querystring, params }) {
    const { userGroupId, orgAlias } = params;
    return Promise.all([
      dispatch(fetchViewDS({
        authedAxios, componentName, querystring, url: userGroupApiUrl({ orgAlias, userGroupId }),
      })),
      dispatch(fetchListDS({
        authedAxios,
        componentName,
        querystring,
        url: userGroupMembersListApiUrl({ orgAlias, userGroupId }),
      })),
    ]);
  }

  static GetComponentName() {
    return 'UserGroupManageView';
  }

  constructor(props) {
    super(props);
    this.state = { addingMembers: false };

    this.handleAddMembersToGroup = this.handleAddMembersToGroup.bind(this);
    this.handleCloseMembersToGroupModal = this.handleCloseMembersToGroupModal.bind(this);
    this.handleCloseRemoveGroupMemberModal = this.handleCloseRemoveGroupMemberModal.bind(this);
    this.handleOpenAddMembersToGroupModal = this.handleOpenAddMembersToGroupModal.bind(this);
    this.handleOpenRemoveGroupMemberModal = this.handleOpenRemoveGroupMemberModal.bind(this);
    this.handleRemoveMemberFromGroup = this.handleRemoveMemberFromGroup.bind(this);
    this.onListUpdate = this.onListUpdate.bind(this);
  }

  handleOpenAddMembersToGroupModal(userType) {
    const { dispatch, match } = this.props;
    const { params: { orgAlias, userGroupId } } = match;
    dispatch(modalOpenAC(ADD_MEMBERS_TO_GROUP_MODAL_ID, {
      orgAlias,
      userGroupId,
      userType,
    }));
  }

  handleCloseMembersToGroupModal() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC(ADD_MEMBERS_TO_GROUP_MODAL_ID));
  }

  handleCloseRemoveGroupMemberModal() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC(REMOVE_MEMBER_FROM_GROUP_MODAL_ID));
  }

  async onListUpdate() {
    const { dispatch, location, match } = this.props;
    const { params: { orgAlias, userGroupId } } = match;

    await dispatch(fetchListDS({
      componentName: UserGroupManageView.GetComponentName(),
      querystring: location.search,
      url: userGroupMembersListApiUrl({ orgAlias, userGroupId }),
    }));
  }

  async handleAddMembersToGroup(userIds) {
    const { dispatch, match } = this.props;
    const { params: { orgAlias, userGroupId } } = match;

    this.setState({ addingMembers: true });
    try {
      const values = await dispatch(
        userGroupAddMembersDS({
          componentName: UserGroupManageView.GetComponentName(),
          orgAlias,
          userGroupId,
          values: { userIds },
        }),
      );
      this.handleCloseMembersToGroupModal();
      if (values.count === 0) {
        toastr.warning('None added', 'No new members have been added');
      } else {
        await this.onListUpdate();
        toastr.success('Well Done!', `${values.count} member(s) added successfully.`);
      }
    } catch (e) {
      toastr.error('Oh Snap!', e.response?.data?._error || e.message);
    } finally {
      this.setState({ addingMembers: false });
    }
  }

  handleOpenRemoveGroupMemberModal(userId) {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(REMOVE_MEMBER_FROM_GROUP_MODAL_ID, {
      userId,
    }));
  }

  handleRemoveMemberFromGroup() {
    const { dispatch, match, removeMemberModalPayload } = this.props;
    const { params: { orgAlias, userGroupId } } = match;
    const { userId } = removeMemberModalPayload;
    return dispatch(
      userGroupRemoveMemberDS({
        componentName: UserGroupManageView.GetComponentName(),
        orgAlias,
        userGroupId,
        userId,
      }),
    ).then(async () => {
      this.handleCloseRemoveGroupMemberModal();
      await this.onListUpdate();
      toastr.success('Well Done!', 'User group member removed successfully.');
    });
  }

  render() {
    const {
      hasOrgAccess,
      history,
      isAddMembersToGroupModalOpen,
      isRemoveUserGroupMemberModalOpen,
      item: userGroup,
      listIsLoading,
      match,
      membersCount,
    } = this.props;
    const { addingMembers } = this.state;
    const { params: { orgAlias, userGroupId } } = match;

    const isAnyManager = hasOrgAccess({ requireManager: true });
    const breadcrumbs = [
      {
        title: 'Groups',
        url: userGroupsUrl(orgAlias),
      },
      {
        title: userGroup.name,
        url: null,
      },
    ];

    const ctaButtonItems = [];

    if (userGroup.allowedActions?.canAddUsers) {
      ctaButtonItems.push(
        {
          label: 'Add providers',
          onClick: () => this.handleOpenAddMembersToGroupModal(PEOPLE_TYPE.PROVIDERS),
          variant: BS_STYLE.PRIMARY,
        },
        {
          label: 'Add managers',
          onClick: () => this.handleOpenAddMembersToGroupModal(PEOPLE_TYPE.MANAGERS),
        },
      );
    }

    if (userGroup.allowedActions?.canEdit) {
      ctaButtonItems.push({
        label: 'Edit',
        onClick: () => history.push(userGroupEditUrl(orgAlias, userGroupId)),
        variant: !userGroup.allowedActions?.canAddUsers ? BS_STYLE.PRIMARY : null,
      });
    }

    return (
      <>
        <ContentHeader
          breadcrumbs={breadcrumbs}
          ctaButtonItems={ctaButtonItems}
        />
        <div className="page page--group">
          <div className="container">
            <div className="mb-5">
              <TDApiConnected
                duck="view"
                component={this.constructor}
                skeletonComponent={UserGroupCardSkeleton}
              >
                <UserGroupCard
                  item={userGroup}
                  membersCount={listIsLoading ? '-' : membersCount}
                />
              </TDApiConnected>
            </div>
            <PeopleList
              addUsersComponent={userGroup.allowedActions?.canAddUsers && (
                <div className="d-flex mt-4">
                  <TDButton
                    data-testid="user-group-members-list-add-providers"
                    label="Add providers"
                    onClick={() => this.handleOpenAddMembersToGroupModal(PEOPLE_TYPE.PROVIDERS)}
                    variant={BS_STYLE.PRIMARY}
                  />
                  <TDButton
                    className="ml-4"
                    data-testid="user-group-members-list-add-managers"
                    label="Add managers"
                    onClick={() => this.handleOpenAddMembersToGroupModal(PEOPLE_TYPE.MANAGERS)}
                    variant={BS_STYLE.PRIMARY}
                  />
                </div>
              )}
              canInviteUsers={false}
              componentName={UserGroupManageView.GetComponentName()}
              getActionsDropDownItems={item => {
                if (userGroup.isDynamic || !isAnyManager) {
                  return null;
                }
                return (
                  <Dropdown.Item
                    onClick={() => this.handleOpenRemoveGroupMemberModal(item.user.id)}
                  >
                    Remove member
                  </Dropdown.Item>
                );
              }}
              noUsersMessage="This group does not have any members yet"
            />
          </div>
        </div>
        {isRemoveUserGroupMemberModalOpen && (
          <ModalSimple
            heading="Delete user member"
            body="Are you sure you want to remove this user from the group?"
            onClose={this.handleCloseRemoveGroupMemberModal}
            open={isRemoveUserGroupMemberModalOpen}
            footer={[
              <TDButton
                key={1}
                className="float-right"
                label="Delete"
                variant={BS_STYLE.DANGER}
                onClick={this.handleRemoveMemberFromGroup}
              />,
              <TDButton
                key={2}
                className="mr-4 float-right"
                label="Cancel"
                onClick={this.handleCloseRemoveGroupMemberModal}
              />,
            ]}
          />
        )}
        {isAddMembersToGroupModalOpen && (
          <UserGroupAddMembersPanel
            handleAddMembersToGroup={this.handleAddMembersToGroup}
            submitting={addingMembers}
          />
        )}
      </>
    );
  }
}

UserGroupManageView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  isAddMembersToGroupModalOpen: PropTypes.bool,
  isRemoveUserGroupMemberModalOpen: PropTypes.bool,
  item: PropTypes.object,
  match: routerMatchContentsSpec.isRequired,
  removeMemberModalPayload: PropTypes.object,
  membersCount: PropTypes.number,
  listIsLoading: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  hasOrgAccess: PropTypes.func.isRequired,
};

UserGroupManageView.defaultProps = {
  isAddMembersToGroupModalOpen: false,
  isRemoveUserGroupMemberModalOpen: false,
  item: {},
  removeMemberModalPayload: {},
  membersCount: 0,
};

const mapStateToProps = (state) => {
  const componentName = UserGroupManageView.GetComponentName();
  const viewState = getViewState(state, componentName);
  const listState = getListState(state, componentName);
  const requestState = getRequestState(state, componentName);

  return {
    isAddMembersToGroupModalOpen: getIsModalOpen(state, ADD_MEMBERS_TO_GROUP_MODAL_ID),
    isRemoveUserGroupMemberModalOpen: getIsModalOpen(state, REMOVE_MEMBER_FROM_GROUP_MODAL_ID),
    item: viewState.item,
    membersCount: listState?.pagination?.total || 0,
    listIsLoading: requestState.isLoading,
    removeMemberModalPayload: getModalPayload(state, REMOVE_MEMBER_FROM_GROUP_MODAL_ID),
    hasOrgAccess: getHasOrgAccess(state),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const UserGroupManageViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserGroupManageView);

export default withRouter(UserGroupManageViewConnected);
