import PropTypes from 'prop-types';

import { BS_STYLE, CURRENCY, CURRENCY_SYMBOL, MODAL_SIZES } from 'core/assets/js/constants';
import { countryNames } from 'core/assets/js/lib/isoCountries';
import { SITE_WIDE_BANNER_TYPE } from 'organizations/assets/js/constants';

export const accessRequirementsSpec = PropTypes.shape({
  requireAuth: PropTypes.bool,
  requireManager: PropTypes.bool,
  requireProvider: PropTypes.bool,
  requireFinController: PropTypes.bool,
  requireOrgCreator: PropTypes.bool,
  requireUserIsApprovedOrgMember: PropTypes.bool,
});


export const finalFormFieldMetaSpec = PropTypes.shape({
  error: PropTypes.string,
  initial: PropTypes.oneOfType([
    PropTypes.array, PropTypes.string, PropTypes.number, PropTypes.bool,
  ]),
  pristine: PropTypes.bool,
  submitError: PropTypes.string,
});

export const finalFormFieldLabelSpec = PropTypes.oneOfType([
  PropTypes.object,
  PropTypes.node,
  PropTypes.string],
);

export const reduxInputSpec = PropTypes.shape({
  'data-testid': PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.object, PropTypes.array, PropTypes.bool, PropTypes.number, PropTypes.string,
  ]),
});

export const paginationSpec = PropTypes.shape({
  count: PropTypes.number,
  end: PropTypes.number,
  next: PropTypes.string,
  page: PropTypes.number,
  pages: PropTypes.number,
  prev: PropTypes.string,
  start: PropTypes.number,
  total: PropTypes.number,
});

export const routerHistorySpec = PropTypes.shape({
  location: PropTypes.object,
  push: PropTypes.func,
  replace: PropTypes.func,
});

export const routerMatchSpec = PropTypes.shape({
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
});

export const routerMatchContentsSpec = PropTypes.shape({
  isExact: PropTypes.bool,
  params: PropTypes.object,
  path: PropTypes.string,
  url: PropTypes.string,
});

export const layoutPropsSpec = PropTypes.shape({
  navbarType: PropTypes.number,
});

export const modalDataObjectOnlySpec = {
  body: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string]),
  buttonLabel: PropTypes.string,
  cancelLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node,
  closeOnConfirm: PropTypes.bool,
  confirmButtonDisabled: PropTypes.bool,
  confirmLabel: PropTypes.string,
  confirmStyle: PropTypes.oneOf(Object.values(BS_STYLE)),
  'data-testid': PropTypes.string,
  heading: PropTypes.string,
  onClose: PropTypes.func,
  onClosed: PropTypes.func,
  onConfirm: PropTypes.func,
  onConfirmSuccess: PropTypes.func,
  onSecondaryAction: PropTypes.func,
  onSecondaryActionSuccess: PropTypes.func,
  open: PropTypes.bool,
  payload: PropTypes.object,
  secondaryActionLabel: PropTypes.string,
  showConfirmButton: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(MODAL_SIZES)),
};

export const worksheetAclSchema = {
  canCreate: PropTypes.bool.isRequired,
};

export const userCardAclSchema = {
  canCreateOnDemandInvoices: PropTypes.bool.isRequired,
};
export const worksheetAclSpec = PropTypes.shape(worksheetAclSchema);
export const userCardAclSpec = PropTypes.shape(userCardAclSchema);

export const countryCodeSpec = PropTypes.oneOf(Object.keys(countryNames));
export const currencySpec = PropTypes.oneOf(Object.keys(CURRENCY_SYMBOL));
export const currencyCodeSpec = PropTypes.oneOf(Object.values(CURRENCY));
export const currencySymbolSpec = PropTypes.oneOf(Object.values(CURRENCY_SYMBOL));
export const modalDataSpec = PropTypes.shape(modalDataObjectOnlySpec);

export const timelineItemSpec = PropTypes.shape({
  header: PropTypes.node,
  author: PropTypes.string,
  timestamp: PropTypes.string.isRequired,
  timestampTitle: PropTypes.string,
  content: PropTypes.node.isRequired,
  footer: PropTypes.node,
});

export const interviewPendingInvitationSpec = PropTypes.shape({
  id: PropTypes.number,
  sentTo: PropTypes.string,
});

export const orgBankAccountOptionsSpec = PropTypes.arrayOf(PropTypes.shape({
  text: PropTypes.string,
  value: PropTypes.number,
}));

export const billableAnalyticsSpec = PropTypes.shape({
  breakdownByCurrency: PropTypes.object,
  totalAmount: PropTypes.string,
  totalProviders: PropTypes.number,
  totalBillableCount: PropTypes.number,
  approvedBillableCount: PropTypes.string,
  approvedProviderCount: PropTypes.number,
  approvedSum: PropTypes.string,
  raisedBillableCount: PropTypes.string,
  raisedProviderCount: PropTypes.number,
  raisedSum: PropTypes.string,
  insufficientfundsProviderCount: PropTypes.number,
  insufficientfundsBillableCount: PropTypes.string,
  insufficientfundsSum: PropTypes.string,
  totalsByCurrency: PropTypes.object,
});

export const proFormaInvoiceAclSchema = {
  canRaise: PropTypes.bool.isRequired,
};

export const proFormaInvoiceAclSpec = PropTypes.shape(proFormaInvoiceAclSchema);

export const siteWideBannerSpec = PropTypes.shape({
  buttonLabel: PropTypes.string,
  buttonUrl: PropTypes.string,
  content: PropTypes.string.isRequired,
  dismissable: PropTypes.bool,
  id: PropTypes.number.isRequired,
  showAfterDismissed: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(SITE_WIDE_BANNER_TYPE)).isRequired,
});
