/* eslint-disable max-len */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import ServiceOrderStatus from 'finance/assets/js/components/ServiceOrderStatus.jsx';
import { financeProFormaInvoiceViewUrl } from 'finance/urls';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { RATE_UNIT } from 'rates/assets/js/constants';
import { BS_SIZE, IMG_SIZE } from 'core/assets/js/constants';
import Table from 'core/assets/js/components/Table.jsx';
import { modalOpenAC, modalCloseAC, getIsModalOpen, getModalPayload } from 'core/assets/js/ducks/modalLauncher';
import { lineBreakToBr, expandColumnComponent } from 'core/assets/js/lib/utils-jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { INVOICE_TYPE } from 'finance/assets/js/constants';
import AttachmentsList from 'core/assets/js/components/AttachmentsList.jsx';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import ProFormaInvoiceCancelForm from 'projects/assets/js/components/ProFormaInvoiceCancelForm.jsx';
import ProFormaInvoiceItemActions from 'finance/assets/js/components/ProFormaInvoiceItemActions.jsx';
import ProFormaInvoiceBreakdown from 'finance/assets/js/components/ProFormaInvoiceBreakdown.jsx';
import withModalForm from 'core/assets/js/components/withModalForm.jsx';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import ExchangeRateInfoPopOver from 'core/assets/js/components/ExchangeRateInfoPopOver.jsx';
import { DOCUMENT_QUERY_SELECTOR_ALL } from 'core/assets/js/config/settings';

const PRO_FORMA_INVOICE_CANCEL_MODAL_ID = 'pro-forma-invoice-cancel-modal';
const PRO_FORMA_INVOICE_DETAILS_MODAL_ID = 'pro-forma-invoice-details-modal';

const ProFormaInvoicesTable = ({
  list, isProFormaInvoiceView, embeddedMode, history, modalPayload, location, onItemUpdate,
  isCancelModalOpen, match: { params: { orgAlias } }, isModalOpen,
  cancelModalPayload, hasOrgAccess, isInvoiceOnDemandView, dispatch,
  location: { pathname }, ...rest
}) => {
  /**
   * prevent default react-bootstrap-table behaviour of expanding when clicking on the whole row
   * making sure that row will only expand when chevron icon is clicked.
   */
  const onTableRowClick = (e) => {
    const tRow = e?.target?.closest('tr');
    const shouldCallAction = (
      e?.target?.tagName !== 'I'
      && !e?.target?.classList.contains('td-drop-button__toggle')
      && tRow?.classList?.contains('clickable')
      && e?.target.tagName !== 'IMG'
    );

    if (shouldCallAction) {
      const objectId = [...tRow.classList]
        .find(c => c.startsWith('objectId-'))
        .replace('objectId-', '');

      history.push(
        financeProFormaInvoiceViewUrl(orgAlias, objectId),
        history.location.pathname,
        '/projects/',
      );

      e.preventDefault();
      e.stopPropagation();
    }
  };

  useEffect(() => {
    const rowSelector = '.finance-report tr td *, .finance-report tr td';
    const rows = DOCUMENT_QUERY_SELECTOR_ALL(rowSelector);

    rows.forEach(
      trow => trow.addEventListener('click', onTableRowClick),
    );

    return () => {
      rows.forEach(
        trow => trow.removeEventListener('click', onTableRowClick),
      );
    };
  });


  const handleShowProFormaInvoiceCancelModal = (serviceOrder) => {
    dispatch(modalOpenAC(PRO_FORMA_INVOICE_CANCEL_MODAL_ID, { serviceOrder }));
  };

  const handleCloseProFormaInvoiceCancelModal = () => (
    dispatch(modalCloseAC(PRO_FORMA_INVOICE_CANCEL_MODAL_ID))
  );

  const handleDetailsShow = (serviceOrder) => {
    dispatch(modalOpenAC(PRO_FORMA_INVOICE_DETAILS_MODAL_ID, serviceOrder));
  };

  const handleDetailsClose = () => {
    dispatch(modalCloseAC());
  };

  // Showing different columns on different type of views.
  let viewColumns = [
    { key: 'managerName', label: 'Manager' },
    {
      key: 'amount',
      label: 'Amount',
      width: '165px',
      columnClassName: 'text-right',
      isMoney: true,
    },
  ];
  if (isInvoiceOnDemandView) {
    viewColumns = viewColumns.filter(col => col.key !== 'managerName');
    viewColumns.splice(1, 0, {
      key: 'period', label: 'Period',
    });
  }

  const memberColumnSpec = {
    key: 'provider',
    label: 'Name',
    width: '240px',
    dataFormat: (cell, ts) => (
      <div className="d-flex align-items-center">
        <ProfilePic
          className="mr-3"
          url={ts.avatar}
          alt={ts.providerName}
          size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
        />
        {ts.providerName}
      </div>
    ),
  };

  const statusColumnSpec = {
    key: 'statusCode',
    label: 'Status',
    dataFormat: (statusCode, row) => <ServiceOrderStatus serviceOrder={row} />,
  };

  const embeddedViewColumns = [
    memberColumnSpec,
    { key: 'period', label: 'Period' },
    { key: 'amount',
      label: 'Amount',
      columnClassName: 'text-right',
      isMoney: true,
    },
    statusColumnSpec,
  ];

  const ListingViewColumns = [
    memberColumnSpec,
    { key: 'period', label: 'Period' },
    { key: 'amount',
      label: 'Amount',
      columnClassName: 'text-right',
      isMoney: true,
    },
    statusColumnSpec,
  ];

  let tableColumns;
  // Show only specific columns based on where the table is being shown.
  if (isProFormaInvoiceView) {
    tableColumns = viewColumns;
  } else if (embeddedMode) {
    tableColumns = embeddedViewColumns;
  } else {
    tableColumns = ListingViewColumns;
  }

  // If is listing, add actions column.
  if (!isProFormaInvoiceView) {
    tableColumns.push({
      columnClassName: 'actions-cell',
      dataAlign: 'right',
      key: 'actions',
      label: '',
      width: '80px',
      dataFormat: (cell, row) => (
        <ProFormaInvoiceItemActions
          history={history}
          onCancelClick={() => handleShowProFormaInvoiceCancelModal(row)}
          orgAlias={orgAlias}
          pathname={pathname}
          serviceOrder={row}
        />
      ),
    });
  }

  let tableOptions = {
    expandableRow: () => true,
    expandComponent: (serviceOrder) => (
      <div className="d-flex flex-column">
        {lineBreakToBr(serviceOrder.summary)}
        {serviceOrder.attachments && (
          <AttachmentsList
            attachments={serviceOrder.attachments}
            label="Proforma Invoice attachments"
          />
        )}
      </div>
    ),
    expandColumnOptions: {
      columnWidth: '30px',
      expandColumnVisible: true,
      expandColumnComponent,
    },
    onRowClick: onTableRowClick,
  };

  if (isProFormaInvoiceView) {
    tableOptions = {
      onRowClick: handleDetailsShow,
    };
  }

  // Format data and add custom columns
  const items = list.map(so => ({
    ...so,
    amount: (
      <>
        <NumberTpl
          value={so.proFormaInvoiceAmount}
          decimals={2}
          prefix={so.proFormaInvoiceCurrencySymbol}
        />
        {so.organizationCurrency !== so.proFormaInvoiceCurrency && (
          <ExchangeRateInfoPopOver
            className="ml-2"
            exchangeRate={so.exchangeRate}
            exchangeRateDate={so.exchangeRateUpdatedAt}
            exchangeRateService={so.proFormaInvoiceToOrganizationFxRateSource}
            sourceCurrency={so.proFormaInvoiceCurrency}
            targetAmount={so.organizationAmount}
            targetCurrency={so.organizationCurrency}
          />
        )}
      </>
    ),
    total: so.organizationAmount,
    invoiceNumbers: (so.invoices || [])
      // get all provider invoices
      .filter(i => i && [INVOICE_TYPE.INBOUND, INVOICE_TYPE.DIRECT].includes(i.type))
      .map(i => i.number)
      .join(', '),
  }));

  const CancelForm = withModalForm({
    heading: 'Cancel Proforma Invoice',
    withFooter: false,
  })(ProFormaInvoiceCancelForm);

  return (
    <>
      <Table
        {...rest}
        {...tableOptions}
        cols={tableColumns}
        isLarge={!embeddedMode}
        items={items}
        trClassName={row => `objectId-${row && row.id}`}
      />

      { modalPayload && (
        <ModalSimple
          body={(
            <ProFormaInvoiceBreakdown
              serviceOrder={modalPayload}
            />
          )}
          data-testid="pro-forma-invoices-table-breakdown"
          heading={`${modalPayload.providerName} for ${modalPayload.period}`}
          open={isModalOpen}
          onClose={handleDetailsClose}
          size={BS_SIZE.LARGE}
        />
      )}

      { cancelModalPayload && (
        <CancelForm
          isModalOpen={isCancelModalOpen}
          onClose={handleCloseProFormaInvoiceCancelModal}
          initialValues={{
            quantity: cancelModalPayload.serviceOrder.quantity,
            summary: cancelModalPayload.serviceOrder.summary,
            attachments: cancelModalPayload.serviceOrder.attachments,
          }}
          serviceOrder={cancelModalPayload.serviceOrder}
          orgAlias={orgAlias}
          onCancelSuccess={(canceledItem) => {
            handleCloseProFormaInvoiceCancelModal();
            onItemUpdate(canceledItem);
          }}
          question="Are you sure you want to cancel this Proforma Invoice?"
        />
      )}
    </>
  );
};

ProFormaInvoicesTable.propTypes = {
  cancelModalPayload: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  embeddedMode: PropTypes.bool,
  hasOrgAccess: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  isCancelModalOpen: PropTypes.bool,
  isInvoiceOnDemandView: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  isProFormaInvoiceView: PropTypes.bool,
  list: PropTypes.array,
  location: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
  modalPayload: PropTypes.object,
  onItemUpdate: PropTypes.func,
  orgAlias: PropTypes.string,
  pathname: PropTypes.string,
  rateUnit: PropTypes.oneOf(Object.values(RATE_UNIT)),
};

ProFormaInvoicesTable.defaultProps = {
  cancelModalPayload: null,
  embeddedMode: false,
  isCancelModalOpen: false,
  isInvoiceOnDemandView: false,
  isModalOpen: false,
  isProFormaInvoiceView: false,
  list: [],
  modalPayload: null,
  onItemUpdate: () => {},
  orgAlias: '',
  pathname: null,
  rateUnit: RATE_UNIT.PER_HOUR,
};

const mapStateToProps = state => ({
  cancelModalPayload: getModalPayload(state, PRO_FORMA_INVOICE_CANCEL_MODAL_ID),
  hasOrgAccess: getHasOrgAccess(state),
  isCancelModalOpen: getIsModalOpen(state, PRO_FORMA_INVOICE_CANCEL_MODAL_ID),
  isModalOpen: getIsModalOpen(state, PRO_FORMA_INVOICE_DETAILS_MODAL_ID),
  modalPayload: getModalPayload(state, PRO_FORMA_INVOICE_DETAILS_MODAL_ID),
});
const mapDispatchToProps = dispatch => ({
  dispatch,
});
const ProFormaInvoicesTableConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProFormaInvoicesTable);

export default withRouter(ProFormaInvoicesTableConnected);
