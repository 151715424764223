/* eslint-disable react/no-multi-comp */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { ButtonGroup, Card, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import ProjectInvitationContent from 'projects/assets/js/components/ProjectInvitationContent.jsx';
import ProjectTaskInvitationContent from 'projects/assets/js/components/ProjectTaskInvitationContent.jsx';
import SkillList from 'core/assets/js/components/SkillList.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TabBar from 'core/assets/js/components/TabBar.jsx';
import { CURRENCY_SYMBOL, BS_STYLE, ICON, DATE_FORMAT_DEFAULT, TABS_STYLING_VARIATION } from 'core/assets/js/constants';
import { INVITATION_ACTION, STATUS, STATUS_CLASS as INVITATION_STATUS_CLASS } from 'invitations/assets/js/constants';
import { MODAL_ID as DISCUSSION_MODAL_ID } from 'projects/assets/js/components/ProjectTaskInvitationRateAdjustmentModal.jsx';
import { PROJECT_MEMBER_STATUS, TASK_ACTION } from 'projects/assets/js/constants';
import { RATE_UNIT_FORMAT } from 'rates/assets/js/constants';
import { formatDate } from 'core/assets/js/lib/utils';
import { invitationSpec } from 'invitations/assets/js/lib/objectSpecs';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { projectViewTaskUrl } from 'projects/urls';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';

const ContentChildren = ({ invitation }) => {
  const { project, task, id: invId, documentAssignments } = invitation;
  const [activeTab, setActiveTab] = useState('task');
  const tabSpec = [{
    label: 'Task',
    key: 'task',
  }, {
    label: 'Project',
    key: 'project',
  }];

  return (
    <div className="project__details">
      <TabBar
        className="justify-content-start mb-5"
        activeKey={activeTab}
        handleTabSelect={(tabKey) => {
          setActiveTab(tabKey);
        }}
        tabSpec={tabSpec}
        variation={TABS_STYLING_VARIATION.SIMPLE}
      />
      { activeTab === 'project' && (
        <ProjectInvitationContent
          storeKey={`ProjectInvContent${invId}_${project.id}`}
          project={project}
        />
      )}
      { activeTab === 'task' && (
        <ProjectTaskInvitationContent
          storeKey={`TaskInvContent${invId}_${task.id}`}
          task={task}
          documentAssignments={documentAssignments}
        />
      )}
    </div>
  );
};

ContentChildren.propTypes = {
  invitation: invitationSpec.isRequired,
};

const ProjectTaskInvitationCardItem = ({
  dispatch,
  history,
  item: invitation,
  onOpenInvitationModal,
  onSignAndAccept,
}) => {
  const {
    project, status, sentOn, hasExpired, orgAlias, token,
    expiresAt, answeredAt, statusLabel, updatedAt, task, sender,
    taskAssignment, projectMemberStatus, documentAssignments,
  } = invitation;
  const { location } = history;
  const parsedQueryString = queryString.parse(location.search);
  const leftProject = projectMemberStatus === PROJECT_MEMBER_STATUS.LEFT;
  const removedFromProject = projectMemberStatus === PROJECT_MEMBER_STATUS.REMOVED;
  const isProjectMember = projectMemberStatus === PROJECT_MEMBER_STATUS.ACCEPTED;
  const isPending = [STATUS.PENDING, STATUS.RATE_ADJUSTMENT_REQUESTED].includes(status);
  const rateAdjustmentRequested = status === STATUS.RATE_ADJUSTMENT_REQUESTED;
  const isAccepted = status === STATUS.ACCEPTED;
  const showLink = isAccepted && !leftProject && !removedFromProject;
  const statusClass = hasExpired ? 'pending' : INVITATION_STATUS_CLASS[status];
  const sentOnFormatted = formatDate(sentOn, DATE_FORMAT_DEFAULT);

  const showRateNegotiationModal = ({ formAction, hasPendingDocuments }) => {
    if (formAction) {
      history.push({
        pathname: location.pathname,
        search: location.search,
        state: { formAction: { assignment: taskAssignment, name: formAction } },
      });
    }

    dispatch(modalOpenAC(
      DISCUSSION_MODAL_ID,
      {
        invitation,
        project,
        hasPendingDocuments,
      },
    ));
  };

  let invitationInfo;
  if (hasExpired) {
    const expiredDateFormatted = formatDate(expiresAt, DATE_FORMAT_DEFAULT);
    invitationInfo = `${statusLabel} on ${expiredDateFormatted}`;
  } else if (status === STATUS.CANCELLED) {
    const cancelledDateFormatted = formatDate(updatedAt, DATE_FORMAT_DEFAULT);
    invitationInfo = `${statusLabel} on ${cancelledDateFormatted}`;
  } else if (status === STATUS.RATE_ADJUSTMENT_REQUESTED) {
    invitationInfo = (
      <a
        onClick={showRateNegotiationModal}
      >
        {statusLabel}
      </a>
    );
  } else {
    const repliedDateFormatted = formatDate(answeredAt, DATE_FORMAT_DEFAULT);
    invitationInfo = `${statusLabel} on ${repliedDateFormatted}`;
  }
  const {
    id: projectId,
    skills,
  } = project;

  const {
    id: taskId,
    title: taskTitle,
    ownerName: taskOwnerName,
  } = task;
  const senderName = sender?.profile?.name || taskOwnerName;

  const assignmentRate = taskAssignment && (
    `${CURRENCY_SYMBOL[taskAssignment.currency]}${taskAssignment.rate}
     ${RATE_UNIT_FORMAT[taskAssignment.rate_unit].abbreviation}`
  );

  const { continueInvitation } = queryString.parse(location?.search);
  const hasPendingDocuments = documentAssignments && documentAssignments.some(da => !da.signedAt);

  const showInvitationModal = () => {
    if (isProjectMember && rateAdjustmentRequested) {
      // Open discussion board with the accept
      showRateNegotiationModal({
        hasPendingDocuments,
      });
    } else {
      // Show simple accept invitation confirmation modal
      const heading = 'Accept invitation';
      const body = (
        <div>
          {'Click on the '}
          <strong>Accept</strong>
          {' button below to start working with '}
          <strong>{senderName}</strong>
          {' on the '}
          <strong>{taskTitle}</strong>
          {' task with the suggested rate of '}
          <strong>{assignmentRate}</strong>
          .
        </div>
      );
      const confirmLabel = 'Accept';
      onOpenInvitationModal(
        heading, body, confirmLabel, 'success', token, project.id, task.id, INVITATION_ACTION.ACCEPT,
      );
    }
  };

  const onAccept = () => {
    if (hasPendingDocuments) {
      onSignAndAccept(documentAssignments);
    } else {
      showInvitationModal();
    }
  };

  useEffect(() => {
    const autoAccept = (continueInvitation === invitation.id);
    if (autoAccept) {
      onAccept();
    }
  }, []);

  const cardHeader = (
    <>
      <div className="d-flex w-100 justify-content-end">
        <div className="invitation-list-item__icon-container invitation-list-item__icon-container--task mr-4">
          <span className={ICON.TASKS} />
        </div>

        <h3>
          { showLink && (
            <Link
              className="text-dark"
              data-testid="project-task-invitation-card-item"
              to={projectViewTaskUrl(orgAlias, projectId, taskId)}
            >
              {taskTitle}
            </Link>
          )}
          { !showLink && (
            <span className="text-secondary">
              {taskTitle}
            </span>
          )}

          {assignmentRate && (
            <React.Fragment>
              <span className="discreet px-3">
                &bull;
              </span>
              <span className="discreet">
                {assignmentRate}
              </span>
            </React.Fragment>
          )}

          {rateAdjustmentRequested && (
            <StatusTag
              className="ml-0 mt-2 ml-md-3 mt-md-0"
              label={invitationInfo}
              statusClass={statusClass}
            />
          )}

          <aside>
            <div className="invitation-list-item__project-title text-dark mt-2 mb-1">
              {`Project: ${project?.title}`}
            </div>
            {`Sent on ${sentOnFormatted} by ${senderName}`}
          </aside>
        </h3>
      </div>

      <div className="flex-shrink-0 action-buttons d-flex flex-column align-items-end d-sm-block mb-4 pb-4 pb-sm-0 mb-sm-0 px-4 px-sm-0">
        { (isPending && !hasExpired)
            && (
              <div className="d-flex w-100 justify-content-end">
                { isProjectMember && (
                  <div>
                    <TDButton
                      className="text-primary font-weight-normal d-inline-block pl-0"
                      onClick={() => showRateNegotiationModal({
                        hasPendingDocuments,
                      })}
                      variant={BS_STYLE.LINK}
                    >
                      Chat with manager
                    </TDButton>
                  </div>
                )}

                <div>
                  <Dropdown as={ButtonGroup} className="td-dropdown">
                    <TDButton
                      data-testid="project-task-invitation-accept-button"
                      variant={BS_STYLE.SUCCESS}
                      onClick={onAccept}
                    >
                      <span className={`${ICON.CHECKMARK} mr-3`} />
                      {hasPendingDocuments ? 'Confirm and accept' : 'Accept'}
                    </TDButton>

                    <Dropdown.Toggle split variant={BS_STYLE.SUCCESS}>
                      <span className={ICON.CHEVRON_DOWN} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu alignRight>
                      { isProjectMember && (
                        <>
                          <Dropdown.Item
                            onClick={() => showRateNegotiationModal({
                              formAction: TASK_ACTION.PROPOSE_TASK_RATE,
                              hasPendingDocuments,
                            })}
                            variant={BS_STYLE.DEFAULT}
                          >
                            {rateAdjustmentRequested
                              ? 'Review suggested rate'
                              : 'Suggest new rate'
                            }
                          </Dropdown.Item>
                        </>
                      )}

                      <Dropdown.Item
                        variant={BS_STYLE.DANGER}
                        onClick={() => {
                          if (isProjectMember && rateAdjustmentRequested) {
                            showInvitationModal();
                            return;
                          }
                          const heading = 'Reject invitation';
                          const body = (
                            <div>
                              Are you sure you want to
                              {' '}
                              <span className="font-weight-bold">reject</span>
                              {' '}
                              the invitation you received from
                              {' '}
                              <span className="font-weight-bold">{senderName}</span>
                              {' '}
                              to join the
                              {' '}
                              <span className="font-weight-bold">{taskTitle}</span>
                              {' '}
                              project?
                            </div>
                          );
                          const confirmLabel = 'Reject';
                          onOpenInvitationModal(
                            heading, body, confirmLabel, 'danger', token, project.id, task.id, INVITATION_ACTION.REJECT,
                          );
                        }}
                      >
                        <span className="text-danger">
                          Reject
                        </span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            )
        }
        {(!isPending || hasExpired) && (
          <StatusTag
            className="ml-2"
            label={invitationInfo}
            statusClass={statusClass}
          />
        )}
        {(leftProject || removedFromProject) && (
          <>
            <div className="invitation-list-item__warning warning d-flex align-items-center text-warning p-2 mt-2">
              <span className={`${ICON.WARNING} mr-3`} />
              Access to this task has been revoked, because
              {removedFromProject && ' the project manager removed you from '}
              {leftProject && ' you left '}
              the project
            </div>
          </>
        )}
      </div>
    </>
  );

  const headingChildren = (
    <SkillList
      skills={skills}
      invertedColors={invitation === null}
      inline
      maxShownItems={10}
      modalId={`project-skills-${project.id}`}
    />
  );

  return (
    <Card className="invitation-list-item">
      <Card.Header className="d-flex flex-column flex-sm-row mx-0 px-4">
        {cardHeader}
      </Card.Header>

      <Card.Body className="mx-4">
        <AreaCollapsible
          contentChildren={(<ContentChildren invitation={invitation} />)}
          headingChildren={headingChildren}
          initiallyExpanded={
            (
              parsedQueryString.expand === invitation.id.toString()
              || parsedQueryString.expand === invitation.taskId.toString()
            )}
          toggleAfterCard
          toggleElementPlacement="right"
        />
      </Card.Body>
    </Card>
  );
};


ProjectTaskInvitationCardItem.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  item: invitationSpec.isRequired,
  onOpenInvitationModal: PropTypes.func.isRequired,
  onSignAndAccept: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({ dispatch });

const ProjectTaskInvitationCardItemConnected = connect(
  null, mapDispatchToProps,
)(ProjectTaskInvitationCardItem);

export default withRouter(ProjectTaskInvitationCardItemConnected);
