import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import StatusColFormat from 'finance/assets/js/components/StatusColFormat.jsx';
import { expenseSpec, proFormaInvoiceSpec, worksheetSpec } from 'finance/assets/js/lib/objectSpecs';
import {
  SERVICE_ORDER_STATUS,
  SERVICE_ORDER_STATUS_CLASS,
  SERVICE_ORDER_STATUS_LABEL,
  SERVICE_ORDER_TYPE_LABEL,
} from 'projects/assets/js/constants';

const ServiceOrderStatus = ({ serviceOrder }) => {
  const hasOrgAccess = useSelector(getHasOrgAccess);
  const isManager = hasOrgAccess({ requireManager: true });

  const {
    invoicedAt, isAutoApproved, isDelayed, paidAt, processAt, statusCode, type,
  } = serviceOrder;
  const isApproved = statusCode === SERVICE_ORDER_STATUS.APPROVED;

  let statusLabel = SERVICE_ORDER_STATUS_LABEL[statusCode];
  let title;
  let statusClass = SERVICE_ORDER_STATUS_CLASS[statusCode];
  if (isApproved) {
    if (paidAt) {
      statusLabel = 'Paid';
    } else if (invoicedAt) {
      statusLabel = 'Invoiced';
    } else if (isAutoApproved && isManager) {
      statusLabel = 'Auto approved';
      statusClass = 'approved-auto';
      title = 'Auto approved by TalentDesk.io';
    } else if (isDelayed) {
      statusLabel = 'Approved';
      statusClass = 'delayed';
      title = `The ${SERVICE_ORDER_TYPE_LABEL[type]} will not be processed prior to ${processAt}`;
    }
  }

  return <StatusColFormat statusLabel={statusLabel} statusClass={statusClass} title={title} />;
};

ServiceOrderStatus.propTypes = {
  serviceOrder: PropTypes.oneOfType([worksheetSpec, expenseSpec, proFormaInvoiceSpec]).isRequired,
};

export default ServiceOrderStatus;
