import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isNumber } from 'lodash';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import CountryFlagIcon from 'core/assets/js/components/CountryFlagIcon.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import OnboardingProgressBar from 'core/assets/js/components/OnboardingProgressBar.jsx';
import OnboardingStatus from 'people/assets/js/components/OnboardingStatus.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import RateAmount from 'rates/assets/js/components/RateAmount.jsx';
import RatingScore from 'core/assets/js/components/RatingScore.jsx';
import SkillList from 'core/assets/js/components/SkillList.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import { RATE_ADJUSTMENT_MODAL_ID } from 'people/assets/js/PeopleListView.jsx';
import { USER_TYPE, BS_TOOLTIP_PLACEMENT } from 'core/assets/js/constants';
import { countryNames } from 'core/assets/js/lib/isoCountries';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { orgUserProfileUrl } from 'people/urls';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';

const TeamMembersGridListItem = ({
  ActionsDropDownComponent,
  item,
  item: {
    aorEnabled,
    currencySymbol,
    isEmployee,
    numReviews,
    onboardingProgress,
    rate,
    rateAdjustmentActions,
    rating,
    rateId,
    rateUnit,
    status,
    user: {
      id: userId,
      profile,
      profile: { name, avatar,  jobTitle, skills: profileSkills },
    },
    userRole: { isAnyManager },
  },
  showProgress,
}) => {
  const dispatch = useDispatch();
  const { params: { orgAlias }  } = useRouteMatch();
  const [skillsModalOpen, setSkillsModalOpen] = useState(false);
  const [skillsModalPayload, setSkillsModalPayload] = useState([]);
  const userType = isAnyManager ? USER_TYPE.MANAGER : USER_TYPE.PROVIDER;
  const hasCompleteRate = !!(currencySymbol && parseFloat(rate) > 0.00);

  // Rate display
  let userRate = '';
  if (hasCompleteRate) {
    userRate = (
      <span>
        <RateAmount
          amount={rate}
          unit={rateUnit}
          symbol={currencySymbol}
        />
      </span>
    );
  }

  return (
    <li
      className="team-grid-list-item text-center h-100"
    >
      <div className="team-grid-list-item__container d-flex flex-column bg-white bg-white p-4">
        <div className="team-grid-list-item__indicators font-weight-bolder text-uppercase d-flex flex-column align-items-start">
          <div>{isEmployee ? 'Employee' : 'Contractor'}</div>
          {aorEnabled && <div>AOR</div>}
        </div>

        {<ActionsDropDownComponent item={item} />}

        <div className="mt-2">
          <Link
            className="d-inline-block mb-5"
            to={orgUserProfileUrl(
              orgAlias,
              userType,
              userId,
            )}
            title={name}
          >
            <ProfilePic
              alt={name}
              url={avatar}
              size={[80, 80]}
            />
          </Link>
        </div>

        <Link
          className="text-dark font-bigger font-weight-bolder"
          to={orgUserProfileUrl(
            orgAlias,
            userType,
            userId,
          )}
          title={name}
        >
          {name}
        </Link>
        <div className="my-2">
          {jobTitle || (
            <span className="discreet">
              -
            </span>
          )}
        </div>

        <div className="w-100">
          <div className="d-inline-flex align-items-center">
            {profile.address_components?.country_code && (
              <CountryFlagIcon
                countryISOCode={profile.address_components.country_code}
              />
            )}
            <span className="ml-2 pl-1 discreet smaller">
              {countryNames[profile.address_components?.country_code]}
            </span>
          </div>
        </div>

        <OnboardingStatus centerAligned status={status} />

        <hr className="my-4 mx-n4" />

        {showProgress && isNumber(onboardingProgress) && (
          <OnboardingProgressBar
            className="mb-4"
            percentage={onboardingProgress}
            showLabel={false}
          />
        )}

        <div className="mt-2 mb-n3">
          <SkillList
            emptyTxt=""
            maxShownItems={2}
            skills={profileSkills && profileSkills.slice(0, 2)}
            alignTags="left"
            onShowMore={
              profileSkills?.length > 2
                ? () => {
                  setSkillsModalPayload(profileSkills);
                  setSkillsModalOpen(true);
                } : null
            }
          />
        </div>

        <div className="d-flex mt-auto align-items-center">
          <RatingScore
            rating={rating}
            numReviews={numReviews}
            classNames="font-smaller mt-4"
          />

          <div className="ml-auto">
            { rateAdjustmentActions && !rateAdjustmentActions.hasPending && hasCompleteRate && (
              <StatusTag
                className="mt-4"
                statusClass="default"
                hideTitle
                hideDot
                label={userRate}
              />
            )}

            { rateAdjustmentActions && rateAdjustmentActions.hasPending && rate && (
              <TDElementWithTooltip
                el={(
                  <span className="py-3">
                    <StatusTag
                      hideTitle
                      className="mt-4 mr-0 cursor-pointer"
                      statusClass="pending"
                      onClick={() => {
                        dispatch(modalOpenAC(RATE_ADJUSTMENT_MODAL_ID, {
                          userCardId: userId,
                          rateId,
                        }));
                      }}
                      label={userRate}
                    />
                  </span>
                )}
                placement={BS_TOOLTIP_PLACEMENT.TOP}
                tooltipMsg="Pending Adjustment"
              />
            )}
          </div>
        </div>
      </div>

      <ModalSimple
        heading="User skills"
        body={(
          <SkillList
            skills={skillsModalPayload}
            alignTags="left"
          />
        )}
        onClose={() => setSkillsModalOpen(false)}
        open={skillsModalOpen}
      />
    </li>
  );
};

TeamMembersGridListItem.propTypes = {
  ActionsDropDownComponent: PropTypes.func.isRequired,
  item: userCardSpec.isRequired,
  showProgress: PropTypes.bool.isRequired,
};

export default TeamMembersGridListItem;
