import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

import { DOCUMENT_GET_ELEMENT_BY_ID } from 'core/assets/js/config/settings';
import { fetchListDS, getListState, listIsLoadingAC } from 'core/assets/js/ducks/list';
import { fetchViewDS, getViewState, viewIsLoadingAC } from 'core/assets/js/ducks/view';

/**
 * A React hook for fetching data and returning the response
 *
 * @param {Object} options
 * @param {Any[]} [changesArray] - Array of entities to execute data call if any of them change
 * @param {String} componentName - storeKey for the data in redux
 * @param {Function} [dataServiceFn] - Data service function to call
 * @param {list|view} [duck]
 * @param {String} [paginationKey] - Key to get pagiantion object from in the query params
 * @param {Function} [selectorFn] - Function for selecting stored data
  * @param {Object} [queryStringParams] - Constant values to have in the query string
 * @param {String} [url] - the URL to call with the data service function
 * @returns {Object}
 */
export const fetchDataHook = ({ // eslint-disable-line import/prefer-default-export
  changesArray = [],
  componentName,
  dataServiceFn,
  duck = 'view',
  paginationKey,
  selectorFn,
  queryStringParams,
  url,
}) => {
  if (!componentName) {
    throw new Error('componentName is required');
  }

  const isView = duck === 'view';

  const dispatch = useDispatch();

  let dsFn = dataServiceFn;
  let isLoadingAC = null;
  if (!dsFn) {
    if (!url) {
      throw new Error('url is required');
    }
    dsFn = isView ? fetchViewDS : fetchListDS;
    isLoadingAC = isView ? viewIsLoadingAC : listIsLoadingAC;
  }

  const location = useLocation();

  const finalChangesArray = [...changesArray, componentName];
  if (!isView) {
    finalChangesArray.push(location.search);
  }

  useEffect(() => {
    let urlSuffix = '';
    if (!isView) {
      let pagination = null;
      if (paginationKey) {
        const parsedQS = qs.parse(location.search);
        if (parsedQS[paginationKey]) {
          try {
            pagination = JSON.parse(parsedQS[paginationKey]);
          } catch (_) {} // eslint-disable-line no-empty
        }
      }
      if (pagination) {
        urlSuffix = `?${qs.stringify(pagination)}`;
      } else {
        urlSuffix = location.search;
      }
    }
    if (queryStringParams && Object.keys(queryStringParams).length > 0) {
      if (!/^\?/.test(urlSuffix)) {
        urlSuffix += '?';
      }
      urlSuffix += qs.stringify(queryStringParams);
    }
    if (isLoadingAC) {
      dispatch(isLoadingAC(true, componentName));
    }
    dispatch(dsFn({ componentName, url: dataServiceFn ? undefined : `${url}${urlSuffix}` }))
      .then(() => {
        if (isLoadingAC) {
          dispatch(isLoadingAC(false, componentName));
        }
      });
  }, finalChangesArray);

  let response = null;
  if (selectorFn) {
    response = useSelector(selectorFn);
  } else {
    response = useSelector(state => (isView ? getViewState : getListState)(state, componentName));
  }

  return response;
};

/**
 * Finds the hubspot chat iframe container and hides it, until the component unmounts
 *
 * @returns {Null}
 */
export const hideHubSpotChat = () => {
  const ref = useRef();

  const chatIframeContainerId = 'hubspot-messages-iframe-container';
  const getIframeContainer = async (attempts = 0) => {
    const el = DOCUMENT_GET_ELEMENT_BY_ID(chatIframeContainerId);
    if (el) {
      return el;
    }
    if (attempts === 10) {
      // Give up
      return null;
    }
    // Wait 500ms and try again
    await new Promise(resolve => setTimeout(resolve, 500));
    return getIframeContainer(attempts + 1);
  };

  useEffect(() => {
    getIframeContainer().then(el => {
      if (el) {
        el.classList.add('hide');
        ref.current = el;
      }
    });

    return () => {
      if (ref.current) {
        ref.current.classList.remove('hide');
        ref.current = null;
      }
    };
  }, []);

  return null;
};
