import { pick } from 'lodash';
import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { useParams } from 'react-router-dom';

import { downloadFile } from 'accounts/assets/js/lib/helpers';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import UserGroupFormSkeleton from 'people/assets/js/components/skeletons/UserGroupFormSkeleton.jsx';
import UserGroupForm from 'people/assets/js/components/UserGroupForm.jsx';
import { CREATE_GROUP_TYPE } from 'people/assets/js/constants';
import { userGroupApiUrl, userGroupsUrl } from 'people/urls';
import { SKILL_IDS_MATCH } from 'skills/assets/js/constants';

const UserGroupEditView = () => {
  const params = useParams();

  const { hasLoaded, item } = fetchDataHook({
    componentName: UserGroupEditView.GetComponentName(params), url: userGroupApiUrl(params),
  });

  const [avatarFile, setAvatarFile] = useState(null);
  const [loadedAvatarFile, setLoadedAvatarFile] = useState(false);

  useEffect(() => {
    if (!item) {
      return;
    }
    if (!item.avatarFileId) {
      setLoadedAvatarFile(true);
      return;
    }
    downloadFile(item.avatarFileId)
      .then(file => {
        setAvatarFile(file);
        setLoadedAvatarFile(true);
      })
      .catch(e => {
        toastr.error('Oh Snap!', e._error || e.message);
      });
  }, [item?.avatarFileId]);

  if (!hasLoaded || !loadedAvatarFile) {
    return <UserGroupFormSkeleton />;
  }

  const initialValues = pick(item, 'name', 'description');
  if (item.isDynamic) {
    initialValues.languages = item.languages.map(l => l.id);
    initialValues.skills = item.skills.map(s => s.id);
    initialValues.skillsMatch = item.allSkills ? SKILL_IDS_MATCH.AND : SKILL_IDS_MATCH.OR;
  }
  if (avatarFile) {
    initialValues.avatar = avatarFile;
  }

  return (
    <>
      <ContentHeader
        breadcrumbs={[
          { title: 'Groups', url: userGroupsUrl(params.orgAlias) },
          { title: 'Edit group', url: null },
        ]}
      />
      <div className="page page--people">
        <div className="container">
          <UserGroupForm
            initialValues={initialValues}
            type={item.isDynamic ? CREATE_GROUP_TYPE.DYNAMIC : CREATE_GROUP_TYPE.STANDARD}
          />
        </div>
      </div>
    </>
  );
};

UserGroupEditView.GetComponentName = () => 'UserGroupEditView';

export default UserGroupEditView;
