// urls

import {
  orgPeopleDeactivateUrl,
  orgPeopleDiscoverUrl,
  orgPeopleDiscoverDetailUrl,
  orgPeopleDiscoverFreelancersUrl,
  orgPeopleInviteesUrl,
  orgPeopleInviteUrl,
  orgPeopleMessagingUrl,
  orgPeopleListUrl,
  orgUserProfileUrl,
  userGroupCreateUrl,
  userGroupEditUrl,
  userGroupManageUrl,
  userGroupsUrl,
} from 'people/urls';
import { LAYOUTS, PROFILE_TABS } from 'core/assets/js/constants';
import { DISCOVER_TALENTS_TABS } from 'people/assets/js/constants';

// Views
import DeactivateUserView from 'people/assets/js/DeactivateUserView.jsx';
import PeopleListView from 'people/assets/js/PeopleListView.jsx';
import DiscoverPeopleDetailView from 'people/assets/js/DiscoverPeopleDetailView.jsx';
import DiscoverPeopleView from 'people/assets/js/DiscoverPeopleView.jsx';
import DiscoverPeopleFreelancersView from 'people/assets/js/DiscoverPeopleFreelancersView.jsx';
import UserGroupsListView from 'people/assets/js/UserGroupsListView.jsx';
import UserGroupCreateView from 'people/assets/js/UserGroupCreateView.jsx';
import UserGroupEditView from 'people/assets/js/UserGroupEditView.jsx';
import UserGroupManageView from 'people/assets/js/UserGroupManageView.jsx';
import ProfileView from 'people/assets/js/ProfileView.jsx';
import InvitePeopleView from 'people/assets/js/InvitePeopleView.jsx';
import OrgInviteeListView from 'people/assets/js/OrgInviteeListView.jsx';
import OrgMessagingView from 'people/assets/js/OrgMessagingView.jsx';

const peopleRouteConfig = [
  {
    path: orgPeopleListUrl(':orgAlias', ':peopleType(managers)'),
    component: PeopleListView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: orgPeopleListUrl(':orgAlias', ':peopleType(providers)'),
    component: PeopleListView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: orgPeopleInviteesUrl(':orgAlias'),
    component: OrgInviteeListView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    path: orgPeopleMessagingUrl(':orgAlias'),
    component: OrgMessagingView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
  },
  {
    path: orgPeopleDiscoverUrl(
      ':orgAlias',
      `:tab(${Object.values(DISCOVER_TALENTS_TABS).join('|')})?`,
    ),
    component: DiscoverPeopleView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    component: DiscoverPeopleFreelancersView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: orgPeopleDiscoverFreelancersUrl(':orgAlias'),
    requireManager: true,
  },
  {
    component: DiscoverPeopleDetailView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: orgPeopleDiscoverDetailUrl(':orgAlias', ':talentId'),
    requireManager: true,
  },
  {
    path: userGroupsUrl(':orgAlias'),
    component: UserGroupsListView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    path: userGroupCreateUrl(':orgAlias', ':type'),
    component: UserGroupCreateView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    path: userGroupEditUrl(':orgAlias', ':userGroupId'),
    component: UserGroupEditView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    path: userGroupManageUrl(':orgAlias', ':userGroupId'),
    component: UserGroupManageView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    path: orgUserProfileUrl(
      ':orgAlias',
      ':userType', ':userId',
      `:tab(${Object.values(PROFILE_TABS).join('|')})?`,
    ),
    component: ProfileView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
  {
    path: orgPeopleInviteUrl(':orgAlias', ':peopleType(providers|managers)'),
    component: InvitePeopleView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireManager: true,
  },
  {
    path: orgPeopleDeactivateUrl(':orgAlias', ':peopleType(providers|managers)', ':userId'),
    component: DeactivateUserView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    requireUserIsApprovedOrgMember: true,
  },
];

export default peopleRouteConfig;
