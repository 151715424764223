import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';

import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import {
  addBackUrlIfLocationContains, financeProFormaInvoiceViewUrl,
  financeProFormaInvoiceEditUrl, financeProFormaInvoiceOnDemandInvoiceUrl,
} from 'finance/urls';
import { SERVICE_ORDER_TYPE } from 'projects/assets/js/constants';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { ON_DEMAND_INVOICE_STEP } from 'finance/assets/js/constants';
import OnDemandInvoiceFuturePeriodModal from 'finance/assets/js/components/invoicing/OnDemandInvoiceFuturePeriodModal.jsx';

const ProFormaInvoiceItemActions = ({
  history, onCancelClick, orgAlias, pathname, serviceOrder,
}) => {
  const showEditButton = serviceOrder?.allowedActions?.canBeEdited;
  const showCancelButton = serviceOrder?.allowedActions?.canBeCancelled;
  const canInvoiceProFormaInvoiceOnDemand = (
    serviceOrder?.allowedActions?.canInvoiceProFormaInvoiceOnDemand
  );
  const canCreateOnDemandInvoices = serviceOrder?.provider?.userCard
    ?.allowedActions.canCreateOnDemandInvoices;
  const shouldInvoiceInLaterDate = serviceOrder?.allowedActions
    ?.shouldInvoiceInLaterDate;
  const showCreateOnDemandInvoiceButton = (
    canInvoiceProFormaInvoiceOnDemand
    || (shouldInvoiceInLaterDate && canCreateOnDemandInvoices)
  );
  const [isRaiseInvoiceModalOpen, openRaiseInvoiceModal] = useState(false);
  const billableReference = String(serviceOrder?.id);
  const handleRaiseInvoiceClick = () => {
    if (shouldInvoiceInLaterDate) {
      openRaiseInvoiceModal(true);
      return;
    }
    history.push(financeProFormaInvoiceOnDemandInvoiceUrl(
      orgAlias, serviceOrder.id, ON_DEMAND_INVOICE_STEP.INIT,
    ));
  };
  return (
    <TDDropButton data-testid="pro-forma-invoices-table-actions" stopPropagation>
      <Dropdown.Item
        eventKey="view-serviceOrder"
        data-testid="view-serviceOrder"
        onClick={(e) => {
          e.stopPropagation();
          history.push(
            addBackUrlIfLocationContains(
              financeProFormaInvoiceViewUrl(orgAlias, serviceOrder.id),
              history.location.pathname,
              '/projects/',
            ),
          );
        }}
      >
        View Proforma Invoice
      </Dropdown.Item>

      { showEditButton && (
        <Dropdown.Item
          eventKey="edit-pro-forma-invoice"
          data-testid="edit-pro-forma-invoice"
          onClick={(e) => {
            e.stopPropagation();
            history.push(financeProFormaInvoiceEditUrl(orgAlias, serviceOrder.id, pathname));
          }}
        >
          <span>Edit Proforma Invoice</span>
        </Dropdown.Item>
      )}

      { showCancelButton && (
        <Dropdown.Item
          eventKey="cancel-pro-forma-invoice"
          data-testid="cancel-pro-forma-invoice"
          onClick={onCancelClick}
        >
          <span className="text-secondary">Cancel Proforma Invoice</span>
        </Dropdown.Item>
      )}
      { showCreateOnDemandInvoiceButton && (
        <Dropdown.Item
          eventKey="raise-invoice"
          data-testid="raise-invoice"
          onClick={(e) => {
            e.stopPropagation();
            handleRaiseInvoiceClick();
          }}
        >
          <span className="text-secondary">Raise invoice</span>
        </Dropdown.Item>
      )}
      <OnDemandInvoiceFuturePeriodModal
        open={isRaiseInvoiceModalOpen}
        onClose={() => openRaiseInvoiceModal(false)}
        billableProcessDate={serviceOrder.processAt}
        billableType={SERVICE_ORDER_TYPE.PROFORMA_INVOICE}
        billableReference={billableReference}
      />
    </TDDropButton>
  );
};

ProFormaInvoiceItemActions.propTypes = {
  history: routerHistorySpec.isRequired,
  serviceOrder: PropTypes.object.isRequired,
  orgAlias: PropTypes.string.isRequired,
  pathname: PropTypes.string,
  onCancelClick: PropTypes.func.isRequired,
};

ProFormaInvoiceItemActions.defaultProps = {
  pathname: null,
};

export default ProFormaInvoiceItemActions;
