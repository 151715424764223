import { isNumber } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown, Card } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import RatingScore from 'core/assets/js/components/RatingScore.jsx';
import { modalOpenAC, getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { PEOPLE_TYPE } from 'people/assets/js/constants';
import { calculateTimeOffWarningMessages } from 'people/assets/js/lib/utils';
import { providerUserCardSpec } from 'organizations/assets/js/lib/objectSpecs';
import {
  ICON, USER_CARD_STATUS, USER_TYPE, BS_TOOLTIP_PLACEMENT, POPPER_PLACEMENT, IMG_SIZE,
} from 'core/assets/js/constants';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import TDElementWithTooltip from 'core/assets/js/components/TDElementWithTooltip.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import { submissionUrl } from 'interviews/urls';
import { orgPeopleDeactivateUrl, orgUserProfileUrl } from 'people/urls';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import RateAdjustmentModal from 'rates/assets/js/components/RateAdjustmentModal.jsx';
import EmploymentStatusIndicator from 'people/assets/js/components/EmploymentStatusIndicator.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import SkillList from 'core/assets/js/components/SkillList.jsx';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import ContactDetailsCard from 'people/assets/js/components/ContactDetailsCard.jsx';
import OnboardingStatus from 'people/assets/js/components/OnboardingStatus.jsx';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import RateAmount from 'rates/assets/js/components/RateAmount.jsx';
import UserToggleActivationModal, { getModalId as getActivationModalId } from 'people/assets/js/components/UserToggleActivationModal.jsx';
import PromoteProviderModal, { getModalId as getPromoteModalId } from 'people/assets/js/components/PromoteProviderModal.jsx';
import ToggleEmploymentModal, { getModalId as getEmploymentModalId } from 'people/assets/js/components/ToggleEmploymentModal.jsx';
import OnboardingProgressBar from 'core/assets/js/components/OnboardingProgressBar.jsx';

const LocationText = ({ addressComponents }) => {
  const location = addressComponents.city
    ? `${addressComponents.city}, ${addressComponents.country}`
    : addressComponents.country;
  return (
    <span className="truncate" title={location}>
      {location}
    </span>
  );
};

LocationText.propTypes = {
  addressComponents: PropTypes.object.isRequired,
};

// eslint-disable-next-line react/no-multi-comp
class ProviderCard extends React.Component {
  constructor(props) {
    super(props);

    this.rateAdjustmentModalId = `rate-adjustment-${props.item.id}`;
    this.openRateAdjustmentModal = this.openRateAdjustmentModal.bind(this);
    this.handleOpenContactModal = this.handleOpenContactModal.bind(this);
    this.handleCloseModals = this.handleCloseModals.bind(this);
    this.handleOpenToggleActivationModal = this.handleOpenToggleActivationModal.bind(this);
    this.handleOpenPromoteToManagerModal = this.handleOpenPromoteToManagerModal.bind(this);
    this.handleOpenToggleEmploymentModal = this.handleOpenToggleEmploymentModal.bind(this);
  }

  openRateAdjustmentModal() {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(this.rateAdjustmentModalId));
  }

  handleOpenContactModal() {
    const { dispatch, contactProviderModalId } = this.props;
    dispatch(modalOpenAC(contactProviderModalId));
  }

  handleCloseModals() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  handleOpenToggleActivationModal() {
    const { dispatch, history, item, orgAlias } = this.props;
    if (item.allowedActions?.canDeactivate && item.hasOutstandingItems) {
      history.push(orgPeopleDeactivateUrl(orgAlias, PEOPLE_TYPE.PROVIDERS, item.user.id));
      return;
    }
    dispatch(modalOpenAC(getActivationModalId(item.user.id)));
  }

  async handleOpenPromoteToManagerModal() {
    const { dispatch, item } = this.props;
    dispatch(modalOpenAC(getPromoteModalId(item.user.id)));
  }

  handleOpenToggleEmploymentModal() {
    const { dispatch, item } = this.props;
    dispatch(modalOpenAC(getEmploymentModalId(item.user.id)));
  }

  render() {
    const {
      availabilityFilter,
      history,
      isContactModalOpen,
      isLastItem,
      item,
      item: {
        allowedActions: {
          canReactivate,
          canChangeRoleToManager,
          canDeactivate,
          canChangeToContractor,
          canChangeToEmployee,
        } = {},
        budget,
        currencySymbol,
        hasUnprocessedServiceOrders,
        isEmployee,
        numReviews,
        onboardingProgress,
        rate,
        rateAdjustmentActions,
        rateUnit,
        rating,
        status,
        submissions,
        user: { email, id, nextTimeoff, profile, profile: { firstName, skills } },
      },
      linkTarget,
      listViewComponentName,
      match,
      onUpdated,
      orgAlias,
      showAvailabilityMessages,
      showContactDetails,
      showProgress,
      warningMessage,
      withActions,
    } = this.props;

    const profileUrl = orgUserProfileUrl(orgAlias, USER_TYPE.PROVIDER, id);
    const submission = submissions && submissions.length > 0 ? submissions[0] : null;
    const editUrl = submission ? submissionUrl(orgAlias, submission.id, '', match.url) : '';
    const hasCompleteRate = !!(currencySymbol && parseFloat(rate) > 0.00);
    const hasCompleteBudget = currencySymbol && parseFloat(budget) > 0.00;

    // Rate display
    let userRate = '';
    if (hasCompleteRate) {
      userRate = (
        <span>
          <RateAmount
            amount={rate}
            unit={rateUnit}
            symbol={currencySymbol}
          />
        </span>
      );
    }

    // Rate adjustment actions
    let rateAdjCanResponderAct = false;
    let rateAdjCanBeInteracted = false;
    const isApproved = status === USER_CARD_STATUS.APPROVED;

    if (rateAdjustmentActions) {
      if (rateAdjustmentActions.canRespond || rateAdjustmentActions.canCancel) {
        rateAdjCanResponderAct = true;
      }

      if (isApproved && (rateAdjustmentActions.canInitiate || rateAdjCanResponderAct)) {
        rateAdjCanBeInteracted = true;
      }
    }

    let showSubmissionLink = false;
    let submissionLinkTxt = null;

    if (submission && submission.allowedActions) {
      showSubmissionLink = submission.allowedActions.canView;
      submissionLinkTxt = `${submission.allowedActions.canReview ? 'Review' : 'View'} onboarding submission`;
    }

    const {
      isOnTimeOff, isLeavingSoon, availabilityMessage, messageTextClassName,
    } = calculateTimeOffWarningMessages({ nextTimeoff, firstName, availabilityFilter });

    const warning = warningMessage(item);

    const canToggleActivation = listViewComponentName && (canReactivate || canDeactivate);

    const canToggleEmploymentStatus = canChangeToContractor || canChangeToEmployee;

    return (
      <Card className="user-item">
        <Card.Body>
          <div className="row d-flex align-items-center position-relative">
            <div className="user-item__basic-info col-12 col-md-auto d-flex">
              <Link
                to={profileUrl}
                target={linkTarget === '_self' ? undefined : linkTarget}
                onClick={e => e.stopPropagation()}
                title={profile.name}
              >
                <ProfilePic
                  url={profile.avatar}
                  alt={profile.name}
                  size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                />
              </Link>

              <div className="col-10 col-md-auto pl-3 d-flex justify-content-center flex-column">
                <Link
                  data-testid="provider-card-title"
                  to={profileUrl}
                  title={profile.name}
                  target={linkTarget === '_self' ? undefined : linkTarget}
                  onClick={e => e.stopPropagation()}
                  className="user-item__title truncate"
                >
                  { profile.name }
                  <EmploymentStatusIndicator
                    userType={USER_TYPE.PROVIDER}
                    isEmployee={isEmployee}
                  />
                </Link>

                <div className="user-item__extra discreet col-auto px-0">
                  { profile.jobTitle && (
                    <span className="truncate" title={profile.jobTitle}>
                      {profile.jobTitle}
                    </span>
                  )}

                  { profile.address_components && (
                    <LocationText addressComponents={profile.address_components} />
                  )}

                  { showContactDetails && profile && (
                    <a className="hover-effect text-secondary" onClick={() => this.handleOpenContactModal()}>
                      contact
                    </a>
                  )}
                </div>
              </div>
            </div>

            <div className="col-12 col-md-auto mt-3 mt-md-0 user-item__details ml-0 ml-md-auto">
              {showProgress && isNumber(onboardingProgress) && (
                <OnboardingProgressBar
                  barClassName="mt-1"
                  className="d-inline-block"
                  label="Account progress"
                  percentage={onboardingProgress}
                  alignRight
                />
              )}

              <RatingScore rating={rating} numReviews={numReviews} classNames="user-item__rating" />

              { rateAdjustmentActions && rateAdjustmentActions.hasPending && rate && (
                <TDElementWithTooltip
                  el={(
                    <span>
                      <StatusTag
                        hideTitle
                        statusClass="pending"
                        onClick={this.openRateAdjustmentModal}
                        label={userRate}
                      />
                    </span>
                  )}
                  placement={BS_TOOLTIP_PLACEMENT.TOP}
                  tooltipMsg="Pending Adjustment"
                />
              )}

              { hasCompleteBudget && (
                <span className="user-item__budget">
                  <i key="coins-icon" className={ICON.COINS} />
                  <NumberTpl prefix={currencySymbol} value={budget} />
                </span>
              )}

              { rateAdjustmentActions && !rateAdjustmentActions.hasPending && hasCompleteRate && (
                <StatusTag
                  statusClass="default"
                  hideTitle
                  hideDot
                  label={userRate}
                />
              )}

              {/* Rate Adjustment modal form */}
              {rateAdjCanBeInteracted && (
                <RateAdjustmentModal
                  rateId={item.rateId}
                  orgAlias={orgAlias}
                  modalId={this.rateAdjustmentModalId}
                  onSuccess={(updatedRate) => {
                    const userCardId = item.id;
                    onUpdated(userCardId, updatedRate);
                  }}
                />
              )}
            </div>
          </div>

          <OnboardingStatus status={status} showBackgroundLine={false} hideDeactivationReason />
        </Card.Body>

        <Card.Footer className="bg-white d-flex flex-nowrap align-items-center">
          { showAvailabilityMessages && (isLeavingSoon || isOnTimeOff) && (
            <div className="border-right my-n1 py-1 mr-3 pr-1">
              <TDElementWithTooltip
                tooltipMsg={availabilityMessage}
                placement={POPPER_PLACEMENT.AUTO}
              >
                <i className={`${ICON.CALENDAR_ALT} ${messageTextClassName} ml-n3 py-2 px-3`} />
              </TDElementWithTooltip>
            </div>
          )}

          <div className="col-10 pl-0">
            { skills && (
              <SkillList
                skills={skills}
                inline
                maxShownItems={7}
                modalId={`user-skills-${id}`}
              />
            )}
          </div>

          { withActions && (
            <TDDropButton
              className="ml-auto"
              stopPropagation
              placement={isLastItem ? POPPER_PLACEMENT.TOP_END : POPPER_PLACEMENT.BOTTOM_END}
            >
              {showSubmissionLink && (
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => history.push(editUrl)}
                >
                  {submissionLinkTxt}
                </Dropdown.Item>
              )}
              {rateAdjCanBeInteracted && (
                <Dropdown.Item
                  eventKey="2"
                  onClick={this.openRateAdjustmentModal}
                >
                  {rateAdjustmentActions.canInitiate
                    ? 'Request rate adjustment'
                    : 'Pending rate adjustment'
                  }
                </Dropdown.Item>
              )}
              <Dropdown.Item
                eventKey="contact"
                onClick={this.handleOpenContactModal}
              >
                Contact
              </Dropdown.Item>
              {canChangeRoleToManager && (
                <Dropdown.Item eventKey="promote" onClick={this.handleOpenPromoteToManagerModal}>
                  Convert to manager
                </Dropdown.Item>
              )}
              {canToggleEmploymentStatus && (
                <Dropdown.Item eventKey="promote" onClick={this.handleOpenToggleEmploymentModal}>
                  Convert to
                  {` ${canChangeToContractor ? 'contractor' : 'employee'}`}
                </Dropdown.Item>
              )}
              {canToggleActivation && (
                <>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    className={canDeactivate && 'text-danger'}
                    eventKey="toggle-activation"
                    onClick={this.handleOpenToggleActivationModal}
                  >
                    {canDeactivate ? 'Deactivate' : 'Activate'}
                  </Dropdown.Item>
                </>
              )}
            </TDDropButton>
          )}
        </Card.Footer>

        {warning && (
          <Card.Footer className="warning text-warning">
            {warning}
          </Card.Footer>
        )}

        {canToggleActivation && (
          <UserToggleActivationModal
            activate={canReactivate}
            listViewComponentName={listViewComponentName}
            userId={id}
            userName={profile.name}
            userType={USER_TYPE.PROVIDER}
          />
        )}

        {canChangeRoleToManager && (
          <PromoteProviderModal
            listViewComponentName={listViewComponentName}
            userId={id}
            userName={profile.name}
          />
        )}

        {canToggleEmploymentStatus && (
          <ToggleEmploymentModal
            cannotConvertToEmployee={canChangeToEmployee && hasUnprocessedServiceOrders}
            listViewComponentName={listViewComponentName}
            toContractor={canChangeToContractor}
            userId={id}
            userName={profile.name}
          />
        )}

        <ModalSimple
          open={isContactModalOpen}
          heading={`Contact ${profile.firstName}`}
          body={(
            <ContactDetailsCard
              email={email}
              profile={profile}
            />
          )}
          onClose={this.handleCloseModals}
        />
      </Card>
    );
  }
}

ProviderCard.propTypes = {
  availabilityFilter: PropTypes.object,
  contactProviderModalId: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  history: routerHistorySpec.isRequired,
  isContactModalOpen: PropTypes.bool,
  isLastItem: PropTypes.bool,
  item: providerUserCardSpec.isRequired,
  linkTarget: PropTypes.string,
  listViewComponentName: PropTypes.string,
  match: routerMatchSpec.isRequired,
  onUpdated: PropTypes.func,
  orgAlias: PropTypes.string.isRequired,
  showAvailabilityMessages: PropTypes.bool,
  showContactDetails: PropTypes.bool,
  showProgress: PropTypes.bool,
  userGroupMemberId: PropTypes.number,
  warningMessage: PropTypes.func,
  withActions: PropTypes.bool,
};

ProviderCard.defaultProps = {
  availabilityFilter: null,
  isContactModalOpen: false,
  isLastItem: false,
  linkTarget: '_self',
  listViewComponentName: null,
  onUpdated: () => {},
  showAvailabilityMessages: false,
  showContactDetails: false,
  showProgress: false,
  userGroupMemberId: null,
  warningMessage: () => null,
  withActions: true,
};

const mapStateToProps = (state, props) => {
  const contactProviderModalId = `contact-provider-id-${props.item.id}`;

  return {
    contactProviderModalId,
    isContactModalOpen: getIsModalOpen(state, contactProviderModalId),
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProviderCardConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProviderCard);

const ProviderCardSelectable = ProviderCardConnected;

export default withRouter(ProviderCardSelectable);
