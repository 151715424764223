import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ButtonGroup, Card, Dropdown } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import NumberFormat from 'react-number-format';

import { generateApplicationStatusLabel } from 'projects/assets/js/lib/helpers';
import ProjectApplicationRejectModalForm, { REJECT_MODAL_ID } from 'projects/assets/js/components/ProjectApplicationRejectModalForm.jsx';
import ProjectApplicationCancelModalForm, { CANCEL_MODAL_ID } from 'projects/assets/js/components/ProjectApplicationCancelModalForm.jsx';
import { getIsModalOpen, modalOpenAC, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import ConfirmationButton from 'core/assets/js/components/ConfirmationButton.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { PROJECT_APPLICATION_STATUS, PROJECT_APPLICATION_STATUS_CLASS } from 'projects/assets/js/constants';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import SkillList from 'core/assets/js/components/SkillList.jsx';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import { formatDate } from 'core/assets/js/lib/utils';
import RatingScore from 'core/assets/js/components/RatingScore.jsx';
import { getDisplayRate } from 'rates/assets/js/lib/utils';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import ProjectBriefDescription from 'projects/assets/js/components/ProjectBriefDescription.jsx';
import { fetchViewDS, getViewState } from 'core/assets/js/ducks/view';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import { projectSpec, projectApplicationSpec } from 'projects/assets/js/lib/objectSpecs';
import {
  projectOpportunitiesUrl,
  projectApplicationViewApiUrl,
  projectApplicationEditUrl,
} from 'projects/urls';
import { routerMatchSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { IMG_SIZE, BS_STYLE, ICON } from 'core/assets/js/constants';
import AttachmentsList from 'core/assets/js/components/AttachmentsList.jsx';
import {
  acceptProjectApplicationDS,
  rejectProjectApplicationDS,
  cancelProjectApplicationDS,
} from 'projects/assets/js/data-services/opportunities';


const ProjectApplicationView = ({
  dispatch, match, history, project, application,
  viewerId, isRejectionModalOpen, isCancellationModalOpen,
}) => {
  const isThemSelf = viewerId === application.userCard.user.id;
  const { orgAlias } = match.params;

  const {
    id: applicationId,
    message,
    attachments,
    status,
    statusLabel,
    statusReason,
    userCard: {
      budget,
      rating,
      numReviews,
      currencySymbol,
      rate,
      rateUnit,
      user: { profile: { skills, jobTitle, name, avatar } },
    },
    allowedActions: { canReviewApplication, canUpdateApplication },
  } = application;

  const {
    id: projectId,
    applicants,
    applicationsCount,
    owner_name,
    deadline,
    max_applicants,
    created_at,
    allowedActions: { canReApply },
  } = project;

  const onCancel = values => dispatch(
    cancelProjectApplicationDS(orgAlias, project.id, application.id, values),
  )
    .then(async () => {
      dispatch(modalCloseAC(CANCEL_MODAL_ID));
      dispatch(fetchViewDS({
        componentName: 'ProjectApplicationView',
        url: projectApplicationViewApiUrl(orgAlias, project.id, application.id),
      }));
    });

  const onAccept = () => dispatch(acceptProjectApplicationDS(orgAlias, project.id, application.id))
    .then(async () => {
      dispatch(fetchViewDS({
        componentName: 'ProjectApplicationView',
        url: projectApplicationViewApiUrl(orgAlias, project.id, application.id),
      }));
    });

  const onReject = values => dispatch(
    rejectProjectApplicationDS(orgAlias, project.id, application.id, values),
  )
    .then(async () => {
      dispatch(modalCloseAC(REJECT_MODAL_ID));
      dispatch(fetchViewDS({
        componentName: 'ProjectApplicationView',
        url: projectApplicationViewApiUrl(orgAlias, project.id, application.id),
      }));
    });

  const hasCompleteRate = (currencySymbol && rate && rateUnit);
  const hasCompleteBudget = currencySymbol && parseFloat(budget) > 0.00;

  // Rate display
  const rateDisplayOpts = {
    currency: currencySymbol,
  };
  if (hasCompleteRate) {
    rateDisplayOpts.showRateUnitDiscreetly = true;
  }
  const userRate = hasCompleteRate
    ? (
      <StatusTag
        statusClass="default"
        hideTitle
        hideDot
        label={getDisplayRate(rate, rateUnit, rateDisplayOpts)}
      />
    )
    : '';

  const breadcrumbs = [
    {
      title: 'Opportunities',
      url: projectOpportunitiesUrl(match.params.orgAlias),
    },
    {
      title: (project && project.title) || '',
      url: null,
    },
  ];

  return (
    <React.Fragment>
      <ContentHeader breadcrumbs={breadcrumbs} />

      <div className="page page--project-application">
        <div className="container">
          <div className="row">
            {canReApply && isThemSelf && (
              <div className="col-12 d-flex mb-4">
                <div className="ml-auto">
                  <TDButton
                    variant={BS_STYLE.PRIMARY}
                    label="Reapply"
                    onClick={() => history.push(
                      projectApplicationEditUrl(orgAlias, projectId, applicationId),
                    )}
                    floatRight
                  />
                </div>
              </div>
            )}

            {canUpdateApplication && isThemSelf && (
              <div className="col-12 d-flex mb-4">
                <div className="ml-auto">
                  <Dropdown as={ButtonGroup} className="td-dropdown">
                    <TDButton
                      variant={BS_STYLE.PRIMARY}
                      label="Edit"
                      onClick={() => history.push(
                        projectApplicationEditUrl(orgAlias, projectId, applicationId),
                      )}
                      floatRight
                    />
                    <Dropdown.Toggle split variant={BS_STYLE.PRIMARY}>
                      <span className={ICON.CHEVRON_DOWN} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu alignRight>
                      <Dropdown.Item
                        key="cancel-application3"
                        className="text-secondary"
                        onClick={() => dispatch(modalOpenAC(CANCEL_MODAL_ID))}
                      >
                        Cancel application
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <ProjectApplicationCancelModalForm
                  application={application}
                  isOpen={isCancellationModalOpen}
                  onSubmit={onCancel}
                />
              </div>

            )}

            { canReviewApplication && (
              <div className="col-12 d-flex mb-4">
                <div className="ml-auto d-flex">
                  <ConfirmationButton
                    buttonStyle={BS_STYLE.SUCCESS}
                    buttonLabel="Accept"
                    buttonIcon={ICON.CHECKMARK}
                    modalProps={{
                      heading: 'Accept application',
                      body: (
                        <p>
                          Are you sure you want to accept this application?
                        </p>
                      ),
                      confirmLabel: 'Accept',
                      confirmStyle: BS_STYLE.SUCCESS,
                      cancelLabel: 'Close',
                      onConfirm: onAccept,
                    }}
                  />

                  <TDButton
                    variant={BS_STYLE.DANGER}
                    btnIcon={ICON.CROSS}
                    label="Reject"
                    onClick={() => dispatch(modalOpenAC(REJECT_MODAL_ID))}
                  />

                  <ProjectApplicationRejectModalForm
                    application={application}
                    isOpen={isRejectionModalOpen}
                    onSubmit={onReject}
                  />
                </div>
              </div>
            )}

            { !canReviewApplication && !isThemSelf && (
              <div className="col-12 d-flex mb-4">
                <div className="ml-auto">
                  <StatusTag
                    className="ml-auto"
                    label={statusLabel}
                    displayAsTag
                    statusClass={PROJECT_APPLICATION_STATUS_CLASS[status]}
                  />
                </div>
              </div>
            )}

            <div className="col-12 col-md-5 mb-4 mb-md-0">
              <Card>
                <Card.Header>
                  {project.title}

                  <aside className="discreet">
                    <div className="mt-2 mb-3">
                      {`Posted on ${formatDate(created_at)} by ${owner_name}`}
                    </div>

                    <div className="d-flex align-items-center">
                      { applicants && applicants.length > 0 && (
                        <div className="project-opportunities-card__applicants mr-3">
                          { applicants.slice(0, 5).map((
                            { user: { id: applicantId, profile } },
                          ) => (
                            <ProfilePic
                              key={applicantId}
                              url={profile.avatar}
                              alt={profile.name}
                              size={[20, 20]}
                            />
                          ))}
                        </div>
                      )}

                      {max_applicants > 0 && (
                        <span className="mr-4">
                          {`${applicationsCount || 0}/${max_applicants} applicants`}
                        </span>
                      )}

                      {deadline && (
                        <span>
                          {`Deadline ${formatDate(deadline)}`}
                        </span>
                      )}
                    </div>
                  </aside>
                </Card.Header>
                <Card.Body>
                  <ProjectBriefDescription project={project} truncateDescription />
                </Card.Body>
              </Card>
            </div>

            <div className="col-12 col-md-7">
              <Card>
                <Card.Header>
                  { isThemSelf && (
                    <div className="d-flex align-items-center">
                      Your application

                      <div className="ml-auto">
                        <StatusTag
                          className="ml-auto my-n2"
                          label={generateApplicationStatusLabel(application)}
                          displayAsTag
                          statusClass={PROJECT_APPLICATION_STATUS_CLASS[status]}
                        />
                      </div>
                    </div>
                  )}

                  { !isThemSelf && (
                    <div className="user-item">
                      <div className="row">
                        <div className="col-12 d-flex">
                          <ProfilePic
                            url={avatar}
                            alt={name}
                            size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                          />

                          <div className="col pl-3 pr-0">
                            <div className="d-flex flex-column flex-md-row position-relative">
                              <div className="user-item__basic-info">
                                {name}

                                <div
                                  className="user-item__extra discreet d-flex align-items-center col-auto px-0"
                                >
                                  <div className="truncate">
                                    {jobTitle && (
                                      <span className="truncate" title={jobTitle}>
                                        {jobTitle}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div
                                className="user-item__details ml-0 ml-md-auto"
                              >
                                <RatingScore
                                  rating={rating}
                                  numReviews={numReviews}
                                />

                                { hasCompleteBudget && (
                                  <span className="user-item__budget">
                                    <i key="coins-icon" className={ICON.COINS} />
                                    <NumberFormat
                                      onClick={() => {}}
                                      key="budget"
                                      value={budget}
                                      prefix={currencySymbol}
                                      displayType="text"
                                      thousandSeparator
                                    />
                                  </span>
                                )}

                                {hasCompleteRate && userRate}
                              </div>
                            </div>

                            {!isEmpty(skills) && (
                              <div className="pt-3 mb-n3 skills">
                                <SkillList skills={skills} />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </Card.Header>
                <Card.Body>
                  <p className="mb-0">{message}</p>

                  {statusReason && (
                    <div className="mt-5">
                      <strong>
                        { status === PROJECT_APPLICATION_STATUS.CANCELLED
                          ? 'Cancellation reason'
                          : 'Rejection reason'
                        }
                      </strong>
                      <blockquote className="py-2 mt-3 mb-0">
                        {statusReason}
                      </blockquote>
                    </div>
                  )}


                  {attachments && attachments.length > 0 && (
                    <div className="mt-5">
                      <AttachmentsList attachments={attachments} />
                    </div>
                  )}
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

ProjectApplicationView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  match: routerMatchSpec.isRequired,
  history: routerHistorySpec.isRequired,
  project: projectSpec,
  application: projectApplicationSpec,
  viewerId: PropTypes.number.isRequired,
  isRejectionModalOpen: PropTypes.bool,
  isCancellationModalOpen: PropTypes.bool,
  statusReason: PropTypes.string,
};
ProjectApplicationView.defaultProps = {
  project: null,
  application: null,
  isRejectionModalOpen: false,
  isCancellationModalOpen: false,
  statusReason: null,
};

const mapStateToProps = (state) => {
  const applicationViewState = getViewState(state, 'ProjectApplicationView');
  const viewerProfile = selectProfile(state);
  const isRejectionModalOpen = getIsModalOpen(state, REJECT_MODAL_ID);
  const isCancellationModalOpen = getIsModalOpen(state, CANCEL_MODAL_ID);

  return {
    application: applicationViewState.item.application,
    project: applicationViewState.item.project,
    viewerId: viewerProfile ? viewerProfile.userId : null,
    isRejectionModalOpen,
    isCancellationModalOpen,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProjectApplicationFormViewConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectApplicationView);

const ProjectApplicationFormViewApiConnected = withTDApiConnected({
  fetchData: ({
    dispatch, params, url, authedAxios, componentName, querystring,
  }) => dispatch(fetchViewDS({
    authedAxios, componentName, querystring,
    url: projectApplicationViewApiUrl(
      params.orgAlias,
      params.projectId,
      params.applicationId,
      url,
    ),
  })),
  duck: 'view',
  storeKey: 'ProjectApplicationView',
  skeletonComponent: null,
})(ProjectApplicationFormViewConnected);

export default withRouter(ProjectApplicationFormViewApiConnected);
