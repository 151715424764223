import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { documentOptionsSpec } from 'documents/assets/js/lib/objectSpecs';
import { documentViewUrl } from 'documents/urls';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const SelectedDocumentsLinks = ({ className, documentOptions, label, selectedDocumentIds }) => {
  const activeOrg = useSelector(selectActiveOrg);

  const selectedDocumentIdsLength = selectedDocumentIds.length;
  if (selectedDocumentIdsLength === 0) {
    return null;
  }

  return (
    <p className={className}>
      {`${label}: `}
      {selectedDocumentIds.map((id, index) => (
        <Fragment key={id}>
          <Link key={id} to={documentViewUrl(activeOrg.alias, id)}>
            {documentOptions.find(o => parseInt(o.value, 10) === parseInt(id, 10))?.label}
          </Link>
          {index < selectedDocumentIdsLength - 1 && ', '}
        </Fragment>
      ))}
    </p>
  );
};

SelectedDocumentsLinks.propTypes = {
  className: PropTypes.string,
  documentOptions: PropTypes.arrayOf(documentOptionsSpec).isRequired,
  label: PropTypes.string,
  selectedDocumentIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
};

SelectedDocumentsLinks.defaultProps = {
  className: null,
  label: 'Selected documents',
};

export default SelectedDocumentsLinks;
