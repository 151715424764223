import React from 'react';

import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import {
  PENDING_COUNT_TYPE, refreshInviteePendingCountDS, refreshProjectInvitationsCountDS,
  refreshFinancePendingCountsDS, pendingCountResetAC,
} from 'core/assets/js/ducks/pendingCount';
import { isReactTest } from 'core/assets/js/config/checks';

const pendingCounts = [
  PENDING_COUNT_TYPE.PURCHASE_ORDERS,
  PENDING_COUNT_TYPE.WORKSHEETS,
  PENDING_COUNT_TYPE.EXPENSES,
  PENDING_COUNT_TYPE.INVOICES,
  PENDING_COUNT_TYPE.PROJECT_INVITATIONS,
  PENDING_COUNT_TYPE.ORG_INVITEES,
];

class PendingCountProvider extends React.Component {
  static FetchPendingCounts({
    params, isAuthenticated, dispatch, authedAxios, isFirstRender, isProjManager,
  }) {
    if (!params.orgAlias) {
      return Promise.all(pendingCounts.map(k => dispatch(pendingCountResetAC(k))));
    }
    const prerequisites = [];
    if (isAuthenticated && isFirstRender) {
      prerequisites.push(
        dispatch(refreshFinancePendingCountsDS({
          orgAlias: params.orgAlias, authedAxios,
        })),
      );
      prerequisites.push(
        dispatch(refreshProjectInvitationsCountDS({
          orgAlias: params.orgAlias, authedAxios,
        })),
      );
      if (isProjManager) {
        prerequisites.push(
          dispatch(refreshInviteePendingCountDS({
            params, authedAxios,
          })),
        );
      }
    }
    return Promise.all(prerequisites);
  }

  render() {
    if (isReactTest) {
      return null;
    }
    return (
      <React.Fragment>
        <TDApiConnected
          duck="pendingCount"
          storeKey="pendingCounts"
          loadingEnabled={false}
          shouldRefetchOnQueryChange={false}
          fetchData={PendingCountProvider.FetchPendingCounts}
          orgSpecific
        />
      </React.Fragment>
    );
  }
}

export default PendingCountProvider;
