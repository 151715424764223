import Big from 'big.js';
import React from 'react';
import PropTypes from 'prop-types';

import { isAmount } from 'core/assets/js/lib/utils';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { RATE_UNIT, RATE_UNIT_FORMAT } from 'rates/assets/js/constants';


const RateAmount = (props) => {
  const { amount, showRateUnitDiscreetly, symbol, unit, withRateUnit } = props;
  const rateUnitFormat = RATE_UNIT_FORMAT[unit];

  if (!isAmount(amount)) {
    return null;
  }
  // Get a formatted rate amount using Big
  const rateAmount = rateUnitFormat.format(amount);
  const isCommission = unit === RATE_UNIT.COMMISSION;
  const rateEl = (
    <NumberTpl
      decimals={rateUnitFormat.decimals}
      key="rateAmount"
      prefix={!isCommission && symbol}
      value={rateAmount}
    />
  );
  const rateUnitEl = withRateUnit
    ? (
      <span key="rateUnit" className={showRateUnitDiscreetly ? 'discreet' : ''}>
        &nbsp;
        {rateUnitFormat.abbreviation}
      </span>
    ) : '';

  const rateElements = [rateEl, rateUnitEl];
  if (isCommission) {
    rateElements.splice(1, 0, <span key="symbol">%</span>);
  }

  return rateElements;
};
RateAmount.propTypes = {
  amount: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string, PropTypes.instanceOf(Big),
  ]).isRequired,
  showRateUnitDiscreetly: PropTypes.bool,
  symbol: PropTypes.string,
  unit: PropTypes.oneOf(Object.values(RATE_UNIT)).isRequired,
  withRateUnit: PropTypes.bool,
};
RateAmount.defaultProps = {
  showRateUnitDiscreetly: false,
  symbol: '',
  withRateUnit: true,
};

export default RateAmount;
