import React from 'react';
import PropTypes from 'prop-types';
import { isNil } from 'lodash';

import OrganizationLogo from 'core/assets/js/components/OrganizationLogo.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import TDCheckbox, { CHECKBOX_SIZES } from 'core/assets/js/components/TDCheckbox.jsx';
import {
  USER_CARD_STATUS,
  USER_CARD_STATUS_CLASS,
  USER_CARD_STATUS_LABEL,
  IMG_SIZE,
} from 'core/assets/js/constants';

import { NavLink } from 'react-router-dom';
import { OverlayTrigger, Tooltip, Card } from 'react-bootstrap';
import { SUBMISSION_STATUS_CLASS, SUBMISSION_STATUS_LABEL, SUBMISSION_STATUS } from 'interviews/assets/js/constants';
import { orgGetStartedUrl } from 'accounts/urls';
import { orgProgressSpec } from 'organizations/assets/js/lib/objectSpecs';
import { projectListUrl } from 'projects/urls';

const OrganizationCardItem = ({ item, onMarkedDefault, showDefaultCheckbox }) => {
  const {
    organization, status, isDefault, submission, onboardingProgress,
  } = item;
  const isRejected = status === USER_CARD_STATUS.REJECTED;
  const isDeactivated = status === USER_CARD_STATUS.DEACTIVATED || !organization.is_active;
  const pendingGetStarted = !isNil(onboardingProgress) && onboardingProgress < 100;
  const hasSubmission = submission && submission.id;

  const tooltip = (
    <Tooltip id={`org-default-status-${organization.id}-${isDefault}`}>
      {isDefault ? 'This is your default Organisation' : 'Set as default Organisation'}
    </Tooltip>
  );

  const statusCheckbox = (
    <OverlayTrigger
      placement="top"
      overlay={tooltip}
    >
      <div className="organization-list-item__default-status align-self-center">
        <TDCheckbox size={CHECKBOX_SIZES.LARGE} checked={isDefault} />
      </div>
    </OverlayTrigger>
  );

  const markDefaultButton = isDefault ? statusCheckbox : (
    <span onClick={() => onMarkedDefault(organization.alias)}>
      {statusCheckbox}
    </span>
  );

  const cardClassNames = ['organization-list-item shadow-none'];

  const statusClass = pendingGetStarted && status !== USER_CARD_STATUS.REJECTED
    ? USER_CARD_STATUS.PENDING : status;
  cardClassNames.push(`card--${USER_CARD_STATUS_CLASS[statusClass]}`);

  let statusTagReason = null;
  let statusTagClass = status;
  if (isDeactivated) {
    statusTagReason = `Your "${organization.name}" account is deactivated.`;
    statusTagClass = USER_CARD_STATUS.DEACTIVATED;
  }
  if (isRejected) {
    statusTagReason = 'Onboarding Submission has been rejected.';
  }

  return (
    <Card
      className={cardClassNames.join(' ')}
    >
      <Card.Body>
        { (isRejected || isDeactivated) && (
          <div className="row">
            <div className="col-10 col-sm-7 col-md-6 col-xl-5">
              <OrganizationLogo
                className="organization-list-item__logo"
                url={organization.logo}
                orgName={organization.name}
                size={[IMG_SIZE.MEDIUM, IMG_SIZE.MEDIUM]}
              />

              <span className="organization-list-item__title text-muted">
                {organization.name}
              </span>

              <div className="organization-list-item__meta">
                {organization.website && (
                  <span>
                    {organization.website}
                  </span>
                )}
              </div>
            </div>

            <div className="d-none d-md-flex col-2 px-0 align-items-center align-content-center">
              <StatusTag
                className="mx-auto"
                statusClass={USER_CARD_STATUS_CLASS[statusTagClass]}
                label={USER_CARD_STATUS_LABEL[statusTagClass]}
                reason={statusTagReason}
              />
            </div>
          </div>
        )}

        { (!isRejected && !isDeactivated) && (
          <div className="row">
            <div className="col-8 col-sm-7 col-md-6 col-xl-5">
              <OrganizationLogo
                className="organization-list-item__logo"
                url={organization.logo}
                orgName={organization.name}
                size={[IMG_SIZE.MEDIUM, IMG_SIZE.MEDIUM]}
              />

              <span className="organization-list-item__title">
                <NavLink
                  className="text-dark truncate"
                  to={pendingGetStarted
                    ? orgGetStartedUrl(organization.alias)
                    : projectListUrl(organization.alias)
                  }
                >
                  {organization.name}
                </NavLink>
              </span>

              <div className="organization-list-item__meta">
                {organization.website && (
                  <span>
                    {organization.website}
                  </span>
                )}
              </div>
            </div>

            <div className="d-none d-md-flex col-2 px-0 align-items-center align-content-center">
              {hasSubmission && !pendingGetStarted && (
                <StatusTag
                  className="mx-auto"
                  statusClass={SUBMISSION_STATUS_CLASS[submission.status]}
                  label={SUBMISSION_STATUS_LABEL[submission.status]}
                />
              )}

              {pendingGetStarted && (
                <StatusTag
                  className="mx-auto"
                  statusClass={SUBMISSION_STATUS_CLASS[SUBMISSION_STATUS.PENDING]}
                  label="Pending Get Started"
                />
              )}
            </div>

            {pendingGetStarted && (
              <div className="pl-0 col-4 col-sm-5 col-md-4 ml-auto justify-content-end align-items-center d-flex">
                <NavLink
                  className="organization-list-item__get-started-cta text-right"
                  to={orgGetStartedUrl(organization.alias)}
                >
                  Complete Get Started steps
                </NavLink>
              </div>
            )}

            {showDefaultCheckbox && !pendingGetStarted && (
              <div className="col-2 ml-auto justify-content-end align-items-center d-flex">
                { markDefaultButton }
              </div>
            )}

          </div>
        )}
      </Card.Body>
    </Card>
  );
};

OrganizationCardItem.propTypes = {
  item: orgProgressSpec,
  onMarkedDefault: PropTypes.func,
  showDefaultCheckbox: PropTypes.bool,
};

OrganizationCardItem.defaultProps = {
  item: {},
  onMarkedDefault: () => {},
  showDefaultCheckbox: false,
};

export default OrganizationCardItem;
