import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { outstandingCountSpec } from 'finance/assets/js/lib/objectSpecs';
import { projectViewUrl } from 'projects/urls';
import TabBar from 'core/assets/js/components/TabBar.jsx';
import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import { PROJECT_TABS } from 'core/assets/js/constants';
import { projectAllowedActionsSpec } from 'projects/assets/js/lib/objectSpecs';


const ProjectTabs = ({
  hasOrgAccess, match, outstandingCounts, applicationsEnabled, projectAllowedActions,
}) => {
  const { purchaseOrders, worksheets, expenses, tasks, projectApplications } = outstandingCounts;
  const { params } = match;
  const { orgAlias, id, tab } = params;
  const isManager = hasOrgAccess({ requireManager: true });

  let tabSpec = [{
    key: 'dashboard',
    label: 'Dashboard',
    href: projectViewUrl(orgAlias, id, PROJECT_TABS.DASHBOARD),
  }].filter(ts => !!ts);

  if (projectAllowedActions?.canViewProjectMember) {
    tabSpec.push({
      key: 'team',
      label: 'Team',
      href: projectViewUrl(orgAlias, id, PROJECT_TABS.TEAM),
    });
  }

  tabSpec = [
    ...tabSpec,
    {
      key: 'tasks',
      label: 'Tasks',
      href: projectViewUrl(orgAlias, id, PROJECT_TABS.TASKS),
      outstandingCount: tasks,
    }, {
      key: 'worksheets',
      label: 'Worksheets',
      href: projectViewUrl(orgAlias, id, PROJECT_TABS.WORKSHEETS),
      outstandingCount: worksheets,
    }, {
      key: 'expenses',
      label: 'Expenses',
      href: projectViewUrl(orgAlias, id, PROJECT_TABS.EXPENSES),
      outstandingCount: expenses,
    },
  ];

  if (isManager) {
    tabSpec.push({
      key: 'purchase-orders',
      label: 'Budget requests',
      href: projectViewUrl(orgAlias, id, PROJECT_TABS.PURCHASE_ORDERS),
      outstandingCount: purchaseOrders,
    });
  }

  if (isManager && applicationsEnabled) {
    // Put "Applicants" tab right after "Team"
    tabSpec.splice(2, 0, {
      key: 'applications',
      label: 'Applicants',
      href: projectViewUrl(orgAlias, id, PROJECT_TABS.APPLICATIONS),
      outstandingCount: projectApplications,
    });
  }

  return (
    <div className="horizontal-scroll" data-testid="project-tabs">
      <TabBar
        activeKey={tab}
        tabSpec={tabSpec}
      />
    </div>
  );
};

ProjectTabs.propTypes = {
  match: PropTypes.object.isRequired,
  applicationsEnabled: PropTypes.bool.isRequired,
  hasOrgAccess: PropTypes.func.isRequired,
  outstandingCounts: outstandingCountSpec,
  projectAllowedActions: projectAllowedActionsSpec,
};

ProjectTabs.defaultProps = {
  outstandingCounts: {},
  projectAllowedActions: {},
};

const mapStateToProps = state => ({
  hasOrgAccess: getHasOrgAccess(state),
});

const ProjectTabsConnected = connect(mapStateToProps)(ProjectTabs);

export default withRouter(ProjectTabsConnected);
