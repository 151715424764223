import PropTypes from 'prop-types';
import React from 'react';

import TDButton from 'core/assets/js/components/TDButton.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { RATE_ADJUSTMENT_SUBMIT_TYPE } from 'rates/assets/js/constants';
import { rateAllowedOptionsSpec } from 'rates/assets/js/lib/objectSpecs';

const RateAdjustmentFooter = ({ allowedActions, form }) => {
  const submitting = form?.getState().submitting;
  return (
    <div key="footer" className="text-right">
      { allowedActions.canCancel && (
        <TDButton
          rounded
          type="submit"
          onClick={() => {
            form.change('submitType', RATE_ADJUSTMENT_SUBMIT_TYPE.CANCEL);
            form.submit();
          }}
          btnIcon={ICON.CROSS}
          variant={BS_STYLE.WARNING}
          disabled={submitting}
          label="Cancel rate adjustment"
        />
      )}
      { allowedActions.canInitiate && (
        <TDButton
          type="submit"
          onClick={() => {
            form.change('submitType', RATE_ADJUSTMENT_SUBMIT_TYPE.INITIATE);
            form.submit();
          }}
          variant={BS_STYLE.PRIMARY}
          disabled={submitting}
          label="Request rate change"
        />
      )}
    </div>
  );
};

RateAdjustmentFooter.propTypes = {
  allowedActions: PropTypes.shape(rateAllowedOptionsSpec).isRequired,
  form: PropTypes.object.isRequired,
};

export default RateAdjustmentFooter;
