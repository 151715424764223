import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { toastr } from 'react-redux-toastr';

import AsyncSelectionField from 'core/assets/js/components/FinalFormFields/AsyncSelectionField.jsx';
import TDLabel from 'core/assets/js/components/TDLabel.jsx';

const SearchSelectField = ({
  extraContentBefore,
  getOptionsByIds,
  label,
  loadOptions: loadOptionsIn,
  name,
  placeholder,
  required,
  sublabel,
  wrapperClassName,
}) => {
  const { values } = useFormState();
  const { change } = useForm();

  const loadOptions = async term => {
    try {
      return loadOptionsIn(term);
    } catch (err) {
      toastr.error('Oh Snap!', err.data?._error || err.message);
      return [];
    }
  };

  const loadOptionsDebounced = useCallback(
    debounce((kw, callback) => {
      loadOptions(kw).then(options => callback(options));
    }, 500),
    [],
  );

  const value = values[name];
  const valueString = JSON.stringify(value);
  useEffect(() => {
    if (
      typeof value !== 'number'
      && (typeof value !== 'string' || !/^\d+$/.test(value))
      && (
        !Array.isArray(value)
        || value.length === 0
        || value.every(option => typeof option.label === 'string' && option.label.length > 0)
      )
    ) {
      return;
    }
    // A value has been passed in, but we don't have the labels to display in the UI
    // So load them and then update the value with the labels
    const ids = [];
    if (typeof value === 'number') {
      ids.push(value);
    } else if (typeof value === 'string' && /^\d+$/.test(value)) {
      ids.push(parseInt(value, 10));
    } else {
      ids.push(...value.reduce(
        (acc, entry) => {
          const entryString = (entry.value || entry).toString();
          if (/^\d+$/.test(entryString)) {
            acc.push(parseInt(entryString, 10));
          }
          return acc;
        },
        [],
      ));
    }
    getOptionsByIds(ids)
      .then(options => {
        change(name, options);
      })
      .catch(err => {
        toastr.error('Oh Snap!', err._error || err.message);
      });
  }, [valueString]);

  return (
    <>
      {label && <TDLabel name={name} label={label} required={required} sublabel={sublabel} />}
      <div className={wrapperClassName}>
        {extraContentBefore}
        <AsyncSelectionField
          className="mt-5"
          isMultiple
          isSearchable
          loadOptions={loadOptionsDebounced}
          name={name}
          placeholder={placeholder}
        />
      </div>
    </>
  );
};

SearchSelectField.propTypes = {
  extraContentBefore: PropTypes.element,
  getOptionsByIds: PropTypes.func.isRequired,
  label: PropTypes.string,
  loadOptions: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  sublabel: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

SearchSelectField.defaultProps = {
  extraContentBefore: null,
  label: null,
  placeholder: 'Search',
  required: false,
  sublabel: null,
  wrapperClassName: 'd-flex flex-column',
};

export default SearchSelectField;
