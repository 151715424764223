import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';

import ContentHeader from 'core/assets/js/layout/placeholder/ContentHeader.jsx';
import InvitationCardItem from 'projects/assets/js/components/InvitationCardItem.jsx';
import ModalConfirm from 'core/assets/js/components/ModalConfirm.jsx';
import ProjectInvitationSearch from 'projects/assets/js/components/ProjectInvitationSearch.jsx';
import ProjectListSkeleton from 'core/assets/js/components/Skeleton/ProjectListSkeleton.jsx';
import ProjectTaskInvitationRateAdjustmentModal, { MODAL_ID as DISCUSSION_MODAL_ID } from 'projects/assets/js/components/ProjectTaskInvitationRateAdjustmentModal.jsx';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { BS_SIZE, TASK_TABS } from 'core/assets/js/constants';
import { INVITATION_ACTION, STATUS } from 'invitations/assets/js/constants';
import { acceptTaskInvitationDS, rejectTaskInvitationDS } from 'projects/assets/js/data-services/tasks';
import { documentAssignmentSignUrl } from 'documents/urls';
import { modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { getListState, listUpdateItemAC, fetchListDS } from 'core/assets/js/ducks/list';
import { invitationSpec } from 'invitations/assets/js/lib/objectSpecs';
import { projectManageInvitationDS } from 'projects/assets/js/data-services/form';
import { projectInvitationsApiUrl, projectInvitationsUrl, projectViewTaskUrl, projectViewUrl } from 'projects/urls';
import { refreshProjectInvitationsCountDS } from 'core/assets/js/ducks/pendingCount';
import { routerHistorySpec, paginationSpec } from 'core/assets/js/lib/objectSpecs';


class ProjectInvitationListView extends React.Component {
  static FetchData({ dispatch, params, url, componentName, authedAxios, querystring }) {
    return Promise.all([
      dispatch(fetchListDS({
        authedAxios,
        componentName,
        querystring,
        url: projectInvitationsApiUrl(params.orgAlias, url),
      })),
    ]);
  }

  static GetComponentName() {
    return 'ProjectInvitationListView';
  }

  constructor(props) {
    super(props);
    this.orgAlias = props.params.orgAlias;
    this.defaultModalState = {
      open: false,
      heading: '-',
      body: '-',
      confirmLabel: 'Ok',
      confirmStyle: 'primary',
    };
    this.state = {
      modal: this.defaultModalState,
    };
    this.props = props;

    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleConfirmModal = this.handleConfirmModal.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleSignAndAccept = this.handleSignAndAccept.bind(this);
  }

  /**
   * Handle the confirmation request of the invitation modal
   */
  handleConfirmModal({ actionType, token, projectId, taskId }) {
    return new Promise(async (resolve, reject) => {
      if (!Object.values(INVITATION_ACTION).includes(actionType)) {
        throw new Error(`Invalid actionType "${actionType}"`);
      }

      const {
        params: { orgAlias }, dispatch, history,
      } = this.props;

      const componentName = this.constructor.GetComponentName();

      try {
        let inv;
        if (taskId) {
          const isAcceptAction = INVITATION_ACTION.ACCEPT === actionType;
          const action = isAcceptAction ? acceptTaskInvitationDS : rejectTaskInvitationDS;
          const { invitation } = await dispatch(action({
            orgAlias, projectId, taskId, values: {
              token,
              message: !isAcceptAction ? 'Invitation got rejected' : '',
            },
          }));
          inv = invitation;
        } else {
          const { data: invitation } = await projectManageInvitationDS(
            orgAlias, projectId, actionType, { token },
          );
          inv = invitation;
        }
        // Update the invitation status and pending count in the redux store
        dispatch(listUpdateItemAC(
          inv.id,
          { status: inv.status, statusLabel: inv.statusLabel, answeredAt: inv.answeredAt },
          componentName,
        ));
        dispatch(refreshProjectInvitationsCountDS({ orgAlias, componentName }));

        let cb;
        if (inv.status === STATUS.ACCEPTED) {
          // Invitation accepted, redirect to project view
          cb = () => {
            const callBackUrl = taskId
              ? projectViewTaskUrl(orgAlias, projectId, taskId, TASK_TABS.DISCUSSION)
              : projectViewUrl(orgAlias, projectId);
            history.push(callBackUrl);
          };
        }
        return resolve(cb);
      } catch (err) {
        return reject(err);
      }
    });
  }

  /**
   * Open the invitation modal and provide its content
   *
   * @param heading
   * @param body
   * @param confirmLabel
   */
  handleOpenModal(heading, body, confirmLabel, confirmStyle, token, projectId, taskId, actionType) {
    this.setState({
      // Data used by the invitation modal
      modal: {
        body,
        confirmLabel,
        confirmStyle,
        heading,
        open: true,
        payload: {
          token,
          projectId,
          taskId,
          actionType,
        },
      },
    });
  }

  handleSignAndAccept(documentAssignments) {
    const {
      dispatch,
      history,
      params: { orgAlias },
    } = this.props;
    const assignment = documentAssignments.find(da => !da.signedAt);

    // Close discussion board modal
    dispatch(modalCloseAC(DISCUSSION_MODAL_ID));

    if (assignment) {
      // if is coming from another document - has already accepted terms
      const skipTermsAndConditionsMessage = !!queryString.parse(
        history?.location?.search,
      ).continueInvitation;

      const url = documentAssignmentSignUrl(
        orgAlias,
        assignment.id,
        {
          backUrl: projectInvitationsUrl(orgAlias, assignment.invitation_id),
          next: projectInvitationsUrl(orgAlias, { continueInvitation: assignment.invitation_id }),
        },
      );

      const [pathname, search] = url.split('?');

      history.push({ pathname, search, state: { skipTermsAndConditionsMessage } });
    }
  }

  /**
   * Close the invitation modal
   */
  handleCloseModal() {
    this.setState({
      modal: this.defaultModalState,
    });
  }

  render() {
    const {
      filtersOpen,
      invitations,
      onFiltersToggle,
      pagination,
      searchActive,
    } = this.props;
    const { modal } = this.state;

    const breadcrumbs = [
      {
        title: 'Invitations',
        url: null,
      },
    ];

    return (
      <React.Fragment>
        <ContentHeader breadcrumbs={breadcrumbs} />

        <div className="page page--projects">
          <div className="container">
            <TDApiConnected
              duck="list"
              component={this.constructor}
              loadingEnabled={false}
              skeletonComponent={ProjectListSkeleton}
            >
              <div className="row">
                {searchActive && (
                  <ProjectInvitationSearch
                    onFiltersToggle={onFiltersToggle}
                    filtersOpen={filtersOpen}
                  />
                )}
              </div>

              <ModalConfirm
                onClose={this.handleCloseModal}
                onConfirm={this.handleConfirmModal}
                size={BS_SIZE.LARGE}
                {...modal}
              />

              {!filtersOpen && (
                <TDList
                  pagination={pagination}
                  items={invitations}
                  cardItem={{
                    component: InvitationCardItem,
                    props: {
                      onOpenInvitationModal: this.handleOpenModal,
                      onSignAndAccept: this.handleSignAndAccept,
                    },
                  }}
                  emptyListMessage="No invitations found."
                />
              )}

              <ProjectTaskInvitationRateAdjustmentModal
                onSignAndAccept={this.handleSignAndAccept}
              />
            </TDApiConnected>

          </div>
        </div>
      </React.Fragment>
    );
  }
}
ProjectInvitationListView.propTypes = {
  dispatch: PropTypes.func.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  history: routerHistorySpec.isRequired,
  invitations: PropTypes.arrayOf(invitationSpec),
  onFiltersToggle: PropTypes.func.isRequired,
  pagination: paginationSpec.isRequired,
  params: PropTypes.object.isRequired,
  searchActive: PropTypes.bool.isRequired,
};
ProjectInvitationListView.defaultProps = {
  invitations: [],
};

const mapStateToProps = (state, props) => {
  const listState = getListState(state, ProjectInvitationListView.GetComponentName());
  return {
    history: props.history,
    invitations: listState.items,
    pagination: listState.pagination,
    params: props.match.params,
    searchActive: listState.search.isActive,
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});
const ProjectInvitationListViewConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectInvitationListView);

export default withRouter(withFilters(ProjectInvitationListViewConnect));
