import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';

import { modalOpenAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import { orgSettingsRatesEditUrl } from 'rates/urls';
import RateAdjustmentModal from 'rates/assets/js/components/RateAdjustmentModal.jsx';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';


export const getModalId = rateId => `edit-rate-modal-${rateId}`;

const Modal = ({ onEdit, orgAlias, rate }) => {
  const rateId = rate.id;
  return (
    <RateAdjustmentModal
      rateId={rateId}
      orgAlias={orgAlias}
      modalId={getModalId(rateId)}
      onSuccess={(updatedRate) => {
        onEdit(updatedRate);
      }}
      rate={rate}
    />
  );
};

Modal.propTypes = {
  onEdit: PropTypes.func,
  orgAlias: PropTypes.string.isRequired,
  rate: PropTypes.shape(rateSpec).isRequired,
};
Modal.defaultProps = {
  onEdit: () => {},
};

class DropdownItem extends React.Component {
  constructor(props) {
    super(props);

    this.openModal = this.openModal.bind(this);
  }

  openModal() {
    const { dispatch, modalId } = this.props;

    dispatch(modalOpenAC(modalId));
  }

  render() {
    const { editInModal, history, orgAlias, rate } = this.props;
    const { allowedActions } = rate;
    const rateId = rate.id;
    const editLabel = allowedActions && allowedActions.canRespond
      ? 'Review'
      : 'Edit';

    return (
      <Dropdown.Item
        eventKey="edit"
        onClick={(e) => {
          e.stopPropagation();

          if (editInModal) {
            this.openModal();
          } else {
            history.push(orgSettingsRatesEditUrl(orgAlias, rateId));
          }
        }}
      >
        {editLabel}
      </Dropdown.Item>
    );
  }
}

DropdownItem.propTypes = {
  alias: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  editInModal: PropTypes.bool,
  history: routerHistorySpec.isRequired,
  modalId: PropTypes.string.isRequired,
  onEdit: PropTypes.func,
  orgAlias: PropTypes.string.isRequired,
  rate: PropTypes.shape(rateSpec).isRequired,
  rateId: PropTypes.number.isRequired,
};
DropdownItem.defaultProps = {
  editInModal: false,
  onEdit: () => {},
};

const mapStateToProps = (state, props) => {
  const { rateId } = props;
  const modalId = getModalId(rateId);

  return {
    modalId,
    isModalOpen: getIsModalOpen(state, modalId),
  };
};
const mapDispatchToProps = dispatch => ({
  dispatch,
});
const DropdownItemConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DropdownItem);
const DropdownOption = withRouter(DropdownItemConnected);

const RateEditOption = {
  DropdownOption,
  Modal,
};

export default RateEditOption;
