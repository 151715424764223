import PropTypes from 'prop-types';
import React from 'react';
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap';

import { BS_STYLE, ICON } from 'core/assets/js/constants';

const SplitButton = ({ actions, className, disabled: allDisabled, primaryButtonVariant }) => {
  const actionsLength = actions.length;
  if (actionsLength === 0) {
    return null;
  }
  const primaryAction = (
    <Button
      className={actions[0].className}
      onClick={actions[0].onClick}
      variant={primaryButtonVariant}
    >
      {actions[0].label}
    </Button>
  );
  if (actionsLength === 1) {
    return primaryAction;
  }
  return (
    <Dropdown as={ButtonGroup} className={`td-dropdown${className ? ` ${className}` : ''}`}>
      {primaryAction}
      <Dropdown.Toggle split variant={primaryButtonVariant}>
        <span className={ICON.CHEVRON_DOWN} />
      </Dropdown.Toggle>
      <Dropdown.Menu alignRight>
        {actions.slice(1).map(action => (
          <Dropdown.Item
            className={action.className}
            disabled={allDisabled || action.disabled}
            key={action.label}
            onClick={action.onClick}
          >
            {action.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

SplitButton.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  })).isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  primaryButtonVariant: PropTypes.oneOf(Object.values(BS_STYLE)),
};

SplitButton.defaultProps = {
  className: null,
  disabled: false,
  primaryButtonVariant: BS_STYLE.PRIMARY,
};

export default SplitButton;
