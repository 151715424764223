import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';

import withFilters from 'core/assets/js/components/withFilters.jsx';
import { getListState } from 'core/assets/js/ducks/list';
import {
  projectSpec,
} from 'projects/assets/js/lib/objectSpecs';
import { routerMatchContentsSpec, paginationSpec } from 'core/assets/js/lib/objectSpecs';
import { fetchTaskManagersListDS } from 'projects/assets/js/data-services/tasks';
import { fetchReviewsDS } from 'projects/assets/js/data-services/view';
import TDApiConnected from 'core/assets/js/components/TDApiConnected.jsx';
import TDList from 'core/assets/js/components/TDList.jsx';
import TaskManagerCard from 'projects/assets/js/components/TaskManagerCard.jsx';
import TaskManagerSearch from 'projects/assets/js/components/TaskManagerSearch.jsx';
import PeopleListSkeleton from 'core/assets/js/components/Skeleton/PeopleListSkeleton.jsx';
import { PEOPLE_DEFAULT_ORDERING } from 'people/assets/js/constants';

const TaskManagersTab = ({
  filtersOpen,
  managers,
  match,
  onFiltersToggle,
  pagination,
  task,
}) => {
  const { orgAlias } = match.params;

  return (
    <>
      <div className="row">
        <TaskManagerSearch
          onFiltersToggle={onFiltersToggle}
          filtersOpen={filtersOpen}
        />
      </div>

      <TDApiConnected
        duck="list"
        storeKey={TaskManagersTab.GetComponentName()}
        fetchData={
          ({ dispatch, params, querystring, authedAxios, url }) => {
            const _queryString = queryString.parse(querystring);

            // Use default ordering in case user haven't specified any.
            if (!_queryString.ordering) {
              _queryString.ordering = JSON.stringify(PEOPLE_DEFAULT_ORDERING);
            }

            const prerequisites = [
              dispatch(fetchTaskManagersListDS({
                url,
                params,
                querystring: queryString.stringify(_queryString),
                projectId: params.id,
                taskId: params.taskId,
                componentName: TaskManagersTab.GetComponentName(),
                authedAxios,
                dispatch,
              })),
            ];

            prerequisites.push(
              dispatch(fetchReviewsDS({
                orgAlias: params.orgAlias,
                id: params.id,
                url,
                authedAxios,
                componentName: TaskManagersTab.GetComponentName(),
              })),
            );

            return Promise.all(prerequisites);
          }}
        blockingLoading
        skeletonComponent={PeopleListSkeleton}
      >
        {!filtersOpen && (
          <TDList
            items={managers || []}
            pagination={pagination}
            cardItem={{
              component: TaskManagerCard,
              props: {
                accessControl: task.accessControl || {},
                orgAlias,
                task,
              },
            }}
            emptyListMessage="No managers found."
          />
        )}
      </TDApiConnected>
    </>
  );
};

TaskManagersTab.propTypes = {
  accessControl: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  filtersOpen: PropTypes.bool.isRequired,
  managers: PropTypes.array.isRequired,
  match: routerMatchContentsSpec.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
  pagination: paginationSpec.isRequired,
  task: projectSpec.isRequired,
};

TaskManagersTab.defaultProps = {
  accessControl: {},
};
TaskManagersTab.GetComponentName = () => 'TaskManagersTab';

const mapStateToProps = (state) => {
  const listState = getListState(state, TaskManagersTab.GetComponentName());
  return {
    accessControl: listState.extras.accessControl,
    managers: listState.items,
    pagination: listState.pagination,
  };
};

const TaskManagersTabConnected = connect(
  mapStateToProps,
)(TaskManagersTab);

export default withRouter(withFilters(TaskManagersTabConnected));
