import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React  from 'react';

import { formatDate } from 'core/assets/js/lib/utils';
import { projectApplicationSpec } from 'projects/assets/js/lib/objectSpecs';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import ProjectApplicationStatusBar from 'projects/assets/js/components/ProjectApplicationStatusBar.jsx';
import {
  projectApplicationEditUrl,
  projectApplicationViewUrl,
  projectOpportunitiesApplyUrl,
  projectViewUrl,
} from 'projects/urls';
import { BS_STYLE, BS_SIZE } from 'core/assets/js/constants';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import SkillList from 'core/assets/js/components/SkillList.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';

const OpportunitiesWidgetCardItem = ({
  item: project, history, match: { params: { orgAlias } }, isManager,
}) => {
  const {
    allowedActions: { canViewProjectDetails, canApply, canReApply },
    id,
    application,
    applicationsCount,
    skills,
    deadline,
    owner_name: ownerName,
    title,
    created_at: createdAt,
    max_applicants: maxApplicants,
    applicants,
  } = project;

  const cardHeader = (
    <div className="d-flex row">
      <div className="col my-0">
        <h3 className="project-opportunities-card__project-title">
          {canViewProjectDetails && (
            <Link to={projectViewUrl(orgAlias, id)}>{title}</Link>
          )}
          {!canViewProjectDetails && title}
        </h3>
        <aside className="discreet">
          <div className="mb-2">
            {`Posted on ${formatDate(createdAt)} by ${ownerName}`}
          </div>

          <div className="d-flex align-items-center">
            { applicants && applicants.length > 0 && (
              <div className="project-opportunities-card__applicants mr-3">
                { applicants.slice(0, 5).map((
                  { user: { id: applicantId, profile: { avatar, name } } },
                ) => (
                  <ProfilePic
                    key={applicantId}
                    url={avatar}
                    alt={name}
                    size={[20, 20]}
                  />
                ))}
              </div>
            )}

            {isManager && maxApplicants > 0 && (
              <span className="mr-4">
                {`${applicationsCount || 0}/${maxApplicants} applicants`}
              </span>
            )}

            {deadline && (
              <span>
                {`Deadline ${formatDate(deadline)}`}
              </span>
            )}
          </div>
        </aside>
      </div>

      <div className="float-right action-buttons col-auto">
        {canApply && (
          <TDButton
            variant={BS_STYLE.DEFAULT}
            size={BS_SIZE.SMALL}
            label={canReApply ? 'Reapply' : 'Apply'}
            onClick={() => {
              if (canReApply) {
                history.push(projectApplicationEditUrl(orgAlias, id, application.id));
              } else {
                history.push(projectOpportunitiesApplyUrl(orgAlias, id));
              }
            }}
          />
        )}

        {application && !canApply && (
          <TDButton
            variant={BS_STYLE.PRIMARY}
            rounded
            label="View application"
            onClick={() => {
              history.push(projectApplicationViewUrl(orgAlias, id, application.id));
            }}
          />
        )}
      </div>
    </div>
  );

  return (
    <div className="project-opportunities-widget-card-item">
      <div className="pb-3 position-relative">
        {cardHeader}

        { application && (
          <ProjectApplicationStatusBar application={application} />
        )}
      </div>

      <div className="py-3">
        <div className="col p-0">
          <SkillList
            skills={skills}
            inline
            maxShownItems={10}
            modalId={`project-skills-${id}`}
          />
        </div>
      </div>
    </div>
  );
};

OpportunitiesWidgetCardItem.propTypes = {
  item: projectApplicationSpec.isRequired,
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  isManager: PropTypes.bool,
};
OpportunitiesWidgetCardItem.defaultProps = {
  isManager: true,
};

export default withRouter(OpportunitiesWidgetCardItem);
