import React from 'react';
import PropTypes from 'prop-types';

const OnboardingProgressBar = ({ barClassName, className, label, percentage, alignRight }) => {
  const classNames = ['get-started-progress'];
  if (className) {
    classNames.push(className);
  }
  if (alignRight) {
    classNames.push('text-right');
  } else {
    classNames.push('text-left');
  }
  return (
    <div className={classNames.join(' ')}>
      {`${label ? `${label}: ` : ''}${percentage}%`}
      <div className={`bar-wrapper${barClassName ? ` ${barClassName}` : ''}`}>
        <div
          className={`bar ${percentage === 100 ? ' complete' : ''}`}
          style={{ width: `${percentage}%` }}
        />
      </div>
    </div>
  );
};

OnboardingProgressBar.GetComponentName = () => 'OnboardingProgressBar';

OnboardingProgressBar.propTypes = {
  barClassName: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  percentage: PropTypes.number.isRequired,
  showProgressLabel: PropTypes.bool,
  alignRight: PropTypes.bool,
};

OnboardingProgressBar.defaultProps = {
  barClassName: '',
  className: '',
  label: 'Progress',
  showProgressLabel: true,
  alignRight: false,
};

export default OnboardingProgressBar;
