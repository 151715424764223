
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link, useHistory, useParams } from 'react-router-dom';

import { getHasOrgAccess } from 'accounts/assets/js/reducers/auth';
import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDDropChevronButton from 'core/assets/js/components/TDDropChevronButton.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { getIsModalOpen, modalOpenAC, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { pluralize } from 'core/assets/js/lib/utils';
import UserGroupAvatar from 'people/assets/js/components/UserGroupAvatar.jsx';
import { deleteUserGroupDS } from 'people/assets/js/data-services/form';
import UserGroupsListView from 'people/assets/js/UserGroupsListView.jsx';
import { userGroupManageUrl } from 'people/urls';

const UserGroupCardPreview = ({
  item, item: { id, isDynamic, languagesCount, membersCount, name, skillsCount },
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { orgAlias } = useParams();
  const [deleting, setDeleting] = useState(false);
  const isAnyManager = useSelector(getHasOrgAccess)({ requireManager: true });
  const deleteUserGroupModalId = `delete-user-group-modal-id-${id}`;
  const handleCloseDeleteGroupModal = () => dispatch(modalCloseAC());
  const manageUrl = userGroupManageUrl(orgAlias, id);

  const infoClassNames = 'd-flex align-items-center text-nowrap mr-5 mt-2 mt-lg-0';

  return (
    <>
      <Card className="user-group-card--preview">
        <Card.Body className="d-flex align-items-center justify-content-between">
          <div
            className={(
              'wrapper d-flex flex-column flex-lg-row align-items-start align-items-lg-center '
              + 'justify-content-between'
            )}
          >
            <Link
              className="d-flex align-items-center text-decoration-none mr-5"
              to={manageUrl}
            >
              <UserGroupAvatar {...item} />
              <div className="d-inline-block description ml-5">
                <h2 className="d-inline-block m-0 text-truncate w-100">{name}</h2>
                <div className="discreet">{`${isDynamic ? 'Dynamic' : 'Standard'} group`}</div>
              </div>
            </Link>
            <div
              className={(
                'd-flex flex-column flex-lg-row align-items-start align-items-lg-center '
                + 'justify-content-end counts'
              )}
            >
              <div className={`${infoClassNames} members-count`}>
                <i className={`${ICON.USER_LIGHT} mr-3`} />
                <span>
                  { membersCount === 0 && 'No members added yet'}
                  { membersCount > 0 && `${membersCount} ${pluralize('member', membersCount)}`}
                </span>
              </div>
              {[
                ['skill', skillsCount, ICON.PEN_RULER_LIGHT],
                ['language', languagesCount, ICON.COMMENTS],
              ].map(([entityName, count, icon]) => isDynamic && count > 0 && (
                <div
                  className={`${infoClassNames} ${entityName}s-count`}
                  key={entityName}
                >
                  <i className={`${icon} mr-3`} />
                  <span>{`${count} ${pluralize(entityName, count)}`}</span>
                </div>
              ))}
            </div>
          </div>
          <TDDropChevronButton>
            <Dropdown.Item
              eventKey="view-group"
              onClick={() => history.push(userGroupManageUrl(orgAlias, id))}
            >
              View
            </Dropdown.Item>
            { isAnyManager && (
              <Dropdown.Item
                className="text-danger"
                eventKey="delete-group"
                onClick={() => dispatch(modalOpenAC(deleteUserGroupModalId))}
              >
                Delete
              </Dropdown.Item>
            )}
          </TDDropChevronButton>
        </Card.Body>
      </Card>
      <ModalSimple
        body="Are you sure you want to delete this user group?"
        heading="Delete user group"
        onClose={handleCloseDeleteGroupModal}
        open={useSelector(state => getIsModalOpen(state, deleteUserGroupModalId))}
        footer={[
          <TDButton
            className="float-right"
            disabled={deleting}
            key={1}
            label="Delete"
            onClick={async () => {
              setDeleting(true);
              try {
                await dispatch(deleteUserGroupDS({
                  componentName: UserGroupsListView.GetComponentName(),
                  orgAlias,
                  userGroupId: id,
                }));
                handleCloseDeleteGroupModal();
                toastr.success('Well Done!', 'Your user group was deleted successfully.');
              } catch (error) {
                toastr.error('Oh Snap!', error.message);
              } finally {
                setDeleting(false);
              }
            }}
            variant={BS_STYLE.DANGER}
          />,
          <TDButton
            className="mr-4 float-right"
            disabled={deleting}
            key={2}
            label="Cancel"
            onClick={handleCloseDeleteGroupModal}
          />,
        ]}
      />
    </>
  );
};

UserGroupCardPreview.propTypes = {
  item: PropTypes.shape({
    avatarFileId: PropTypes.number,
    id: PropTypes.number,
    isDynamic: PropTypes.bool,
    languagesCount: PropTypes.number,
    membersCount: PropTypes.number,
    name: PropTypes.string,
    owner: PropTypes.object,
    skillsCount: PropTypes.number,
  }),
};

UserGroupCardPreview.defaultProps = {
  item: {},
};

export default UserGroupCardPreview;
