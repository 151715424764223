import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { toastr } from 'react-redux-toastr';

import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { BS_STYLE } from 'core/assets/js/constants';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import {
  financeOnDemandInvoiceResultsApiUrl, financeWorksheetOnDemandInvoiceUrl,
  financeExpenseOnDemandInvoiceUrl, financeOnDemandInvoicePreviewApiUrl,
  financeInvoiceViewUrl,
} from 'finance/urls';
import { ON_DEMAND_INVOICE_STEP, INVOICE_STATUS } from 'finance/assets/js/constants';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import SettingsPageSkeleton from 'core/assets/js/components/Skeleton/SettingsPageSkeleton.jsx';
import { putViewDS } from 'core/assets/js/ducks/view';
import Invoice from 'finance/assets/js/components/Invoice.jsx';
import { SERVICE_ORDER_TYPE } from 'projects/assets/js/constants';
import { objectFromBase64 } from 'core/assets/js/lib/utils';

const componentName = 'BillableOnDemandInvoicePreview';

const BillableOnDemandInvoicePreview = ({
  history, location, match: { params: { orgAlias, id } }, item, billableType,
  dispatch,
}) => {
  const parsed = queryString.parse(location.search);
  const info = objectFromBase64(parsed.info);
  const { invoicePreview } = item;
  const isWorksheet = billableType === SERVICE_ORDER_TYPE.WORKSHEET;
  const url = isWorksheet
    ? financeWorksheetOnDemandInvoiceUrl : financeExpenseOnDemandInvoiceUrl;
  return (
    <div>
      <div className="invoice-page">
        <div className="finance-report__content-wrapper mb-2">
          <div className="row">
            <div className="col-12">
              <Invoice
                attachments={invoicePreview.attachments}
                invoice={{
                  ...invoicePreview,
                  invoiceItems: invoicePreview.invoiceItems,
                }}
                ownerFE={invoicePreview.ownerFE}
                systemFE={invoicePreview.systemFE}
                invoicingSettings={invoicePreview.invoicingSettings}
                recipientFE={invoicePreview.recipientFE}
                firstBillable={invoicePreview.firstBillable}
                lastBillable={invoicePreview.lastBillable}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5 ">
        <div className="col-12 text-right">
          <TDButton
            variant={BS_STYLE.DEFAULT}
            label="Back"
            onClick={() => history.push({
              pathname: url(
                orgAlias, id, ON_DEMAND_INVOICE_STEP.INIT,
              ),
              search: location.search,
            })}
          />
          <TDButton
            variant={BS_STYLE.PRIMARY}
            disabled={invoicePreview.status !== INVOICE_STATUS.PREVIEW}
            onClick={() => {
              toastr.info(
                'Raise invoice',
                'We are raising your invoice. This may take a few seconds.',
              );
              return dispatch(putViewDS({
                values: info,
                componentName: 'BillableOnDemandInvoiceResults',
                url: financeOnDemandInvoiceResultsApiUrl(
                  orgAlias, id,
                ),
              })).then(({ publishedInvoiceId }) => {
                history.push(
                  financeInvoiceViewUrl(orgAlias, publishedInvoiceId,
                  ));
              });
            }}
            label="Raise your invoice"
          />
        </div>
      </div>
    </div>
  );
};

BillableOnDemandInvoicePreview.propTypes = {
  history: routerHistorySpec.isRequired,
  billableType: PropTypes.number,
  match: routerMatchSpec.isRequired,
  item: PropTypes.object,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

BillableOnDemandInvoicePreview.defaultProps = {
  item: {},
  billableType: null,
};

const TdApiConnectedBillableOnDemandInvoicePreview = withTDApiConnected({
  fetchData: ({ dispatch, params, url, querystring }) => {
    toastr.info(
      'Invoice preview',
      'We are preparing your invoice preview. This may take a few seconds.',
    );
    const parsed = queryString.parse(querystring);
    const info = objectFromBase64(parsed.info);
    return Promise.all([
      dispatch(putViewDS({
        values: info,
        url: financeOnDemandInvoicePreviewApiUrl(
          params.orgAlias, params.id, url,
        ),
        componentName,
      })),
    ]);
  },
  duck: 'view',
  storeKey: componentName,
  skeletonComponent: SettingsPageSkeleton,
})(BillableOnDemandInvoicePreview);

export default TdApiConnectedBillableOnDemandInvoicePreview;
