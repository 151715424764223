import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ICON } from 'core/assets/js/constants';

const AreaCollapsible = ({
  className,
  containerElement,
  contentChildren,
  fieldSet,
  headingChildren,
  headingClassName,
  icons,
  iconClassName,
  initiallyExpanded,
  keepInDOM,
  makeCollapsible,
  onStateChange,
  solidColor,
  expandOnHeaderChildren,
  toggleAfterCard,
  toggleElementPlacement,
}) => {
  const [isExpanded, setIsExpanded] = useState(initiallyExpanded);
  const icon = isExpanded ? icons.collapse : icons.expand;
  const contClassName = ['area-collapsible'];

  useEffect(() => {
    setIsExpanded(initiallyExpanded);
  }, [initiallyExpanded]);

  useEffect(() => {
    if (onStateChange) {
      onStateChange(isExpanded);
    }
  }, [isExpanded]);

  if (className) {
    contClassName.push(className);
  }

  if (fieldSet) {
    contClassName.push('area-collapsible--fieldset');
  }

  if (isExpanded) {
    contClassName.push('expanded');
  }

  if (solidColor) {
    contClassName.push('area-collapsible--solid-color');
  }

  const toggleElement = (
    <a
      className={`area-collapsible__toggle m${toggleElementPlacement === 'right' ? 'l' : 'r'}-auto`}
      role="button"
      tabIndex={0}
      onClick={() =>  {
        if (expandOnHeaderChildren) {
          setIsExpanded(!isExpanded);
        }
      }}
    >
      <span className={`${icon}${iconClassName ? ` ${iconClassName}` : ''}`} />
    </a>
  );
  const WrapperElement = containerElement;
  const toggleNode = (
    <WrapperElement
      data-testid="area-collapsible-advanced-options"
      className={`${headingClassName} d-flex flex-nowrap area-collapsible__header justify-content-between w-100 align-items-center`}
      onClick={() =>  {
        if (expandOnHeaderChildren) {
          return;
        }
        setIsExpanded(!isExpanded);
      }}
    >
      {headingChildren}
      { makeCollapsible && !fieldSet && toggleElement }
    </WrapperElement>
  );


  return (
    <div className={`${contClassName.join(' ')}`}>
      { !toggleAfterCard && toggleNode }
      <div className={`area-collapsible__content${!isExpanded && keepInDOM ? '--collapsed' : ''}`}>
        { (isExpanded || keepInDOM) && contentChildren }
      </div>
      { toggleAfterCard && toggleNode }
    </div>
  );
};

AreaCollapsible.propTypes = {
  className: PropTypes.string,
  containerElement: PropTypes.any,
  contentChildren: PropTypes.node.isRequired,
  fieldSet: PropTypes.bool,
  headingChildren: PropTypes.node.isRequired,
  headingClassName: PropTypes.string,
  icons: PropTypes.object,
  iconClassName: PropTypes.string,
  initiallyExpanded: PropTypes.bool,
  isExpanded: PropTypes.bool,
  keepInDOM: PropTypes.bool,
  makeCollapsible: PropTypes.bool,
  onStateChange: PropTypes.func,
  solidColor: PropTypes.bool,
  expandOnHeaderChildren: PropTypes.bool,
  toggleAfterCard: PropTypes.bool,
  toggleElementPlacement: PropTypes.string,
};

AreaCollapsible.defaultProps = {
  className: '',
  containerElement: 'h3',
  fieldSet: false,
  headingClassName: '',
  icons: { expand: ICON.CHEVRON_DOWN, collapse: ICON.CHEVRON_UP },
  iconClassName: '',
  initiallyExpanded: false,
  isExpanded: false,
  keepInDOM: false,
  makeCollapsible: true,
  onStateChange: () => {},
  solidColor: false,
  expandOnHeaderChildren: false,
  toggleAfterCard: false,
  toggleElementPlacement: 'right',
};

export default AreaCollapsible;
