import React from 'react';
import PropTypes from 'prop-types';
import { Card, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import queryString from 'query-string';

import { rateSpec } from 'rates/assets/js/lib/objectSpecs';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import { orgSettingsRatesEditUrl } from 'rates/urls';
import { routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import RateAmount from 'rates/assets/js/components/RateAmount.jsx';
import RateDefaultOption from 'rates/assets/js/components/RateDefaultOption.jsx';
import RateSetAsDefaultOption from 'rates/assets/js/components/RateSetAsDefaultOption.jsx';
import RateDeleteOption from 'rates/assets/js/components/RateDeleteOption.jsx';
import RateEditOption, {
  getModalId as getRateEditModalId,
} from 'rates/assets/js/components/RateEditOption.jsx';
import RateRenameOption from 'rates/assets/js/components/RateRenameOption.jsx';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import {
  selectActiveUserCard,
} from 'organizations/assets/js/reducers/organizations';
import { userCardSpec } from 'organizations/assets/js/lib/objectSpecs';


class RateCard extends React.Component {
  componentDidMount() {
    const { dispatch, location, item } = this.props;

    const qs = queryString.parse(location.search);
    const targetRateId = qs.targetRateId && parseInt(qs.targetRateId, 10);
    if (targetRateId && item && item.id === targetRateId) {
      dispatch(modalOpenAC(getRateEditModalId(targetRateId)));
    }
  }

  render() {
    const {
      activeInTasks,
      editInModal,
      item,
      onEdit,
      listComponentName,
      match,
      userCard,
    } = this.props;
    const {
      alias,
      allowedActions,
      amount,
      currencySymbol,
      hasPendingRateAdjustment,
      id,
      isRateActive,
      isRateDraft,
      latestRateAdjustment,
      pendingStatusLabel,
      unit,
    } = item;
    const { orgAlias } = match.params;

    // If the rate is active show as a rate amount the last agreed amount,
    // otherwise the last proposed amount
    const rateAmount = isRateActive
      ? amount
      : latestRateAdjustment.new_amt;

    // Show the `Pending approval` label only when there is a pending rate adjustment
    const statusLabel = pendingStatusLabel
      && (
        <StatusTag
          displayAsTag
          label={pendingStatusLabel}
          statusClass="pending"
        />
      );

    // Draft rates or ACTIVE rates with a pending rate adjustment are highlighted with an orange
    // background colour
    const cardClassName = ['shadow-none rate-card'];
    if (isRateDraft || hasPendingRateAdjustment) {
      cardClassName.push('card--pending');
    } else {
      cardClassName.push('card--light-gray');
    }

    const canSetAsDefault = allowedActions.canSetAsDefault && !item.isDefault;

    return (
      <Card className={cardClassName.join(' ')} data-testid="rate-card">
        <Card.Body>
          <div className="row d-flex flex-nowrap align-items-center">
            <div className="col-auto pr-0">
              <div className="col-auto px-0 rate-card__infos">
                <span className="rate-card__title">
                  <Link
                    className="text-dark"
                    to={(orgSettingsRatesEditUrl(orgAlias, item.id))}
                  >
                    {alias}
                    {item.isDefault && (
                      <StatusTag
                        displayAsTag
                        statusClass="posted"
                        className="ml-3"
                        label="Default Rate"
                      />
                    )}
                  </Link>
                </span>
                <span className="ml-n3 ml-sm-4 d-block d-sm-inline w-100 status-pending__container">
                  {statusLabel}
                </span>
              </div>
              {!!activeInTasks && (
                <div className="rate-card__meta col-auto px-0">
                  {`Currently used in ${activeInTasks} tasks`}
                </div>
              )}
            </div>
            <div className="rate-card__amount ml-auto px-0 col-auto">
              <RateAmount
                amount={rateAmount}
                showRateUnitDiscreetly
                unit={unit}
                symbol={currencySymbol}
              />
            </div>

            <div className="card__actions ml-1 col-auto justify-content-end">
              <RateEditOption.Modal
                orgAlias={orgAlias}
                rate={item}
                onEdit={onEdit}
              />
              <RateRenameOption.Modal
                alias={alias}
                listComponentName={listComponentName}
                orgAlias={orgAlias}
                rate={item}
                rateId={item.id}
              />
              <RateDeleteOption.Modal
                alias={alias}
                listComponentName={listComponentName}
                orgAlias={orgAlias}
                rate={item}
              />
              {item.isDefault && userCard?.user?.id === item.user_id && (
                <RateDefaultOption.Modal rate={item} />
              )}
              <TDDropButton data-testid="rate-card-dropdown-button">
                {item.isDefault && userCard?.user?.id === item.user_id && (
                  <React.Fragment>
                    <RateDefaultOption.DropdownOption rate={item} />
                    <Dropdown.Divider />
                  </React.Fragment>
                )}
                {canSetAsDefault && (
                  <>
                    <RateSetAsDefaultOption listComponentName={listComponentName} rate={item} />
                    <Dropdown.Divider />
                  </>
                )}
                <RateEditOption.DropdownOption
                  alias={alias}
                  editInModal={editInModal}
                  orgAlias={orgAlias}
                  rate={item}
                  rateId={item.id}
                  onEdit={onEdit}
                />
                <RateRenameOption.DropdownOption alias={alias} rateId={id} />
                <RateDeleteOption.DropdownOption alias={alias} rate={item} />
              </TDDropButton>
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  }
}

RateCard.propTypes = {
  activeInTasks: PropTypes.number,
  dispatch: PropTypes.func.isRequired,
  editInModal: PropTypes.bool,
  item: PropTypes.shape(rateSpec).isRequired,
  listComponentName: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  match: routerMatchSpec.isRequired,
  onEdit: PropTypes.func,
  userCard: userCardSpec.isRequired,
};

RateCard.defaultProps = {
  activeInTasks: 0,
  editInModal: false,
  onEdit: () => {},
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const mapStateToProps = (state) => ({
  userCard: selectActiveUserCard(state),
});

const RateCardConnected = connect(
  mapStateToProps, mapDispatchToProps,
)(RateCard);

export default withRouter(RateCardConnected);
