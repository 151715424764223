import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';

import ModalSimple from 'core/assets/js/components/ModalSimple.jsx';
import { ICON } from 'core/assets/js/constants';

const TalentPortfolioCard = ({ portfolio }) => {
  const [show, setShow] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [portfolioWithDocumentPlaceholder, setPortfolioWithDocumentPlaceholder] = useState([]);

  const renderVideo = (item) => {
    return (
      <iframe
        allowFullScreen
        frameBorder="0"
        height="400"
        src={item.embedUrl}
        title={item.title}
        width="600"
      />
    );
  };

  useEffect(() => {
    const updatedPortfolio = portfolio?.map(p => {
      if (p.itemType === 'file') {
        const imageUrl = '/img/document-placeholder.png';
        return { ...p, file: p.original,
          original: imageUrl, thumbnail: imageUrl,
        };
      }
      if (p.itemType === 'embed') {
        return {
          ...p, embedUrl: p.original, original: p.thumbnail,
          renderItem: renderVideo,
        };
      }
      return p;
    });
    setPortfolioWithDocumentPlaceholder(updatedPortfolio);
  }, [portfolio]);

  const getDownloadButton = () => {
    const currentItem = portfolioWithDocumentPlaceholder[currentImageIndex];
    if (currentItem.itemType === 'file') {
      return (
        <a className="image-gallery-download-action with-icon cursor-pointer" href={currentItem.file}>
          <span className={ICON.DOWNLOAD} />
          {' '}
          <span>Download</span>
        </a>
      );
    }

    return null;
  };


  return (
    <>
      <Card>
        <Card.Header>Portfolio</Card.Header>
        <Card.Body>
          <div className="row talent__portfolio-container">
            {portfolioWithDocumentPlaceholder?.length
              ? portfolioWithDocumentPlaceholder.slice(0, 6).map((img, idx) => (
                <div className="col-6 col-xs-6 col-sm-6 col-lg-4 pl-0 pr-0 pb-3 talent__image" key={`${img.thumbnail}-${idx}`}>
                  <img
                    alt={img.title}
                    className="freelancer-details-card__portfolio-image"
                    data-testid={`freelancer-details-card__portfolio-image-${img.title}`}
                    onClick={() => {
                      setCurrentImageIndex(idx);
                      setShow(true);
                    }}
                    src={img.thumbnail}
                  />
                </div>
              ))
              : <p className="text-muted m-auto">Loading...</p>}
          </div>
        </Card.Body>
        <Card.Footer className="d-flex justify-content-end">
          <span className="imitate-link" onClick={() => setShow(true)}>
            {`View all (${portfolio.length})`}
          </span>
        </Card.Footer>
      </Card>
      <ModalSimple
        body={(
          <ImageGallery
            additionalClass="talent"
            items={portfolioWithDocumentPlaceholder}
            onSlide={(currentIndex) => setCurrentImageIndex(currentIndex)}
            renderCustomControls={getDownloadButton}
            showFullscreenButton={false}
            showPlayButton={false}
            showBullets={false}
            startIndex={currentImageIndex}
          />
        )}
        data-testid="talent-image-gallery"
        heading="Portfolio"
        noFooter
        onClose={() => setShow(false)}
        open={show}
        size="lg"
      />
    </>
  );
};

TalentPortfolioCard.propTypes = {
  portfolio: PropTypes.array,
};

TalentPortfolioCard.defaultProps = {
  portfolio: [],
};

export default TalentPortfolioCard;
