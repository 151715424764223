import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { Card } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';

import { truncate } from 'core/assets/js/lib/utils';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { projectApplicationViewUrl } from 'projects/urls';
import ProjectApplicationStatusBar from 'projects/assets/js/components/ProjectApplicationStatusBar.jsx';
import StatusTag from 'core/assets/js/components/StatusTag.jsx';
import { routerMatchSpec, routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { selectProfile } from 'accounts/assets/js/reducers/auth';
import { modalOpenAC, getIsModalOpen, modalCloseAC } from 'core/assets/js/ducks/modalLauncher';
import { projectSpec } from 'projects/assets/js/lib/objectSpecs';
import { USER_TYPE, IMG_SIZE, BS_STYLE, ICON } from 'core/assets/js/constants';
import { orgUserProfileUrl } from 'people/urls';
import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import SkillList from 'core/assets/js/components/SkillList.jsx';

import { getDisplayRate } from 'rates/assets/js/lib/utils';
import RatingScore from 'core/assets/js/components/RatingScore.jsx';


class ProjectApplicationCard extends React.Component {
  constructor(props) {
    super(props);
    this.handleOpenContactModal = this.handleOpenContactModal.bind(this);
    this.handleCloseContactModal = this.handleCloseContactModal.bind(this);

    this.contactProviderModalId = `contact-member-modal-${props.item.id}`;
  }

  handleOpenContactModal() {
    const { dispatch } = this.props;
    dispatch(modalOpenAC(this.contactProviderModalId));
  }

  handleCloseContactModal() {
    const { dispatch } = this.props;
    dispatch(modalCloseAC());
  }

  render() {
    const {
      history,
      match,
      item,
      item: {
        id,
        userId,
        message,
        userCard: {
          budget,
          rating,
          numReviews,
          currencySymbol,
          rate,
          rateUnit,
          user: { profile: { skills, jobTitle, name, avatar } },
        },
      },
      project: { id: projectId },

    } = this.props;

    const { orgAlias } = match.params;

    const profileUrl = orgUserProfileUrl(orgAlias, USER_TYPE.PROVIDER, userId);
    const hasCompleteRate = currencySymbol && parseFloat(rate) > 0.00;
    const hasCompleteBudget = currencySymbol && parseFloat(budget) > 0.00;

    // Rate display
    const rateDisplayOpts = {
      currency: currencySymbol,
    };
    if (hasCompleteRate) {
      rateDisplayOpts.showRateUnitDiscreetly = true;
    }
    const userRate = hasCompleteRate
      ? (
        <StatusTag
          statusClass="default"
          hideTitle
          hideDot
          label={getDisplayRate(rate, rateUnit, rateDisplayOpts)}
        />
      )
      : '';

    return (
      <Card
        className="project-opportunities-card"
      >
        <Card.Body className="pb-3 position-relative">
          <div className="user-item">
            <div className="row">
              <div className="col-12 d-flex">
                <ProfilePic
                  url={avatar}
                  alt={name}
                  size={[IMG_SIZE.SMALL, IMG_SIZE.SMALL]}
                />

                <div className="col pl-3 pr-0">
                  <div className="d-flex position-relative">
                    <div className="user-item__basic-info">
                      <Link to={profileUrl}>{name}</Link>

                      <div
                        className="user-item__extra discreet d-flex align-items-center col-auto px-0"
                      >
                        <div className="truncate">
                          {jobTitle && (
                            <span className="truncate" title={jobTitle}>
                              {jobTitle}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className="user-item__details ml-auto"
                    >
                      <RatingScore
                        rating={rating}
                        numReviews={numReviews}
                      />

                      { hasCompleteBudget && (
                        <span className="user-item__budget">
                          <i key="coins-icon" className={ICON.COINS} />
                          <NumberFormat
                            key="budget"
                            value={budget}
                            prefix={currencySymbol}
                            displayType="text"
                            thousandSeparator
                          />
                        </span>
                      )}

                      {hasCompleteRate && userRate}
                    </div>
                  </div>

                  {!isEmpty(skills) && (
                    <div className="pt-3 mb-n2 skills">
                      <SkillList skills={skills} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <ProjectApplicationStatusBar application={item} />
        </Card.Body>

        <div className="row align-items-center p-4">
          <div className="col-12 col-md-7">
            {truncate(message, 35)}
          </div>

          <div className="ml-auto px-4 py-0 pt-md-2">
            <TDButton
              variant={BS_STYLE.PRIMARY}
              rounded
              label="View application"
              onClick={() => {
                history.push(projectApplicationViewUrl(orgAlias, projectId, id));
              }}
            />
          </div>
        </div>
      </Card>
    );
  }
}

ProjectApplicationCard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  item: projectSpec.isRequired,
  project: projectSpec.isRequired,
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  orgAlias: PropTypes.string.isRequired,
};

const mapStateToProps = (state, props) => {
  const userId = selectProfile(state).userId;

  return {
    isContactModalOpen: getIsModalOpen(state, `contact-member-modal-${props.item.id}`),
    userId,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
});

const ProjectMemberCardConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectApplicationCard);

export default withRouter(ProjectMemberCardConnected);
