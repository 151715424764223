import AORContractSignView from 'documents/assets/js/AORContractSignView.jsx';
import AORContractSignConfirmationView from 'documents/assets/js/AORContractSignConfirmationView.jsx';
import DocumentAssignmentSignConfirmationView from 'documents/assets/js/DocumentAssignmentSignConfirmationView.jsx';
import DocumentAssignmentSignView from 'documents/assets/js/DocumentAssignmentSignView.jsx';
import DocumentAssignmentView from 'documents/assets/js/DocumentAssignmentView.jsx';
import DocumentCreateView from 'documents/assets/js/DocumentCreateView.jsx';
import DocumentEditView from 'documents/assets/js/DocumentEditView.jsx';
import DocumentEsignReadyView from 'documents/assets/js/DocumentEsignReadyView.jsx';
import DocumentListView from 'documents/assets/js/DocumentListView.jsx';
import DocumentNewVersionView from 'documents/assets/js/DocumentNewVersionView.jsx';
import DocumentEsignConfirmationExternalView from 'documents/assets/js/DocumentEsignConfirmationExternalView.jsx';
import DocumentEsignConfirmationInvitingManagerView from 'documents/assets/js/DocumentEsignConfirmationInvitingManagerView.jsx';
import DocumentSignExternalView from 'documents/assets/js/DocumentSignExternalView.jsx';
import DocumentView from 'documents/assets/js/DocumentView.jsx';
import DocumentSignaturesRequestJobView from 'documents/assets/js/DocumentSignaturesRequestJobView.jsx';
import {
  aorContractSignUrl,
  aorContractSignConfirmationUrl,
  documentAssignmentSignConfirmationExternalUrl,
  documentAssignmentSignConfirmationInvitingManagerUrl,
  documentAssignmentSignConfirmationUrl,
  documentAssignmentSignExternalUrl,
  documentAssignmentSignUrl,
  documentAssignmentViewUrl,
  documentCreateUrl,
  documentEditUrl,
  documentEsignReadyView,
  documentNewVersionUrl,
  documentSignaturesRequestJobView,
  documentViewUrl,
  documentsUrl,
} from 'documents/urls';
import { LAYOUTS } from 'core/assets/js/constants';
import { DOCUMENT_VIEW_TABS } from 'documents/assets/js/constants';
import { TYPE as INVITATION_TYPE } from 'invitations/assets/js/constants';

const INVITATION_TYPE_VALUES = Object.values(INVITATION_TYPE);

const documentsRouteConfig = [
  {
    component: DocumentListView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: documentsUrl(':orgAlias'),
    requireOrgCreator: true,
    requireManager: true,
    requireFinController: true,
  },
  {
    component: DocumentCreateView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: documentCreateUrl(':orgAlias'),
    requireOrgCreator: true,
    requireManager: true,
    requireFinController: true,
  },
  {
    component: DocumentEditView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: documentEditUrl(':orgAlias', ':id'),
    requireOrgCreator: true,
    requireManager: true,
    requireFinController: true,
  },
  {
    component: DocumentView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: documentViewUrl(':orgAlias', ':id', `:activeTab(${Object.values(DOCUMENT_VIEW_TABS).join('|')})?`),
    requireOrgCreator: true,
    requireManager: true,
    requireFinController: true,
  },
  {
    component: DocumentNewVersionView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: documentNewVersionUrl(':orgAlias', ':id'),
    requireOrgCreator: true,
    requireManager: true,
    requireFinController: true,
  },
  {
    component: DocumentAssignmentSignView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: documentAssignmentSignUrl(':orgAlias', ':id'),
  },
  {
    component: DocumentAssignmentSignConfirmationView,
    exact: true,
    layout: LAYOUTS.NAKED,
    path: documentAssignmentSignConfirmationUrl(
      `:invitationType(${Object.values(INVITATION_TYPE_VALUES).join('|')})?`,
    ),
  },
  {
    component: DocumentAssignmentView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: documentAssignmentViewUrl(':orgAlias', ':id'),
  },
  {
    component: AORContractSignView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: aorContractSignUrl(':orgAlias'),
  },
  {
    component: AORContractSignConfirmationView,
    exact: true,
    layout: LAYOUTS.NAKED,
    path: aorContractSignConfirmationUrl(':orgAlias'),
  },
  {
    component: DocumentEsignReadyView,
    exact: true,
    layout: LAYOUTS.NAKED,
    path: documentEsignReadyView(),
  },
  {
    component: DocumentSignExternalView,
    exact: true,
    layout: LAYOUTS.NAKED,
    path: documentAssignmentSignExternalUrl(':token'),
  },
  {
    component: DocumentEsignConfirmationExternalView,
    exact: true,
    layout: LAYOUTS.NAKED,
    path: documentAssignmentSignConfirmationExternalUrl(':token'),
  },
  {
    component: DocumentEsignConfirmationInvitingManagerView,
    exact: true,
    layout: LAYOUTS.NAKED,
    path: documentAssignmentSignConfirmationInvitingManagerUrl(':invitationId'),
  },
  {
    component: DocumentSignaturesRequestJobView,
    exact: true,
    layout: LAYOUTS.DEFAULT,
    path: documentSignaturesRequestJobView(':orgAlias', ':jobId'),
  },
];

export default documentsRouteConfig;
