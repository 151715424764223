import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { take } from 'lodash';
import { Popover, OverlayTrigger } from 'react-bootstrap';

import HeaderCta from 'core/assets/js/components/HeaderCta.jsx';
import RectangleSkeleton from 'core/assets/js/components/Skeleton/RectangleSkeleton.jsx';
import { ICON, CUSTOM_ICONS } from 'core/assets/js/constants';
import { orgGetStartedUrl } from 'accounts/urls';
import { routerHistorySpec } from 'core/assets/js/lib/objectSpecs';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

const renderBreadcrumbs = ({ backUrl, items, history, activeOrg }) => {
  const currentItem = items[items.length - 1]; // Get last item
  const fromGetStarted = history?.location?.state?.fromGetStarted;
  const from = history?.location?.state?.from;

  // Show skeleton loader in case title is not set yet.
  let currentTitle = currentItem.title || (
    <React.Fragment>
      <RectangleSkeleton width="95" height="19" />
      <RectangleSkeleton width="60" height="19" />
    </React.Fragment>
  );

  if (history?.location?.state?.customTitle) {
    currentTitle = history.location.state.customTitle;
  }

  const constituents = [];

  constituents.push(
    <li
      className="breadcrumbs__current-path pr-3 text-truncate"
      data-testid="breadcrumbs-current-path"
      key="breadcrumbs-title"
    >
      { currentItem.url && (
        <Link className="text-truncate w-100" to={currentItem.url}>{currentTitle}</Link>
      )}

      { !currentItem.url && (
        <span data-testid="current-page-title" className="breadcrumbs__title text-truncate w-100">{currentTitle}</span>
      )}
    </li>,
  );

  const path = [];

  const showBackButton = (items.length > 1 && history.action === 'PUSH')
  || backUrl || fromGetStarted || from;

  if (showBackButton) {
    path.push(
      <li className="breadcrumbs__back-button-container" key="breadcrumbs-back">
        <a onClick={
          () => {
            if (fromGetStarted) {
              history.push(orgGetStartedUrl(activeOrg.alias));
            } else if (from) {
              history.push(from);
            } else if (backUrl) {
              history.push(backUrl);
            } else {
              history.goBack();
            }
          }}
        >
          <i className={`${ICON.ARROW_LEFT_LONG}`} />
          <span className="d-none d-md-inline">Back</span>
        </a>
      </li>,
    );
  }

  // If only 2 items are present, show both inline.
  if (items.length === 2) {
    const rootItem = items[0];
    path.push(
      <li key="breadcrumbs-item-path" className="d-none d-md-inline-block breadcrumbs__parent-path">
        { rootItem.url && (
          <Link to={rootItem.url}>{rootItem.title || ''}</Link>
        )}
        { !rootItem.url && (
          <span>{typeof rootItem === 'string' ? rootItem : rootItem.title}</span>
        )}
      </li>,
    );
    // Otherwise group all paths (except current page) using a dropdown.
  } else if (items.length > 2) {
    path.push([
      <li key="breadcrumbs-dropdown-list-item" className="d-none d-md-inline-block">
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          overlay={(
            <Popover placement="bottom" id="breadcrumbs-popover">
              <ul>
                {take(items, items.length - 1).reverse().map(item => (
                  <li key={`breadcrumbs-item-${item.title}`}>
                    {item.url && <Link to={item.url}>{item.title}</Link>}
                    {!item.url && <span>{item.title}</span>}
                  </li>
                ))}
              </ul>
            </Popover>
          )}
        >
          <span className="breadcrumbs-dropdown-toggle">
            <img
              className="breadcrumbs-dropdown-icon"
              alt="dropdown icon"
              src={CUSTOM_ICONS.BREADCRUMBS}
            />
            <img
              className="breadcrumbs-dropdown-icon-active"
              alt="dropdown icon"
              src={CUSTOM_ICONS.BREADCRUMBS_ACTIVE}
            />
          </span>
        </OverlayTrigger>
      </li>,
    ]);
  }

  if (items.length > 1) {
    const prevItem = items[items.length - 2];

    if (!showBackButton) {
      path.push(
        <li
          className="d-inline-block d-md-none breadcrumbs__back-button-container"
          key="breadcrumbs-dropdown-list-prev-item"
        >
          <Link to={prevItem.url}>
            <i className={ICON.ARROW_LEFT_LONG} />
          </Link>
        </li>,
      );
    }

    path.push(
      <li key="breadcrumbs-separator" className="separator d-none d-md-inline-block">
        <span className={ICON.CHEVRON_RIGHT} />
      </li>,
    );
  }

  constituents.unshift(path);
  return constituents;
};

const Breadcrumbs = ({ backUrl, items, ctaButtonItems, ctaComponent, history, activeOrg }) => (
  <div className="breadcrumbs d-flex align-items-center">
    <div className="breadcrumbs__heading">
      <ul className="breadcrumbs__list">
        {renderBreadcrumbs({ backUrl, items, history, activeOrg })}
        {ctaComponent && (
          <li className="breadcrumbs-cta-wrapper ml-auto">
            {ctaComponent}
          </li>
        )}
        {ctaButtonItems && (
          <li className="breadcrumbs-cta-wrapper ml-auto">
            <HeaderCta
              ctaButtonItems={ctaButtonItems}
              ctaComponent={ctaComponent}
            />
          </li>
        )}
      </ul>
    </div>
  </div>
);

Breadcrumbs.propTypes = {
  activeOrg: PropTypes.object.isRequired,
  backUrl: PropTypes.string,
  ctaButtonItems: PropTypes.array,
  ctaComponent: PropTypes.node,
  history: routerHistorySpec.isRequired,
  items: PropTypes.array.isRequired,
};
Breadcrumbs.defaultProps = {
  backUrl: null,
  ctaButtonItems: null,
  ctaComponent: null,
};

const mapStateToProps = state => ({
  activeOrg: selectActiveOrg(state),
});

const BreadcrumbsConnected = connect(
  mapStateToProps,
)(Breadcrumbs);

export default withRouter(BreadcrumbsConnected);
