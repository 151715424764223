import moment from 'moment';
import { get, isEmpty, pick } from 'lodash';
import { CURRENCY } from 'core/assets/js/constants';
import { SYSTEM_BANK_BUSINESS_NUMBER, SYSTEM_BANK_TYPE } from 'settings/assets/js/constants';
import {
  DEFAULT_PAYMENT_PROVIDERS,
  INITIAL_INVOICING_SETTINGS_FX_MARKUP_AS_A_PERCENT,
  OUTBOUND_INVOICE_TEMPLATES,
  RAISED_BY, TAX_METHOD,
  INVOICE_SEGMENT_TYPES,
  TRANSACTION_MODE,
} from 'finance/assets/js/constants';
import { SERVICE_ORDER_TYPE } from 'projects/assets/js/constants';
import InvoicingFeeScheme from 'finance/assets/js/lib/InvoicingFeeScheme';
import InvoicingFrequency from 'finance/assets/js/lib/InvoicingFrequency';
import FinancialAssociation from 'finance/assets/js/lib/FinancialAssociation';
import { extractAddressComponent } from 'core/assets/js/lib/utils';
import Logger from 'core/assets/js/lib/Logger';

const logger = new Logger('invoicing:settings');

class InvoicingSettings {
  static fromOrg(org, { taxMethod } = {}) {
    const address = JSON.parse(get(org, 'billingDetail.address_dump', org.address_dump || 'null'));
    const countryCode = extractAddressComponent(address, 'country_code') || 'GB';
    if (!org.invoicing_system_number) {
      throw new Error('invoicing system number is required');
    }
    const overrides = org.systemBankAccountOverrides.map(o => ({
      invoiceSegment: o.invoice_segment,
      systemBankAccountId: o.system_bank_account_id,
    }));
    return new InvoicingSettings({
      orgId: org.id,
      orgOwnerId: org.user_id,
      orgAlias: org.unique_alias,
      currency: org.currency,
      targetCurrency: org.invoicing_target_currency,
      targetSystemCurrency: org.invoicing_system_currency,
      balanceCurrency: org.invoicing_balance_currency,
      transactionMode: org.invoicing_transaction_mode,
      mode: org.invoicing_mode,
      feeScheme: org.invoicing_fee_scheme,
      billableExpenses: org.invoicing_billable_expenses,
      chargeBankFees: org.invoicing_charge_bank_fees,
      vatPercent: org.invoicing_vat_percent,
      onTrial: org.on_trial,
      trialExpiresAt: org.trial_expires_at,
      paidTrial: org.paid_trial,
      // this will be converted to the respective invoicing frequency
      invoicingDays: InvoicingFrequency.fromDump(
        org.invoicing_frequency_dump,
      ).toCalendarDaysArray(),
      serializedInvoicingFrequency: InvoicingFrequency.fromDump(
        org.invoicing_frequency_dump,
      ).serialize(),
      countryCode,
      revolutAccountOverrideId: org.revolut_account_override_id,
      systemNumber: org.invoicing_system_number,
      invoicingOutboundTemplate: org.invoicing_outbound_template,
      invoicingOutboundTemplateDescription: org.invoicing_outbound_template_description,
      aorOnboardingInvitationsEnabled: org.aor_onboarding_invitations_enabled,
      invoicingFXMarkUp: org.invoicing_fx_markup,
      invoicingDefaultPaymentProvider: org.invoicing_default_payment_provider,
      apiAccessEnabled: org.api_access_enabled,
      codatIntegrationsStatus: org.codat_integrations_status,
      hideProvidersInvoicePaymentDetails: org.hide_providers_invoice_payment_details,
      taxMethod,
      systemBankAccountOverrides: overrides,
    });
  }

  static _getSystemBankAliasFromLegacy(systemNumber, legacyAlias) {
    if (!systemNumber) {
      throw new Error('systemNumber is required');
    }
    if (!legacyAlias) {
      throw new Error('legacyAlias is required');
    }
    switch (systemNumber.toString()) {
      case SYSTEM_BANK_BUSINESS_NUMBER.TD.toString():
        return {
          barclays_gbp: SYSTEM_BANK_TYPE.TD_BARCLAYS_GBP,
          barclays_eur: SYSTEM_BANK_TYPE.TD_BARCLAYS_EUR,
          barclays_usd: SYSTEM_BANK_TYPE.TD_BARCLAYS_USD,
          transferwise_gbp: SYSTEM_BANK_TYPE.TD_WISE_GBP,
          transferwise_eur: SYSTEM_BANK_TYPE.TD_WISE_EUR,
          transferwise_usd: SYSTEM_BANK_TYPE.TD_WISE_USD,
          transferwise_eur_local: SYSTEM_BANK_TYPE.TD_WISE_EUR_LOCAL,
          transferwise_usd_local: SYSTEM_BANK_TYPE.TD_WISE_USD_LOCAL,
          transferwise_cad_local: SYSTEM_BANK_TYPE.TD_WISE_CAD_LOCAL,
          transferwise_nzd_local: SYSTEM_BANK_TYPE.TD_WISE_NZD_LOCAL,
        }[legacyAlias];
      case SYSTEM_BANK_BUSINESS_NUMBER.SERVICES.toString():
        return {
          barclays_gbp: SYSTEM_BANK_TYPE.SERVICES_BARCLAYS_GBP,
          barclays_eur: SYSTEM_BANK_TYPE.SERVICES_BARCLAYS_EUR,
          barclays_usd: SYSTEM_BANK_TYPE.SERVICES_BARCLAYS_USD,
          transferwise_gbp: SYSTEM_BANK_TYPE.SERVICES_WISE_GBP,
          transferwise_eur: SYSTEM_BANK_TYPE.SERVICES_WISE_EUR,
          transferwise_usd: SYSTEM_BANK_TYPE.SERVICES_WISE_USD,
          transferwise_eur_local: SYSTEM_BANK_TYPE.SERVICES_WISE_EUR_LOCAL,
          transferwise_usd_local: SYSTEM_BANK_TYPE.SERVICES_WISE_USD_LOCAL,
          transferwise_cad_local: SYSTEM_BANK_TYPE.SERVICES_WISE_CAD_LOCAL,
          transferwise_nzd_local: SYSTEM_BANK_TYPE.SERVICES_WISE_NZD_LOCAL,
        }[legacyAlias];
      default:
        throw new Error(`unknown system number ${systemNumber}`);
    }
  }

  /**
   * Selects the default bank for a given system registration number, currency and country code
   *
   * @param {String} systemRegistrationNumber - the system registration number used on this invoice
   * @param {String} bankCurrency - the bank currency we want to use
   * @param {String} orgCountryCode - the ISO country code of a client
   * @returns {String} the alias of the system bank account to be used
   */
  static _getDefaultSystemBankAlias = ({
    systemRegistrationNumber, bankCurrency, orgCountryCode,
  }) => {
    if (!systemRegistrationNumber) {
      throw new Error('systemRegistrationNumber is required');
    }
    const stringSystemNumber = systemRegistrationNumber.toString();
    let prefix;
    if (stringSystemNumber === SYSTEM_BANK_BUSINESS_NUMBER.TD.toString()) {
      prefix = 'TD';
    } else if (stringSystemNumber === SYSTEM_BANK_BUSINESS_NUMBER.SERVICES.toString()) {
      prefix = 'SERVICES';
    } else {
      throw new Error(`unknown system number ${systemRegistrationNumber}`);
    }
    if (!bankCurrency) {
      throw new Error('bankCurrency is required');
    }
    if (!orgCountryCode) {
      throw new Error('orgCountryCode is required');
    }
    const commonType = (() => {
      const canProcessUSDLocal = ['US'].includes(orgCountryCode);
      const canProcessGBP = ['GB', 'IN'].includes(orgCountryCode);
      const canProcessCAD = ['CA'].includes(orgCountryCode);
      const canProcessNZD = ['NZ'].includes(orgCountryCode);
      const canReceiveTransferwise = !['MY'].includes(orgCountryCode);
      switch (bankCurrency) {
        case CURRENCY.EUR:
          return 'WISE_EUR';
        case CURRENCY.GBP:
          if (canProcessGBP) {
            return 'WISE_GBP';
          }
          return 'BARCLAYS_GBP';
        case CURRENCY.USD:
          if (canProcessUSDLocal) {
            return 'WISE_USD_LOCAL';
          }
          if (canReceiveTransferwise) {
            return 'WISE_USD';
          }
          return 'BARCLAYS_USD';
        case CURRENCY.CAD:
          if (canProcessCAD) {
            return 'WISE_CAD_LOCAL';
          }
          return 'WISE_GBP';
        case CURRENCY.NZD:
          if (canProcessNZD) {
            return 'WISE_NZD_LOCAL';
          }
          return 'BARCLAYS_GBP';
        case CURRENCY.JPY:
          return 'REVOLUT_JPY';
        case CURRENCY.HKD:
          return 'REVOLUT_HKD';
        default:
          return 'BARCLAYS_GBP';
      }
    })();

    const type = SYSTEM_BANK_TYPE[`${prefix}_${commonType}`];

    if (!type) {
      throw new Error('cannot resolve type');
    }

    return type;
  };

  constructor(...args) {
    if (args[0] instanceof InvoicingSettings) {
      return args[0];
    }
    this.init(...args);
  }

  init({
    id, orgId, orgOwnerId, orgAlias, mode, currency, targetCurrency, billableExpenses, feeScheme,
    vatPercent, onTrial, trialExpiresAt, paidTrial, countryCode, revolutAccountOverrideId,
    invoicingDays, serializedInvoicingFrequency,
    chargeBankFees, targetSystemCurrency, balanceCurrency,
    transactionMode, apiAccessEnabled, codatIntegrationsStatus, hideProvidersInvoicePaymentDetails,
    systemNumber, invoicingOutboundTemplate,
    invoicingOutboundTemplateDescription, invoicingFXMarkUp, invoicingDefaultPaymentProvider,
    aorOnboardingInvitationsEnabled,
    systemBankAccountOverrides = [],
    // for backwards compatibility
    taxMethod = TAX_METHOD.SUBTOTAL,
  }) {
    this.id = id;
    if (!orgId) {
      throw new Error('org id is required');
    }
    if (!systemNumber) {
      throw new Error('system registration number is required');
    }
    if (!countryCode) {
      throw new Error('country code is required');
    }
    this.orgId = orgId;
    this.orgOwnerId = orgOwnerId;
    this.orgAlias = orgAlias;
    this.currency = currency;
    this.targetCurrency = targetCurrency || currency;
    this.mode = mode;
    this.feeScheme = new InvoicingFeeScheme(feeScheme, { currency });
    if (!this.feeScheme.getProcessingScheme().getCurrency()
      || !this.feeScheme.getLicenceScheme().getCurrency()
    ) {
      throw new Error('missing currency for invoicing settings fee scheme');
    }
    this.billableExpenses = billableExpenses;
    this.chargeBankFees = chargeBankFees;
    this.vatPercent = vatPercent;
    this.onTrial = onTrial;
    this.trialExpiresAt = moment(trialExpiresAt).toISOString();
    this.paidTrial = paidTrial;
    this.countryCode = countryCode;
    this.revolutAccountOverrideId = revolutAccountOverrideId;
    this.invoicingDays = invoicingDays;
    this.serializedInvoicingFrequency = serializedInvoicingFrequency;
    this.balanceCurrency = balanceCurrency || currency;
    this.transactionMode = transactionMode;
    this.targetSystemCurrency = targetSystemCurrency;
    this.systemNumber = systemNumber;
    this.invoicingOutboundTemplate = invoicingOutboundTemplate;
    this.invoicingOutboundTemplateDescription = invoicingOutboundTemplateDescription;
    this.aorOnboardingInvitationsEnabled = aorOnboardingInvitationsEnabled;
    this.invoicingFXMarkUp = invoicingFXMarkUp || INITIAL_INVOICING_SETTINGS_FX_MARKUP_AS_A_PERCENT;
    this.invoicingDefaultPaymentProvider = invoicingDefaultPaymentProvider
      || DEFAULT_PAYMENT_PROVIDERS.WISE;
    this.taxMethod = parseInt(taxMethod, 10);
    this.apiAccessEnabled = apiAccessEnabled;
    this.codatIntegrationsStatus = codatIntegrationsStatus;
    this.hideProvidersInvoicePaymentDetails = hideProvidersInvoicePaymentDetails;
    this.systemBankAccountOverrides = systemBankAccountOverrides;
  }

  serialize() {
    return {
      ...pick(this, [
        'orgId', 'systemNumber', 'orgOwnerId', 'orgAlias', 'currency', 'targetCurrency', 'mode',
        'billableExpenses', 'chargeBankFees', 'vatPercent', 'onTrial', 'paidTrial', 'countryCode',
        'invoicingDays', 'serializedInvoicingFrequency', 'balanceCurrency', 'targetSystemCurrency',
        'transactionMode', 'revolutAccountOverrideId',
        'invoicingOutboundTemplate', 'invoicingOutboundTemplateDescription',
        'aorOnboardingInvitationsEnabled',
        'invoicingFXMarkUp', 'taxMethod', 'apiAccessEnabled', 'codatIntegrationsStatus',
        'systemBankAccountOverrides', 'hideProvidersInvoicePaymentDetails',
        'invoicingDefaultPaymentProvider',
      ]),
      trialExpiresAt: moment(this.trialExpiresAt).toISOString(),
      feeScheme: this.feeScheme.serialize(),
    };
  }

  worksheetWasOnFreeTrial({ worksheetCreatedAt }) {
    const { orgId, onTrial, trialExpiresAt, paidTrial } = this;
    const date = moment(worksheetCreatedAt);

    if (paidTrial) {
      return false;
    }

    if (!onTrial) {
      return false;
    }

    const wasOnTrial = moment(trialExpiresAt) > date;
    if (wasOnTrial) {
      logger.log(
        `no fee for org ${orgId} because they were on trial at ${date.format()}`,
        `till ${moment(trialExpiresAt).format()}`,
      );
    }
    return wasOnTrial;
  }

  worksheetIsBillable({ raisedBy, serviceOrderType, worksheetCreatedAt } = {}) {
    const { orgBillableExpenses } = this;

    if (raisedBy === RAISED_BY.PROVIDER) {
      // logger.log('no fee for invoice items of contractor invoices');
      return false;
    }

    if (serviceOrderType === SERVICE_ORDER_TYPE.EXPENSE && !orgBillableExpenses) {
      // logger.log('no fee for expenses when they are not billable');
      return false;
    }

    const onTrial = this.worksheetWasOnFreeTrial({ worksheetCreatedAt });

    if (onTrial) {
      return false;
    }
    return true;
  }

  getFeeScheme() {
    const { feeScheme } = this;
    return feeScheme;
  }

  getCurrency() {
    const { currency } = this;
    return currency;
  }

  getTargetCurrency() {
    const { targetCurrency } = this;
    return targetCurrency;
  }

  getBalanceCurrency() {
    const { balanceCurrency } = this;
    return balanceCurrency;
  }

  getTransactionMode() {
    const { transactionMode } = this;
    return transactionMode || TRANSACTION_MODE.FIXED_BALANCE;
  }

  getMode() {
    const { mode } = this;
    return mode;
  }

  getOrgId() {
    const { orgId } = this;
    return orgId;
  }

  getOrgOwnerId() {
    const { orgOwnerId } = this;
    return orgOwnerId;
  }

  getOrgAlias() {
    const { orgAlias } = this;
    return orgAlias;
  }

  getVatPercent(ownerFE, recipientFE) {
    const fe = new FinancialAssociation(ownerFE, recipientFE, this);
    return fe.getVatPercent();
  }

  getLicenceFee(...props) {
    const { feeScheme } = this;
    return {
      currency: feeScheme.getLicenceScheme().getCurrency(),
      licenceFee: feeScheme.getLicenceFee(...props),
      licenceFeeAnalysis: feeScheme.applyLicenceFee(...props),
    };
  }

  getProcessingFee({
    monthlyBillableAmount, currency, monthlyApprovedWorksheets,
    prepaidHistory, considerFloor,
  }) {
    const processingFeeScheme = this.feeScheme.getProcessingScheme();
    return processingFeeScheme.applyToInvoiceAmounts({
      invoicingMode: this.getMode(),
      monthlyBillableAmount, currency,
      monthlyApprovedWorksheets, prepaidHistory, considerFloor,
    });
  }

  aggregatePrepaidHistory({ prepaidHistory }) {
    const processingFeeScheme = this.feeScheme.getProcessingScheme();
    const currency = this.getCurrency();
    return processingFeeScheme.aggregatePrepaidHistory({ currency, prepaidHistory });
  }

  getInvoicingFrequency() {
    const { serializedInvoicingFrequency, invoicingDays } = this;
    if (serializedInvoicingFrequency) {
      return new InvoicingFrequency(serializedInvoicingFrequency);
    }
    if (invoicingDays) {
      return InvoicingFrequency.fromCalendarDays(invoicingDays);
    }
    throw new Error('invoicing settings with unknown invoicing frequency');
  }

  getOutboundInvoiceTemplate() {
    const { invoicingOutboundTemplate } = this;
    return invoicingOutboundTemplate
      || OUTBOUND_INVOICE_TEMPLATES.BASIC;
  }

  getFXMarkUp() {
    const { invoicingFXMarkUp } = this;
    return parseFloat(invoicingFXMarkUp) / 100.0;
  }

  /**
   * Get the default payment provider in these invoice settings.
   * @return {DEFAULT_PAYMENT_PROVIDERS} enum value for these settings.
   */
  getDefaultPaymentProvider() {
    const { invoicingDefaultPaymentProvider } = this;
    return invoicingDefaultPaymentProvider;
  }

  getTaxMethod() {
    return this.taxMethod;
  }

  isApiAccessEnabled() {
    return this.apiAccessEnabled;
  }

  getCodatIntegrationsStatus() {
    return this.codatIntegrationsStatus;
  }

  getAOROnboardingInvitationsEnabled() {
    return this.aorOnboardingInvitationsEnabled;
  }

  /**
   * Check if service is enabled for charging.
   *
   * @param {SERVICE_KEY_NAME} key - key for service to check.
   * @return {boolean} if charging is enabled for this service.
   */
  isServiceEnabledForServiceKey(key) {
    const feeScheme = this.getFeeScheme();
    return feeScheme?.getLicenceScheme()?.hasFeeForServiceKey(key) || false;
  }

  /**
   * A flag defining if payment details for all providers will show up in their invoices
   * @returns {*}
   */
  shouldHideProvidersInvoicePaymentDetails() {
    return this.hideProvidersInvoicePaymentDetails;
  }

  getSystemBankCurrency() {
    const { currency, targetCurrency, targetSystemCurrency } = this;
    return targetSystemCurrency || targetCurrency || currency;
  }

  getCountryCode() {
    const { countryCode } = this;
    return countryCode;
  }

  /**
   * Returns the system bank id for a given invoice type
   *
   * @param {INVOICE_TYPE} invoiceType - the type of the invoice
   * @param {String} systemRegistrationNumber - the system registration number used on this invoice
   * @returns {String} the system bank alias for this invoice type
   */
  getSystemBankAlias({ invoiceType, systemRegistrationNumber }) {
    if (!systemRegistrationNumber) {
      throw new Error('systemRegistrationNumber is required');
    }
    if (!invoiceType) {
      throw new Error('invoiceType is required');
    }
    const defaultAlias = InvoicingSettings._getDefaultSystemBankAlias({
      systemRegistrationNumber,
      bankCurrency: this.getSystemBankCurrency(),
      orgCountryCode: this.getCountryCode(),
    });
    return defaultAlias;
  }

  /**
   * Returns the system bank id overrides for a given invoice type
   *
   * @param {INVOICE_TYPE} invoiceType - the type of the invoice
   * @returns {Number} the system bank override ids for this invoice type
   */
  getSystemBankOverrides(invoiceType) {
    if (!invoiceType) {
      throw new Error('invoiceType is required');
    }
    const { systemBankAccountOverrides } = this;
    if (isEmpty(systemBankAccountOverrides)) {
      return null;
    }
    const _matchesOverride = (override) => {
      const validInvoiceTypes = INVOICE_SEGMENT_TYPES[override.invoiceSegment];
      if (!validInvoiceTypes) {
        throw new Error(`cannot find valid invoice types for segment ${override.invoiceSegment}`);
      }
      return validInvoiceTypes.includes(invoiceType);
    };
    const candidates = systemBankAccountOverrides.filter(_matchesOverride);
    return candidates.map(c => c.systemBankAccountId);
  }

  hasRevolutAccountOverride() {
    const { revolutAccountOverrideId } = this;
    return !!revolutAccountOverrideId;
  }

  getRevolutAccountOverrideId() {
    const { revolutAccountOverrideId } = this;
    return revolutAccountOverrideId;
  }
}

export default InvoicingSettings;
