import React from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { useDispatch, useSelector } from 'react-redux';

import FinanceTableSkeleton from 'finance/assets/js/skeletons/FinanceTableSkeleton.jsx';
import { getListState, listReplaceItemAC } from 'core/assets/js/ducks/list';
import { viewFetchExtrasAC } from 'core/assets/js/ducks/view';
import { worksheetSpec } from 'finance/assets/js/lib/objectSpecs';
import { CURRENCY_SYMBOL } from 'core/assets/js/constants';
import WorksheetsTable from 'finance/assets/js/components/WorksheetsTable.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import { withTDApiConnected } from 'core/assets/js/components/TDApiConnected.jsx';
import { fetchFinanceWorksheetListDS } from 'finance/assets/js/data-services/list';
import { selectActiveOrg } from 'organizations/assets/js/reducers/organizations';

export const COMPONENT_NAME = 'TaskWorksheetsTab';

const TaskWorksheetsTab = ({ items, parentComponentName }) => {
  const dispatch = useDispatch();
  const { pagination } = useSelector(state =>
    getListState(
      state,
      TaskWorksheetsTab.GetComponentName(),
    ),
  );
  const activeOrg = useSelector(selectActiveOrg);

  const currency = activeOrg?.currency;
  const rateUnit = activeOrg?.default_rate_unit;
  const currencySymbol = CURRENCY_SYMBOL[currency];
  const emptyText = 'No worksheets found';

  const onUpdated = (updatedItem, projectAllowedActions) => {
    // Update list item
    dispatch(listReplaceItemAC(updatedItem, TaskWorksheetsTab.GetComponentName()));

    // Update project Allowed actions.
    dispatch(viewFetchExtrasAC(projectAllowedActions, parentComponentName, 'allowedActions'));
  };

  return (
    <>
      <div
        className="finance-report finance-report__table-wrapper finance-report__table-wrapper--responsive finance-report__table-wrapper--list"
      >
        <WorksheetsTable
          list={items}
          currency={currencySymbol}
          rateUnit={rateUnit}
          emptyText={emptyText}
          onItemUpdate={onUpdated}
          embeddedMode
        />
      </div>

      <TDPagination {...pagination} />
    </>
  );
};

TaskWorksheetsTab.propTypes = {
  items: PropTypes.arrayOf(worksheetSpec).isRequired,
  parentComponentName: PropTypes.string.isRequired,
};

TaskWorksheetsTab.GetComponentName = () => COMPONENT_NAME;

export default withTDApiConnected({
  fetchData: ({
    dispatch, authedAxios, componentName, params, querystring, url,
  }) => dispatch(fetchFinanceWorksheetListDS({
    url,
    querystring: qs.stringify({ ...querystring, taskId: params.taskId }),
    params,
    componentName,
    authedAxios,
    dispatch,
  })),
  duck: 'list',
  storeKey: TaskWorksheetsTab.GetComponentName(),
  skeletonComponent: FinanceTableSkeleton,
})(TaskWorksheetsTab);
