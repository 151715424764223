import React  from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import { withRouter, Link } from 'react-router-dom';

import ProfilePic from 'core/assets/js/components/ProfilePic.jsx';
import { formatDate } from 'core/assets/js/lib/utils';
import ProjectApplicationStatusBar from 'projects/assets/js/components/ProjectApplicationStatusBar.jsx';
import ProjectBriefDescription from 'projects/assets/js/components/ProjectBriefDescription.jsx';
import { projectApplicationSpec } from 'projects/assets/js/lib/objectSpecs';
import {
  projectOpportunitiesApplyUrl,
  projectViewUrl,
  projectApplicationViewUrl,
  projectApplicationEditUrl,
} from 'projects/urls';
import { routerHistorySpec, routerMatchSpec } from 'core/assets/js/lib/objectSpecs';
import { BS_STYLE, PROJECT_TABS } from 'core/assets/js/constants';
import AreaCollapsible from 'core/assets/js/components/AreaCollapsible.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import SkillList from 'core/assets/js/components/SkillList.jsx';


const ProjectOpportunityCardItem = ({
  item: project, history, match: { params: { orgAlias } }, isManager,
}) => {
  const {
    allowedActions: { canManageInvitations, canViewProjectDetails, canApply, canReApply },
    id,
    application,
    applicationsCount,
    skills,
    deadline,
    owner_name,
    title,
    created_at,
    max_applicants,
    applicants,
  } = project;

  const cardHeader = (
    <div className="d-flex row">
      <div className="col my-0">
        <h3 className="project-opportunities-card__project-title">
          {canViewProjectDetails && (
            <Link to={projectViewUrl(orgAlias, id)}>{title}</Link>
          )}
          {!canViewProjectDetails && title}
        </h3>
        <aside className="discreet">
          <div className="mb-2">
            {`Posted on ${formatDate(created_at)} by ${owner_name}`}
          </div>

          <div className="d-flex align-items-center">
            { applicants && applicants.length > 0 && (
              <div className="project-opportunities-card__applicants mr-3">
                { applicants.slice(0, 5).map((
                  { user: { id: applicantId, profile: { avatar, name } } },
                ) => (
                  <ProfilePic
                    key={applicantId}
                    url={avatar}
                    alt={name}
                    size={[20, 20]}
                  />
                ))}
              </div>
            )}

            {isManager && max_applicants > 0 && (
              <span className="mr-4">
                {`${applicationsCount || 0}/${max_applicants} applicants`}
              </span>
            )}

            {deadline && (
              <span>
                {`Deadline ${formatDate(deadline)}`}
              </span>
            )}
          </div>
        </aside>
      </div>

      <div className="float-right action-buttons col-auto">
        {canApply && (
          <TDButton
            variant={BS_STYLE.PRIMARY}
            rounded
            label={canReApply ? 'Reapply' : 'Apply'}
            onClick={() => {
              if (canReApply) {
                history.push(projectApplicationEditUrl(orgAlias, id, application.id));
              } else {
                history.push(projectOpportunitiesApplyUrl(orgAlias, id));
              }
            }}
          />
        )}

        {application && !canApply && (
          <TDButton
            variant={BS_STYLE.PRIMARY}
            rounded
            label="View application"
            onClick={() => {
              history.push(projectApplicationViewUrl(orgAlias, id, application.id));
            }}
          />
        )}

        {canManageInvitations && (
          <TDButton
            variant={BS_STYLE.PRIMARY}
            rounded
            label="View Applications"
            onClick={() => {
              history.push(projectViewUrl(orgAlias, id, PROJECT_TABS.APPLICATIONS));
            }}
          />
        )}
      </div>
    </div>
  );

  const headingChildren = (
    <div className="col p-0">
      <SkillList
        skills={skills}
        inline
        maxShownItems={10}
        modalId={`project-skills-${id}`}
      />
    </div>
  );

  const contentChildren = (
    <React.Fragment>
      <ProjectBriefDescription project={project} showSkills={false} />

      <h4 className="mt-5 mb-2">Skills</h4>
    </React.Fragment>
  );

  return (
    <Card
      className="project-opportunities-card"
    >
      <Card.Header className="pb-3 position-relative">
        {cardHeader}

        { application && (
          <ProjectApplicationStatusBar application={application} />
        )}
      </Card.Header>

      <Card.Body className="py-3">
        <AreaCollapsible
          headingClassName="m-0 row p-0"
          contentChildren={contentChildren}
          headingChildren={headingChildren}
          toggleElementPlacement="right"
          toggleAfterCard
        />
      </Card.Body>
    </Card>
  );
};

ProjectOpportunityCardItem.propTypes = {
  item: projectApplicationSpec.isRequired,
  history: routerHistorySpec.isRequired,
  match: routerMatchSpec.isRequired,
  isManager: PropTypes.bool,
};
ProjectOpportunityCardItem.defaultProps = {
  isManager: true,
};

export default withRouter(ProjectOpportunityCardItem);
