import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Dropdown, Modal } from 'react-bootstrap';
import { Field, reduxForm, initialize } from 'redux-form';
import { toastr } from 'react-redux-toastr';

import { BS_STYLE } from 'core/assets/js/constants';
import { listUpdateItemAC } from 'core/assets/js/ducks/list';
import { modalOpenAC, modalCloseAC, getIsModalOpen } from 'core/assets/js/ducks/modalLauncher';
import { renameRateDS } from 'rates/assets/js/data-services/rates';
import InputField from 'core/assets/js/components/ReduxFormFields/InputField.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import { rateSpec } from 'rates/assets/js/lib/objectSpecs';

export const getModalId = rateId => `rename-modal-${rateId}`;


class RenameModal extends React.Component {
  constructor(props) {
    super(props);

    this.handleModalClose = this.handleModalClose.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  async onSubmit(values) {
    try {
      const { dispatch, listComponentName, orgAlias, rate } = this.props;
      const { alias } = values;
      const updatedRate = await dispatch(renameRateDS(orgAlias, rate.id, { alias }));

      toastr.success('Well Done!', `The new rate alias is "${updatedRate.alias}"`);
      this.handleModalClose();

      dispatch(listUpdateItemAC(
        updatedRate.id, { alias: updatedRate.alias }, listComponentName,
      ));
    } catch (e) {
      toastr.error('Oh Snap!', e._error || e.message);
    }
  }

  handleModalClose() {
    const { dispatch } = this.props;

    dispatch(modalCloseAC());
  }

  render() {
    const { alias, handleSubmit, isModalOpen, pristine, rate, submitting } = this.props;

    const { canRename } = rate.allowedActions;

    return (
      <form>
        <Modal data-testid="rate-rename-modal" show={isModalOpen} onHide={this.handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>{`Rename rate "${alias}"`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                {canRename && (
                  <Field
                    component={InputField}
                    label="Alias"
                    name="alias"
                    type="text"
                  />
                )}
                {!canRename && (
                  <p>You do not have permission to rename this rate</p>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <TDButton
              onClick={this.handleModalClose}
              label="Cancel"
            />
            {canRename && (
              <TDButton
                type="submit"
                variant={BS_STYLE.PRIMARY}
                disabled={pristine || submitting}
                onClick={handleSubmit(this.onSubmit)}
                label="Save"
              />
            )}
          </Modal.Footer>
        </Modal>
      </form>
    );
  }
}

RenameModal.propTypes = {
  alias: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  listComponentName: PropTypes.string.isRequired,
  modalId: PropTypes.string.isRequired,
  orgAlias: PropTypes.string.isRequired,
  pristine: PropTypes.bool.isRequired,
  rate: PropTypes.shape(rateSpec).isRequired,
  submitting: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool,
};

RenameModal.defaultProps = {
  isModalOpen: false,
};

const MODAL_FORM = 'rateRenameForm';
const ModalForm = reduxForm({
  form: MODAL_FORM,
})(RenameModal);

const ModalConnected = connect(
  (state, props) => {
    const { alias, rateId } = props;
    const modalId = getModalId(rateId);

    return {
      modalId,
      isModalOpen: getIsModalOpen(state, modalId),
      initialValues: {
        alias,
      },
    };
  },
  dispatch => ({ dispatch }),
)(ModalForm);


const DropdownItem = ({
  alias, dispatch, rateId,
}) => {
  const modalId = getModalId(rateId);
  return (
    <Dropdown.Item
      eventKey="rename"
      onClick={() => {
        // Reinitialize the form using the new values and open the modal
        dispatch(initialize(MODAL_FORM, { alias }));
        dispatch(modalOpenAC(modalId));
      }}
    >
      Rename
    </Dropdown.Item>
  );
};

DropdownItem.propTypes = {
  alias: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  rateId: PropTypes.number.isRequired,
};

const DropdownItemConnected = connect(
  null,
  dispatch => ({ dispatch }),
)(DropdownItem);

const RateRenameOption = {
  DropdownOption: DropdownItemConnected,
  Modal: ModalConnected,
};

export default RateRenameOption;
