import PropTypes from 'prop-types';
import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link,  useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import ListSkeleton from 'core/assets/js/components/Skeleton/ListSkeleton.jsx';
import RolesSearch from 'roles/assets/js/components/RolesSearch.jsx';
import TDButton from 'core/assets/js/components/TDButton.jsx';
import TDDropButton from 'core/assets/js/components/TDDropButton.jsx';
import TDPagination from 'core/assets/js/components/TDPagination.jsx';
import withFilters from 'core/assets/js/components/withFilters.jsx';
import { BS_STYLE, ICON } from 'core/assets/js/constants';
import { DELETE_MODAL_ID } from 'roles/assets/js/components/DeleteRoleModal.jsx';
import { ROLE_TABS, SYSTEM_ROLES } from 'roles/assets/js/constants';
import { modalOpenAC } from 'core/assets/js/ducks/modalLauncher';
import { roleCreateUrl, roleDuplicateUrl, roleEditUrl, rolesListApiUrl, systemRolesListApiUrl, systemRoleViewUrl } from 'roles/urls';
import RoleCard from 'roles/assets/js/components/RoleCard.jsx';
import { fetchDataHook } from 'core/assets/js/ducks/hooks';

/**
 * Roles listing table
 * @param {Object} params
 * @param {Boolean} params.filtersOpen Indicates if filters are open
 * @param {Function} params.onFiltersToggle Filters toggle callback function
 *
 * @returns {JSX.Element}
 */
const RolesTable = ({
  filtersOpen,
  onFiltersToggle,
}) => {
  const { orgAlias } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { hasLoaded: hasLoadedSystemRole, items: systemRoles } = fetchDataHook({
    componentName: `${RolesTable.GetComponentName()}-systemRoles`,
    duck: 'list',
    url: systemRolesListApiUrl(orgAlias),
  });

  const {
    hasLoaded: hasLoadedRoles,
    items: roles,
    pagination,
    search: { isActive },
  } = fetchDataHook({
    componentName: `${RolesTable.GetComponentName()}-roles`,
    duck: 'list',
    url: rolesListApiUrl(orgAlias),
  });

  const actionsRowFormatter = (role, systemRole = '') => {
    const isSystemRole = Object.values(SYSTEM_ROLES).includes(systemRole);
    return (
      <TDDropButton
        data-testid="roles-list-item-actions"
        overlayClassName="roles-list__item-actions"
        popperConfig={{
          modifiers: {
            preventOverflow: {
              enabled: false,
            },
          },
        }}
        toggleEl={(
          <TDButton
            className="px-4 ml-auto"
            btnIcon={ICON.CHEVRON_DOWN}
          />
        )}
      >
        {(isSystemRole || role?.allowedActions?.canEdit) && (
          <Dropdown.Item
            onClick={() => history.push(
              isSystemRole
                ? systemRoleViewUrl(orgAlias, systemRole, ROLE_TABS.CONFIG)
                : roleEditUrl(orgAlias, role?.id, ROLE_TABS.CONFIG),
            )}
          >
            Open
          </Dropdown.Item>
        )}
        <Dropdown.Item
          onClick={() => history.push(roleDuplicateUrl(
            orgAlias, isSystemRole ? systemRole : role?.id,
          ))}
        >
          Duplicate
        </Dropdown.Item>
        {!isSystemRole && role?.allowedActions?.canDelete && (
          <Dropdown.Item
            className="text-danger"
            onClick={() => dispatch(modalOpenAC(DELETE_MODAL_ID, role))}
          >
            Delete
          </Dropdown.Item>
        )}
      </TDDropButton>
    );
  };

  const formatUsers = (role, systemRole = '') => {
    const isSystemRole = Object.values(SYSTEM_ROLES).includes(systemRole);
    if (isSystemRole) {
      return `${role.membersCount} members`;
    }

    const {
      id,
      membersCount,
      allowedActions: {
        canEdit,
      },
    } = role;

    if (!canEdit) {
      return membersCount;
    }

    return (
      <Link
        className="text-dark"
        to={roleEditUrl(orgAlias, id, ROLE_TABS.USERS)}
      >
        {`${membersCount} members`}
      </Link>
    );
  };

  const formatName = (role, systemRole = '') => {
    const isSystemRole = Object.values(SYSTEM_ROLES).includes(systemRole);
    const {
      name,
      id,
    } = role;

    if (isSystemRole) {
      return name;
    }

    return (
      <Link
        to={() => roleEditUrl(orgAlias, id)}
      >
        {name}
      </Link>
    );
  };

  if (!hasLoadedSystemRole || !hasLoadedRoles) {
    return <ListSkeleton />;
  }

  return (
    <div
      className="roles-table"
      data-testid="roles-table"
    >
      {isActive && (
        <div className="row">
          <RolesSearch
            onFiltersToggle={onFiltersToggle}
            filtersOpen={filtersOpen}
          />
        </div>
      )}

      <div>
        <h2 className="mt-2 mb-3">Default roles</h2>
        {Object.values(SYSTEM_ROLES).map(systemRole => (
          <RoleCard
            actionsRowFormatter={() => actionsRowFormatter(systemRoles[systemRole], systemRole)}
            formatName={() => formatName(systemRoles[systemRole], systemRole)}
            formatUsers={() => formatUsers(systemRoles[systemRole], systemRole)}
            role={systemRoles[systemRole]}
            systemRole={systemRole}
            key={systemRole}
          />
        ))}
        <h2 className="mt-5 mb-3">Custom roles</h2>
        {roles.length > 0 ? (
          roles.map(role => (
            <RoleCard
              actionsRowFormatter={() => actionsRowFormatter(role)}
              formatName={() => formatName(role)}
              formatUsers={() => formatUsers(role)}
              role={role}
              key={role.id}
            />
          ))
        ) : (
          <div
            className="empty-list-message rounded shadow-sm p-4 bg-white d-flex align-center align-items-center"
            data-testid="roles-table-empty-message"
          >
            <div className="w-100 text-center">
              <i className={`${ICON.USERS_GEAR_DUOTONE} mr-4`} />
              <br />
              You haven&apos;t added any roles yet
              <br />
              <TDButton
                className="mt-4"
                data-testid="create-role-cta"
                onClick={() => history.push(roleCreateUrl(orgAlias))}
                variant={BS_STYLE.PRIMARY}
              >
                Create role
              </TDButton>
            </div>
          </div>
        )}
      </div>

      <TDPagination {...pagination} />
    </div>
  );
};

RolesTable.GetComponentName = () => 'RolesListView';

RolesTable.propTypes = {
  filtersOpen: PropTypes.bool.isRequired,
  onFiltersToggle: PropTypes.func.isRequired,
};

export default withFilters(RolesTable);
